const worldMap = {
  "crs": {
    "type": "fullname",
    "properties": {
      "fullname": "urn:ogc:def:crs:EPSG:540003"
    }
  },
  "type": "FeatureCollection",
  "features": [
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3343,
              2238
            ],
            [
              3359,
              2229
            ],
            [
              3354,
              2208
            ],
            [
              3354,
              2206
            ],
            [
              3356,
              2206
            ],
            [
              3359,
              2204
            ],
            [
              3360,
              2205
            ],
            [
              3361,
              2206
            ],
            [
              3368,
              2208
            ],
            [
              3370,
              2208
            ],
            [
              3372,
              2207
            ],
            [
              3373,
              2205
            ],
            [
              3373,
              2204
            ],
            [
              3373,
              2199
            ],
            [
              3368,
              2183
            ],
            [
              3364,
              2151
            ],
            [
              3365,
              2150
            ],
            [
              3366,
              2148
            ],
            [
              3366,
              2147
            ],
            [
              3366,
              2143
            ],
            [
              3374,
              2128
            ],
            [
              3377,
              2127
            ],
            [
              3382,
              2126
            ],
            [
              3387,
              2128
            ],
            [
              3467,
              2176
            ],
            [
              3517,
              2168
            ],
            [
              3513,
              2136
            ],
            [
              3505,
              2136
            ],
            [
              3500,
              2139
            ],
            [
              3488,
              2140
            ],
            [
              3428,
              2135
            ],
            [
              3408,
              2129
            ],
            [
              3406,
              2125
            ],
            [
              3399,
              2122
            ],
            [
              3389,
              2115
            ],
            [
              3384,
              2114
            ],
            [
              3383,
              2114
            ],
            [
              3383,
              2111
            ],
            [
              3381,
              2108
            ],
            [
              3375,
              2112
            ],
            [
              3350,
              2085
            ],
            [
              3359,
              2080
            ],
            [
              3373,
              2054
            ],
            [
              3373,
              2051
            ],
            [
              3373,
              2049
            ],
            [
              3374,
              2048
            ],
            [
              3375,
              2047
            ],
            [
              3375,
              2045
            ],
            [
              3374,
              2045
            ],
            [
              3371,
              2042
            ],
            [
              3370,
              2040
            ],
            [
              3370,
              2039
            ],
            [
              3374,
              2034
            ],
            [
              3375,
              2032
            ],
            [
              3376,
              2031
            ],
            [
              3375,
              2030
            ],
            [
              3374,
              2028
            ],
            [
              3370,
              2026
            ],
            [
              3368,
              2024
            ],
            [
              3366,
              2016
            ],
            [
              3358,
              2013
            ],
            [
              3356,
              2012
            ],
            [
              3345,
              1999
            ],
            [
              3347,
              1979
            ],
            [
              3347,
              1977
            ],
            [
              3345,
              1973
            ],
            [
              3346,
              1966
            ],
            [
              3344,
              1964
            ],
            [
              3344,
              1963
            ],
            [
              3344,
              1961
            ],
            [
              3344,
              1960
            ],
            [
              3343,
              1959
            ],
            [
              3332,
              1954
            ],
            [
              3313,
              1953
            ],
            [
              3284,
              1959
            ],
            [
              3301,
              1914
            ],
            [
              3291,
              1906
            ],
            [
              3290,
              1906
            ],
            [
              3287,
              1906
            ],
            [
              3287,
              1906
            ],
            [
              3285,
              1903
            ],
            [
              3277,
              1900
            ],
            [
              3271,
              1901
            ],
            [
              3267,
              1899
            ],
            [
              3264,
              1899
            ],
            [
              3262,
              1899
            ],
            [
              3261,
              1899
            ],
            [
              3259,
              1899
            ],
            [
              3257,
              1898
            ],
            [
              3254,
              1894
            ],
            [
              3255,
              1887
            ],
            [
              3255,
              1885
            ],
            [
              3252,
              1873
            ],
            [
              3251,
              1871
            ],
            [
              3248,
              1867
            ],
            [
              3247,
              1866
            ],
            [
              3245,
              1866
            ],
            [
              3244,
              1865
            ],
            [
              3241,
              1858
            ],
            [
              3242,
              1840
            ],
            [
              3243,
              1834
            ],
            [
              3245,
              1830
            ],
            [
              3245,
              1829
            ],
            [
              3245,
              1828
            ],
            [
              3245,
              1828
            ],
            [
              3242,
              1826
            ],
            [
              3241,
              1825
            ],
            [
              3235,
              1816
            ],
            [
              3234,
              1815
            ],
            [
              3233,
              1814
            ],
            [
              3232,
              1813
            ],
            [
              3230,
              1812
            ],
            [
              3228,
              1810
            ],
            [
              3227,
              1810
            ],
            [
              3225,
              1810
            ],
            [
              3223,
              1809
            ],
            [
              3219,
              1808
            ],
            [
              3216,
              1808
            ],
            [
              3210,
              1818
            ],
            [
              3202,
              1821
            ],
            [
              3199,
              1821
            ],
            [
              3197,
              1819
            ],
            [
              3198,
              1817
            ],
            [
              3201,
              1817
            ],
            [
              3203,
              1817
            ],
            [
              3203,
              1817
            ],
            [
              3202,
              1816
            ],
            [
              3201,
              1815
            ],
            [
              3200,
              1815
            ],
            [
              3192,
              1817
            ],
            [
              3181,
              1821
            ],
            [
              3136,
              1783
            ],
            [
              3113,
              1790
            ],
            [
              3107,
              1787
            ],
            [
              3106,
              1786
            ],
            [
              3104,
              1785
            ],
            [
              3103,
              1784
            ],
            [
              3101,
              1783
            ],
            [
              3099,
              1782
            ],
            [
              3098,
              1775
            ],
            [
              3096,
              1774
            ],
            [
              3091,
              1769
            ],
            [
              3090,
              1768
            ],
            [
              3082,
              1767
            ],
            [
              3081,
              1766
            ],
            [
              3081,
              1766
            ],
            [
              3075,
              1746
            ],
            [
              3075,
              1743
            ],
            [
              3078,
              1738
            ],
            [
              3078,
              1737
            ],
            [
              3078,
              1736
            ],
            [
              3077,
              1728
            ],
            [
              3077,
              1723
            ],
            [
              3074,
              1716
            ],
            [
              3073,
              1714
            ],
            [
              3073,
              1713
            ],
            [
              3073,
              1712
            ],
            [
              3071,
              1699
            ],
            [
              3007,
              1681
            ],
            [
              3004,
              1681
            ],
            [
              2995,
              1683
            ],
            [
              2975,
              1683
            ],
            [
              2953,
              1672
            ],
            [
              2864,
              1673
            ],
            [
              2858,
              1674
            ],
            [
              2774,
              1700
            ],
            [
              2772,
              1700
            ],
            [
              2775,
              1704
            ],
            [
              2780,
              1709
            ],
            [
              2780,
              1709
            ],
            [
              2807,
              1739
            ],
            [
              2825,
              1760
            ],
            [
              2826,
              1763
            ],
            [
              2826,
              1767
            ],
            [
              2826,
              1768
            ],
            [
              2827,
              1770
            ],
            [
              2827,
              1772
            ],
            [
              2823,
              1789
            ],
            [
              2820,
              1792
            ],
            [
              2819,
              1793
            ],
            [
              2818,
              1794
            ],
            [
              2815,
              1794
            ],
            [
              2808,
              1795
            ],
            [
              2773,
              1800
            ],
            [
              2768,
              1834
            ],
            [
              2770,
              1836
            ],
            [
              2771,
              1837
            ],
            [
              2771,
              1842
            ],
            [
              2771,
              1847
            ],
            [
              2757,
              1894
            ],
            [
              2756,
              1898
            ],
            [
              2757,
              1903
            ],
            [
              2752,
              1962
            ],
            [
              2765,
              1990
            ],
            [
              2769,
              1991
            ],
            [
              2771,
              1992
            ],
            [
              2772,
              1992
            ],
            [
              2774,
              1995
            ],
            [
              2775,
              1996
            ],
            [
              2775,
              1996
            ],
            [
              2776,
              1996
            ],
            [
              2778,
              1997
            ],
            [
              2779,
              2002
            ],
            [
              2780,
              2003
            ],
            [
              2781,
              2004
            ],
            [
              2785,
              2008
            ],
            [
              2811,
              2048
            ],
            [
              2839,
              2046
            ],
            [
              2872,
              2034
            ],
            [
              2912,
              2074
            ],
            [
              2936,
              2081
            ],
            [
              2951,
              2088
            ],
            [
              2951,
              2089
            ],
            [
              2952,
              2090
            ],
            [
              2956,
              2092
            ],
            [
              2964,
              2093
            ],
            [
              2964,
              2093
            ],
            [
              2965,
              2096
            ],
            [
              2966,
              2096
            ],
            [
              2966,
              2097
            ],
            [
              2968,
              2097
            ],
            [
              2972,
              2098
            ],
            [
              2974,
              2099
            ],
            [
              2975,
              2100
            ],
            [
              2977,
              2101
            ],
            [
              2981,
              2108
            ],
            [
              2982,
              2110
            ],
            [
              2983,
              2112
            ],
            [
              2991,
              2153
            ],
            [
              3008,
              2162
            ],
            [
              3032,
              2162
            ],
            [
              3033,
              2162
            ],
            [
              3035,
              2163
            ],
            [
              3037,
              2167
            ],
            [
              3039,
              2168
            ],
            [
              3039,
              2169
            ],
            [
              3040,
              2170
            ],
            [
              3040,
              2172
            ],
            [
              3039,
              2174
            ],
            [
              3042,
              2180
            ],
            [
              3077,
              2168
            ],
            [
              3078,
              2168
            ],
            [
              3082,
              2169
            ],
            [
              3086,
              2168
            ],
            [
              3087,
              2168
            ],
            [
              3088,
              2169
            ],
            [
              3089,
              2170
            ],
            [
              3090,
              2170
            ],
            [
              3091,
              2171
            ],
            [
              3092,
              2171
            ],
            [
              3093,
              2170
            ],
            [
              3099,
              2169
            ],
            [
              3102,
              2170
            ],
            [
              3117,
              2171
            ],
            [
              3118,
              2171
            ],
            [
              3120,
              2170
            ],
            [
              3122,
              2169
            ],
            [
              3123,
              2165
            ],
            [
              3130,
              2159
            ],
            [
              3159,
              2161
            ],
            [
              3160,
              2153
            ],
            [
              3173,
              2142
            ],
            [
              3293,
              2204
            ],
            [
              3298,
              2209
            ],
            [
              3302,
              2212
            ],
            [
              3307,
              2217
            ],
            [
              3310,
              2218
            ],
            [
              3310,
              2219
            ],
            [
              3311,
              2220
            ],
            [
              3317,
              2233
            ],
            [
              3322,
              2235
            ],
            [
              3321,
              2237
            ],
            [
              3321,
              2237
            ],
            [
              3322,
              2238
            ],
            [
              3326,
              2238
            ],
            [
              3327,
              2240
            ],
            [
              3343,
              2238
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Asia",
        "fullname": "Afghanistan",
        "admin": "Afghanistan",
        "woe_id": 23424739,
        "id": "AF",
        "abbrev": "Afg.",
        "subregion": "Southern Asia",
        "region_wb": "South Asia",
        "iso_a3": "AFG",
        "name": "AF",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                710,
                -628
              ],
              [
                711,
                -631
              ],
              [
                712,
                -635
              ],
              [
                713,
                -651
              ],
              [
                716,
                -658
              ],
              [
                716,
                -659
              ],
              [
                715,
                -659
              ],
              [
                713,
                -662
              ],
              [
                710,
                -671
              ],
              [
                710,
                -703
              ],
              [
                714,
                -714
              ],
              [
                712,
                -749
              ],
              [
                599,
                -749
              ],
              [
                599,
                -887
              ],
              [
                600,
                -930
              ],
              [
                609,
                -955
              ],
              [
                643,
                -987
              ],
              [
                649,
                -992
              ],
              [
                653,
                -994
              ],
              [
                655,
                -994
              ],
              [
                656,
                -995
              ],
              [
                659,
                -997
              ],
              [
                660,
                -1001
              ],
              [
                668,
                -1009
              ],
              [
                673,
                -1009
              ],
              [
                673,
                -1009
              ],
              [
                674,
                -1009
              ],
              [
                675,
                -1010
              ],
              [
                676,
                -1012
              ],
              [
                678,
                -1013
              ],
              [
                677,
                -1013
              ],
              [
                678,
                -1015
              ],
              [
                673,
                -1016
              ],
              [
                662,
                -1018
              ],
              [
                627,
                -1025
              ],
              [
                599,
                -1030
              ],
              [
                585,
                -1033
              ],
              [
                571,
                -1035
              ],
              [
                567,
                -1036
              ],
              [
                534,
                -1037
              ],
              [
                507,
                -1027
              ],
              [
                491,
                -1030
              ],
              [
                434,
                -1025
              ],
              [
                427,
                -1024
              ],
              [
                422,
                -1022
              ],
              [
                419,
                -1021
              ],
              [
                411,
                -1012
              ],
              [
                409,
                -1010
              ],
              [
                408,
                -1009
              ],
              [
                408,
                -1009
              ],
              [
                402,
                -1000
              ],
              [
                399,
                -1000
              ],
              [
                384,
                -1000
              ],
              [
                377,
                -1000
              ],
              [
                370,
                -1000
              ],
              [
                356,
                -1000
              ],
              [
                341,
                -1000
              ],
              [
                284,
                -1000
              ],
              [
                255,
                -1000
              ],
              [
                165,
                -1000
              ],
              [
                150,
                -1001
              ],
              [
                118,
                -976
              ],
              [
                72,
                -992
              ],
              [
                47,
                -986
              ],
              [
                38,
                -988
              ],
              [
                35,
                -990
              ],
              [
                32,
                -992
              ],
              [
                31,
                -993
              ],
              [
                30,
                -993
              ],
              [
                29,
                -993
              ],
              [
                28,
                -992
              ],
              [
                28,
                -991
              ],
              [
                27,
                -915
              ],
              [
                57,
                -849
              ],
              [
                70,
                -774
              ],
              [
                133,
                -706
              ],
              [
                139,
                -616
              ],
              [
                97,
                -528
              ],
              [
                118,
                -484
              ],
              [
                87,
                -407
              ],
              [
                57,
                -362
              ],
              [
                108,
                -346
              ],
              [
                283,
                -346
              ],
              [
                351,
                -471
              ],
              [
                453,
                -466
              ],
              [
                462,
                -410
              ],
              [
                518,
                -426
              ],
              [
                588,
                -426
              ],
              [
                597,
                -489
              ],
              [
                592,
                -557
              ],
              [
                618,
                -600
              ],
              [
                610,
                -627
              ],
              [
                657,
                -641
              ],
              [
                710,
                -628
              ]
            ]
          ],
          [
            [
              [
                101,
                -277
              ],
              [
                101,
                -279
              ],
              [
                99,
                -280
              ],
              [
                88,
                -282
              ],
              [
                71,
                -307
              ],
              [
                53,
                -341
              ],
              [
                54,
                -325
              ],
              [
                48,
                -308
              ],
              [
                43,
                -300
              ],
              [
                48,
                -302
              ],
              [
                49,
                -298
              ],
              [
                45,
                -299
              ],
              [
                44,
                -299
              ],
              [
                43,
                -299
              ],
              [
                42,
                -299
              ],
              [
                42,
                -298
              ],
              [
                49,
                -293
              ],
              [
                50,
                -290
              ],
              [
                52,
                -285
              ],
              [
                52,
                -284
              ],
              [
                53,
                -284
              ],
              [
                54,
                -287
              ],
              [
                62,
                -276
              ],
              [
                75,
                -273
              ],
              [
                79,
                -269
              ],
              [
                80,
                -268
              ],
              [
                81,
                -268
              ],
              [
                82,
                -267
              ],
              [
                84,
                -264
              ],
              [
                86,
                -265
              ],
              [
                87,
                -265
              ],
              [
                89,
                -264
              ],
              [
                92,
                -269
              ],
              [
                99,
                -276
              ],
              [
                101,
                -277
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Angola",
        "admin": "Angola",
        "woe_id": 23424745,
        "id": "AO",
        "abbrev": "Ang.",
        "subregion": "Middle Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "AGO",
        "name": "AO",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              492,
              2511
            ],
            [
              520,
              2466
            ],
            [
              521,
              2414
            ],
            [
              543,
              2398
            ],
            [
              500,
              2318
            ],
            [
              450,
              2384
            ],
            [
              467,
              2450
            ],
            [
              453,
              2464
            ],
            [
              492,
              2511
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Europe",
        "fullname": "Albania",
        "admin": "Albania",
        "woe_id": 23424742,
        "id": "AL",
        "abbrev": "Alb.",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "ALB",
        "name": "AL",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2517,
              1445
            ],
            [
              2523,
              1407
            ],
            [
              2491,
              1369
            ],
            [
              2456,
              1272
            ],
            [
              2310,
              1286
            ],
            [
              2254,
              1364
            ],
            [
              2381,
              1352
            ],
            [
              2411,
              1364
            ],
            [
              2506,
              1471
            ],
            [
              2517,
              1445
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Asia",
        "fullname": "United Arab Emirates",
        "admin": "United Arab Emirates",
        "woe_id": 23424738,
        "id": "AE",
        "abbrev": "U.A.E.",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "ARE",
        "name": "AE",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -4467,
                -3439
              ],
              [
                -4467,
                -3275
              ],
              [
                -4431,
                -3348
              ],
              [
                -4347,
                -3415
              ],
              [
                -4377,
                -3449
              ],
              [
                -4467,
                -3439
              ]
            ]
          ],
          [
            [
              [
                -3682,
                -1480
              ],
              [
                -3680,
                -1480
              ],
              [
                -3679,
                -1480
              ],
              [
                -3674,
                -1484
              ],
              [
                -3667,
                -1479
              ],
              [
                -3644,
                -1484
              ],
              [
                -3630,
                -1519
              ],
              [
                -3639,
                -1574
              ],
              [
                -3695,
                -1599
              ],
              [
                -3755,
                -1654
              ],
              [
                -3851,
                -1759
              ],
              [
                -3864,
                -1778
              ],
              [
                -3882,
                -1862
              ],
              [
                -3885,
                -1902
              ],
              [
                -3881,
                -1937
              ],
              [
                -3903,
                -1977
              ],
              [
                -3899,
                -2030
              ],
              [
                -3846,
                -2061
              ],
              [
                -3825,
                -2091
              ],
              [
                -3830,
                -2135
              ],
              [
                -3798,
                -2178
              ],
              [
                -3850,
                -2263
              ],
              [
                -3932,
                -2298
              ],
              [
                -4033,
                -2316
              ],
              [
                -4097,
                -2314
              ],
              [
                -4114,
                -2375
              ],
              [
                -4115,
                -2442
              ],
              [
                -4195,
                -2461
              ],
              [
                -4264,
                -2435
              ],
              [
                -4274,
                -2451
              ],
              [
                -4251,
                -2595
              ],
              [
                -4282,
                -2631
              ],
              [
                -4276,
                -2678
              ],
              [
                -4311,
                -2722
              ],
              [
                -4368,
                -2738
              ],
              [
                -4410,
                -2803
              ],
              [
                -4364,
                -2863
              ],
              [
                -4318,
                -2867
              ],
              [
                -4318,
                -2938
              ],
              [
                -4411,
                -3015
              ],
              [
                -4422,
                -3075
              ],
              [
                -4480,
                -3103
              ],
              [
                -4497,
                -3150
              ],
              [
                -4457,
                -3253
              ],
              [
                -4541,
                -3227
              ],
              [
                -4650,
                -3226
              ],
              [
                -4671,
                -3204
              ],
              [
                -4683,
                -3122
              ],
              [
                -4716,
                -3135
              ],
              [
                -4741,
                -3089
              ],
              [
                -4737,
                -3028
              ],
              [
                -4688,
                -2990
              ],
              [
                -4685,
                -2927
              ],
              [
                -4638,
                -2840
              ],
              [
                -4644,
                -2774
              ],
              [
                -4617,
                -2743
              ],
              [
                -4645,
                -2681
              ],
              [
                -4641,
                -2598
              ],
              [
                -4663,
                -2585
              ],
              [
                -4651,
                -2492
              ],
              [
                -4653,
                -2432
              ],
              [
                -4620,
                -2311
              ],
              [
                -4592,
                -2298
              ],
              [
                -4610,
                -2234
              ],
              [
                -4602,
                -2155
              ],
              [
                -4565,
                -2127
              ],
              [
                -4556,
                -2034
              ],
              [
                -4534,
                -2012
              ],
              [
                -4543,
                -1926
              ],
              [
                -4576,
                -1844
              ],
              [
                -4555,
                -1780
              ],
              [
                -4535,
                -1756
              ],
              [
                -4546,
                -1709
              ],
              [
                -4524,
                -1650
              ],
              [
                -4497,
                -1622
              ],
              [
                -4455,
                -1517
              ],
              [
                -4463,
                -1432
              ],
              [
                -4396,
                -1388
              ],
              [
                -4386,
                -1317
              ],
              [
                -4333,
                -1256
              ],
              [
                -4305,
                -1275
              ],
              [
                -4298,
                -1274
              ],
              [
                -4296,
                -1274
              ],
              [
                -4265,
                -1274
              ],
              [
                -4241,
                -1281
              ],
              [
                -4238,
                -1286
              ],
              [
                -4240,
                -1289
              ],
              [
                -4232,
                -1301
              ],
              [
                -4232,
                -1301
              ],
              [
                -4233,
                -1303
              ],
              [
                -4233,
                -1305
              ],
              [
                -4233,
                -1306
              ],
              [
                -4232,
                -1307
              ],
              [
                -4230,
                -1311
              ],
              [
                -4228,
                -1312
              ],
              [
                -4226,
                -1319
              ],
              [
                -4225,
                -1315
              ],
              [
                -4224,
                -1313
              ],
              [
                -4224,
                -1309
              ],
              [
                -4205,
                -1269
              ],
              [
                -4141,
                -1269
              ],
              [
                -4131,
                -1286
              ],
              [
                -4122,
                -1291
              ],
              [
                -4122,
                -1291
              ],
              [
                -4121,
                -1292
              ],
              [
                -4120,
                -1293
              ],
              [
                -4117,
                -1296
              ],
              [
                -4112,
                -1297
              ],
              [
                -4112,
                -1299
              ],
              [
                -4111,
                -1299
              ],
              [
                -4111,
                -1299
              ],
              [
                -4111,
                -1299
              ],
              [
                -4109,
                -1301
              ],
              [
                -4106,
                -1306
              ],
              [
                -4106,
                -1311
              ],
              [
                -4094,
                -1330
              ],
              [
                -4082,
                -1341
              ],
              [
                -4074,
                -1345
              ],
              [
                -4071,
                -1347
              ],
              [
                -4071,
                -1348
              ],
              [
                -4070,
                -1348
              ],
              [
                -4069,
                -1348
              ],
              [
                -4068,
                -1352
              ],
              [
                -4057,
                -1356
              ],
              [
                -4056,
                -1358
              ],
              [
                -4055,
                -1358
              ],
              [
                -4046,
                -1363
              ],
              [
                -4040,
                -1375
              ],
              [
                -4003,
                -1388
              ],
              [
                -3992,
                -1388
              ],
              [
                -3991,
                -1388
              ],
              [
                -3988,
                -1387
              ],
              [
                -3986,
                -1387
              ],
              [
                -3954,
                -1408
              ],
              [
                -3953,
                -1409
              ],
              [
                -3952,
                -1410
              ],
              [
                -3950,
                -1412
              ],
              [
                -3949,
                -1412
              ],
              [
                -3947,
                -1416
              ],
              [
                -3918,
                -1433
              ],
              [
                -3859,
                -1457
              ],
              [
                -3857,
                -1461
              ],
              [
                -3854,
                -1463
              ],
              [
                -3852,
                -1468
              ],
              [
                -3852,
                -1468
              ],
              [
                -3849,
                -1471
              ],
              [
                -3848,
                -1473
              ],
              [
                -3848,
                -1474
              ],
              [
                -3848,
                -1479
              ],
              [
                -3849,
                -1480
              ],
              [
                -3849,
                -1480
              ],
              [
                -3851,
                -1481
              ],
              [
                -3851,
                -1483
              ],
              [
                -3854,
                -1483
              ],
              [
                -3854,
                -1485
              ],
              [
                -3855,
                -1485
              ],
              [
                -3855,
                -1485
              ],
              [
                -3856,
                -1485
              ],
              [
                -3857,
                -1485
              ],
              [
                -3859,
                -1486
              ],
              [
                -3865,
                -1506
              ],
              [
                -3877,
                -1513
              ],
              [
                -3882,
                -1522
              ],
              [
                -3882,
                -1527
              ],
              [
                -3884,
                -1530
              ],
              [
                -3886,
                -1551
              ],
              [
                -3898,
                -1562
              ],
              [
                -3901,
                -1570
              ],
              [
                -3904,
                -1573
              ],
              [
                -3909,
                -1575
              ],
              [
                -3901,
                -1583
              ],
              [
                -3886,
                -1581
              ],
              [
                -3874,
                -1582
              ],
              [
                -3827,
                -1595
              ],
              [
                -3747,
                -1593
              ],
              [
                -3703,
                -1553
              ],
              [
                -3701,
                -1547
              ],
              [
                -3700,
                -1546
              ],
              [
                -3699,
                -1545
              ],
              [
                -3686,
                -1524
              ],
              [
                -3685,
                -1511
              ],
              [
                -3682,
                -1495
              ],
              [
                -3685,
                -1487
              ],
              [
                -3684,
                -1485
              ],
              [
                -3682,
                -1485
              ],
              [
                -3681,
                -1485
              ],
              [
                -3681,
                -1483
              ],
              [
                -3682,
                -1481
              ],
              [
                -3682,
                -1480
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Americas",
        "fullname": "Argentina",
        "admin": "Argentina",
        "woe_id": 23424747,
        "id": "AR",
        "abbrev": "Arg.",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "ARG",
        "name": "AR",
        "continent": "South America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1886,
              2427
            ],
            [
              1910,
              2405
            ],
            [
              1929,
              2336
            ],
            [
              1976,
              2291
            ],
            [
              1971,
              2268
            ],
            [
              1950,
              2267
            ],
            [
              1929,
              2299
            ],
            [
              1874,
              2322
            ],
            [
              1812,
              2349
            ],
            [
              1799,
              2415
            ],
            [
              1886,
              2427
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Asia",
        "fullname": "Armenia",
        "admin": "Armenia",
        "woe_id": 23424743,
        "id": "AM",
        "abbrev": "Arm.",
        "subregion": "Western Asia",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "ARM",
        "name": "AM",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3227,
              -2991
            ],
            [
              3261,
              -3002
            ],
            [
              3265,
              -3054
            ],
            [
              3223,
              -3037
            ],
            [
              3227,
              -2991
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Seven seas (open ocean)",
        "fullname": "Fr. S. Antarctic Lands",
        "admin": "French Southern and Antarctic Lands",
        "woe_id": 28289406,
        "id": "TF",
        "abbrev": "Fr. S.A.L.",
        "subregion": "Seven seas (open ocean)",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "ATF",
        "name": "TF",
        "continent": "Seven seas (open ocean)"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                7464,
                -2432
              ],
              [
                7557,
                -2461
              ],
              [
                7664,
                -2450
              ],
              [
                7660,
                -2504
              ],
              [
                7601,
                -2604
              ],
              [
                7573,
                -2628
              ],
              [
                7535,
                -2618
              ],
              [
                7492,
                -2557
              ],
              [
                7489,
                -2515
              ],
              [
                7455,
                -2450
              ],
              [
                7464,
                -2432
              ]
            ]
          ],
          [
            [
              [
                7340,
                -619
              ],
              [
                7358,
                -684
              ],
              [
                7390,
                -727
              ],
              [
                7395,
                -793
              ],
              [
                7419,
                -834
              ],
              [
                7459,
                -838
              ],
              [
                7493,
                -860
              ],
              [
                7500,
                -946
              ],
              [
                7537,
                -1001
              ],
              [
                7535,
                -1051
              ],
              [
                7558,
                -1097
              ],
              [
                7677,
                -1155
              ],
              [
                7682,
                -1184
              ],
              [
                7723,
                -1239
              ],
              [
                7740,
                -1291
              ],
              [
                7803,
                -1310
              ],
              [
                7802,
                -1352
              ],
              [
                7856,
                -1388
              ],
              [
                7891,
                -1431
              ],
              [
                7919,
                -1487
              ],
              [
                7958,
                -1643
              ],
              [
                7959,
                -1679
              ],
              [
                7924,
                -1793
              ],
              [
                7921,
                -1840
              ],
              [
                7898,
                -1898
              ],
              [
                7850,
                -1938
              ],
              [
                7809,
                -2018
              ],
              [
                7796,
                -2073
              ],
              [
                7766,
                -2121
              ],
              [
                7756,
                -2220
              ],
              [
                7728,
                -2239
              ],
              [
                7662,
                -2241
              ],
              [
                7582,
                -2295
              ],
              [
                7523,
                -2295
              ],
              [
                7476,
                -2246
              ],
              [
                7396,
                -2309
              ],
              [
                7332,
                -2277
              ],
              [
                7278,
                -2277
              ],
              [
                7218,
                -2245
              ],
              [
                7186,
                -2205
              ],
              [
                7190,
                -2164
              ],
              [
                7168,
                -2117
              ],
              [
                7110,
                -2082
              ],
              [
                7116,
                -2044
              ],
              [
                7043,
                -1975
              ],
              [
                7008,
                -1991
              ],
              [
                6952,
                -2052
              ],
              [
                6901,
                -1944
              ],
              [
                6878,
                -1944
              ],
              [
                6845,
                -1879
              ],
              [
                6764,
                -1874
              ],
              [
                6705,
                -1839
              ],
              [
                6582,
                -1852
              ],
              [
                6487,
                -1889
              ],
              [
                6425,
                -1886
              ],
              [
                6310,
                -1942
              ],
              [
                6279,
                -1989
              ],
              [
                6202,
                -1998
              ],
              [
                6192,
                -1986
              ],
              [
                6080,
                -1992
              ],
              [
                5974,
                -2059
              ],
              [
                5882,
                -2060
              ],
              [
                5798,
                -2002
              ],
              [
                5798,
                -1977
              ],
              [
                5837,
                -1952
              ],
              [
                5838,
                -1850
              ],
              [
                5799,
                -1761
              ],
              [
                5799,
                -1716
              ],
              [
                5753,
                -1633
              ],
              [
                5745,
                -1585
              ],
              [
                5721,
                -1546
              ],
              [
                5750,
                -1533
              ],
              [
                5753,
                -1493
              ],
              [
                5710,
                -1411
              ],
              [
                5734,
                -1331
              ],
              [
                5724,
                -1303
              ],
              [
                5745,
                -1260
              ],
              [
                5825,
                -1240
              ],
              [
                5898,
                -1188
              ],
              [
                5932,
                -1194
              ],
              [
                5975,
                -1173
              ],
              [
                6093,
                -1146
              ],
              [
                6146,
                -1121
              ],
              [
                6204,
                -1047
              ],
              [
                6201,
                -993
              ],
              [
                6241,
                -950
              ],
              [
                6270,
                -997
              ],
              [
                6294,
                -986
              ],
              [
                6284,
                -941
              ],
              [
                6336,
                -926
              ],
              [
                6324,
                -903
              ],
              [
                6396,
                -827
              ],
              [
                6447,
                -817
              ],
              [
                6468,
                -790
              ],
              [
                6537,
                -846
              ],
              [
                6617,
                -860
              ],
              [
                6603,
                -829
              ],
              [
                6646,
                -746
              ],
              [
                6695,
                -702
              ],
              [
                6753,
                -710
              ],
              [
                6814,
                -674
              ],
              [
                6922,
                -707
              ],
              [
                6948,
                -697
              ],
              [
                7013,
                -708
              ],
              [
                7010,
                -746
              ],
              [
                6968,
                -768
              ],
              [
                6969,
                -814
              ],
              [
                6945,
                -860
              ],
              [
                7017,
                -915
              ],
              [
                7072,
                -935
              ],
              [
                7096,
                -961
              ],
              [
                7144,
                -973
              ],
              [
                7198,
                -1019
              ],
              [
                7244,
                -1004
              ],
              [
                7277,
                -925
              ],
              [
                7291,
                -864
              ],
              [
                7283,
                -834
              ],
              [
                7291,
                -717
              ],
              [
                7319,
                -632
              ],
              [
                7340,
                -619
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Oceania",
        "fullname": "Australia",
        "admin": "Australia",
        "woe_id": -90,
        "id": "AU",
        "abbrev": "Auz.",
        "subregion": "Australia and New Zealand",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "AUS",
        "name": "AU",
        "continent": "Oceania"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              318,
              2931
            ],
            [
              329,
              2888
            ],
            [
              270,
              2807
            ],
            [
              181,
              2776
            ],
            [
              136,
              2783
            ],
            [
              124,
              2786
            ],
            [
              118,
              2787
            ],
            [
              112,
              2786
            ],
            [
              110,
              2786
            ],
            [
              64,
              2795
            ],
            [
              48,
              2817
            ],
            [
              38,
              2820
            ],
            [
              36,
              2819
            ],
            [
              33,
              2818
            ],
            [
              27,
              2815
            ],
            [
              27,
              2815
            ],
            [
              25,
              2815
            ],
            [
              24,
              2817
            ],
            [
              19,
              2817
            ],
            [
              11,
              2817
            ],
            [
              8,
              2815
            ],
            [
              7,
              2815
            ],
            [
              5,
              2816
            ],
            [
              3,
              2816
            ],
            [
              -45,
              2808
            ],
            [
              -94,
              2821
            ],
            [
              -97,
              2836
            ],
            [
              -96,
              2855
            ],
            [
              -53,
              2838
            ],
            [
              -45,
              2848
            ],
            [
              -46,
              2855
            ],
            [
              -41,
              2854
            ],
            [
              -40,
              2854
            ],
            [
              -40,
              2855
            ],
            [
              -38,
              2857
            ],
            [
              -36,
              2857
            ],
            [
              20,
              2859
            ],
            [
              83,
              2864
            ],
            [
              83,
              2896
            ],
            [
              120,
              2915
            ],
            [
              126,
              2929
            ],
            [
              132,
              2928
            ],
            [
              133,
              2928
            ],
            [
              134,
              2927
            ],
            [
              135,
              2926
            ],
            [
              137,
              2925
            ],
            [
              143,
              2943
            ],
            [
              190,
              2929
            ],
            [
              208,
              2960
            ],
            [
              318,
              2931
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Europe",
        "fullname": "Austria",
        "admin": "Austria",
        "woe_id": 23424750,
        "id": "AT",
        "abbrev": "Aust.",
        "subregion": "Western Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "AUT",
        "name": "AT",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                1950,
                2267
              ],
              [
                1911,
                2276
              ],
              [
                1876,
                2318
              ],
              [
                1874,
                2322
              ],
              [
                1929,
                2299
              ],
              [
                1950,
                2267
              ]
            ]
          ],
          [
            [
              [
                1966,
                2467
              ],
              [
                2013,
                2428
              ],
              [
                2047,
                2421
              ],
              [
                2086,
                2464
              ],
              [
                2160,
                2366
              ],
              [
                2142,
                2356
              ],
              [
                2108,
                2288
              ],
              [
                2103,
                2239
              ],
              [
                2066,
                2259
              ],
              [
                2073,
                2301
              ],
              [
                2046,
                2321
              ],
              [
                1971,
                2268
              ],
              [
                1976,
                2291
              ],
              [
                1929,
                2336
              ],
              [
                1910,
                2405
              ],
              [
                1886,
                2427
              ],
              [
                1959,
                2442
              ],
              [
                1966,
                2467
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Asia",
        "fullname": "Azerbaijan",
        "admin": "Azerbaijan",
        "woe_id": 23424741,
        "id": "AZ",
        "abbrev": "Aze.",
        "subregion": "Western Asia",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "AZE",
        "name": "AZ",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1079,
              -152
            ],
            [
              1071,
              -177
            ],
            [
              1094,
              -195
            ],
            [
              1074,
              -214
            ],
            [
              1059,
              -238
            ],
            [
              1058,
              -242
            ],
            [
              1057,
              -244
            ],
            [
              1056,
              -247
            ],
            [
              1048,
              -257
            ],
            [
              1037,
              -262
            ],
            [
              1035,
              -264
            ],
            [
              1034,
              -265
            ],
            [
              1034,
              -265
            ],
            [
              1033,
              -268
            ],
            [
              1027,
              -267
            ],
            [
              1014,
              -267
            ],
            [
              1005,
              -188
            ],
            [
              997,
              -182
            ],
            [
              995,
              -176
            ],
            [
              992,
              -175
            ],
            [
              991,
              -174
            ],
            [
              992,
              -172
            ],
            [
              993,
              -170
            ],
            [
              994,
              -164
            ],
            [
              999,
              -163
            ],
            [
              1009,
              -167
            ],
            [
              1011,
              -176
            ],
            [
              1031,
              -175
            ],
            [
              1079,
              -152
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Africa",
        "fullname": "Burundi",
        "admin": "Burundi",
        "woe_id": 23424774,
        "id": "BI",
        "abbrev": "Bur.",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "BDI",
        "name": "BI",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -294,
              3086
            ],
            [
              -288,
              3040
            ],
            [
              -306,
              2998
            ],
            [
              -395,
              3028
            ],
            [
              -399,
              3050
            ],
            [
              -474,
              3084
            ],
            [
              -489,
              3111
            ],
            [
              -442,
              3132
            ],
            [
              -394,
              3132
            ],
            [
              -391,
              3132
            ],
            [
              -363,
              3141
            ],
            [
              -304,
              3112
            ],
            [
              -294,
              3086
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Europe",
        "fullname": "Belgium",
        "admin": "Belgium",
        "woe_id": 23424757,
        "id": "BE",
        "abbrev": "Belg.",
        "subregion": "Western Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "BEL",
        "name": "BE",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -429,
              639
            ],
            [
              -430,
              637
            ],
            [
              -434,
              631
            ],
            [
              -434,
              628
            ],
            [
              -435,
              627
            ],
            [
              -436,
              624
            ],
            [
              -436,
              623
            ],
            [
              -435,
              622
            ],
            [
              -425,
              607
            ],
            [
              -421,
              589
            ],
            [
              -418,
              583
            ],
            [
              -416,
              583
            ],
            [
              -415,
              580
            ],
            [
              -415,
              578
            ],
            [
              -415,
              577
            ],
            [
              -417,
              571
            ],
            [
              -417,
              568
            ],
            [
              -420,
              566
            ],
            [
              -433,
              534
            ],
            [
              -455,
              511
            ],
            [
              -457,
              494
            ],
            [
              -475,
              490
            ],
            [
              -477,
              474
            ],
            [
              -476,
              458
            ],
            [
              -478,
              454
            ],
            [
              -478,
              452
            ],
            [
              -479,
              449
            ],
            [
              -479,
              448
            ],
            [
              -479,
              447
            ],
            [
              -478,
              446
            ],
            [
              -477,
              442
            ],
            [
              -477,
              441
            ],
            [
              -478,
              438
            ],
            [
              -479,
              426
            ],
            [
              -480,
              425
            ],
            [
              -480,
              424
            ],
            [
              -480,
              424
            ],
            [
              -480,
              424
            ],
            [
              -479,
              422
            ],
            [
              -478,
              420
            ],
            [
              -477,
              419
            ],
            [
              -477,
              419
            ],
            [
              -478,
              411
            ],
            [
              -478,
              409
            ],
            [
              -477,
              407
            ],
            [
              -474,
              377
            ],
            [
              -478,
              373
            ],
            [
              -477,
              363
            ],
            [
              -474,
              360
            ],
            [
              -474,
              357
            ],
            [
              -475,
              356
            ],
            [
              -476,
              355
            ],
            [
              -477,
              354
            ],
            [
              -477,
              353
            ],
            [
              -476,
              350
            ],
            [
              -477,
              349
            ],
            [
              -478,
              346
            ],
            [
              -478,
              342
            ],
            [
              -478,
              339
            ],
            [
              -539,
              330
            ],
            [
              -540,
              489
            ],
            [
              -555,
              543
            ],
            [
              -587,
              564
            ],
            [
              -579,
              599
            ],
            [
              -549,
              625
            ],
            [
              -517,
              623
            ],
            [
              -496,
              650
            ],
            [
              -471,
              679
            ],
            [
              -448,
              657
            ],
            [
              -445,
              651
            ],
            [
              -434,
              646
            ],
            [
              -431,
              643
            ],
            [
              -430,
              640
            ],
            [
              -429,
              640
            ],
            [
              -429,
              639
            ],
            [
              -429,
              639
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Africa",
        "fullname": "Benin",
        "admin": "Benin",
        "woe_id": 23424764,
        "id": "BJ",
        "abbrev": "Benin",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "BEN",
        "name": "BJ",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -617,
              821
            ],
            [
              -621,
              801
            ],
            [
              -575,
              716
            ],
            [
              -496,
              650
            ],
            [
              -517,
              623
            ],
            [
              -549,
              625
            ],
            [
              -579,
              599
            ],
            [
              -639,
              607
            ],
            [
              -658,
              599
            ],
            [
              -783,
              599
            ],
            [
              -794,
              579
            ],
            [
              -783,
              533
            ],
            [
              -786,
              527
            ],
            [
              -784,
              518
            ],
            [
              -782,
              517
            ],
            [
              -780,
              514
            ],
            [
              -784,
              509
            ],
            [
              -792,
              517
            ],
            [
              -797,
              526
            ],
            [
              -805,
              533
            ],
            [
              -834,
              540
            ],
            [
              -868,
              528
            ],
            [
              -907,
              537
            ],
            [
              -915,
              559
            ],
            [
              -932,
              560
            ],
            [
              -936,
              563
            ],
            [
              -937,
              566
            ],
            [
              -938,
              567
            ],
            [
              -938,
              568
            ],
            [
              -938,
              571
            ],
            [
              -935,
              576
            ],
            [
              -933,
              591
            ],
            [
              -921,
              623
            ],
            [
              -919,
              653
            ],
            [
              -876,
              673
            ],
            [
              -852,
              735
            ],
            [
              -811,
              729
            ],
            [
              -788,
              772
            ],
            [
              -743,
              781
            ],
            [
              -672,
              831
            ],
            [
              -617,
              821
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Burkina Faso",
        "admin": "Burkina Faso",
        "woe_id": 23424978,
        "id": "BF",
        "abbrev": "B.F.",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "BFA",
        "name": "BF",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4546,
              1230
            ],
            [
              4529,
              1176
            ],
            [
              4505,
              1246
            ],
            [
              4484,
              1277
            ],
            [
              4437,
              1250
            ],
            [
              4413,
              1219
            ],
            [
              4352,
              1220
            ],
            [
              4350,
              1239
            ],
            [
              4321,
              1328
            ],
            [
              4332,
              1366
            ],
            [
              4292,
              1387
            ],
            [
              4320,
              1438
            ],
            [
              4295,
              1462
            ],
            [
              4316,
              1495
            ],
            [
              4393,
              1462
            ],
            [
              4391,
              1430
            ],
            [
              4423,
              1417
            ],
            [
              4514,
              1419
            ],
            [
              4520,
              1373
            ],
            [
              4479,
              1355
            ],
            [
              4466,
              1326
            ],
            [
              4488,
              1289
            ],
            [
              4523,
              1333
            ],
            [
              4546,
              1230
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Asia",
        "fullname": "Bangladesh",
        "admin": "Bangladesh",
        "woe_id": 23424759,
        "id": "BD",
        "abbrev": "Bang.",
        "subregion": "Southern Asia",
        "region_wb": "South Asia",
        "iso_a3": "BGD",
        "name": "BD",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              968,
              2592
            ],
            [
              941,
              2566
            ],
            [
              930,
              2522
            ],
            [
              909,
              2511
            ],
            [
              935,
              2479
            ],
            [
              937,
              2472
            ],
            [
              925,
              2474
            ],
            [
              925,
              2473
            ],
            [
              925,
              2472
            ],
            [
              924,
              2470
            ],
            [
              914,
              2471
            ],
            [
              910,
              2468
            ],
            [
              895,
              2480
            ],
            [
              859,
              2472
            ],
            [
              853,
              2462
            ],
            [
              851,
              2462
            ],
            [
              845,
              2462
            ],
            [
              827,
              2453
            ],
            [
              831,
              2431
            ],
            [
              830,
              2431
            ],
            [
              825,
              2430
            ],
            [
              817,
              2428
            ],
            [
              799,
              2429
            ],
            [
              784,
              2424
            ],
            [
              741,
              2445
            ],
            [
              691,
              2432
            ],
            [
              680,
              2434
            ],
            [
              675,
              2432
            ],
            [
              673,
              2433
            ],
            [
              672,
              2434
            ],
            [
              652,
              2430
            ],
            [
              657,
              2455
            ],
            [
              648,
              2475
            ],
            [
              620,
              2495
            ],
            [
              624,
              2496
            ],
            [
              629,
              2501
            ],
            [
              629,
              2502
            ],
            [
              630,
              2505
            ],
            [
              630,
              2506
            ],
            [
              630,
              2507
            ],
            [
              630,
              2508
            ],
            [
              624,
              2513
            ],
            [
              624,
              2514
            ],
            [
              626,
              2519
            ],
            [
              625,
              2520
            ],
            [
              627,
              2524
            ],
            [
              626,
              2526
            ],
            [
              624,
              2528
            ],
            [
              624,
              2529
            ],
            [
              624,
              2529
            ],
            [
              625,
              2530
            ],
            [
              625,
              2530
            ],
            [
              627,
              2531
            ],
            [
              627,
              2531
            ],
            [
              628,
              2532
            ],
            [
              629,
              2533
            ],
            [
              630,
              2533
            ],
            [
              630,
              2533
            ],
            [
              633,
              2534
            ],
            [
              641,
              2534
            ],
            [
              653,
              2547
            ],
            [
              655,
              2555
            ],
            [
              652,
              2556
            ],
            [
              645,
              2564
            ],
            [
              641,
              2567
            ],
            [
              638,
              2568
            ],
            [
              636,
              2570
            ],
            [
              633,
              2571
            ],
            [
              632,
              2572
            ],
            [
              630,
              2573
            ],
            [
              629,
              2574
            ],
            [
              629,
              2575
            ],
            [
              628,
              2578
            ],
            [
              627,
              2579
            ],
            [
              627,
              2580
            ],
            [
              627,
              2580
            ],
            [
              627,
              2581
            ],
            [
              627,
              2582
            ],
            [
              627,
              2583
            ],
            [
              627,
              2585
            ],
            [
              622,
              2590
            ],
            [
              622,
              2591
            ],
            [
              622,
              2591
            ],
            [
              622,
              2593
            ],
            [
              621,
              2594
            ],
            [
              620,
              2596
            ],
            [
              620,
              2598
            ],
            [
              621,
              2599
            ],
            [
              622,
              2601
            ],
            [
              622,
              2603
            ],
            [
              622,
              2605
            ],
            [
              623,
              2606
            ],
            [
              623,
              2608
            ],
            [
              623,
              2609
            ],
            [
              623,
              2610
            ],
            [
              625,
              2610
            ],
            [
              629,
              2611
            ],
            [
              634,
              2614
            ],
            [
              639,
              2625
            ],
            [
              675,
              2602
            ],
            [
              788,
              2586
            ],
            [
              882,
              2621
            ],
            [
              968,
              2592
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Europe",
        "fullname": "Bulgaria",
        "admin": "Bulgaria",
        "woe_id": 23424771,
        "id": "BG",
        "abbrev": "Bulg.",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "BGR",
        "name": "BG",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              434,
              2669
            ],
            [
              444,
              2578
            ],
            [
              440,
              2578
            ],
            [
              435,
              2576
            ],
            [
              434,
              2577
            ],
            [
              434,
              2578
            ],
            [
              433,
              2579
            ],
            [
              431,
              2578
            ],
            [
              428,
              2576
            ],
            [
              415,
              2558
            ],
            [
              402,
              2541
            ],
            [
              401,
              2512
            ],
            [
              357,
              2534
            ],
            [
              353,
              2538
            ],
            [
              249,
              2663
            ],
            [
              317,
              2697
            ],
            [
              434,
              2669
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Europe",
        "fullname": "Bosnia and Herz.",
        "admin": "Bosnia and Herzegovina",
        "woe_id": 23424761,
        "id": "BA",
        "abbrev": "B.H.",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "BIH",
        "name": "BA",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              944,
              3496
            ],
            [
              945,
              3494
            ],
            [
              949,
              3491
            ],
            [
              951,
              3489
            ],
            [
              952,
              3488
            ],
            [
              953,
              3488
            ],
            [
              955,
              3488
            ],
            [
              958,
              3491
            ],
            [
              971,
              3491
            ],
            [
              1013,
              3481
            ],
            [
              1040,
              3471
            ],
            [
              1074,
              3468
            ],
            [
              1099,
              3438
            ],
            [
              1110,
              3381
            ],
            [
              1107,
              3367
            ],
            [
              1113,
              3365
            ],
            [
              1114,
              3364
            ],
            [
              1115,
              3364
            ],
            [
              1116,
              3362
            ],
            [
              1118,
              3358
            ],
            [
              1120,
              3356
            ],
            [
              1120,
              3355
            ],
            [
              1120,
              3350
            ],
            [
              1121,
              3348
            ],
            [
              1122,
              3347
            ],
            [
              1132,
              3341
            ],
            [
              1133,
              3340
            ],
            [
              1136,
              3339
            ],
            [
              1141,
              3337
            ],
            [
              1145,
              3336
            ],
            [
              1146,
              3316
            ],
            [
              1148,
              3314
            ],
            [
              1152,
              3313
            ],
            [
              1164,
              3315
            ],
            [
              1169,
              3313
            ],
            [
              1176,
              3311
            ],
            [
              1180,
              3308
            ],
            [
              1185,
              3295
            ],
            [
              1195,
              3291
            ],
            [
              1195,
              3289
            ],
            [
              1196,
              3288
            ],
            [
              1199,
              3289
            ],
            [
              1200,
              3287
            ],
            [
              1199,
              3278
            ],
            [
              1182,
              3268
            ],
            [
              1164,
              3260
            ],
            [
              1127,
              3269
            ],
            [
              1138,
              3207
            ],
            [
              1135,
              3202
            ],
            [
              1142,
              3198
            ],
            [
              1143,
              3193
            ],
            [
              1146,
              3191
            ],
            [
              1146,
              3190
            ],
            [
              1146,
              3188
            ],
            [
              1146,
              3186
            ],
            [
              1140,
              3186
            ],
            [
              1130,
              3188
            ],
            [
              1125,
              3187
            ],
            [
              1121,
              3186
            ],
            [
              1118,
              3182
            ],
            [
              1101,
              3184
            ],
            [
              1077,
              3149
            ],
            [
              1080,
              3145
            ],
            [
              1081,
              3140
            ],
            [
              1081,
              3136
            ],
            [
              1083,
              3135
            ],
            [
              1083,
              3133
            ],
            [
              1084,
              3132
            ],
            [
              1083,
              3132
            ],
            [
              1083,
              3129
            ],
            [
              1082,
              3128
            ],
            [
              1080,
              3127
            ],
            [
              1079,
              3124
            ],
            [
              1079,
              3123
            ],
            [
              1079,
              3123
            ],
            [
              1079,
              3122
            ],
            [
              1078,
              3122
            ],
            [
              1074,
              3124
            ],
            [
              1072,
              3126
            ],
            [
              1068,
              3127
            ],
            [
              1056,
              3140
            ],
            [
              1026,
              3141
            ],
            [
              1018,
              3133
            ],
            [
              1014,
              3134
            ],
            [
              1011,
              3132
            ],
            [
              1010,
              3132
            ],
            [
              1008,
              3132
            ],
            [
              1008,
              3134
            ],
            [
              1007,
              3135
            ],
            [
              1007,
              3137
            ],
            [
              1005,
              3138
            ],
            [
              1005,
              3138
            ],
            [
              1004,
              3139
            ],
            [
              1005,
              3141
            ],
            [
              1004,
              3143
            ],
            [
              1004,
              3144
            ],
            [
              1002,
              3147
            ],
            [
              1001,
              3149
            ],
            [
              999,
              3151
            ],
            [
              996,
              3151
            ],
            [
              995,
              3151
            ],
            [
              994,
              3151
            ],
            [
              989,
              3146
            ],
            [
              984,
              3146
            ],
            [
              955,
              3143
            ],
            [
              811,
              3173
            ],
            [
              734,
              3170
            ],
            [
              711,
              3148
            ],
            [
              709,
              3148
            ],
            [
              707,
              3149
            ],
            [
              706,
              3150
            ],
            [
              698,
              3152
            ],
            [
              697,
              3152
            ],
            [
              692,
              3151
            ],
            [
              690,
              3150
            ],
            [
              690,
              3149
            ],
            [
              690,
              3148
            ],
            [
              691,
              3145
            ],
            [
              691,
              3143
            ],
            [
              690,
              3143
            ],
            [
              686,
              3153
            ],
            [
              692,
              3185
            ],
            [
              706,
              3265
            ],
            [
              683,
              3325
            ],
            [
              692,
              3322
            ],
            [
              700,
              3324
            ],
            [
              701,
              3324
            ],
            [
              702,
              3324
            ],
            [
              703,
              3323
            ],
            [
              708,
              3326
            ],
            [
              724,
              3326
            ],
            [
              727,
              3321
            ],
            [
              728,
              3321
            ],
            [
              730,
              3321
            ],
            [
              733,
              3321
            ],
            [
              750,
              3329
            ],
            [
              756,
              3327
            ],
            [
              756,
              3337
            ],
            [
              758,
              3338
            ],
            [
              758,
              3340
            ],
            [
              760,
              3341
            ],
            [
              760,
              3341
            ],
            [
              772,
              3340
            ],
            [
              775,
              3341
            ],
            [
              777,
              3343
            ],
            [
              779,
              3348
            ],
            [
              789,
              3349
            ],
            [
              792,
              3352
            ],
            [
              794,
              3352
            ],
            [
              795,
              3352
            ],
            [
              800,
              3353
            ],
            [
              812,
              3396
            ],
            [
              857,
              3458
            ],
            [
              913,
              3468
            ],
            [
              944,
              3496
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Europe",
        "fullname": "Belarus",
        "admin": "Belarus",
        "woe_id": 23424765,
        "id": "BY",
        "abbrev": "Bela.",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "BLR",
        "name": "BY",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -5567,
              1026
            ],
            [
              -5555,
              1006
            ],
            [
              -5562,
              939
            ],
            [
              -5580,
              899
            ],
            [
              -5602,
              883
            ],
            [
              -5601,
              878
            ],
            [
              -5619,
              878
            ],
            [
              -5615,
              988
            ],
            [
              -5567,
              1026
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Americas",
        "fullname": "Belize",
        "admin": "Belize",
        "woe_id": 23424760,
        "id": "BZ",
        "abbrev": "Belize",
        "subregion": "Central America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "BLZ",
        "name": "BZ",
        "continent": "North America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -3881,
              -1161
            ],
            [
              -3882,
              -1141
            ],
            [
              -3933,
              -1110
            ],
            [
              -3985,
              -1111
            ],
            [
              -4083,
              -1132
            ],
            [
              -4111,
              -1185
            ],
            [
              -4111,
              -1214
            ],
            [
              -4132,
              -1282
            ],
            [
              -4141,
              -1269
            ],
            [
              -4205,
              -1269
            ],
            [
              -4224,
              -1309
            ],
            [
              -4224,
              -1313
            ],
            [
              -4225,
              -1315
            ],
            [
              -4226,
              -1319
            ],
            [
              -4228,
              -1312
            ],
            [
              -4230,
              -1311
            ],
            [
              -4232,
              -1307
            ],
            [
              -4233,
              -1306
            ],
            [
              -4233,
              -1303
            ],
            [
              -4232,
              -1301
            ],
            [
              -4232,
              -1301
            ],
            [
              -4235,
              -1297
            ],
            [
              -4240,
              -1289
            ],
            [
              -4241,
              -1281
            ],
            [
              -4265,
              -1274
            ],
            [
              -4289,
              -1275
            ],
            [
              -4296,
              -1274
            ],
            [
              -4298,
              -1274
            ],
            [
              -4305,
              -1275
            ],
            [
              -4333,
              -1256
            ],
            [
              -4386,
              -1317
            ],
            [
              -4404,
              -1321
            ],
            [
              -4406,
              -1321
            ],
            [
              -4425,
              -1318
            ],
            [
              -4424,
              -1302
            ],
            [
              -4443,
              -1227
            ],
            [
              -4455,
              -1208
            ],
            [
              -4463,
              -1203
            ],
            [
              -4463,
              -1195
            ],
            [
              -4460,
              -1187
            ],
            [
              -4470,
              -1182
            ],
            [
              -4474,
              -1174
            ],
            [
              -4473,
              -1172
            ],
            [
              -4469,
              -1171
            ],
            [
              -4472,
              -1167
            ],
            [
              -4472,
              -1165
            ],
            [
              -4472,
              -1163
            ],
            [
              -4472,
              -1160
            ],
            [
              -4474,
              -1160
            ],
            [
              -4476,
              -1159
            ],
            [
              -4476,
              -1158
            ],
            [
              -4475,
              -1157
            ],
            [
              -4470,
              -1137
            ],
            [
              -4470,
              -1134
            ],
            [
              -4468,
              -1132
            ],
            [
              -4467,
              -1131
            ],
            [
              -4459,
              -1120
            ],
            [
              -4459,
              -1115
            ],
            [
              -4487,
              -1090
            ],
            [
              -4495,
              -1037
            ],
            [
              -4504,
              -1034
            ],
            [
              -4505,
              -1033
            ],
            [
              -4506,
              -1031
            ],
            [
              -4506,
              -1024
            ],
            [
              -4507,
              -1022
            ],
            [
              -4514,
              -1016
            ],
            [
              -4515,
              -1014
            ],
            [
              -4516,
              -1012
            ],
            [
              -4516,
              -1007
            ],
            [
              -4516,
              -1007
            ],
            [
              -4516,
              -1001
            ],
            [
              -4517,
              -999
            ],
            [
              -4521,
              -995
            ],
            [
              -4522,
              -995
            ],
            [
              -4525,
              -995
            ],
            [
              -4525,
              -995
            ],
            [
              -4522,
              -989
            ],
            [
              -4511,
              -983
            ],
            [
              -4498,
              -963
            ],
            [
              -4497,
              -962
            ],
            [
              -4495,
              -962
            ],
            [
              -4494,
              -961
            ],
            [
              -4489,
              -959
            ],
            [
              -4490,
              -949
            ],
            [
              -4487,
              -945
            ],
            [
              -4479,
              -938
            ],
            [
              -4486,
              -933
            ],
            [
              -4495,
              -933
            ],
            [
              -4497,
              -933
            ],
            [
              -4498,
              -932
            ],
            [
              -4500,
              -929
            ],
            [
              -4501,
              -926
            ],
            [
              -4503,
              -920
            ],
            [
              -4511,
              -901
            ],
            [
              -4504,
              -887
            ],
            [
              -4503,
              -886
            ],
            [
              -4503,
              -886
            ],
            [
              -4504,
              -884
            ],
            [
              -4504,
              -883
            ],
            [
              -4500,
              -880
            ],
            [
              -4499,
              -878
            ],
            [
              -4498,
              -878
            ],
            [
              -4497,
              -878
            ],
            [
              -4496,
              -878
            ],
            [
              -4496,
              -876
            ],
            [
              -4497,
              -875
            ],
            [
              -4499,
              -872
            ],
            [
              -4503,
              -870
            ],
            [
              -4503,
              -869
            ],
            [
              -4509,
              -861
            ],
            [
              -4501,
              -840
            ],
            [
              -4497,
              -835
            ],
            [
              -4493,
              -831
            ],
            [
              -4492,
              -831
            ],
            [
              -4488,
              -829
            ],
            [
              -4487,
              -828
            ],
            [
              -4487,
              -827
            ],
            [
              -4488,
              -824
            ],
            [
              -4488,
              -820
            ],
            [
              -4481,
              -818
            ],
            [
              -4486,
              -804
            ],
            [
              -4488,
              -792
            ],
            [
              -4492,
              -789
            ],
            [
              -4492,
              -786
            ],
            [
              -4489,
              -785
            ],
            [
              -4489,
              -783
            ],
            [
              -4487,
              -780
            ],
            [
              -4486,
              -776
            ],
            [
              -4485,
              -765
            ],
            [
              -4486,
              -761
            ],
            [
              -4486,
              -758
            ],
            [
              -4486,
              -752
            ],
            [
              -4486,
              -751
            ],
            [
              -4487,
              -745
            ],
            [
              -4486,
              -742
            ],
            [
              -4479,
              -734
            ],
            [
              -4475,
              -733
            ],
            [
              -4474,
              -733
            ],
            [
              -4473,
              -730
            ],
            [
              -4474,
              -729
            ],
            [
              -4475,
              -729
            ],
            [
              -4476,
              -727
            ],
            [
              -4475,
              -726
            ],
            [
              -4472,
              -724
            ],
            [
              -4470,
              -721
            ],
            [
              -4470,
              -720
            ],
            [
              -4471,
              -718
            ],
            [
              -4506,
              -656
            ],
            [
              -4520,
              -633
            ],
            [
              -4515,
              -633
            ],
            [
              -4509,
              -632
            ],
            [
              -4481,
              -636
            ],
            [
              -4466,
              -642
            ],
            [
              -4418,
              -619
            ],
            [
              -4355,
              -574
            ],
            [
              -4281,
              -569
            ],
            [
              -4289,
              -605
            ],
            [
              -4283,
              -658
            ],
            [
              -4264,
              -692
            ],
            [
              -4230,
              -718
            ],
            [
              -4197,
              -718
            ],
            [
              -4141,
              -748
            ],
            [
              -4108,
              -756
            ],
            [
              -4107,
              -756
            ],
            [
              -4106,
              -757
            ],
            [
              -4102,
              -758
            ],
            [
              -4089,
              -775
            ],
            [
              -4087,
              -779
            ],
            [
              -4073,
              -778
            ],
            [
              -4066,
              -780
            ],
            [
              -4063,
              -779
            ],
            [
              -4060,
              -777
            ],
            [
              -4034,
              -781
            ],
            [
              -4029,
              -783
            ],
            [
              -4025,
              -787
            ],
            [
              -4021,
              -790
            ],
            [
              -4010,
              -796
            ],
            [
              -3991,
              -936
            ],
            [
              -3956,
              -937
            ],
            [
              -3913,
              -939
            ],
            [
              -3902,
              -939
            ],
            [
              -3898,
              -939
            ],
            [
              -3894,
              -993
            ],
            [
              -3873,
              -1007
            ],
            [
              -3871,
              -1007
            ],
            [
              -3869,
              -1008
            ],
            [
              -3864,
              -1007
            ],
            [
              -3861,
              -1010
            ],
            [
              -3860,
              -1018
            ],
            [
              -3857,
              -1020
            ],
            [
              -3855,
              -1026
            ],
            [
              -3856,
              -1027
            ],
            [
              -3857,
              -1027
            ],
            [
              -3852,
              -1036
            ],
            [
              -3849,
              -1043
            ],
            [
              -3847,
              -1046
            ],
            [
              -3860,
              -1088
            ],
            [
              -3858,
              -1089
            ],
            [
              -3857,
              -1090
            ],
            [
              -3856,
              -1096
            ],
            [
              -3861,
              -1097
            ],
            [
              -3873,
              -1123
            ],
            [
              -3877,
              -1130
            ],
            [
              -3877,
              -1132
            ],
            [
              -3879,
              -1136
            ],
            [
              -3881,
              -1161
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Americas",
        "fullname": "Bolivia",
        "admin": "Bolivia",
        "woe_id": 23424762,
        "id": "BO",
        "abbrev": "Bolivia",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "BOL",
        "name": "BO",
        "continent": "South America"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -3430,
                -25
              ],
              [
                -3337,
                -32
              ],
              [
                -3360,
                -99
              ],
              [
                -3415,
                -119
              ],
              [
                -3456,
                -119
              ],
              [
                -3470,
                -91
              ],
              [
                -3458,
                -29
              ],
              [
                -3430,
                -25
              ]
            ]
          ],
          [
            [
              [
                -3787,
                91
              ],
              [
                -3785,
                90
              ],
              [
                -3784,
                89
              ],
              [
                -3781,
                90
              ],
              [
                -3762,
                85
              ],
              [
                -3758,
                86
              ],
              [
                -3756,
                88
              ],
              [
                -3756,
                90
              ],
              [
                -3756,
                94
              ],
              [
                -3756,
                96
              ],
              [
                -3756,
                96
              ],
              [
                -3756,
                97
              ],
              [
                -3756,
                97
              ],
              [
                -3762,
                103
              ],
              [
                -3765,
                107
              ],
              [
                -3768,
                108
              ],
              [
                -3769,
                108
              ],
              [
                -3769,
                109
              ],
              [
                -3767,
                113
              ],
              [
                -3760,
                123
              ],
              [
                -3706,
                127
              ],
              [
                -3698,
                119
              ],
              [
                -3690,
                120
              ],
              [
                -3688,
                119
              ],
              [
                -3688,
                117
              ],
              [
                -3688,
                116
              ],
              [
                -3689,
                115
              ],
              [
                -3688,
                114
              ],
              [
                -3688,
                113
              ],
              [
                -3688,
                112
              ],
              [
                -3685,
                112
              ],
              [
                -3683,
                112
              ],
              [
                -3682,
                112
              ],
              [
                -3680,
                112
              ],
              [
                -3675,
                106
              ],
              [
                -3656,
                100
              ],
              [
                -3576,
                114
              ],
              [
                -3519,
                208
              ],
              [
                -3486,
                171
              ],
              [
                -3451,
                84
              ],
              [
                -3420,
                75
              ],
              [
                -3424,
                42
              ],
              [
                -3492,
                -21
              ],
              [
                -3544,
                -108
              ],
              [
                -3475,
                -81
              ],
              [
                -3462,
                -119
              ],
              [
                -3446,
                -128
              ],
              [
                -3367,
                -107
              ],
              [
                -3332,
                -91
              ],
              [
                -3322,
                -62
              ],
              [
                -3290,
                -50
              ],
              [
                -3236,
                -63
              ],
              [
                -3119,
                -121
              ],
              [
                -3110,
                -160
              ],
              [
                -3051,
                -149
              ],
              [
                -3003,
                -172
              ],
              [
                -2941,
                -181
              ],
              [
                -2865,
                -177
              ],
              [
                -2830,
                -196
              ],
              [
                -2708,
                -293
              ],
              [
                -2615,
                -306
              ],
              [
                -2601,
                -325
              ],
              [
                -2575,
                -419
              ],
              [
                -2576,
                -461
              ],
              [
                -2603,
                -533
              ],
              [
                -2665,
                -607
              ],
              [
                -2693,
                -623
              ],
              [
                -2772,
                -745
              ],
              [
                -2814,
                -772
              ],
              [
                -2814,
                -845
              ],
              [
                -2802,
                -911
              ],
              [
                -2820,
                -1013
              ],
              [
                -2851,
                -1066
              ],
              [
                -2850,
                -1118
              ],
              [
                -2926,
                -1241
              ],
              [
                -2920,
                -1266
              ],
              [
                -2976,
                -1300
              ],
              [
                -2981,
                -1325
              ],
              [
                -3127,
                -1331
              ],
              [
                -3174,
                -1377
              ],
              [
                -3194,
                -1372
              ],
              [
                -3303,
                -1440
              ],
              [
                -3348,
                -1508
              ],
              [
                -3345,
                -1618
              ],
              [
                -3359,
                -1663
              ],
              [
                -3414,
                -1714
              ],
              [
                -3443,
                -1777
              ],
              [
                -3488,
                -1764
              ],
              [
                -3506,
                -1815
              ],
              [
                -3548,
                -1855
              ],
              [
                -3544,
                -1883
              ],
              [
                -3572,
                -1940
              ],
              [
                -3614,
                -1980
              ],
              [
                -3623,
                -1975
              ],
              [
                -3623,
                -1969
              ],
              [
                -3622,
                -1959
              ],
              [
                -3623,
                -1949
              ],
              [
                -3623,
                -1945
              ],
              [
                -3621,
                -1940
              ],
              [
                -3621,
                -1940
              ],
              [
                -3621,
                -1940
              ],
              [
                -3611,
                -1932
              ],
              [
                -3610,
                -1927
              ],
              [
                -3600,
                -1919
              ],
              [
                -3616,
                -1907
              ],
              [
                -3629,
                -1893
              ],
              [
                -3629,
                -1890
              ],
              [
                -3630,
                -1886
              ],
              [
                -3636,
                -1875
              ],
              [
                -3682,
                -1839
              ],
              [
                -3761,
                -1797
              ],
              [
                -3765,
                -1794
              ],
              [
                -3766,
                -1793
              ],
              [
                -3768,
                -1790
              ],
              [
                -3768,
                -1789
              ],
              [
                -3769,
                -1788
              ],
              [
                -3770,
                -1788
              ],
              [
                -3774,
                -1783
              ],
              [
                -3781,
                -1778
              ],
              [
                -3798,
                -1761
              ],
              [
                -3803,
                -1758
              ],
              [
                -3805,
                -1756
              ],
              [
                -3807,
                -1754
              ],
              [
                -3822,
                -1756
              ],
              [
                -3826,
                -1763
              ],
              [
                -3828,
                -1766
              ],
              [
                -3830,
                -1766
              ],
              [
                -3832,
                -1765
              ],
              [
                -3833,
                -1766
              ],
              [
                -3836,
                -1765
              ],
              [
                -3846,
                -1765
              ],
              [
                -3851,
                -1759
              ],
              [
                -3755,
                -1654
              ],
              [
                -3695,
                -1599
              ],
              [
                -3639,
                -1574
              ],
              [
                -3630,
                -1519
              ],
              [
                -3644,
                -1484
              ],
              [
                -3667,
                -1479
              ],
              [
                -3674,
                -1484
              ],
              [
                -3679,
                -1480
              ],
              [
                -3680,
                -1480
              ],
              [
                -3682,
                -1480
              ],
              [
                -3682,
                -1479
              ],
              [
                -3682,
                -1477
              ],
              [
                -3682,
                -1476
              ],
              [
                -3683,
                -1475
              ],
              [
                -3683,
                -1474
              ],
              [
                -3683,
                -1472
              ],
              [
                -3663,
                -1406
              ],
              [
                -3667,
                -1395
              ],
              [
                -3666,
                -1392
              ],
              [
                -3664,
                -1391
              ],
              [
                -3662,
                -1390
              ],
              [
                -3672,
                -1382
              ],
              [
                -3673,
                -1381
              ],
              [
                -3683,
                -1375
              ],
              [
                -3701,
                -1385
              ],
              [
                -3716,
                -1388
              ],
              [
                -3727,
                -1385
              ],
              [
                -3728,
                -1384
              ],
              [
                -3729,
                -1382
              ],
              [
                -3730,
                -1371
              ],
              [
                -3731,
                -1367
              ],
              [
                -3734,
                -1363
              ],
              [
                -3735,
                -1341
              ],
              [
                -3738,
                -1334
              ],
              [
                -3738,
                -1330
              ],
              [
                -3740,
                -1328
              ],
              [
                -3740,
                -1327
              ],
              [
                -3740,
                -1325
              ],
              [
                -3740,
                -1324
              ],
              [
                -3741,
                -1319
              ],
              [
                -3741,
                -1317
              ],
              [
                -3741,
                -1315
              ],
              [
                -3740,
                -1313
              ],
              [
                -3739,
                -1311
              ],
              [
                -3740,
                -1305
              ],
              [
                -3746,
                -1300
              ],
              [
                -3746,
                -1298
              ],
              [
                -3747,
                -1293
              ],
              [
                -3755,
                -1287
              ],
              [
                -3772,
                -1285
              ],
              [
                -3773,
                -1284
              ],
              [
                -3775,
                -1283
              ],
              [
                -3778,
                -1281
              ],
              [
                -3784,
                -1273
              ],
              [
                -3808,
                -1286
              ],
              [
                -3810,
                -1286
              ],
              [
                -3812,
                -1284
              ],
              [
                -3814,
                -1284
              ],
              [
                -3815,
                -1283
              ],
              [
                -3816,
                -1283
              ],
              [
                -3817,
                -1282
              ],
              [
                -3821,
                -1283
              ],
              [
                -3828,
                -1280
              ],
              [
                -3864,
                -1277
              ],
              [
                -3869,
                -1276
              ],
              [
                -3872,
                -1273
              ],
              [
                -3872,
                -1271
              ],
              [
                -3872,
                -1271
              ],
              [
                -3865,
                -1194
              ],
              [
                -3872,
                -1188
              ],
              [
                -3872,
                -1177
              ],
              [
                -3873,
                -1176
              ],
              [
                -3874,
                -1176
              ],
              [
                -3875,
                -1176
              ],
              [
                -3877,
                -1174
              ],
              [
                -3881,
                -1161
              ],
              [
                -3865,
                -1150
              ],
              [
                -3879,
                -1138
              ],
              [
                -3879,
                -1136
              ],
              [
                -3873,
                -1123
              ],
              [
                -3861,
                -1097
              ],
              [
                -3860,
                -1088
              ],
              [
                -3860,
                -1086
              ],
              [
                -3857,
                -1078
              ],
              [
                -3848,
                -1050
              ],
              [
                -3843,
                -1049
              ],
              [
                -3847,
                -1046
              ],
              [
                -3849,
                -1043
              ],
              [
                -3852,
                -1036
              ],
              [
                -3857,
                -1027
              ],
              [
                -3857,
                -1020
              ],
              [
                -3860,
                -1018
              ],
              [
                -3861,
                -1010
              ],
              [
                -3864,
                -1007
              ],
              [
                -3869,
                -1008
              ],
              [
                -3871,
                -1007
              ],
              [
                -3873,
                -1007
              ],
              [
                -3894,
                -993
              ],
              [
                -3898,
                -939
              ],
              [
                -3902,
                -939
              ],
              [
                -3913,
                -939
              ],
              [
                -3956,
                -937
              ],
              [
                -3991,
                -936
              ],
              [
                -4010,
                -796
              ],
              [
                -4021,
                -790
              ],
              [
                -4025,
                -787
              ],
              [
                -4029,
                -783
              ],
              [
                -4034,
                -781
              ],
              [
                -4060,
                -777
              ],
              [
                -4063,
                -779
              ],
              [
                -4066,
                -780
              ],
              [
                -4087,
                -779
              ],
              [
                -4089,
                -775
              ],
              [
                -4102,
                -762
              ],
              [
                -4102,
                -758
              ],
              [
                -4106,
                -757
              ],
              [
                -4107,
                -756
              ],
              [
                -4108,
                -756
              ],
              [
                -4197,
                -718
              ],
              [
                -4230,
                -718
              ],
              [
                -4264,
                -692
              ],
              [
                -4283,
                -658
              ],
              [
                -4281,
                -569
              ],
              [
                -4355,
                -574
              ],
              [
                -4418,
                -619
              ],
              [
                -4466,
                -642
              ],
              [
                -4481,
                -636
              ],
              [
                -4509,
                -632
              ],
              [
                -4520,
                -633
              ],
              [
                -4579,
                -636
              ],
              [
                -4581,
                -552
              ],
              [
                -4621,
                -579
              ],
              [
                -4666,
                -579
              ],
              [
                -4679,
                -550
              ],
              [
                -4740,
                -487
              ],
              [
                -4754,
                -405
              ],
              [
                -4718,
                -378
              ],
              [
                -4706,
                -305
              ],
              [
                -4642,
                -269
              ],
              [
                -4590,
                -252
              ],
              [
                -4541,
                -255
              ],
              [
                -4510,
                -84
              ],
              [
                -4547,
                -25
              ],
              [
                -4535,
                75
              ],
              [
                -4441,
                78
              ],
              [
                -4395,
                99
              ],
              [
                -4369,
                50
              ],
              [
                -4339,
                25
              ],
              [
                -4271,
                45
              ],
              [
                -4212,
                74
              ],
              [
                -4220,
                157
              ],
              [
                -4211,
                201
              ],
              [
                -4070,
                221
              ],
              [
                -4018,
                256
              ],
              [
                -4026,
                273
              ],
              [
                -3958,
                202
              ],
              [
                -3984,
                133
              ],
              [
                -3971,
                89
              ],
              [
                -3942,
                59
              ],
              [
                -3822,
                95
              ],
              [
                -3787,
                91
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Americas",
        "fullname": "Brazil",
        "admin": "Brazil",
        "woe_id": 23424768,
        "id": "BR",
        "abbrev": "Brazil",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "BRA",
        "name": "BR",
        "continent": "South America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              5744,
              240
            ],
            [
              5799,
              256
            ],
            [
              5777,
              207
            ],
            [
              5744,
              240
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Asia",
        "fullname": "Brunei",
        "admin": "Brunei",
        "woe_id": 23424773,
        "id": "BN",
        "abbrev": "Brunei",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "BRN",
        "name": "BN",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4494,
              1573
            ],
            [
              4516,
              1519
            ],
            [
              4392,
              1509
            ],
            [
              4344,
              1522
            ],
            [
              4340,
              1546
            ],
            [
              4378,
              1596
            ],
            [
              4417,
              1608
            ],
            [
              4494,
              1573
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Asia",
        "fullname": "Bhutan",
        "admin": "Bhutan",
        "woe_id": 23424770,
        "id": "BT",
        "abbrev": "Bhutan",
        "subregion": "Southern Asia",
        "region_wb": "South Asia",
        "iso_a3": "BTN",
        "name": "BT",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              783,
              -1024
            ],
            [
              831,
              -1123
            ],
            [
              919,
              -1181
            ],
            [
              938,
              -1244
            ],
            [
              994,
              -1257
            ],
            [
              993,
              -1268
            ],
            [
              1005,
              -1273
            ],
            [
              1011,
              -1280
            ],
            [
              1010,
              -1280
            ],
            [
              1006,
              -1279
            ],
            [
              1004,
              -1279
            ],
            [
              994,
              -1282
            ],
            [
              987,
              -1295
            ],
            [
              955,
              -1303
            ],
            [
              930,
              -1332
            ],
            [
              919,
              -1339
            ],
            [
              917,
              -1341
            ],
            [
              916,
              -1341
            ],
            [
              914,
              -1340
            ],
            [
              900,
              -1351
            ],
            [
              880,
              -1366
            ],
            [
              873,
              -1394
            ],
            [
              873,
              -1396
            ],
            [
              872,
              -1401
            ],
            [
              847,
              -1424
            ],
            [
              842,
              -1424
            ],
            [
              840,
              -1424
            ],
            [
              817,
              -1431
            ],
            [
              801,
              -1483
            ],
            [
              757,
              -1496
            ],
            [
              657,
              -1465
            ],
            [
              641,
              -1505
            ],
            [
              583,
              -1557
            ],
            [
              551,
              -1558
            ],
            [
              536,
              -1554
            ],
            [
              532,
              -1557
            ],
            [
              529,
              -1558
            ],
            [
              527,
              -1559
            ],
            [
              524,
              -1555
            ],
            [
              524,
              -1553
            ],
            [
              523,
              -1551
            ],
            [
              523,
              -1549
            ],
            [
              523,
              -1547
            ],
            [
              524,
              -1543
            ],
            [
              522,
              -1539
            ],
            [
              522,
              -1537
            ],
            [
              522,
              -1535
            ],
            [
              523,
              -1532
            ],
            [
              523,
              -1531
            ],
            [
              525,
              -1530
            ],
            [
              526,
              -1529
            ],
            [
              531,
              -1522
            ],
            [
              533,
              -1518
            ],
            [
              535,
              -1515
            ],
            [
              536,
              -1514
            ],
            [
              534,
              -1510
            ],
            [
              533,
              -1499
            ],
            [
              532,
              -1496
            ],
            [
              531,
              -1496
            ],
            [
              531,
              -1495
            ],
            [
              530,
              -1494
            ],
            [
              528,
              -1489
            ],
            [
              528,
              -1488
            ],
            [
              526,
              -1488
            ],
            [
              526,
              -1486
            ],
            [
              526,
              -1485
            ],
            [
              526,
              -1484
            ],
            [
              526,
              -1484
            ],
            [
              524,
              -1484
            ],
            [
              524,
              -1483
            ],
            [
              525,
              -1483
            ],
            [
              526,
              -1480
            ],
            [
              523,
              -1478
            ],
            [
              524,
              -1475
            ],
            [
              525,
              -1474
            ],
            [
              525,
              -1473
            ],
            [
              523,
              -1474
            ],
            [
              521,
              -1470
            ],
            [
              519,
              -1469
            ],
            [
              518,
              -1466
            ],
            [
              517,
              -1465
            ],
            [
              516,
              -1461
            ],
            [
              513,
              -1458
            ],
            [
              513,
              -1457
            ],
            [
              513,
              -1456
            ],
            [
              513,
              -1455
            ],
            [
              511,
              -1453
            ],
            [
              510,
              -1449
            ],
            [
              509,
              -1448
            ],
            [
              488,
              -1431
            ],
            [
              487,
              -1269
            ],
            [
              544,
              -1266
            ],
            [
              543,
              -1054
            ],
            [
              674,
              -1036
            ],
            [
              692,
              -1063
            ],
            [
              722,
              -1037
            ],
            [
              783,
              -1024
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Africa",
        "fullname": "Botswana",
        "admin": "Botswana",
        "woe_id": 23424755,
        "id": "BW",
        "abbrev": "Bwa.",
        "subregion": "Southern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "BWA",
        "name": "BW",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              649,
              595
            ],
            [
              649,
              593
            ],
            [
              657,
              582
            ],
            [
              673,
              568
            ],
            [
              691,
              538
            ],
            [
              692,
              535
            ],
            [
              694,
              525
            ],
            [
              692,
              502
            ],
            [
              682,
              495
            ],
            [
              681,
              488
            ],
            [
              681,
              486
            ],
            [
              682,
              485
            ],
            [
              683,
              474
            ],
            [
              759,
              440
            ],
            [
              788,
              393
            ],
            [
              845,
              355
            ],
            [
              854,
              320
            ],
            [
              863,
              318
            ],
            [
              870,
              312
            ],
            [
              879,
              310
            ],
            [
              882,
              306
            ],
            [
              887,
              305
            ],
            [
              890,
              302
            ],
            [
              892,
              298
            ],
            [
              892,
              296
            ],
            [
              894,
              295
            ],
            [
              894,
              295
            ],
            [
              895,
              294
            ],
            [
              895,
              293
            ],
            [
              892,
              287
            ],
            [
              892,
              286
            ],
            [
              892,
              285
            ],
            [
              893,
              284
            ],
            [
              893,
              280
            ],
            [
              897,
              273
            ],
            [
              902,
              270
            ],
            [
              902,
              268
            ],
            [
              904,
              267
            ],
            [
              905,
              266
            ],
            [
              903,
              266
            ],
            [
              902,
              267
            ],
            [
              899,
              267
            ],
            [
              897,
              269
            ],
            [
              893,
              270
            ],
            [
              886,
              273
            ],
            [
              850,
              265
            ],
            [
              801,
              283
            ],
            [
              785,
              264
            ],
            [
              738,
              268
            ],
            [
              678,
              239
            ],
            [
              651,
              252
            ],
            [
              644,
              246
            ],
            [
              639,
              233
            ],
            [
              635,
              233
            ],
            [
              634,
              232
            ],
            [
              633,
              231
            ],
            [
              633,
              229
            ],
            [
              634,
              228
            ],
            [
              635,
              227
            ],
            [
              635,
              227
            ],
            [
              635,
              226
            ],
            [
              628,
              216
            ],
            [
              624,
              213
            ],
            [
              612,
              214
            ],
            [
              585,
              222
            ],
            [
              575,
              220
            ],
            [
              522,
              229
            ],
            [
              473,
              269
            ],
            [
              437,
              257
            ],
            [
              417,
              227
            ],
            [
              409,
              227
            ],
            [
              408,
              226
            ],
            [
              407,
              224
            ],
            [
              407,
              223
            ],
            [
              412,
              177
            ],
            [
              340,
              184
            ],
            [
              297,
              176
            ],
            [
              276,
              107
            ],
            [
              270,
              142
            ],
            [
              219,
              192
            ],
            [
              182,
              278
            ],
            [
              193,
              332
            ],
            [
              236,
              403
            ],
            [
              308,
              405
            ],
            [
              359,
              429
            ],
            [
              410,
              433
            ],
            [
              438,
              487
            ],
            [
              513,
              494
            ],
            [
              581,
              556
            ],
            [
              585,
              579
            ],
            [
              649,
              595
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Africa",
        "fullname": "Central African Rep.",
        "admin": "Central African Republic",
        "woe_id": 23424792,
        "id": "CF",
        "abbrev": "C.A.R.",
        "subregion": "Middle Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "CAF",
        "name": "CF",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -4012,
                2817
              ],
              [
                -4013,
                2773
              ],
              [
                -3973,
                2744
              ],
              [
                -4025,
                2717
              ],
              [
                -4059,
                2717
              ],
              [
                -4070,
                2750
              ],
              [
                -4012,
                2817
              ]
            ]
          ],
          [
            [
              [
                -4807,
                2678
              ],
              [
                -4742,
                2706
              ],
              [
                -4719,
                2751
              ],
              [
                -4646,
                2794
              ],
              [
                -4639,
                2791
              ],
              [
                -4616,
                2799
              ],
              [
                -4573,
                2818
              ],
              [
                -4490,
                2904
              ],
              [
                -4443,
                2934
              ],
              [
                -4354,
                2968
              ],
              [
                -4295,
                2978
              ],
              [
                -4242,
                2968
              ],
              [
                -4226,
                2918
              ],
              [
                -4254,
                2875
              ],
              [
                -4256,
                2802
              ],
              [
                -4237,
                2764
              ],
              [
                -4188,
                2736
              ],
              [
                -4122,
                2720
              ],
              [
                -4092,
                2737
              ],
              [
                -4039,
                2698
              ],
              [
                -4159,
                2661
              ],
              [
                -4223,
                2632
              ],
              [
                -4255,
                2598
              ],
              [
                -4301,
                2575
              ],
              [
                -4331,
                2617
              ],
              [
                -4320,
                2649
              ],
              [
                -4256,
                2688
              ],
              [
                -4281,
                2710
              ],
              [
                -4344,
                2684
              ],
              [
                -4369,
                2682
              ],
              [
                -4372,
                2691
              ],
              [
                -4389,
                2692
              ],
              [
                -4393,
                2688
              ],
              [
                -4395,
                2689
              ],
              [
                -4399,
                2694
              ],
              [
                -4402,
                2696
              ],
              [
                -4420,
                2726
              ],
              [
                -4419,
                2744
              ],
              [
                -4421,
                2820
              ],
              [
                -4421,
                2823
              ],
              [
                -4429,
                2831
              ],
              [
                -4442,
                2841
              ],
              [
                -4502,
                2848
              ],
              [
                -4544,
                2796
              ],
              [
                -4558,
                2742
              ],
              [
                -4594,
                2695
              ],
              [
                -4627,
                2679
              ],
              [
                -4807,
                2678
              ]
            ]
          ],
          [
            [
              [
                -4215,
                3028
              ],
              [
                -4089,
                2985
              ],
              [
                -4110,
                2964
              ],
              [
                -4185,
                2987
              ],
              [
                -4215,
                3028
              ]
            ]
          ],
          [
            [
              [
                -7779,
                3095
              ],
              [
                -7741,
                3077
              ],
              [
                -7643,
                3054
              ],
              [
                -7609,
                2997
              ],
              [
                -7552,
                2968
              ],
              [
                -7538,
                2910
              ],
              [
                -7646,
                2953
              ],
              [
                -7706,
                2989
              ],
              [
                -7773,
                3049
              ],
              [
                -7779,
                3095
              ]
            ]
          ],
          [
            [
              [
                -3754,
                3150
              ],
              [
                -3746,
                3110
              ],
              [
                -3769,
                3043
              ],
              [
                -3755,
                3000
              ],
              [
                -3717,
                2978
              ],
              [
                -3651,
                2994
              ],
              [
                -3643,
                2933
              ],
              [
                -3607,
                2861
              ],
              [
                -3590,
                2802
              ],
              [
                -3624,
                2790
              ],
              [
                -3642,
                2854
              ],
              [
                -3697,
                2844
              ],
              [
                -3719,
                2811
              ],
              [
                -3759,
                2871
              ],
              [
                -3804,
                2855
              ],
              [
                -3878,
                2866
              ],
              [
                -3935,
                2857
              ],
              [
                -3951,
                2879
              ],
              [
                -3910,
                2940
              ],
              [
                -3801,
                3128
              ],
              [
                -3754,
                3150
              ]
            ]
          ],
          [
            [
              [
                -8065,
                3342
              ],
              [
                -7993,
                3329
              ],
              [
                -8010,
                3273
              ],
              [
                -8052,
                3278
              ],
              [
                -8065,
                3342
              ]
            ]
          ],
          [
            [
              [
                -5220,
                4060
              ],
              [
                -5212,
                4036
              ],
              [
                -5278,
                3991
              ],
              [
                -5287,
                4057
              ],
              [
                -5220,
                4060
              ]
            ]
          ],
          [
            [
              [
                -5409,
                4313
              ],
              [
                -5332,
                4259
              ],
              [
                -5235,
                4219
              ],
              [
                -5193,
                4162
              ],
              [
                -5152,
                4162
              ],
              [
                -5159,
                4102
              ],
              [
                -5244,
                4148
              ],
              [
                -5300,
                4161
              ],
              [
                -5397,
                4073
              ],
              [
                -5461,
                4190
              ],
              [
                -5439,
                4301
              ],
              [
                -5409,
                4313
              ]
            ]
          ],
          [
            [
              [
                -4856,
                4549
              ],
              [
                -4826,
                4476
              ],
              [
                -4873,
                4448
              ],
              [
                -4934,
                4447
              ],
              [
                -4953,
                4491
              ],
              [
                -4917,
                4544
              ],
              [
                -4856,
                4549
              ]
            ]
          ],
          [
            [
              [
                -6075,
                4674
              ],
              [
                -6000,
                4637
              ],
              [
                -6013,
                4565
              ],
              [
                -6079,
                4570
              ],
              [
                -6175,
                4599
              ],
              [
                -6121,
                4693
              ],
              [
                -6075,
                4674
              ]
            ]
          ],
          [
            [
              [
                -4807,
                2678
              ],
              [
                -4838,
                2669
              ],
              [
                -4904,
                2615
              ],
              [
                -4926,
                2584
              ],
              [
                -5029,
                2584
              ],
              [
                -5056,
                2573
              ],
              [
                -5048,
                2528
              ],
              [
                -5116,
                2499
              ],
              [
                -5174,
                2488
              ],
              [
                -5238,
                2453
              ],
              [
                -5277,
                2490
              ],
              [
                -5243,
                2518
              ],
              [
                -5222,
                2583
              ],
              [
                -5244,
                2698
              ],
              [
                -5245,
                2701
              ],
              [
                -5247,
                2704
              ],
              [
                -5303,
                2734
              ],
              [
                -5304,
                2755
              ],
              [
                -5323,
                2751
              ],
              [
                -5331,
                2761
              ],
              [
                -5332,
                2771
              ],
              [
                -5335,
                2777
              ],
              [
                -5332,
                2784
              ],
              [
                -5334,
                2785
              ],
              [
                -5337,
                2785
              ],
              [
                -5375,
                2810
              ],
              [
                -5569,
                2909
              ],
              [
                -5625,
                2887
              ],
              [
                -5671,
                2896
              ],
              [
                -5741,
                2890
              ],
              [
                -5810,
                2926
              ],
              [
                -5844,
                2933
              ],
              [
                -5872,
                2925
              ],
              [
                -5878,
                2933
              ],
              [
                -5898,
                2934
              ],
              [
                -5903,
                2939
              ],
              [
                -5918,
                2940
              ],
              [
                -5924,
                2943
              ],
              [
                -5924,
                2944
              ],
              [
                -5925,
                2945
              ],
              [
                -5925,
                2953
              ],
              [
                -5930,
                2971
              ],
              [
                -5930,
                2974
              ],
              [
                -5931,
                2977
              ],
              [
                -5931,
                2980
              ],
              [
                -5932,
                2982
              ],
              [
                -5933,
                2982
              ],
              [
                -5940,
                2985
              ],
              [
                -5950,
                2986
              ],
              [
                -5951,
                2959
              ],
              [
                -7493,
                2959
              ],
              [
                -7603,
                3028
              ],
              [
                -7596,
                3049
              ],
              [
                -7683,
                3088
              ],
              [
                -7760,
                3105
              ],
              [
                -7793,
                3235
              ],
              [
                -7904,
                3326
              ],
              [
                -7921,
                3376
              ],
              [
                -7896,
                3428
              ],
              [
                -7904,
                3467
              ],
              [
                -7897,
                3477
              ],
              [
                -7898,
                3479
              ],
              [
                -7899,
                3477
              ],
              [
                -7899,
                3477
              ],
              [
                -7899,
                3477
              ],
              [
                -7899,
                3478
              ],
              [
                -7899,
                3485
              ],
              [
                -7900,
                3488
              ],
              [
                -7902,
                3491
              ],
              [
                -7903,
                3492
              ],
              [
                -7904,
                3493
              ],
              [
                -7910,
                3491
              ],
              [
                -7920,
                3494
              ],
              [
                -7945,
                3514
              ],
              [
                -7987,
                3531
              ],
              [
                -7998,
                3531
              ],
              [
                -8002,
                3547
              ],
              [
                -8016,
                3552
              ],
              [
                -8011,
                3566
              ],
              [
                -8091,
                3681
              ],
              [
                -8134,
                3712
              ],
              [
                -8204,
                3790
              ],
              [
                -8248,
                3776
              ],
              [
                -8268,
                3737
              ],
              [
                -8323,
                3744
              ],
              [
                -8436,
                3835
              ],
              [
                -8458,
                3823
              ],
              [
                -8487,
                3825
              ],
              [
                -8512,
                3832
              ],
              [
                -8513,
                3833
              ],
              [
                -8513,
                3834
              ],
              [
                -8513,
                4495
              ],
              [
                -8513,
                4523
              ],
              [
                -8513,
                4607
              ],
              [
                -8513,
                4636
              ],
              [
                -8513,
                4664
              ],
              [
                -8513,
                4679
              ],
              [
                -8409,
                4665
              ],
              [
                -8379,
                4639
              ],
              [
                -8270,
                4603
              ],
              [
                -8186,
                4662
              ],
              [
                -8105,
                4648
              ],
              [
                -7919,
                4730
              ],
              [
                -7842,
                4682
              ],
              [
                -7803,
                4712
              ],
              [
                -7786,
                4773
              ],
              [
                -7738,
                4739
              ],
              [
                -7713,
                4690
              ],
              [
                -7643,
                4646
              ],
              [
                -7617,
                4720
              ],
              [
                -7583,
                4720
              ],
              [
                -7571,
                4649
              ],
              [
                -7516,
                4664
              ],
              [
                -7504,
                4697
              ],
              [
                -7419,
                4690
              ],
              [
                -7354,
                4655
              ],
              [
                -7229,
                4617
              ],
              [
                -7127,
                4599
              ],
              [
                -7061,
                4603
              ],
              [
                -7050,
                4544
              ],
              [
                -6995,
                4490
              ],
              [
                -6838,
                4496
              ],
              [
                -6783,
                4519
              ],
              [
                -6751,
                4494
              ],
              [
                -6667,
                4489
              ],
              [
                -6684,
                4578
              ],
              [
                -6571,
                4606
              ],
              [
                -6542,
                4602
              ],
              [
                -6518,
                4553
              ],
              [
                -6478,
                4528
              ],
              [
                -6409,
                4520
              ],
              [
                -6349,
                4492
              ],
              [
                -6261,
                4504
              ],
              [
                -6177,
                4491
              ],
              [
                -6108,
                4571
              ],
              [
                -5998,
                4530
              ],
              [
                -5933,
                4522
              ],
              [
                -5898,
                4648
              ],
              [
                -5996,
                4692
              ],
              [
                -6024,
                4723
              ],
              [
                -6031,
                4796
              ],
              [
                -6004,
                4856
              ],
              [
                -5971,
                4844
              ],
              [
                -5954,
                4911
              ],
              [
                -5916,
                4916
              ],
              [
                -5841,
                4862
              ],
              [
                -5826,
                4795
              ],
              [
                -5783,
                4773
              ],
              [
                -5788,
                4701
              ],
              [
                -5745,
                4680
              ],
              [
                -5654,
                4565
              ],
              [
                -5643,
                4619
              ],
              [
                -5602,
                4638
              ],
              [
                -5551,
                4596
              ],
              [
                -5538,
                4550
              ],
              [
                -5558,
                4488
              ],
              [
                -5512,
                4440
              ],
              [
                -5432,
                4524
              ],
              [
                -5412,
                4662
              ],
              [
                -5345,
                4699
              ],
              [
                -5253,
                4682
              ],
              [
                -5200,
                4641
              ],
              [
                -5173,
                4580
              ],
              [
                -5229,
                4547
              ],
              [
                -5219,
                4509
              ],
              [
                -5172,
                4467
              ],
              [
                -5188,
                4424
              ],
              [
                -5324,
                4350
              ],
              [
                -5397,
                4358
              ],
              [
                -5464,
                4307
              ],
              [
                -5552,
                4167
              ],
              [
                -5614,
                4146
              ],
              [
                -5658,
                4166
              ],
              [
                -5697,
                4069
              ],
              [
                -5742,
                4044
              ],
              [
                -5778,
                4052
              ],
              [
                -5854,
                3950
              ],
              [
                -5884,
                3930
              ],
              [
                -5930,
                3798
              ],
              [
                -5930,
                3726
              ],
              [
                -5907,
                3701
              ],
              [
                -5840,
                3705
              ],
              [
                -5786,
                3565
              ],
              [
                -5719,
                3584
              ],
              [
                -5599,
                3552
              ],
              [
                -5526,
                3488
              ],
              [
                -5420,
                3452
              ],
              [
                -5389,
                3430
              ],
              [
                -5322,
                3432
              ],
              [
                -5231,
                3417
              ],
              [
                -5238,
                3359
              ],
              [
                -5221,
                3315
              ],
              [
                -5231,
                3256
              ],
              [
                -5180,
                3188
              ],
              [
                -5088,
                3114
              ],
              [
                -5018,
                3205
              ],
              [
                -5045,
                3259
              ],
              [
                -5053,
                3342
              ],
              [
                -5074,
                3388
              ],
              [
                -4978,
                3428
              ],
              [
                -4916,
                3490
              ],
              [
                -4912,
                3585
              ],
              [
                -4939,
                3640
              ],
              [
                -5017,
                3702
              ],
              [
                -4954,
                3812
              ],
              [
                -5000,
                3880
              ],
              [
                -4975,
                3914
              ],
              [
                -4999,
                4002
              ],
              [
                -4962,
                4027
              ],
              [
                -4811,
                3992
              ],
              [
                -4748,
                4018
              ],
              [
                -4636,
                3946
              ],
              [
                -4638,
                3915
              ],
              [
                -4550,
                3896
              ],
              [
                -4528,
                3864
              ],
              [
                -4513,
                3717
              ],
              [
                -4475,
                3718
              ],
              [
                -4408,
                3662
              ],
              [
                -4355,
                3683
              ],
              [
                -4300,
                3731
              ],
              [
                -4255,
                3838
              ],
              [
                -4231,
                3829
              ],
              [
                -4090,
                3613
              ],
              [
                -4089,
                3503
              ],
              [
                -4008,
                3467
              ],
              [
                -4008,
                3428
              ],
              [
                -3930,
                3419
              ],
              [
                -3923,
                3394
              ],
              [
                -3864,
                3386
              ],
              [
                -3843,
                3345
              ],
              [
                -3750,
                3279
              ],
              [
                -3761,
                3217
              ],
              [
                -3744,
                3185
              ],
              [
                -3814,
                3137
              ],
              [
                -3898,
                3128
              ],
              [
                -3988,
                3050
              ],
              [
                -4088,
                3048
              ],
              [
                -4278,
                3055
              ],
              [
                -4357,
                3041
              ],
              [
                -4397,
                2984
              ],
              [
                -4438,
                2979
              ],
              [
                -4491,
                2942
              ],
              [
                -4539,
                2872
              ],
              [
                -4583,
                2825
              ],
              [
                -4597,
                2819
              ],
              [
                -4619,
                2799
              ],
              [
                -4648,
                2795
              ],
              [
                -4711,
                2762
              ],
              [
                -4765,
                2702
              ],
              [
                -4807,
                2678
              ]
            ]
          ],
          [
            [
              [
                -7014,
                5054
              ],
              [
                -6992,
                4983
              ],
              [
                -6948,
                5023
              ],
              [
                -6793,
                4971
              ],
              [
                -6784,
                5021
              ],
              [
                -6726,
                4984
              ],
              [
                -6660,
                4876
              ],
              [
                -6669,
                5059
              ],
              [
                -6605,
                5055
              ],
              [
                -6522,
                5000
              ],
              [
                -6502,
                4930
              ],
              [
                -6465,
                4875
              ],
              [
                -6477,
                4821
              ],
              [
                -6418,
                4773
              ],
              [
                -6306,
                4725
              ],
              [
                -6361,
                4670
              ],
              [
                -6330,
                4612
              ],
              [
                -6410,
                4594
              ],
              [
                -6550,
                4632
              ],
              [
                -6589,
                4664
              ],
              [
                -6631,
                4618
              ],
              [
                -6698,
                4610
              ],
              [
                -6759,
                4580
              ],
              [
                -6959,
                4567
              ],
              [
                -6987,
                4631
              ],
              [
                -7114,
                4644
              ],
              [
                -7185,
                4690
              ],
              [
                -7169,
                4726
              ],
              [
                -7249,
                4816
              ],
              [
                -7286,
                4878
              ],
              [
                -7256,
                4969
              ],
              [
                -7192,
                5012
              ],
              [
                -7036,
                5062
              ],
              [
                -7014,
                5054
              ]
            ]
          ],
          [
            [
              [
                -6477,
                5054
              ],
              [
                -6519,
                5008
              ],
              [
                -6584,
                5065
              ],
              [
                -6509,
                5102
              ],
              [
                -6477,
                5054
              ]
            ]
          ],
          [
            [
              [
                -5121,
                5102
              ],
              [
                -4998,
                5092
              ],
              [
                -4947,
                5075
              ],
              [
                -4897,
                5002
              ],
              [
                -5000,
                5010
              ],
              [
                -5099,
                5005
              ],
              [
                -5121,
                5102
              ]
            ]
          ],
          [
            [
              [
                -5469,
                5112
              ],
              [
                -5388,
                5108
              ],
              [
                -5472,
                5010
              ],
              [
                -5461,
                4917
              ],
              [
                -5410,
                4866
              ],
              [
                -5418,
                5007
              ],
              [
                -5388,
                5055
              ],
              [
                -5261,
                5100
              ],
              [
                -5188,
                5097
              ],
              [
                -5138,
                5039
              ],
              [
                -5116,
                4947
              ],
              [
                -4968,
                4995
              ],
              [
                -4834,
                4968
              ],
              [
                -4826,
                4930
              ],
              [
                -4780,
                4924
              ],
              [
                -4746,
                4858
              ],
              [
                -4713,
                4837
              ],
              [
                -4682,
                4880
              ],
              [
                -4632,
                4866
              ],
              [
                -4617,
                4802
              ],
              [
                -4574,
                4803
              ],
              [
                -4452,
                4772
              ],
              [
                -4413,
                4573
              ],
              [
                -4309,
                4506
              ],
              [
                -4265,
                4524
              ],
              [
                -4165,
                4422
              ],
              [
                -4087,
                4422
              ],
              [
                -4068,
                4365
              ],
              [
                -4116,
                4361
              ],
              [
                -4132,
                4295
              ],
              [
                -4163,
                4298
              ],
              [
                -4188,
                4233
              ],
              [
                -4258,
                4266
              ],
              [
                -4317,
                4342
              ],
              [
                -4386,
                4377
              ],
              [
                -4401,
                4302
              ],
              [
                -4356,
                4237
              ],
              [
                -4295,
                4214
              ],
              [
                -4236,
                4093
              ],
              [
                -4250,
                4051
              ],
              [
                -4359,
                4066
              ],
              [
                -4321,
                3998
              ],
              [
                -4335,
                3964
              ],
              [
                -4398,
                3989
              ],
              [
                -4462,
                3998
              ],
              [
                -4638,
                4078
              ],
              [
                -4634,
                4126
              ],
              [
                -4689,
                4139
              ],
              [
                -4776,
                4212
              ],
              [
                -4807,
                4185
              ],
              [
                -4862,
                4202
              ],
              [
                -4925,
                4170
              ],
              [
                -4982,
                4184
              ],
              [
                -4999,
                4236
              ],
              [
                -4957,
                4283
              ],
              [
                -4868,
                4263
              ],
              [
                -4795,
                4271
              ],
              [
                -4751,
                4311
              ],
              [
                -4786,
                4352
              ],
              [
                -4675,
                4435
              ],
              [
                -4679,
                4474
              ],
              [
                -4722,
                4545
              ],
              [
                -4758,
                4552
              ],
              [
                -4901,
                4655
              ],
              [
                -4973,
                4732
              ],
              [
                -5033,
                4732
              ],
              [
                -5040,
                4703
              ],
              [
                -5159,
                4720
              ],
              [
                -5228,
                4694
              ],
              [
                -5273,
                4714
              ],
              [
                -5426,
                4713
              ],
              [
                -5474,
                4746
              ],
              [
                -5541,
                4737
              ],
              [
                -5588,
                4760
              ],
              [
                -5553,
                4835
              ],
              [
                -5651,
                4847
              ],
              [
                -5659,
                4949
              ],
              [
                -5608,
                5048
              ],
              [
                -5536,
                5098
              ],
              [
                -5469,
                5112
              ]
            ]
          ],
          [
            [
              [
                -6211,
                5117
              ],
              [
                -6177,
                5100
              ],
              [
                -6067,
                5113
              ],
              [
                -6108,
                5026
              ],
              [
                -6030,
                4994
              ],
              [
                -6028,
                4900
              ],
              [
                -6107,
                4881
              ],
              [
                -6149,
                4841
              ],
              [
                -6178,
                4849
              ],
              [
                -6295,
                4992
              ],
              [
                -6269,
                5049
              ],
              [
                -6275,
                5108
              ],
              [
                -6211,
                5117
              ]
            ]
          ],
          [
            [
              [
                -5815,
                5137
              ],
              [
                -5672,
                5118
              ],
              [
                -5765,
                5005
              ],
              [
                -5852,
                4999
              ],
              [
                -5890,
                4923
              ],
              [
                -5949,
                4913
              ],
              [
                -5978,
                5000
              ],
              [
                -5975,
                5085
              ],
              [
                -5955,
                5129
              ],
              [
                -5844,
                5147
              ],
              [
                -5815,
                5137
              ]
            ]
          ],
          [
            [
              [
                -7427,
                5189
              ],
              [
                -7348,
                5158
              ],
              [
                -7231,
                5159
              ],
              [
                -7164,
                5138
              ],
              [
                -7082,
                5081
              ],
              [
                -7292,
                4982
              ],
              [
                -7349,
                4941
              ],
              [
                -7374,
                4864
              ],
              [
                -7512,
                4822
              ],
              [
                -7566,
                4885
              ],
              [
                -7660,
                4932
              ],
              [
                -7622,
                4975
              ],
              [
                -7567,
                5077
              ],
              [
                -7567,
                5172
              ],
              [
                -7427,
                5189
              ]
            ]
          ],
          [
            [
              [
                -5907,
                5305
              ],
              [
                -5855,
                5251
              ],
              [
                -5863,
                5200
              ],
              [
                -5937,
                5201
              ],
              [
                -6031,
                5245
              ],
              [
                -5985,
                5294
              ],
              [
                -5907,
                5305
              ]
            ]
          ],
          [
            [
              [
                -6132,
                5427
              ],
              [
                -6098,
                5409
              ],
              [
                -6087,
                5317
              ],
              [
                -6111,
                5241
              ],
              [
                -6239,
                5239
              ],
              [
                -6252,
                5285
              ],
              [
                -6368,
                5293
              ],
              [
                -6300,
                5400
              ],
              [
                -6256,
                5359
              ],
              [
                -6236,
                5425
              ],
              [
                -6132,
                5427
              ]
            ]
          ],
          [
            [
              [
                -6705,
                5445
              ],
              [
                -6701,
                5397
              ],
              [
                -6648,
                5347
              ],
              [
                -6543,
                5349
              ],
              [
                -6522,
                5312
              ],
              [
                -6557,
                5244
              ],
              [
                -6612,
                5229
              ],
              [
                -6714,
                5246
              ],
              [
                -6756,
                5222
              ],
              [
                -6916,
                5173
              ],
              [
                -6986,
                5177
              ],
              [
                -7028,
                5245
              ],
              [
                -7096,
                5235
              ],
              [
                -7200,
                5260
              ],
              [
                -7182,
                5302
              ],
              [
                -7088,
                5402
              ],
              [
                -7015,
                5404
              ],
              [
                -7001,
                5372
              ],
              [
                -6947,
                5381
              ],
              [
                -6861,
                5332
              ],
              [
                -6850,
                5296
              ],
              [
                -6725,
                5293
              ],
              [
                -6767,
                5407
              ],
              [
                -6705,
                5445
              ]
            ]
          ],
          [
            [
              [
                -5954,
                5467
              ],
              [
                -5881,
                5458
              ],
              [
                -5812,
                5418
              ],
              [
                -5742,
                5430
              ],
              [
                -5687,
                5397
              ],
              [
                -5664,
                5351
              ],
              [
                -5560,
                5293
              ],
              [
                -5514,
                5307
              ],
              [
                -5449,
                5284
              ],
              [
                -5321,
                5329
              ],
              [
                -5165,
                5325
              ],
              [
                -5083,
                5290
              ],
              [
                -5118,
                5245
              ],
              [
                -5113,
                5195
              ],
              [
                -5202,
                5178
              ],
              [
                -5298,
                5191
              ],
              [
                -5530,
                5178
              ],
              [
                -5599,
                5201
              ],
              [
                -5659,
                5186
              ],
              [
                -5774,
                5213
              ],
              [
                -5799,
                5280
              ],
              [
                -5780,
                5334
              ],
              [
                -5836,
                5392
              ],
              [
                -5946,
                5376
              ],
              [
                -6033,
                5437
              ],
              [
                -5984,
                5474
              ],
              [
                -5954,
                5467
              ]
            ]
          ],
          [
            [
              [
                -7122,
                5521
              ],
              [
                -7114,
                5429
              ],
              [
                -7169,
                5414
              ],
              [
                -7191,
                5380
              ],
              [
                -7245,
                5413
              ],
              [
                -7326,
                5337
              ],
              [
                -7412,
                5340
              ],
              [
                -7426,
                5400
              ],
              [
                -7273,
                5508
              ],
              [
                -7184,
                5499
              ],
              [
                -7122,
                5521
              ]
            ]
          ],
          [
            [
              [
                -5964,
                5557
              ],
              [
                -5863,
                5557
              ],
              [
                -5865,
                5518
              ],
              [
                -5964,
                5557
              ]
            ]
          ],
          [
            [
              [
                -6758,
                5592
              ],
              [
                -6793,
                5524
              ],
              [
                -6898,
                5503
              ],
              [
                -6907,
                5585
              ],
              [
                -6758,
                5592
              ]
            ]
          ],
          [
            [
              [
                -6047,
                5668
              ],
              [
                -5967,
                5645
              ],
              [
                -5944,
                5580
              ],
              [
                -6045,
                5558
              ],
              [
                -6090,
                5595
              ],
              [
                -6129,
                5677
              ],
              [
                -6047,
                5668
              ]
            ]
          ],
          [
            [
              [
                -6425,
                5747
              ],
              [
                -6355,
                5706
              ],
              [
                -6327,
                5716
              ],
              [
                -6243,
                5684
              ],
              [
                -6175,
                5565
              ],
              [
                -6255,
                5567
              ],
              [
                -6282,
                5608
              ],
              [
                -6416,
                5644
              ],
              [
                -6449,
                5703
              ],
              [
                -6491,
                5680
              ],
              [
                -6509,
                5742
              ],
              [
                -6425,
                5747
              ]
            ]
          ],
          [
            [
              [
                -5816,
                5999
              ],
              [
                -5787,
                5991
              ],
              [
                -5689,
                5901
              ],
              [
                -5533,
                5882
              ],
              [
                -5466,
                5713
              ],
              [
                -5608,
                5605
              ],
              [
                -5660,
                5657
              ],
              [
                -5681,
                5601
              ],
              [
                -5774,
                5609
              ],
              [
                -5866,
                5669
              ],
              [
                -5890,
                5753
              ],
              [
                -5983,
                5755
              ],
              [
                -6037,
                5848
              ],
              [
                -5954,
                5959
              ],
              [
                -5906,
                5954
              ],
              [
                -5896,
                6005
              ],
              [
                -5816,
                5999
              ]
            ]
          ],
          [
            [
              [
                -4526,
                6242
              ],
              [
                -4522,
                6224
              ],
              [
                -4386,
                6216
              ],
              [
                -4390,
                6183
              ],
              [
                -4247,
                6213
              ],
              [
                -4177,
                6202
              ],
              [
                -4146,
                6159
              ],
              [
                -4072,
                6155
              ],
              [
                -4058,
                6116
              ],
              [
                -4229,
                6053
              ],
              [
                -4284,
                6054
              ],
              [
                -4319,
                5987
              ],
              [
                -4468,
                5916
              ],
              [
                -4580,
                5847
              ],
              [
                -4607,
                5803
              ],
              [
                -4812,
                5735
              ],
              [
                -4829,
                5628
              ],
              [
                -4928,
                5572
              ],
              [
                -4984,
                5576
              ],
              [
                -4996,
                5468
              ],
              [
                -4981,
                5424
              ],
              [
                -5066,
                5384
              ],
              [
                -5140,
                5369
              ],
              [
                -5170,
                5415
              ],
              [
                -5230,
                5396
              ],
              [
                -5349,
                5385
              ],
              [
                -5460,
                5397
              ],
              [
                -5529,
                5388
              ],
              [
                -5636,
                5412
              ],
              [
                -5632,
                5451
              ],
              [
                -5566,
                5481
              ],
              [
                -5504,
                5572
              ],
              [
                -5521,
                5638
              ],
              [
                -5472,
                5681
              ],
              [
                -5342,
                5699
              ],
              [
                -5383,
                5781
              ],
              [
                -5463,
                5798
              ],
              [
                -5466,
                5868
              ],
              [
                -5314,
                5861
              ],
              [
                -5075,
                5898
              ],
              [
                -5081,
                5935
              ],
              [
                -5265,
                5898
              ],
              [
                -5300,
                5926
              ],
              [
                -5348,
                5894
              ],
              [
                -5444,
                5898
              ],
              [
                -5469,
                5921
              ],
              [
                -5555,
                5916
              ],
              [
                -5573,
                5957
              ],
              [
                -5652,
                5959
              ],
              [
                -5634,
                6021
              ],
              [
                -5722,
                6067
              ],
              [
                -5550,
                6104
              ],
              [
                -5510,
                6085
              ],
              [
                -5469,
                6122
              ],
              [
                -5366,
                6155
              ],
              [
                -5268,
                6130
              ],
              [
                -5185,
                6202
              ],
              [
                -5111,
                6217
              ],
              [
                -5003,
                6205
              ],
              [
                -4886,
                6233
              ],
              [
                -4778,
                6226
              ],
              [
                -4757,
                6207
              ],
              [
                -4688,
                6240
              ],
              [
                -4526,
                6242
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Americas",
        "fullname": "Canada",
        "admin": "Canada",
        "woe_id": 23424775,
        "id": "CA",
        "abbrev": "Can.",
        "subregion": "Northern America",
        "region_wb": "North America",
        "iso_a3": "CAN",
        "name": "CA",
        "continent": "North America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -96,
              2855
            ],
            [
              -97,
              2836
            ],
            [
              -94,
              2821
            ],
            [
              -45,
              2808
            ],
            [
              -54,
              2786
            ],
            [
              -63,
              2776
            ],
            [
              -63,
              2774
            ],
            [
              -63,
              2773
            ],
            [
              -65,
              2772
            ],
            [
              -65,
              2772
            ],
            [
              -65,
              2771
            ],
            [
              -65,
              2770
            ],
            [
              -65,
              2769
            ],
            [
              -65,
              2769
            ],
            [
              -62,
              2767
            ],
            [
              -62,
              2765
            ],
            [
              -62,
              2764
            ],
            [
              -68,
              2762
            ],
            [
              -111,
              2769
            ],
            [
              -149,
              2756
            ],
            [
              -166,
              2776
            ],
            [
              -176,
              2756
            ],
            [
              -179,
              2754
            ],
            [
              -181,
              2753
            ],
            [
              -183,
              2747
            ],
            [
              -192,
              2741
            ],
            [
              -208,
              2746
            ],
            [
              -234,
              2739
            ],
            [
              -243,
              2751
            ],
            [
              -244,
              2751
            ],
            [
              -245,
              2751
            ],
            [
              -245,
              2750
            ],
            [
              -246,
              2750
            ],
            [
              -246,
              2750
            ],
            [
              -247,
              2751
            ],
            [
              -247,
              2751
            ],
            [
              -246,
              2751
            ],
            [
              -246,
              2752
            ],
            [
              -246,
              2752
            ],
            [
              -247,
              2753
            ],
            [
              -247,
              2753
            ],
            [
              -246,
              2753
            ],
            [
              -246,
              2754
            ],
            [
              -246,
              2756
            ],
            [
              -251,
              2757
            ],
            [
              -248,
              2766
            ],
            [
              -249,
              2768
            ],
            [
              -251,
              2770
            ],
            [
              -252,
              2771
            ],
            [
              -252,
              2772
            ],
            [
              -250,
              2774
            ],
            [
              -250,
              2776
            ],
            [
              -251,
              2777
            ],
            [
              -251,
              2777
            ],
            [
              -260,
              2779
            ],
            [
              -267,
              2778
            ],
            [
              -272,
              2776
            ],
            [
              -276,
              2775
            ],
            [
              -277,
              2775
            ],
            [
              -286,
              2772
            ],
            [
              -287,
              2789
            ],
            [
              -279,
              2795
            ],
            [
              -270,
              2800
            ],
            [
              -236,
              2841
            ],
            [
              -238,
              2852
            ],
            [
              -225,
              2847
            ],
            [
              -217,
              2847
            ],
            [
              -215,
              2848
            ],
            [
              -215,
              2849
            ],
            [
              -214,
              2849
            ],
            [
              -211,
              2852
            ],
            [
              -211,
              2852
            ],
            [
              -211,
              2852
            ],
            [
              -211,
              2852
            ],
            [
              -211,
              2852
            ],
            [
              -211,
              2852
            ],
            [
              -212,
              2853
            ],
            [
              -211,
              2854
            ],
            [
              -211,
              2853
            ],
            [
              -210,
              2854
            ],
            [
              -210,
              2854
            ],
            [
              -210,
              2855
            ],
            [
              -210,
              2855
            ],
            [
              -211,
              2855
            ],
            [
              -209,
              2857
            ],
            [
              -209,
              2857
            ],
            [
              -207,
              2858
            ],
            [
              -203,
              2859
            ],
            [
              -201,
              2859
            ],
            [
              -201,
              2856
            ],
            [
              -192,
              2859
            ],
            [
              -188,
              2859
            ],
            [
              -188,
              2858
            ],
            [
              -187,
              2857
            ],
            [
              -177,
              2857
            ],
            [
              -175,
              2859
            ],
            [
              -174,
              2859
            ],
            [
              -173,
              2860
            ],
            [
              -172,
              2861
            ],
            [
              -166,
              2861
            ],
            [
              -163,
              2858
            ],
            [
              -157,
              2858
            ],
            [
              -153,
              2861
            ],
            [
              -150,
              2859
            ],
            [
              -151,
              2864
            ],
            [
              -153,
              2863
            ],
            [
              -157,
              2862
            ],
            [
              -159,
              2864
            ],
            [
              -160,
              2865
            ],
            [
              -160,
              2866
            ],
            [
              -160,
              2866
            ],
            [
              -160,
              2867
            ],
            [
              -158,
              2868
            ],
            [
              -158,
              2868
            ],
            [
              -157,
              2870
            ],
            [
              -156,
              2871
            ],
            [
              -152,
              2872
            ],
            [
              -152,
              2873
            ],
            [
              -151,
              2874
            ],
            [
              -142,
              2866
            ],
            [
              -96,
              2855
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Europe",
        "fullname": "Switzerland",
        "admin": "Switzerland",
        "woe_id": 23424957,
        "id": "CH",
        "abbrev": "Switz.",
        "subregion": "Western Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "CHE",
        "name": "CH",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -4467,
                -3275
              ],
              [
                -4467,
                -3439
              ],
              [
                -4467,
                -3441
              ],
              [
                -4468,
                -3447
              ],
              [
                -4589,
                -3437
              ],
              [
                -4546,
                -3385
              ],
              [
                -4570,
                -3326
              ],
              [
                -4511,
                -3261
              ],
              [
                -4467,
                -3275
              ]
            ]
          ],
          [
            [
              [
                -4805,
                -2986
              ],
              [
                -4794,
                -3075
              ],
              [
                -4830,
                -3068
              ],
              [
                -4805,
                -2986
              ]
            ]
          ],
          [
            [
              [
                -4764,
                -2504
              ],
              [
                -4738,
                -2527
              ],
              [
                -4760,
                -2614
              ],
              [
                -4789,
                -2603
              ],
              [
                -4764,
                -2504
              ]
            ]
          ],
          [
            [
              [
                -4386,
                -1317
              ],
              [
                -4396,
                -1388
              ],
              [
                -4463,
                -1432
              ],
              [
                -4455,
                -1517
              ],
              [
                -4497,
                -1622
              ],
              [
                -4524,
                -1650
              ],
              [
                -4546,
                -1709
              ],
              [
                -4535,
                -1756
              ],
              [
                -4555,
                -1780
              ],
              [
                -4576,
                -1844
              ],
              [
                -4543,
                -1926
              ],
              [
                -4534,
                -2012
              ],
              [
                -4556,
                -2034
              ],
              [
                -4565,
                -2127
              ],
              [
                -4602,
                -2155
              ],
              [
                -4610,
                -2234
              ],
              [
                -4592,
                -2298
              ],
              [
                -4620,
                -2311
              ],
              [
                -4653,
                -2432
              ],
              [
                -4651,
                -2492
              ],
              [
                -4663,
                -2585
              ],
              [
                -4641,
                -2598
              ],
              [
                -4645,
                -2681
              ],
              [
                -4617,
                -2743
              ],
              [
                -4644,
                -2774
              ],
              [
                -4638,
                -2840
              ],
              [
                -4685,
                -2927
              ],
              [
                -4688,
                -2990
              ],
              [
                -4737,
                -3028
              ],
              [
                -4741,
                -3089
              ],
              [
                -4716,
                -3135
              ],
              [
                -4683,
                -3122
              ],
              [
                -4671,
                -3204
              ],
              [
                -4650,
                -3226
              ],
              [
                -4541,
                -3227
              ],
              [
                -4457,
                -3253
              ],
              [
                -4495,
                -3242
              ],
              [
                -4588,
                -3285
              ],
              [
                -4598,
                -3360
              ],
              [
                -4635,
                -3365
              ],
              [
                -4722,
                -3320
              ],
              [
                -4745,
                -3247
              ],
              [
                -4739,
                -3216
              ],
              [
                -4774,
                -3166
              ],
              [
                -4792,
                -2993
              ],
              [
                -4778,
                -2967
              ],
              [
                -4802,
                -2917
              ],
              [
                -4782,
                -2847
              ],
              [
                -4862,
                -2837
              ],
              [
                -4777,
                -2777
              ],
              [
                -4746,
                -2799
              ],
              [
                -4720,
                -2671
              ],
              [
                -4701,
                -2516
              ],
              [
                -4748,
                -2501
              ],
              [
                -4764,
                -2448
              ],
              [
                -4749,
                -2380
              ],
              [
                -4724,
                -2334
              ],
              [
                -4748,
                -2234
              ],
              [
                -4711,
                -2170
              ],
              [
                -4691,
                -2096
              ],
              [
                -4668,
                -2067
              ],
              [
                -4657,
                -2007
              ],
              [
                -4635,
                -1965
              ],
              [
                -4622,
                -1895
              ],
              [
                -4639,
                -1786
              ],
              [
                -4615,
                -1743
              ],
              [
                -4628,
                -1683
              ],
              [
                -4594,
                -1603
              ],
              [
                -4568,
                -1467
              ],
              [
                -4576,
                -1418
              ],
              [
                -4575,
                -1331
              ],
              [
                -4560,
                -1323
              ],
              [
                -4547,
                -1235
              ],
              [
                -4550,
                -1156
              ],
              [
                -4565,
                -1055
              ],
              [
                -4542,
                -1050
              ],
              [
                -4535,
                -1020
              ],
              [
                -4526,
                -1016
              ],
              [
                -4523,
                -1014
              ],
              [
                -4516,
                -1007
              ],
              [
                -4516,
                -1007
              ],
              [
                -4516,
                -1012
              ],
              [
                -4515,
                -1014
              ],
              [
                -4514,
                -1016
              ],
              [
                -4507,
                -1022
              ],
              [
                -4506,
                -1024
              ],
              [
                -4506,
                -1031
              ],
              [
                -4505,
                -1033
              ],
              [
                -4504,
                -1034
              ],
              [
                -4495,
                -1037
              ],
              [
                -4487,
                -1090
              ],
              [
                -4459,
                -1120
              ],
              [
                -4467,
                -1131
              ],
              [
                -4468,
                -1132
              ],
              [
                -4470,
                -1134
              ],
              [
                -4470,
                -1137
              ],
              [
                -4464,
                -1155
              ],
              [
                -4468,
                -1155
              ],
              [
                -4475,
                -1157
              ],
              [
                -4476,
                -1159
              ],
              [
                -4474,
                -1160
              ],
              [
                -4472,
                -1160
              ],
              [
                -4472,
                -1161
              ],
              [
                -4472,
                -1163
              ],
              [
                -4472,
                -1165
              ],
              [
                -4472,
                -1167
              ],
              [
                -4469,
                -1171
              ],
              [
                -4470,
                -1182
              ],
              [
                -4460,
                -1187
              ],
              [
                -4458,
                -1189
              ],
              [
                -4463,
                -1195
              ],
              [
                -4463,
                -1203
              ],
              [
                -4443,
                -1227
              ],
              [
                -4425,
                -1318
              ],
              [
                -4406,
                -1321
              ],
              [
                -4404,
                -1321
              ],
              [
                -4386,
                -1317
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Americas",
        "fullname": "Chile",
        "admin": "Chile",
        "woe_id": 23424782,
        "id": "CL",
        "abbrev": "Chile",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "CHL",
        "name": "CL",
        "continent": "South America"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                5559,
                1123
              ],
              [
                5576,
                1098
              ],
              [
                5544,
                1038
              ],
              [
                5498,
                1011
              ],
              [
                5448,
                1028
              ],
              [
                5444,
                1073
              ],
              [
                5482,
                1110
              ],
              [
                5559,
                1123
              ]
            ]
          ],
          [
            [
              [
                6668,
                2510
              ],
              [
                6622,
                2504
              ],
              [
                6577,
                2476
              ],
              [
                6542,
                2476
              ],
              [
                6537,
                2434
              ],
              [
                6465,
                2460
              ],
              [
                6416,
                2401
              ],
              [
                6324,
                2348
              ],
              [
                6173,
                2282
              ],
              [
                6160,
                2309
              ],
              [
                6208,
                2375
              ],
              [
                6156,
                2404
              ],
              [
                6104,
                2354
              ],
              [
                6054,
                2334
              ],
              [
                6020,
                2285
              ],
              [
                5952,
                2282
              ],
              [
                5944,
                2251
              ],
              [
                5973,
                2220
              ],
              [
                6021,
                2217
              ],
              [
                6022,
                2165
              ],
              [
                6048,
                2155
              ],
              [
                6121,
                2200
              ],
              [
                6194,
                2146
              ],
              [
                6122,
                2112
              ],
              [
                6119,
                2092
              ],
              [
                6060,
                2056
              ],
              [
                6035,
                2023
              ],
              [
                6094,
                1976
              ],
              [
                6130,
                1895
              ],
              [
                6157,
                1856
              ],
              [
                6153,
                1801
              ],
              [
                6190,
                1765
              ],
              [
                6134,
                1742
              ],
              [
                6190,
                1698
              ],
              [
                6186,
                1665
              ],
              [
                6126,
                1585
              ],
              [
                6093,
                1523
              ],
              [
                6000,
                1382
              ],
              [
                5916,
                1323
              ],
              [
                5884,
                1286
              ],
              [
                5757,
                1264
              ],
              [
                5749,
                1262
              ],
              [
                5686,
                1224
              ],
              [
                5588,
                1204
              ],
              [
                5506,
                1151
              ],
              [
                5510,
                1193
              ],
              [
                5472,
                1208
              ],
              [
                5408,
                1201
              ],
              [
                5337,
                1231
              ],
              [
                5334,
                1282
              ],
              [
                5289,
                1284
              ],
              [
                5260,
                1308
              ],
              [
                5226,
                1280
              ],
              [
                5129,
                1257
              ],
              [
                5080,
                1254
              ],
              [
                5079,
                1256
              ],
              [
                5078,
                1256
              ],
              [
                5077,
                1256
              ],
              [
                5074,
                1257
              ],
              [
                5072,
                1257
              ],
              [
                5071,
                1257
              ],
              [
                5068,
                1257
              ],
              [
                5067,
                1256
              ],
              [
                5063,
                1255
              ],
              [
                5054,
                1257
              ],
              [
                5046,
                1244
              ],
              [
                5026,
                1205
              ],
              [
                4959,
                1215
              ],
              [
                4878,
                1356
              ],
              [
                4833,
                1356
              ],
              [
                4824,
                1393
              ],
              [
                4868,
                1442
              ],
              [
                4892,
                1505
              ],
              [
                4888,
                1562
              ],
              [
                4823,
                1620
              ],
              [
                4812,
                1601
              ],
              [
                4792,
                1608
              ],
              [
                4746,
                1671
              ],
              [
                4669,
                1658
              ],
              [
                4595,
                1628
              ],
              [
                4549,
                1583
              ],
              [
                4494,
                1573
              ],
              [
                4417,
                1608
              ],
              [
                4378,
                1596
              ],
              [
                4340,
                1546
              ],
              [
                4325,
                1594
              ],
              [
                4312,
                1587
              ],
              [
                4298,
                1585
              ],
              [
                4296,
                1582
              ],
              [
                4297,
                1579
              ],
              [
                4292,
                1581
              ],
              [
                4281,
                1582
              ],
              [
                4275,
                1576
              ],
              [
                4243,
                1577
              ],
              [
                4236,
                1584
              ],
              [
                4229,
                1585
              ],
              [
                4226,
                1589
              ],
              [
                4222,
                1588
              ],
              [
                4220,
                1589
              ],
              [
                4209,
                1593
              ],
              [
                4209,
                1590
              ],
              [
                4208,
                1588
              ],
              [
                4208,
                1586
              ],
              [
                4207,
                1585
              ],
              [
                4206,
                1584
              ],
              [
                4203,
                1582
              ],
              [
                4194,
                1587
              ],
              [
                4192,
                1587
              ],
              [
                4191,
                1587
              ],
              [
                4191,
                1587
              ],
              [
                4190,
                1588
              ],
              [
                4190,
                1588
              ],
              [
                4189,
                1590
              ],
              [
                4188,
                1592
              ],
              [
                4189,
                1594
              ],
              [
                4187,
                1597
              ],
              [
                4183,
                1592
              ],
              [
                4157,
                1603
              ],
              [
                4127,
                1607
              ],
              [
                4040,
                1660
              ],
              [
                4020,
                1686
              ],
              [
                4002,
                1688
              ],
              [
                3985,
                1704
              ],
              [
                3982,
                1706
              ],
              [
                3980,
                1707
              ],
              [
                3978,
                1709
              ],
              [
                3977,
                1709
              ],
              [
                3963,
                1713
              ],
              [
                3921,
                1732
              ],
              [
                3910,
                1709
              ],
              [
                3905,
                1710
              ],
              [
                3901,
                1718
              ],
              [
                3899,
                1721
              ],
              [
                3899,
                1721
              ],
              [
                3798,
                1790
              ],
              [
                3773,
                1791
              ],
              [
                3784,
                1853
              ],
              [
                3815,
                1878
              ],
              [
                3775,
                1929
              ],
              [
                3772,
                1962
              ],
              [
                3720,
                2051
              ],
              [
                3663,
                2060
              ],
              [
                3629,
                2071
              ],
              [
                3618,
                2112
              ],
              [
                3583,
                2141
              ],
              [
                3538,
                2148
              ],
              [
                3558,
                2162
              ],
              [
                3573,
                2173
              ],
              [
                3551,
                2244
              ],
              [
                3504,
                2246
              ],
              [
                3490,
                2267
              ],
              [
                3497,
                2276
              ],
              [
                3486,
                2290
              ],
              [
                3488,
                2298
              ],
              [
                3488,
                2300
              ],
              [
                3487,
                2304
              ],
              [
                3487,
                2305
              ],
              [
                3498,
                2307
              ],
              [
                3504,
                2315
              ],
              [
                3498,
                2329
              ],
              [
                3505,
                2340
              ],
              [
                3505,
                2342
              ],
              [
                3505,
                2343
              ],
              [
                3506,
                2344
              ],
              [
                3506,
                2344
              ],
              [
                3508,
                2346
              ],
              [
                3554,
                2375
              ],
              [
                3575,
                2371
              ],
              [
                3595,
                2383
              ],
              [
                3601,
                2371
              ],
              [
                3599,
                2366
              ],
              [
                3601,
                2362
              ],
              [
                3602,
                2361
              ],
              [
                3603,
                2361
              ],
              [
                3606,
                2362
              ],
              [
                3615,
                2362
              ],
              [
                3633,
                2371
              ],
              [
                3635,
                2370
              ],
              [
                3635,
                2369
              ],
              [
                3636,
                2365
              ],
              [
                3637,
                2364
              ],
              [
                3638,
                2364
              ],
              [
                3645,
                2369
              ],
              [
                3654,
                2383
              ],
              [
                3655,
                2390
              ],
              [
                3668,
                2408
              ],
              [
                3670,
                2409
              ],
              [
                3676,
                2411
              ],
              [
                3702,
                2406
              ],
              [
                3736,
                2410
              ],
              [
                3751,
                2427
              ],
              [
                3751,
                2428
              ],
              [
                3750,
                2430
              ],
              [
                3752,
                2433
              ],
              [
                3769,
                2443
              ],
              [
                3853,
                2476
              ],
              [
                3855,
                2487
              ],
              [
                3864,
                2543
              ],
              [
                3886,
                2549
              ],
              [
                3847,
                2679
              ],
              [
                3971,
                2715
              ],
              [
                4019,
                2832
              ],
              [
                4062,
                2815
              ],
              [
                4151,
                2821
              ],
              [
                4167,
                2917
              ],
              [
                4210,
                2925
              ],
              [
                4253,
                2965
              ],
              [
                4280,
                2971
              ],
              [
                4280,
                2970
              ],
              [
                4281,
                2968
              ],
              [
                4281,
                2967
              ],
              [
                4281,
                2961
              ],
              [
                4277,
                2954
              ],
              [
                4280,
                2945
              ],
              [
                4289,
                2942
              ],
              [
                4294,
                2936
              ],
              [
                4291,
                2926
              ],
              [
                4349,
                2887
              ],
              [
                4377,
                2890
              ],
              [
                4388,
                2875
              ],
              [
                4396,
                2875
              ],
              [
                4398,
                2876
              ],
              [
                4399,
                2877
              ],
              [
                4399,
                2878
              ],
              [
                4399,
                2879
              ],
              [
                4399,
                2879
              ],
              [
                4400,
                2880
              ],
              [
                4402,
                2879
              ],
              [
                4405,
                2879
              ],
              [
                4406,
                2878
              ],
              [
                4406,
                2877
              ],
              [
                4405,
                2874
              ],
              [
                4407,
                2870
              ],
              [
                4414,
                2866
              ],
              [
                4419,
                2865
              ],
              [
                4420,
                2864
              ],
              [
                4422,
                2864
              ],
              [
                4422,
                2863
              ],
              [
                4421,
                2862
              ],
              [
                4421,
                2861
              ],
              [
                4421,
                2860
              ],
              [
                4422,
                2859
              ],
              [
                4424,
                2855
              ],
              [
                4424,
                2854
              ],
              [
                4427,
                2852
              ],
              [
                4427,
                2846
              ],
              [
                4430,
                2844
              ],
              [
                4430,
                2843
              ],
              [
                4429,
                2841
              ],
              [
                4429,
                2840
              ],
              [
                4428,
                2839
              ],
              [
                4429,
                2839
              ],
              [
                4442,
                2818
              ],
              [
                4449,
                2816
              ],
              [
                4458,
                2799
              ],
              [
                4458,
                2749
              ],
              [
                4457,
                2747
              ],
              [
                4454,
                2744
              ],
              [
                4442,
                2730
              ],
              [
                4441,
                2728
              ],
              [
                4439,
                2712
              ],
              [
                4440,
                2711
              ],
              [
                4444,
                2708
              ],
              [
                4447,
                2701
              ],
              [
                4447,
                2699
              ],
              [
                4447,
                2698
              ],
              [
                4450,
                2697
              ],
              [
                4451,
                2695
              ],
              [
                4450,
                2693
              ],
              [
                4451,
                2691
              ],
              [
                4453,
                2691
              ],
              [
                4495,
                2682
              ],
              [
                4556,
                2681
              ],
              [
                4567,
                2678
              ],
              [
                4573,
                2678
              ],
              [
                4574,
                2678
              ],
              [
                4578,
                2679
              ],
              [
                4579,
                2678
              ],
              [
                4599,
                2675
              ],
              [
                4684,
                2627
              ],
              [
                4703,
                2629
              ],
              [
                4700,
                2610
              ],
              [
                4705,
                2609
              ],
              [
                4708,
                2608
              ],
              [
                4710,
                2608
              ],
              [
                4728,
                2569
              ],
              [
                4730,
                2561
              ],
              [
                4731,
                2558
              ],
              [
                4733,
                2556
              ],
              [
                4757,
                2524
              ],
              [
                4804,
                2527
              ],
              [
                4932,
                2512
              ],
              [
                4940,
                2514
              ],
              [
                4960,
                2520
              ],
              [
                4962,
                2520
              ],
              [
                5053,
                2509
              ],
              [
                5067,
                2493
              ],
              [
                5072,
                2488
              ],
              [
                5075,
                2487
              ],
              [
                5169,
                2458
              ],
              [
                5191,
                2462
              ],
              [
                5213,
                2466
              ],
              [
                5252,
                2457
              ],
              [
                5340,
                2493
              ],
              [
                5419,
                2505
              ],
              [
                5492,
                2504
              ],
              [
                5493,
                2505
              ],
              [
                5494,
                2506
              ],
              [
                5502,
                2511
              ],
              [
                5543,
                2526
              ],
              [
                5544,
                2527
              ],
              [
                5546,
                2530
              ],
              [
                5547,
                2532
              ],
              [
                5552,
                2535
              ],
              [
                5555,
                2538
              ],
              [
                5556,
                2541
              ],
              [
                5558,
                2544
              ],
              [
                5559,
                2545
              ],
              [
                5573,
                2561
              ],
              [
                5582,
                2566
              ],
              [
                5595,
                2571
              ],
              [
                5610,
                2577
              ],
              [
                5619,
                2586
              ],
              [
                5624,
                2587
              ],
              [
                5626,
                2588
              ],
              [
                5627,
                2588
              ],
              [
                5629,
                2589
              ],
              [
                5629,
                2591
              ],
              [
                5629,
                2593
              ],
              [
                5629,
                2595
              ],
              [
                5628,
                2597
              ],
              [
                5623,
                2605
              ],
              [
                5620,
                2608
              ],
              [
                5614,
                2612
              ],
              [
                5612,
                2614
              ],
              [
                5605,
                2622
              ],
              [
                5599,
                2638
              ],
              [
                5618,
                2676
              ],
              [
                5633,
                2684
              ],
              [
                5667,
                2672
              ],
              [
                5674,
                2669
              ],
              [
                5694,
                2664
              ],
              [
                5715,
                2662
              ],
              [
                5716,
                2662
              ],
              [
                5717,
                2661
              ],
              [
                5718,
                2660
              ],
              [
                5722,
                2660
              ],
              [
                5724,
                2660
              ],
              [
                5747,
                2674
              ],
              [
                5768,
                2692
              ],
              [
                5774,
                2704
              ],
              [
                5797,
                2704
              ],
              [
                5819,
                2705
              ],
              [
                5826,
                2706
              ],
              [
                5836,
                2709
              ],
              [
                5890,
                2769
              ],
              [
                5981,
                2797
              ],
              [
                6013,
                2800
              ],
              [
                6018,
                2797
              ],
              [
                6019,
                2797
              ],
              [
                6022,
                2799
              ],
              [
                6027,
                2793
              ],
              [
                6041,
                2790
              ],
              [
                6062,
                2788
              ],
              [
                6067,
                2793
              ],
              [
                6068,
                2793
              ],
              [
                6070,
                2793
              ],
              [
                6072,
                2794
              ],
              [
                6073,
                2796
              ],
              [
                6074,
                2796
              ],
              [
                6074,
                2797
              ],
              [
                6074,
                2806
              ],
              [
                6063,
                2828
              ],
              [
                5998,
                2885
              ],
              [
                5983,
                2888
              ],
              [
                5982,
                2888
              ],
              [
                5979,
                2889
              ],
              [
                5978,
                2890
              ],
              [
                5953,
                2886
              ],
              [
                5941,
                2873
              ],
              [
                5936,
                2869
              ],
              [
                5934,
                2865
              ],
              [
                5932,
                2863
              ],
              [
                5904,
                2879
              ],
              [
                5848,
                2867
              ],
              [
                5829,
                2896
              ],
              [
                5845,
                2907
              ],
              [
                5894,
                3019
              ],
              [
                5954,
                2996
              ],
              [
                6041,
                3038
              ],
              [
                6040,
                3075
              ],
              [
                6086,
                3154
              ],
              [
                6123,
                3187
              ],
              [
                6095,
                3244
              ],
              [
                6128,
                3275
              ],
              [
                6282,
                3296
              ],
              [
                6344,
                3265
              ],
              [
                6394,
                3258
              ],
              [
                6434,
                3208
              ],
              [
                6467,
                3109
              ],
              [
                6532,
                2998
              ],
              [
                6564,
                3003
              ],
              [
                6623,
                2979
              ],
              [
                6669,
                2933
              ],
              [
                6696,
                2865
              ],
              [
                6780,
                2867
              ],
              [
                6812,
                2895
              ],
              [
                6884,
                2915
              ],
              [
                6906,
                2867
              ],
              [
                6871,
                2835
              ],
              [
                6857,
                2765
              ],
              [
                6804,
                2680
              ],
              [
                6740,
                2701
              ],
              [
                6697,
                2672
              ],
              [
                6710,
                2567
              ],
              [
                6668,
                2510
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Asia",
        "fullname": "China",
        "admin": "China",
        "woe_id": 23424781,
        "id": "CN",
        "abbrev": "China",
        "subregion": "Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "CHN",
        "name": "CN",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -979,
              583
            ],
            [
              -954,
              566
            ],
            [
              -942,
              568
            ],
            [
              -941,
              568
            ],
            [
              -940,
              567
            ],
            [
              -938,
              567
            ],
            [
              -937,
              566
            ],
            [
              -932,
              560
            ],
            [
              -915,
              559
            ],
            [
              -868,
              528
            ],
            [
              -834,
              540
            ],
            [
              -805,
              533
            ],
            [
              -797,
              526
            ],
            [
              -792,
              517
            ],
            [
              -784,
              509
            ],
            [
              -782,
              512
            ],
            [
              -780,
              514
            ],
            [
              -779,
              513
            ],
            [
              -778,
              502
            ],
            [
              -770,
              442
            ],
            [
              -770,
              441
            ],
            [
              -773,
              439
            ],
            [
              -775,
              440
            ],
            [
              -776,
              439
            ],
            [
              -776,
              438
            ],
            [
              -776,
              437
            ],
            [
              -775,
              436
            ],
            [
              -775,
              434
            ],
            [
              -776,
              432
            ],
            [
              -788,
              420
            ],
            [
              -788,
              419
            ],
            [
              -788,
              419
            ],
            [
              -788,
              418
            ],
            [
              -789,
              417
            ],
            [
              -794,
              406
            ],
            [
              -800,
              378
            ],
            [
              -803,
              377
            ],
            [
              -803,
              377
            ],
            [
              -803,
              377
            ],
            [
              -810,
              366
            ],
            [
              -811,
              363
            ],
            [
              -811,
              361
            ],
            [
              -810,
              360
            ],
            [
              -809,
              358
            ],
            [
              -809,
              358
            ],
            [
              -810,
              355
            ],
            [
              -812,
              354
            ],
            [
              -812,
              353
            ],
            [
              -812,
              353
            ],
            [
              -808,
              333
            ],
            [
              -804,
              327
            ],
            [
              -796,
              297
            ],
            [
              -785,
              294
            ],
            [
              -784,
              287
            ],
            [
              -782,
              284
            ],
            [
              -782,
              283
            ],
            [
              -782,
              282
            ],
            [
              -783,
              281
            ],
            [
              -784,
              281
            ],
            [
              -786,
              281
            ],
            [
              -785,
              270
            ],
            [
              -815,
              269
            ],
            [
              -857,
              279
            ],
            [
              -957,
              264
            ],
            [
              -1016,
              243
            ],
            [
              -1046,
              225
            ],
            [
              -1051,
              226
            ],
            [
              -1053,
              227
            ],
            [
              -1053,
              228
            ],
            [
              -1053,
              228
            ],
            [
              -1053,
              229
            ],
            [
              -1052,
              230
            ],
            [
              -1053,
              246
            ],
            [
              -1053,
              265
            ],
            [
              -1043,
              291
            ],
            [
              -1046,
              309
            ],
            [
              -1073,
              333
            ],
            [
              -1109,
              349
            ],
            [
              -1093,
              375
            ],
            [
              -1102,
              404
            ],
            [
              -1104,
              405
            ],
            [
              -1101,
              408
            ],
            [
              -1100,
              408
            ],
            [
              -1099,
              407
            ],
            [
              -1094,
              407
            ],
            [
              -1090,
              405
            ],
            [
              -1078,
              437
            ],
            [
              -1092,
              444
            ],
            [
              -1088,
              458
            ],
            [
              -1064,
              452
            ],
            [
              -1060,
              455
            ],
            [
              -1059,
              456
            ],
            [
              -1059,
              458
            ],
            [
              -1059,
              459
            ],
            [
              -1060,
              464
            ],
            [
              -1069,
              510
            ],
            [
              -1082,
              510
            ],
            [
              -1085,
              515
            ],
            [
              -1086,
              518
            ],
            [
              -1086,
              519
            ],
            [
              -1086,
              519
            ],
            [
              -1084,
              534
            ],
            [
              -1084,
              535
            ],
            [
              -1085,
              535
            ],
            [
              -1085,
              538
            ],
            [
              -1086,
              538
            ],
            [
              -1086,
              539
            ],
            [
              -1087,
              539
            ],
            [
              -1087,
              540
            ],
            [
              -1085,
              543
            ],
            [
              -1083,
              546
            ],
            [
              -1079,
              548
            ],
            [
              -1076,
              552
            ],
            [
              -1075,
              552
            ],
            [
              -1074,
              552
            ],
            [
              -1067,
              555
            ],
            [
              -1061,
              566
            ],
            [
              -1019,
              553
            ],
            [
              -979,
              583
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "C\u00c3\u00b4te d'Ivoire",
        "admin": "Ivory Coast",
        "woe_id": 23424854,
        "id": "CI",
        "abbrev": "I.C.",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "CIV",
        "name": "CI",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              236,
              403
            ],
            [
              193,
              332
            ],
            [
              182,
              278
            ],
            [
              219,
              192
            ],
            [
              270,
              142
            ],
            [
              276,
              107
            ],
            [
              252,
              89
            ],
            [
              185,
              106
            ],
            [
              114,
              103
            ],
            [
              114,
              104
            ],
            [
              114,
              106
            ],
            [
              114,
              107
            ],
            [
              113,
              107
            ],
            [
              111,
              109
            ],
            [
              83,
              107
            ],
            [
              53,
              110
            ],
            [
              28,
              110
            ],
            [
              23,
              112
            ],
            [
              20,
              111
            ],
            [
              5,
              111
            ],
            [
              5,
              110
            ],
            [
              5,
              110
            ],
            [
              5,
              109
            ],
            [
              5,
              108
            ],
            [
              5,
              107
            ],
            [
              4,
              107
            ],
            [
              4,
              104
            ],
            [
              4,
              104
            ],
            [
              3,
              103
            ],
            [
              -71,
              103
            ],
            [
              -78,
              106
            ],
            [
              -79,
              107
            ],
            [
              -81,
              109
            ],
            [
              -81,
              110
            ],
            [
              -81,
              111
            ],
            [
              -81,
              112
            ],
            [
              -81,
              115
            ],
            [
              -80,
              125
            ],
            [
              -77,
              143
            ],
            [
              -76,
              165
            ],
            [
              -128,
              211
            ],
            [
              -134,
              309
            ],
            [
              -64,
              374
            ],
            [
              -37,
              377
            ],
            [
              14,
              352
            ],
            [
              52,
              429
            ],
            [
              86,
              477
            ],
            [
              90,
              508
            ],
            [
              152,
              615
            ],
            [
              186,
              627
            ],
            [
              191,
              665
            ],
            [
              191,
              666
            ],
            [
              190,
              667
            ],
            [
              190,
              667
            ],
            [
              187,
              668
            ],
            [
              184,
              670
            ],
            [
              182,
              674
            ],
            [
              165,
              676
            ],
            [
              163,
              678
            ],
            [
              157,
              717
            ],
            [
              177,
              717
            ],
            [
              200,
              691
            ],
            [
              217,
              629
            ],
            [
              213,
              588
            ],
            [
              239,
              547
            ],
            [
              163,
              542
            ],
            [
              150,
              522
            ],
            [
              219,
              457
            ],
            [
              236,
              403
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Cameroon",
        "admin": "Cameroon",
        "woe_id": 23424785,
        "id": "CM",
        "abbrev": "Cam.",
        "subregion": "Middle Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "CMR",
        "name": "CM",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                1272,
                2024
              ],
              [
                1273,
                2023
              ],
              [
                1266,
                2023
              ],
              [
                1266,
                2024
              ],
              [
                1272,
                2024
              ]
            ]
          ],
          [
            [
              [
                1192,
                2030
              ],
              [
                1193,
                2031
              ],
              [
                1195,
                2031
              ],
              [
                1196,
                2031
              ],
              [
                1192,
                2030
              ]
            ]
          ],
          [
            [
              [
                1253,
                2022
              ],
              [
                1255,
                2019
              ],
              [
                1198,
                2031
              ],
              [
                1199,
                2031
              ],
              [
                1253,
                2022
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 9,
        "region_un": "Asia",
        "fullname": "Cyprus U.N. Buffer Zone",
        "admin": "Cyprus No Mans Area",
        "woe_id": -99,
        "id": "Cyprus_U.N._Buffer_Zone",
        "subregion": "Western Asia",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "-99",
        "name": "-99",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              905,
              266
            ],
            [
              958,
              221
            ],
            [
              1026,
              242
            ],
            [
              1059,
              203
            ],
            [
              1078,
              197
            ],
            [
              1080,
              184
            ],
            [
              1086,
              185
            ],
            [
              1095,
              181
            ],
            [
              1094,
              178
            ],
            [
              1095,
              177
            ],
            [
              1096,
              178
            ],
            [
              1097,
              179
            ],
            [
              1098,
              179
            ],
            [
              1099,
              178
            ],
            [
              1099,
              172
            ],
            [
              1089,
              152
            ],
            [
              1096,
              144
            ],
            [
              1088,
              119
            ],
            [
              1114,
              111
            ],
            [
              1119,
              104
            ],
            [
              1119,
              103
            ],
            [
              1119,
              103
            ],
            [
              1119,
              100
            ],
            [
              1117,
              97
            ],
            [
              1114,
              93
            ],
            [
              1110,
              88
            ],
            [
              1109,
              86
            ],
            [
              1105,
              82
            ],
            [
              1086,
              66
            ],
            [
              1081,
              60
            ],
            [
              1079,
              57
            ],
            [
              1074,
              51
            ],
            [
              1073,
              50
            ],
            [
              1073,
              50
            ],
            [
              1068,
              49
            ],
            [
              1064,
              48
            ],
            [
              1063,
              48
            ],
            [
              1063,
              47
            ],
            [
              1061,
              46
            ],
            [
              1060,
              39
            ],
            [
              1057,
              34
            ],
            [
              1056,
              33
            ],
            [
              1056,
              33
            ],
            [
              1050,
              31
            ],
            [
              1044,
              26
            ],
            [
              1032,
              -12
            ],
            [
              1027,
              -43
            ],
            [
              1027,
              -44
            ],
            [
              1028,
              -46
            ],
            [
              1028,
              -50
            ],
            [
              1025,
              -59
            ],
            [
              1026,
              -66
            ],
            [
              1023,
              -69
            ],
            [
              1024,
              -79
            ],
            [
              1023,
              -84
            ],
            [
              1024,
              -86
            ],
            [
              1024,
              -87
            ],
            [
              1024,
              -87
            ],
            [
              1024,
              -89
            ],
            [
              1024,
              -89
            ],
            [
              1025,
              -91
            ],
            [
              1025,
              -92
            ],
            [
              1024,
              -96
            ],
            [
              1022,
              -96
            ],
            [
              1020,
              -98
            ],
            [
              1018,
              -100
            ],
            [
              1017,
              -102
            ],
            [
              1017,
              -102
            ],
            [
              1016,
              -102
            ],
            [
              1016,
              -102
            ],
            [
              1016,
              -102
            ],
            [
              1012,
              -102
            ],
            [
              1011,
              -103
            ],
            [
              1010,
              -104
            ],
            [
              1009,
              -107
            ],
            [
              1008,
              -107
            ],
            [
              1006,
              -110
            ],
            [
              1005,
              -111
            ],
            [
              1004,
              -113
            ],
            [
              1002,
              -115
            ],
            [
              1000,
              -119
            ],
            [
              999,
              -121
            ],
            [
              999,
              -123
            ],
            [
              999,
              -125
            ],
            [
              1000,
              -134
            ],
            [
              1001,
              -136
            ],
            [
              1000,
              -138
            ],
            [
              998,
              -143
            ],
            [
              996,
              -144
            ],
            [
              991,
              -146
            ],
            [
              991,
              -147
            ],
            [
              990,
              -148
            ],
            [
              990,
              -149
            ],
            [
              989,
              -150
            ],
            [
              988,
              -150
            ],
            [
              985,
              -151
            ],
            [
              984,
              -152
            ],
            [
              984,
              -153
            ],
            [
              984,
              -154
            ],
            [
              984,
              -155
            ],
            [
              985,
              -157
            ],
            [
              986,
              -162
            ],
            [
              986,
              -166
            ],
            [
              986,
              -167
            ],
            [
              988,
              -168
            ],
            [
              991,
              -169
            ],
            [
              992,
              -169
            ],
            [
              993,
              -170
            ],
            [
              993,
              -171
            ],
            [
              992,
              -175
            ],
            [
              995,
              -176
            ],
            [
              997,
              -182
            ],
            [
              1005,
              -188
            ],
            [
              1014,
              -267
            ],
            [
              1015,
              -268
            ],
            [
              1015,
              -270
            ],
            [
              1014,
              -275
            ],
            [
              1010,
              -289
            ],
            [
              1025,
              -333
            ],
            [
              1033,
              -390
            ],
            [
              1068,
              -427
            ],
            [
              1090,
              -477
            ],
            [
              987,
              -493
            ],
            [
              957,
              -536
            ],
            [
              956,
              -538
            ],
            [
              957,
              -540
            ],
            [
              962,
              -541
            ],
            [
              965,
              -544
            ],
            [
              971,
              -559
            ],
            [
              972,
              -564
            ],
            [
              975,
              -567
            ],
            [
              975,
              -567
            ],
            [
              974,
              -568
            ],
            [
              973,
              -569
            ],
            [
              971,
              -576
            ],
            [
              971,
              -587
            ],
            [
              970,
              -589
            ],
            [
              968,
              -590
            ],
            [
              968,
              -591
            ],
            [
              970,
              -593
            ],
            [
              971,
              -596
            ],
            [
              971,
              -608
            ],
            [
              975,
              -615
            ],
            [
              960,
              -655
            ],
            [
              964,
              -684
            ],
            [
              994,
              -714
            ],
            [
              1037,
              -701
            ],
            [
              1024,
              -762
            ],
            [
              1017,
              -766
            ],
            [
              1009,
              -769
            ],
            [
              1006,
              -771
            ],
            [
              1004,
              -772
            ],
            [
              1003,
              -774
            ],
            [
              1002,
              -774
            ],
            [
              1001,
              -774
            ],
            [
              960,
              -722
            ],
            [
              904,
              -688
            ],
            [
              881,
              -670
            ],
            [
              878,
              -685
            ],
            [
              860,
              -691
            ],
            [
              824,
              -687
            ],
            [
              788,
              -672
            ],
            [
              784,
              -647
            ],
            [
              750,
              -655
            ],
            [
              744,
              -661
            ],
            [
              737,
              -662
            ],
            [
              733,
              -659
            ],
            [
              730,
              -659
            ],
            [
              729,
              -658
            ],
            [
              728,
              -657
            ],
            [
              734,
              -651
            ],
            [
              734,
              -650
            ],
            [
              735,
              -649
            ],
            [
              734,
              -648
            ],
            [
              734,
              -647
            ],
            [
              734,
              -642
            ],
            [
              729,
              -638
            ],
            [
              719,
              -638
            ],
            [
              719,
              -631
            ],
            [
              715,
              -629
            ],
            [
              712,
              -628
            ],
            [
              710,
              -628
            ],
            [
              657,
              -641
            ],
            [
              610,
              -627
            ],
            [
              618,
              -600
            ],
            [
              592,
              -557
            ],
            [
              597,
              -489
            ],
            [
              588,
              -426
            ],
            [
              518,
              -426
            ],
            [
              462,
              -410
            ],
            [
              453,
              -466
            ],
            [
              351,
              -471
            ],
            [
              283,
              -346
            ],
            [
              108,
              -346
            ],
            [
              53,
              -341
            ],
            [
              71,
              -307
            ],
            [
              88,
              -282
            ],
            [
              99,
              -280
            ],
            [
              101,
              -279
            ],
            [
              101,
              -277
            ],
            [
              101,
              -275
            ],
            [
              102,
              -274
            ],
            [
              104,
              -274
            ],
            [
              107,
              -280
            ],
            [
              117,
              -286
            ],
            [
              174,
              -257
            ],
            [
              200,
              -287
            ],
            [
              258,
              -239
            ],
            [
              277,
              -206
            ],
            [
              278,
              -137
            ],
            [
              312,
              -88
            ],
            [
              363,
              -47
            ],
            [
              381,
              103
            ],
            [
              399,
              134
            ],
            [
              406,
              154
            ],
            [
              411,
              157
            ],
            [
              412,
              160
            ],
            [
              413,
              168
            ],
            [
              413,
              205
            ],
            [
              411,
              215
            ],
            [
              409,
              221
            ],
            [
              407,
              222
            ],
            [
              407,
              223
            ],
            [
              407,
              224
            ],
            [
              408,
              226
            ],
            [
              409,
              227
            ],
            [
              417,
              227
            ],
            [
              437,
              257
            ],
            [
              473,
              269
            ],
            [
              522,
              229
            ],
            [
              575,
              220
            ],
            [
              585,
              222
            ],
            [
              612,
              214
            ],
            [
              624,
              213
            ],
            [
              628,
              216
            ],
            [
              631,
              221
            ],
            [
              632,
              224
            ],
            [
              635,
              226
            ],
            [
              635,
              227
            ],
            [
              635,
              227
            ],
            [
              634,
              228
            ],
            [
              633,
              229
            ],
            [
              633,
              231
            ],
            [
              634,
              232
            ],
            [
              635,
              233
            ],
            [
              639,
              233
            ],
            [
              644,
              246
            ],
            [
              651,
              252
            ],
            [
              678,
              239
            ],
            [
              738,
              268
            ],
            [
              788,
              279
            ],
            [
              801,
              283
            ],
            [
              831,
              276
            ],
            [
              850,
              265
            ],
            [
              886,
              273
            ],
            [
              893,
              270
            ],
            [
              897,
              269
            ],
            [
              899,
              267
            ],
            [
              902,
              267
            ],
            [
              903,
              266
            ],
            [
              903,
              266
            ],
            [
              905,
              266
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Africa",
        "fullname": "Dem. Rep. Congo",
        "admin": "Democratic Republic of the Congo",
        "woe_id": 23424780,
        "id": "CD",
        "abbrev": "D.R.C.",
        "subregion": "Middle Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "COD",
        "name": "CD",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              412,
              177
            ],
            [
              413,
              168
            ],
            [
              412,
              160
            ],
            [
              411,
              157
            ],
            [
              406,
              154
            ],
            [
              399,
              134
            ],
            [
              381,
              103
            ],
            [
              363,
              -47
            ],
            [
              312,
              -88
            ],
            [
              278,
              -137
            ],
            [
              277,
              -206
            ],
            [
              258,
              -239
            ],
            [
              200,
              -287
            ],
            [
              174,
              -257
            ],
            [
              117,
              -286
            ],
            [
              107,
              -280
            ],
            [
              104,
              -274
            ],
            [
              102,
              -274
            ],
            [
              101,
              -275
            ],
            [
              101,
              -277
            ],
            [
              99,
              -276
            ],
            [
              92,
              -269
            ],
            [
              89,
              -264
            ],
            [
              87,
              -265
            ],
            [
              86,
              -265
            ],
            [
              84,
              -264
            ],
            [
              82,
              -267
            ],
            [
              81,
              -268
            ],
            [
              80,
              -268
            ],
            [
              79,
              -269
            ],
            [
              75,
              -273
            ],
            [
              62,
              -276
            ],
            [
              54,
              -287
            ],
            [
              53,
              -284
            ],
            [
              52,
              -284
            ],
            [
              52,
              -285
            ],
            [
              50,
              -290
            ],
            [
              49,
              -293
            ],
            [
              42,
              -298
            ],
            [
              42,
              -299
            ],
            [
              40,
              -297
            ],
            [
              36,
              -291
            ],
            [
              29,
              -280
            ],
            [
              31,
              -274
            ],
            [
              -8,
              -238
            ],
            [
              -3,
              -225
            ],
            [
              17,
              -216
            ],
            [
              22,
              -223
            ],
            [
              24,
              -225
            ],
            [
              27,
              -224
            ],
            [
              28,
              -225
            ],
            [
              31,
              -225
            ],
            [
              32,
              -216
            ],
            [
              24,
              -195
            ],
            [
              24,
              -195
            ],
            [
              24,
              -191
            ],
            [
              25,
              -188
            ],
            [
              28,
              -185
            ],
            [
              21,
              -176
            ],
            [
              15,
              -174
            ],
            [
              17,
              -149
            ],
            [
              17,
              -148
            ],
            [
              19,
              -148
            ],
            [
              22,
              -149
            ],
            [
              23,
              -149
            ],
            [
              28,
              -153
            ],
            [
              29,
              -152
            ],
            [
              31,
              -151
            ],
            [
              38,
              -148
            ],
            [
              45,
              -153
            ],
            [
              67,
              -148
            ],
            [
              68,
              -138
            ],
            [
              69,
              -136
            ],
            [
              69,
              -135
            ],
            [
              67,
              -134
            ],
            [
              66,
              -132
            ],
            [
              65,
              -130
            ],
            [
              65,
              -124
            ],
            [
              65,
              -123
            ],
            [
              66,
              -123
            ],
            [
              67,
              -124
            ],
            [
              68,
              -125
            ],
            [
              69,
              -125
            ],
            [
              74,
              -120
            ],
            [
              86,
              -125
            ],
            [
              90,
              -133
            ],
            [
              92,
              -141
            ],
            [
              94,
              -140
            ],
            [
              96,
              -143
            ],
            [
              97,
              -145
            ],
            [
              97,
              -145
            ],
            [
              97,
              -145
            ],
            [
              98,
              -145
            ],
            [
              98,
              -146
            ],
            [
              97,
              -146
            ],
            [
              97,
              -147
            ],
            [
              97,
              -148
            ],
            [
              96,
              -150
            ],
            [
              95,
              -150
            ],
            [
              96,
              -151
            ],
            [
              96,
              -151
            ],
            [
              98,
              -149
            ],
            [
              105,
              -151
            ],
            [
              105,
              -151
            ],
            [
              106,
              -150
            ],
            [
              108,
              -150
            ],
            [
              117,
              -154
            ],
            [
              118,
              -154
            ],
            [
              120,
              -154
            ],
            [
              122,
              -154
            ],
            [
              147,
              -157
            ],
            [
              158,
              -158
            ],
            [
              161,
              -154
            ],
            [
              161,
              -153
            ],
            [
              161,
              -152
            ],
            [
              166,
              -148
            ],
            [
              162,
              -144
            ],
            [
              167,
              -128
            ],
            [
              174,
              -125
            ],
            [
              174,
              -125
            ],
            [
              174,
              -108
            ],
            [
              178,
              -102
            ],
            [
              178,
              -100
            ],
            [
              178,
              -99
            ],
            [
              179,
              -98
            ],
            [
              179,
              -98
            ],
            [
              180,
              -97
            ],
            [
              180,
              -97
            ],
            [
              180,
              -96
            ],
            [
              181,
              -53
            ],
            [
              181,
              -51
            ],
            [
              179,
              -49
            ],
            [
              170,
              -42
            ],
            [
              162,
              -43
            ],
            [
              162,
              -39
            ],
            [
              161,
              -37
            ],
            [
              160,
              -35
            ],
            [
              149,
              -21
            ],
            [
              149,
              -17
            ],
            [
              147,
              -15
            ],
            [
              147,
              -11
            ],
            [
              147,
              -10
            ],
            [
              146,
              -8
            ],
            [
              146,
              -7
            ],
            [
              146,
              -6
            ],
            [
              148,
              -3
            ],
            [
              150,
              2
            ],
            [
              157,
              12
            ],
            [
              160,
              13
            ],
            [
              166,
              12
            ],
            [
              178,
              28
            ],
            [
              178,
              30
            ],
            [
              178,
              31
            ],
            [
              178,
              31
            ],
            [
              179,
              32
            ],
            [
              179,
              32
            ],
            [
              179,
              33
            ],
            [
              179,
              34
            ],
            [
              178,
              34
            ],
            [
              176,
              36
            ],
            [
              169,
              47
            ],
            [
              167,
              57
            ],
            [
              163,
              59
            ],
            [
              161,
              60
            ],
            [
              160,
              60
            ],
            [
              156,
              59
            ],
            [
              142,
              62
            ],
            [
              111,
              50
            ],
            [
              104,
              71
            ],
            [
              105,
              72
            ],
            [
              105,
              76
            ],
            [
              108,
              85
            ],
            [
              109,
              94
            ],
            [
              113,
              96
            ],
            [
              114,
              98
            ],
            [
              114,
              100
            ],
            [
              114,
              103
            ],
            [
              185,
              106
            ],
            [
              252,
              89
            ],
            [
              276,
              107
            ],
            [
              297,
              176
            ],
            [
              340,
              184
            ],
            [
              412,
              177
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Africa",
        "fullname": "Congo",
        "admin": "Republic of Congo",
        "woe_id": 23424779,
        "id": "CG",
        "abbrev": "Rep. Congo",
        "subregion": "Middle Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "COG",
        "name": "CG",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -4617,
              647
            ],
            [
              -4653,
              637
            ],
            [
              -4706,
              568
            ],
            [
              -4712,
              503
            ],
            [
              -4677,
              449
            ],
            [
              -4680,
              399
            ],
            [
              -4660,
              378
            ],
            [
              -4549,
              371
            ],
            [
              -4512,
              325
            ],
            [
              -4408,
              333
            ],
            [
              -4423,
              279
            ],
            [
              -4411,
              193
            ],
            [
              -4422,
              144
            ],
            [
              -4394,
              120
            ],
            [
              -4369,
              50
            ],
            [
              -4395,
              99
            ],
            [
              -4441,
              78
            ],
            [
              -4535,
              75
            ],
            [
              -4547,
              -25
            ],
            [
              -4510,
              -84
            ],
            [
              -4541,
              -255
            ],
            [
              -4584,
              -229
            ],
            [
              -4546,
              -170
            ],
            [
              -4592,
              -143
            ],
            [
              -4642,
              -138
            ],
            [
              -4708,
              -153
            ],
            [
              -4747,
              -88
            ],
            [
              -4783,
              -71
            ],
            [
              -4813,
              -27
            ],
            [
              -4839,
              -24
            ],
            [
              -4882,
              2
            ],
            [
              -4958,
              5
            ],
            [
              -5037,
              62
            ],
            [
              -5015,
              127
            ],
            [
              -4995,
              122
            ],
            [
              -4946,
              185
            ],
            [
              -4958,
              206
            ],
            [
              -4954,
              348
            ],
            [
              -4985,
              387
            ],
            [
              -4946,
              429
            ],
            [
              -4956,
              468
            ],
            [
              -4916,
              468
            ],
            [
              -4860,
              526
            ],
            [
              -4851,
              576
            ],
            [
              -4814,
              606
            ],
            [
              -4727,
              616
            ],
            [
              -4670,
              649
            ],
            [
              -4636,
              682
            ],
            [
              -4617,
              647
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Americas",
        "fullname": "Colombia",
        "admin": "Colombia",
        "woe_id": 23424787,
        "id": "CO",
        "abbrev": "Col.",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "COL",
        "name": "CO",
        "continent": "South America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -5306,
              595
            ],
            [
              -5246,
              519
            ],
            [
              -5264,
              432
            ],
            [
              -5305,
              488
            ],
            [
              -5374,
              533
            ],
            [
              -5418,
              538
            ],
            [
              -5421,
              604
            ],
            [
              -5306,
              595
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Americas",
        "fullname": "Costa Rica",
        "admin": "Costa Rica",
        "woe_id": 23424791,
        "id": "CR",
        "abbrev": "C.R.",
        "subregion": "Central America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "CRI",
        "name": "CR",
        "continent": "North America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -4828,
              1108
            ],
            [
              -4830,
              1113
            ],
            [
              -4832,
              1113
            ],
            [
              -4836,
              1109
            ],
            [
              -4893,
              1114
            ],
            [
              -4976,
              1106
            ],
            [
              -4941,
              1144
            ],
            [
              -4994,
              1156
            ],
            [
              -5033,
              1210
            ],
            [
              -5060,
              1205
            ],
            [
              -5127,
              1233
            ],
            [
              -5204,
              1242
            ],
            [
              -5207,
              1271
            ],
            [
              -5250,
              1271
            ],
            [
              -5293,
              1242
            ],
            [
              -5327,
              1271
            ],
            [
              -5282,
              1290
            ],
            [
              -5216,
              1302
            ],
            [
              -5104,
              1287
            ],
            [
              -5066,
              1255
            ],
            [
              -5034,
              1254
            ],
            [
              -4963,
              1223
            ],
            [
              -4915,
              1190
            ],
            [
              -4858,
              1177
            ],
            [
              -4782,
              1118
            ],
            [
              -4828,
              1108
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Americas",
        "fullname": "Cuba",
        "admin": "Cuba",
        "woe_id": 23424793,
        "id": "CU",
        "abbrev": "Cuba",
        "subregion": "Caribbean",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "CUB",
        "name": "CU",
        "continent": "North America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1272,
              2024
            ],
            [
              1266,
              2024
            ],
            [
              1253,
              2022
            ],
            [
              1199,
              2031
            ],
            [
              1273,
              2049
            ],
            [
              1272,
              2024
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Asia",
        "fullname": "N. Cyprus",
        "admin": "Northern Cyprus",
        "woe_id": -90,
        "id": "N._Cyprus",
        "abbrev": "N. Cy.",
        "subregion": "Western Asia",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "-99",
        "name": "-99",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1255,
              2019
            ],
            [
              1217,
              1997
            ],
            [
              1192,
              2030
            ],
            [
              1196,
              2031
            ],
            [
              1198,
              2031
            ],
            [
              1255,
              2019
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Asia",
        "fullname": "Cyprus",
        "admin": "Cyprus",
        "woe_id": -90,
        "id": "CY",
        "abbrev": "Cyp.",
        "subregion": "Western Asia",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "CYP",
        "name": "CY",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              198,
              3094
            ],
            [
              208,
              3094
            ],
            [
              209,
              3105
            ],
            [
              284,
              3079
            ],
            [
              304,
              3038
            ],
            [
              331,
              3059
            ],
            [
              423,
              2996
            ],
            [
              369,
              2954
            ],
            [
              318,
              2931
            ],
            [
              208,
              2960
            ],
            [
              190,
              2929
            ],
            [
              143,
              2943
            ],
            [
              131,
              2955
            ],
            [
              119,
              2959
            ],
            [
              97,
              2981
            ],
            [
              86,
              2983
            ],
            [
              63,
              3013
            ],
            [
              70,
              3060
            ],
            [
              98,
              3067
            ],
            [
              106,
              3068
            ],
            [
              110,
              3074
            ],
            [
              119,
              3077
            ],
            [
              122,
              3075
            ],
            [
              125,
              3077
            ],
            [
              127,
              3080
            ],
            [
              127,
              3081
            ],
            [
              126,
              3082
            ],
            [
              128,
              3083
            ],
            [
              131,
              3084
            ],
            [
              136,
              3084
            ],
            [
              139,
              3084
            ],
            [
              140,
              3084
            ],
            [
              143,
              3084
            ],
            [
              144,
              3084
            ],
            [
              181,
              3106
            ],
            [
              187,
              3093
            ],
            [
              197,
              3091
            ],
            [
              198,
              3094
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Europe",
        "fullname": "Czech Rep.",
        "admin": "Czech Republic",
        "woe_id": 23424810,
        "id": "CZ",
        "abbrev": "Cz. Rep.",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "CZE",
        "name": "CZ",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -102,
              3392
            ],
            [
              -5,
              3331
            ],
            [
              69,
              3366
            ],
            [
              168,
              3311
            ],
            [
              168,
              3303
            ],
            [
              170,
              3292
            ],
            [
              172,
              3290
            ],
            [
              173,
              3287
            ],
            [
              176,
              3274
            ],
            [
              189,
              3222
            ],
            [
              196,
              3184
            ],
            [
              186,
              3167
            ],
            [
              194,
              3143
            ],
            [
              206,
              3138
            ],
            [
              210,
              3125
            ],
            [
              210,
              3123
            ],
            [
              209,
              3121
            ],
            [
              208,
              3117
            ],
            [
              208,
              3115
            ],
            [
              208,
              3114
            ],
            [
              208,
              3113
            ],
            [
              207,
              3113
            ],
            [
              207,
              3112
            ],
            [
              207,
              3110
            ],
            [
              207,
              3109
            ],
            [
              201,
              3099
            ],
            [
              198,
              3096
            ],
            [
              198,
              3094
            ],
            [
              197,
              3091
            ],
            [
              181,
              3106
            ],
            [
              144,
              3084
            ],
            [
              143,
              3084
            ],
            [
              140,
              3084
            ],
            [
              139,
              3084
            ],
            [
              136,
              3084
            ],
            [
              131,
              3084
            ],
            [
              128,
              3083
            ],
            [
              126,
              3082
            ],
            [
              125,
              3077
            ],
            [
              122,
              3075
            ],
            [
              119,
              3077
            ],
            [
              110,
              3074
            ],
            [
              106,
              3068
            ],
            [
              98,
              3067
            ],
            [
              70,
              3060
            ],
            [
              55,
              3035
            ],
            [
              63,
              3013
            ],
            [
              86,
              2983
            ],
            [
              97,
              2981
            ],
            [
              119,
              2959
            ],
            [
              142,
              2943
            ],
            [
              137,
              2925
            ],
            [
              135,
              2926
            ],
            [
              134,
              2927
            ],
            [
              133,
              2928
            ],
            [
              132,
              2928
            ],
            [
              126,
              2929
            ],
            [
              120,
              2915
            ],
            [
              83,
              2896
            ],
            [
              83,
              2864
            ],
            [
              -10,
              2845
            ],
            [
              -36,
              2857
            ],
            [
              -38,
              2857
            ],
            [
              -40,
              2855
            ],
            [
              -40,
              2854
            ],
            [
              -41,
              2854
            ],
            [
              -46,
              2855
            ],
            [
              -53,
              2838
            ],
            [
              -73,
              2854
            ],
            [
              -142,
              2866
            ],
            [
              -151,
              2874
            ],
            [
              -152,
              2873
            ],
            [
              -151,
              2872
            ],
            [
              -152,
              2872
            ],
            [
              -156,
              2871
            ],
            [
              -157,
              2870
            ],
            [
              -158,
              2868
            ],
            [
              -158,
              2868
            ],
            [
              -160,
              2867
            ],
            [
              -160,
              2866
            ],
            [
              -160,
              2866
            ],
            [
              -160,
              2865
            ],
            [
              -160,
              2865
            ],
            [
              -159,
              2864
            ],
            [
              -157,
              2862
            ],
            [
              -153,
              2863
            ],
            [
              -151,
              2864
            ],
            [
              -157,
              2858
            ],
            [
              -163,
              2858
            ],
            [
              -166,
              2861
            ],
            [
              -172,
              2861
            ],
            [
              -173,
              2860
            ],
            [
              -174,
              2859
            ],
            [
              -175,
              2859
            ],
            [
              -177,
              2857
            ],
            [
              -187,
              2857
            ],
            [
              -188,
              2858
            ],
            [
              -188,
              2859
            ],
            [
              -192,
              2859
            ],
            [
              -201,
              2856
            ],
            [
              -201,
              2859
            ],
            [
              -203,
              2859
            ],
            [
              -205,
              2858
            ],
            [
              -206,
              2895
            ],
            [
              -172,
              2957
            ],
            [
              -275,
              2992
            ],
            [
              -288,
              3040
            ],
            [
              -294,
              3086
            ],
            [
              -283,
              3142
            ],
            [
              -236,
              3206
            ],
            [
              -227,
              3272
            ],
            [
              -226,
              3304
            ],
            [
              -174,
              3293
            ],
            [
              -134,
              3317
            ],
            [
              -126,
              3368
            ],
            [
              -145,
              3398
            ],
            [
              -102,
              3392
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Europe",
        "fullname": "Germany",
        "admin": "Germany",
        "woe_id": 23424829,
        "id": "DE",
        "abbrev": "Ger.",
        "subregion": "Western Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "DEU",
        "name": "DE",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1788,
              627
            ],
            [
              1770,
              599
            ],
            [
              1707,
              598
            ],
            [
              1705,
              630
            ],
            [
              1740,
              682
            ],
            [
              1781,
              696
            ],
            [
              1798,
              670
            ],
            [
              1788,
              627
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Africa",
        "fullname": "Djibouti",
        "admin": "Djibouti",
        "woe_id": 23424797,
        "id": "DJ",
        "abbrev": "Dji.",
        "subregion": "Eastern Africa",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "DJI",
        "name": "DJ",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                60,
                3494
              ],
              [
                75,
                3462
              ],
              [
                35,
                3405
              ],
              [
                -1,
                3423
              ],
              [
                60,
                3494
              ]
            ]
          ],
          [
            [
              [
                -102,
                3392
              ],
              [
                -145,
                3398
              ],
              [
                -175,
                3528
              ],
              [
                -151,
                3530
              ],
              [
                -72,
                3611
              ],
              [
                -33,
                3497
              ],
              [
                -87,
                3448
              ],
              [
                -102,
                3392
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Europe",
        "fullname": "Denmark",
        "admin": "Denmark",
        "woe_id": 23424796,
        "id": "DK",
        "abbrev": "Den.",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "DNK",
        "name": "DK",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -4643,
              1001
            ],
            [
              -4642,
              1098
            ],
            [
              -4599,
              1111
            ],
            [
              -4538,
              1093
            ],
            [
              -4521,
              1062
            ],
            [
              -4473,
              1054
            ],
            [
              -4479,
              1020
            ],
            [
              -4537,
              1026
            ],
            [
              -4552,
              1013
            ],
            [
              -4643,
              1001
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Americas",
        "fullname": "Dominican Rep.",
        "admin": "Dominican Republic",
        "woe_id": 23424800,
        "id": "DO",
        "abbrev": "Dom. Rep.",
        "subregion": "Caribbean",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "DOM",
        "name": "DO",
        "continent": "North America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -97,
              1723
            ],
            [
              -110,
              1716
            ],
            [
              -80,
              1656
            ],
            [
              -74,
              1580
            ],
            [
              -86,
              1545
            ],
            [
              -80,
              1498
            ],
            [
              -104,
              1474
            ],
            [
              -72,
              1432
            ],
            [
              -69,
              1399
            ],
            [
              17,
              1365
            ],
            [
              40,
              1320
            ],
            [
              -211,
              1165
            ],
            [
              -303,
              1084
            ],
            [
              -393,
              1065
            ],
            [
              -445,
              1055
            ],
            [
              -450,
              1102
            ],
            [
              -524,
              1128
            ],
            [
              -565,
              1179
            ],
            [
              -899,
              1408
            ],
            [
              -909,
              1414
            ],
            [
              -919,
              1420
            ],
            [
              -938,
              1433
            ],
            [
              -1115,
              1545
            ],
            [
              -1115,
              1567
            ],
            [
              -1115,
              1628
            ],
            [
              -1056,
              1672
            ],
            [
              -1029,
              1679
            ],
            [
              -1009,
              1675
            ],
            [
              -1004,
              1680
            ],
            [
              -951,
              1686
            ],
            [
              -874,
              1740
            ],
            [
              -844,
              1747
            ],
            [
              -833,
              1753
            ],
            [
              -834,
              1756
            ],
            [
              -834,
              1760
            ],
            [
              -831,
              1763
            ],
            [
              -828,
              1768
            ],
            [
              -833,
              1776
            ],
            [
              -839,
              1781
            ],
            [
              -840,
              1781
            ],
            [
              -842,
              1780
            ],
            [
              -843,
              1784
            ],
            [
              -843,
              1790
            ],
            [
              -843,
              1791
            ],
            [
              -842,
              1792
            ],
            [
              -839,
              1794
            ],
            [
              -835,
              1794
            ],
            [
              -834,
              1810
            ],
            [
              -810,
              1815
            ],
            [
              -797,
              1818
            ],
            [
              -788,
              1819
            ],
            [
              -770,
              1840
            ],
            [
              -763,
              1840
            ],
            [
              -759,
              1840
            ],
            [
              -699,
              1837
            ],
            [
              -698,
              1856
            ],
            [
              -723,
              1963
            ],
            [
              -731,
              1980
            ],
            [
              -727,
              1988
            ],
            [
              -734,
              1994
            ],
            [
              -734,
              1995
            ],
            [
              -731,
              1999
            ],
            [
              -729,
              2002
            ],
            [
              -730,
              2004
            ],
            [
              -752,
              2020
            ],
            [
              -753,
              2021
            ],
            [
              -754,
              2023
            ],
            [
              -754,
              2025
            ],
            [
              -753,
              2026
            ],
            [
              -752,
              2025
            ],
            [
              -750,
              2026
            ],
            [
              -744,
              2024
            ],
            [
              -728,
              2028
            ],
            [
              -676,
              2068
            ],
            [
              -571,
              2114
            ],
            [
              -482,
              2122
            ],
            [
              -466,
              2135
            ],
            [
              -362,
              2140
            ],
            [
              -333,
              2124
            ],
            [
              -217,
              2152
            ],
            [
              -154,
              2141
            ],
            [
              -149,
              2143
            ],
            [
              -148,
              2140
            ],
            [
              -146,
              2137
            ],
            [
              -146,
              2136
            ],
            [
              -147,
              2136
            ],
            [
              -159,
              2124
            ],
            [
              -163,
              2112
            ],
            [
              -162,
              2105
            ],
            [
              -165,
              2098
            ],
            [
              -165,
              2096
            ],
            [
              -165,
              2095
            ],
            [
              -165,
              2093
            ],
            [
              -165,
              2092
            ],
            [
              -165,
              2091
            ],
            [
              -166,
              2090
            ],
            [
              -167,
              2085
            ],
            [
              -167,
              2082
            ],
            [
              -167,
              2081
            ],
            [
              -168,
              2077
            ],
            [
              -169,
              2072
            ],
            [
              -169,
              2069
            ],
            [
              -168,
              2067
            ],
            [
              -164,
              2061
            ],
            [
              -169,
              1997
            ],
            [
              -192,
              1983
            ],
            [
              -195,
              1973
            ],
            [
              -203,
              1969
            ],
            [
              -206,
              1966
            ],
            [
              -208,
              1964
            ],
            [
              -209,
              1963
            ],
            [
              -211,
              1950
            ],
            [
              -199,
              1922
            ],
            [
              -199,
              1920
            ],
            [
              -199,
              1920
            ],
            [
              -199,
              1911
            ],
            [
              -196,
              1907
            ],
            [
              -124,
              1836
            ],
            [
              -123,
              1831
            ],
            [
              -121,
              1825
            ],
            [
              -120,
              1818
            ],
            [
              -118,
              1811
            ],
            [
              -112,
              1784
            ],
            [
              -110,
              1777
            ],
            [
              -102,
              1743
            ],
            [
              -100,
              1737
            ],
            [
              -99,
              1730
            ],
            [
              -97,
              1723
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Algeria",
        "admin": "Algeria",
        "woe_id": 23424740,
        "id": "DZ",
        "abbrev": "Alg.",
        "subregion": "Northern Africa",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "DZA",
        "name": "DZ",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -4839,
              -24
            ],
            [
              -4854,
              -102
            ],
            [
              -4917,
              -162
            ],
            [
              -4982,
              -185
            ],
            [
              -5011,
              -213
            ],
            [
              -5047,
              -295
            ],
            [
              -5107,
              -259
            ],
            [
              -5121,
              -208
            ],
            [
              -5150,
              -108
            ],
            [
              -5106,
              -15
            ],
            [
              -5107,
              28
            ],
            [
              -5044,
              47
            ],
            [
              -5037,
              62
            ],
            [
              -4958,
              5
            ],
            [
              -4882,
              2
            ],
            [
              -4839,
              -24
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Americas",
        "fullname": "Ecuador",
        "admin": "Ecuador",
        "woe_id": 23424801,
        "id": "EC",
        "abbrev": "Ecu.",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "ECU",
        "name": "EC",
        "continent": "South America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1283,
              1790
            ],
            [
              1285,
              1783
            ],
            [
              1321,
              1678
            ],
            [
              1276,
              1576
            ],
            [
              1244,
              1585
            ],
            [
              1272,
              1504
            ],
            [
              1355,
              1355
            ],
            [
              1366,
              1286
            ],
            [
              1433,
              1231
            ],
            [
              767,
              1231
            ],
            [
              767,
              1659
            ],
            [
              758,
              1702
            ],
            [
              753,
              1714
            ],
            [
              751,
              1718
            ],
            [
              763,
              1740
            ],
            [
              764,
              1742
            ],
            [
              764,
              1743
            ],
            [
              765,
              1747
            ],
            [
              766,
              1750
            ],
            [
              768,
              1755
            ],
            [
              768,
              1757
            ],
            [
              769,
              1801
            ],
            [
              814,
              1808
            ],
            [
              898,
              1794
            ],
            [
              993,
              1760
            ],
            [
              1068,
              1802
            ],
            [
              1109,
              1808
            ],
            [
              1163,
              1775
            ],
            [
              1252,
              1778
            ],
            [
              1283,
              1790
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Africa",
        "fullname": "Egypt",
        "admin": "Egypt",
        "woe_id": 23424802,
        "id": "EG",
        "abbrev": "Egypt",
        "subregion": "Northern Africa",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "EGY",
        "name": "EG",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1413,
              785
            ],
            [
              1407,
              833
            ],
            [
              1436,
              898
            ],
            [
              1439,
              945
            ],
            [
              1514,
              979
            ],
            [
              1529,
              999
            ],
            [
              1547,
              964
            ],
            [
              1564,
              890
            ],
            [
              1591,
              842
            ],
            [
              1671,
              806
            ],
            [
              1701,
              766
            ],
            [
              1735,
              745
            ],
            [
              1762,
              705
            ],
            [
              1740,
              682
            ],
            [
              1653,
              776
            ],
            [
              1613,
              796
            ],
            [
              1519,
              793
            ],
            [
              1508,
              808
            ],
            [
              1489,
              820
            ],
            [
              1487,
              814
            ],
            [
              1483,
              806
            ],
            [
              1473,
              781
            ],
            [
              1471,
              776
            ],
            [
              1470,
              776
            ],
            [
              1468,
              777
            ],
            [
              1468,
              780
            ],
            [
              1466,
              781
            ],
            [
              1456,
              796
            ],
            [
              1446,
              794
            ],
            [
              1443,
              786
            ],
            [
              1425,
              788
            ],
            [
              1423,
              788
            ],
            [
              1419,
              787
            ],
            [
              1415,
              786
            ],
            [
              1414,
              786
            ],
            [
              1414,
              785
            ],
            [
              1413,
              785
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Africa",
        "fullname": "Eritrea",
        "admin": "Eritrea",
        "woe_id": 23424806,
        "id": "ER",
        "abbrev": "Erit.",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "ERI",
        "name": "ER",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -730,
              2568
            ],
            [
              -701,
              2545
            ],
            [
              -632,
              2520
            ],
            [
              -550,
              2514
            ],
            [
              -534,
              2508
            ],
            [
              -452,
              2503
            ],
            [
              -453,
              2464
            ],
            [
              -514,
              2426
            ],
            [
              -574,
              2410
            ],
            [
              -648,
              2310
            ],
            [
              -649,
              2241
            ],
            [
              -681,
              2183
            ],
            [
              -723,
              2170
            ],
            [
              -736,
              2144
            ],
            [
              -794,
              2131
            ],
            [
              -876,
              2129
            ],
            [
              -943,
              2083
            ],
            [
              -967,
              2095
            ],
            [
              -993,
              2144
            ],
            [
              -1044,
              2159
            ],
            [
              -1041,
              2240
            ],
            [
              -1019,
              2277
            ],
            [
              -1038,
              2306
            ],
            [
              -1014,
              2342
            ],
            [
              -1011,
              2411
            ],
            [
              -977,
              2446
            ],
            [
              -1029,
              2473
            ],
            [
              -1087,
              2462
            ],
            [
              -1119,
              2472
            ],
            [
              -1145,
              2552
            ],
            [
              -1070,
              2593
            ],
            [
              -1034,
              2580
            ],
            [
              -933,
              2579
            ],
            [
              -894,
              2570
            ],
            [
              -828,
              2576
            ],
            [
              -804,
              2565
            ],
            [
              -730,
              2568
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Europe",
        "fullname": "Spain",
        "admin": "Spain",
        "woe_id": 23424950,
        "id": "ES",
        "abbrev": "Sp.",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "ESP",
        "name": "ES",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              937,
              3764
            ],
            [
              903,
              3705
            ],
            [
              918,
              3636
            ],
            [
              900,
              3605
            ],
            [
              873,
              3613
            ],
            [
              868,
              3609
            ],
            [
              863,
              3609
            ],
            [
              856,
              3605
            ],
            [
              825,
              3625
            ],
            [
              824,
              3631
            ],
            [
              812,
              3632
            ],
            [
              787,
              3646
            ],
            [
              731,
              3637
            ],
            [
              684,
              3698
            ],
            [
              683,
              3742
            ],
            [
              796,
              3772
            ],
            [
              937,
              3764
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Europe",
        "fullname": "Estonia",
        "admin": "Estonia",
        "woe_id": 23424805,
        "id": "EE",
        "abbrev": "Est.",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "EST",
        "name": "EE",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1740,
              682
            ],
            [
              1705,
              630
            ],
            [
              1707,
              598
            ],
            [
              1770,
              599
            ],
            [
              1765,
              555
            ],
            [
              1798,
              510
            ],
            [
              1832,
              486
            ],
            [
              1997,
              430
            ],
            [
              2053,
              430
            ],
            [
              2050,
              427
            ],
            [
              2045,
              422
            ],
            [
              2028,
              406
            ],
            [
              2015,
              393
            ],
            [
              1966,
              346
            ],
            [
              1883,
              257
            ],
            [
              1829,
              259
            ],
            [
              1763,
              222
            ],
            [
              1726,
              217
            ],
            [
              1714,
              210
            ],
            [
              1714,
              207
            ],
            [
              1712,
              205
            ],
            [
              1709,
              203
            ],
            [
              1707,
              203
            ],
            [
              1702,
              206
            ],
            [
              1687,
              203
            ],
            [
              1669,
              204
            ],
            [
              1650,
              222
            ],
            [
              1575,
              176
            ],
            [
              1501,
              184
            ],
            [
              1430,
              230
            ],
            [
              1385,
              231
            ],
            [
              1379,
              235
            ],
            [
              1379,
              241
            ],
            [
              1371,
              249
            ],
            [
              1372,
              280
            ],
            [
              1333,
              297
            ],
            [
              1326,
              309
            ],
            [
              1319,
              332
            ],
            [
              1318,
              338
            ],
            [
              1315,
              343
            ],
            [
              1315,
              346
            ],
            [
              1314,
              349
            ],
            [
              1313,
              351
            ],
            [
              1312,
              354
            ],
            [
              1312,
              354
            ],
            [
              1311,
              355
            ],
            [
              1311,
              356
            ],
            [
              1307,
              359
            ],
            [
              1301,
              360
            ],
            [
              1288,
              372
            ],
            [
              1255,
              411
            ],
            [
              1218,
              419
            ],
            [
              1227,
              444
            ],
            [
              1226,
              445
            ],
            [
              1225,
              446
            ],
            [
              1225,
              446
            ],
            [
              1225,
              447
            ],
            [
              1224,
              447
            ],
            [
              1225,
              450
            ],
            [
              1225,
              453
            ],
            [
              1249,
              456
            ],
            [
              1254,
              451
            ],
            [
              1262,
              454
            ],
            [
              1268,
              454
            ],
            [
              1269,
              455
            ],
            [
              1270,
              455
            ],
            [
              1275,
              460
            ],
            [
              1276,
              462
            ],
            [
              1277,
              463
            ],
            [
              1277,
              464
            ],
            [
              1278,
              465
            ],
            [
              1276,
              498
            ],
            [
              1275,
              512
            ],
            [
              1275,
              516
            ],
            [
              1277,
              525
            ],
            [
              1284,
              544
            ],
            [
              1290,
              558
            ],
            [
              1287,
              570
            ],
            [
              1287,
              573
            ],
            [
              1287,
              575
            ],
            [
              1304,
              593
            ],
            [
              1318,
              584
            ],
            [
              1319,
              585
            ],
            [
              1319,
              586
            ],
            [
              1319,
              587
            ],
            [
              1320,
              587
            ],
            [
              1321,
              588
            ],
            [
              1324,
              591
            ],
            [
              1324,
              615
            ],
            [
              1331,
              630
            ],
            [
              1330,
              643
            ],
            [
              1331,
              644
            ],
            [
              1331,
              646
            ],
            [
              1332,
              647
            ],
            [
              1339,
              650
            ],
            [
              1345,
              657
            ],
            [
              1347,
              664
            ],
            [
              1349,
              666
            ],
            [
              1350,
              666
            ],
            [
              1350,
              667
            ],
            [
              1351,
              671
            ],
            [
              1362,
              689
            ],
            [
              1365,
              690
            ],
            [
              1366,
              693
            ],
            [
              1366,
              693
            ],
            [
              1366,
              693
            ],
            [
              1366,
              694
            ],
            [
              1367,
              694
            ],
            [
              1368,
              694
            ],
            [
              1390,
              697
            ],
            [
              1390,
              709
            ],
            [
              1390,
              710
            ],
            [
              1391,
              711
            ],
            [
              1392,
              712
            ],
            [
              1391,
              715
            ],
            [
              1396,
              733
            ],
            [
              1405,
              745
            ],
            [
              1408,
              769
            ],
            [
              1413,
              785
            ],
            [
              1414,
              785
            ],
            [
              1414,
              786
            ],
            [
              1415,
              786
            ],
            [
              1416,
              786
            ],
            [
              1418,
              787
            ],
            [
              1419,
              787
            ],
            [
              1423,
              788
            ],
            [
              1425,
              788
            ],
            [
              1443,
              786
            ],
            [
              1456,
              796
            ],
            [
              1466,
              781
            ],
            [
              1468,
              780
            ],
            [
              1468,
              777
            ],
            [
              1470,
              776
            ],
            [
              1471,
              776
            ],
            [
              1473,
              781
            ],
            [
              1483,
              806
            ],
            [
              1489,
              820
            ],
            [
              1490,
              819
            ],
            [
              1492,
              816
            ],
            [
              1493,
              813
            ],
            [
              1495,
              811
            ],
            [
              1508,
              808
            ],
            [
              1519,
              793
            ],
            [
              1613,
              796
            ],
            [
              1653,
              776
            ],
            [
              1740,
              682
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Africa",
        "fullname": "Ethiopia",
        "admin": "Ethiopia",
        "woe_id": 23424808,
        "id": "ET",
        "abbrev": "Eth.",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "ETH",
        "name": "ET",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              989,
              4618
            ],
            [
              962,
              4571
            ],
            [
              1043,
              4472
            ],
            [
              997,
              4409
            ],
            [
              1052,
              4315
            ],
            [
              1032,
              4281
            ],
            [
              1035,
              4222
            ],
            [
              1114,
              4082
            ],
            [
              1116,
              4019
            ],
            [
              1003,
              3912
            ],
            [
              925,
              3853
            ],
            [
              883,
              3853
            ],
            [
              663,
              3798
            ],
            [
              633,
              3839
            ],
            [
              571,
              3858
            ],
            [
              563,
              4007
            ],
            [
              572,
              4081
            ],
            [
              612,
              4084
            ],
            [
              628,
              4117
            ],
            [
              677,
              4142
            ],
            [
              755,
              4228
            ],
            [
              788,
              4284
            ],
            [
              721,
              4315
            ],
            [
              692,
              4372
            ],
            [
              712,
              4407
            ],
            [
              688,
              4516
            ],
            [
              646,
              4556
            ],
            [
              604,
              4564
            ],
            [
              523,
              4619
            ],
            [
              582,
              4641
            ],
            [
              624,
              4587
            ],
            [
              708,
              4597
            ],
            [
              760,
              4573
            ],
            [
              805,
              4604
            ],
            [
              826,
              4683
            ],
            [
              852,
              4705
            ],
            [
              930,
              4720
            ],
            [
              999,
              4681
            ],
            [
              989,
              4618
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Europe",
        "fullname": "Finland",
        "admin": "Finland",
        "woe_id": 23424812,
        "id": "FI",
        "abbrev": "Fin.",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "FIN",
        "name": "FI",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              9338,
              -998
            ],
            [
              9361,
              -1040
            ],
            [
              9285,
              -1042
            ],
            [
              9288,
              -1015
            ],
            [
              9338,
              -998
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Oceania",
        "fullname": "Fiji",
        "admin": "Fiji",
        "woe_id": 23424813,
        "id": "FJ",
        "abbrev": "Fiji",
        "subregion": "Melanesia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "FJI",
        "name": "FJ",
        "continent": "Oceania"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -3519,
                208
              ],
              [
                -3576,
                114
              ],
              [
                -3656,
                100
              ],
              [
                -3675,
                106
              ],
              [
                -3680,
                112
              ],
              [
                -3682,
                112
              ],
              [
                -3683,
                112
              ],
              [
                -3682,
                113
              ],
              [
                -3681,
                113
              ],
              [
                -3680,
                113
              ],
              [
                -3678,
                113
              ],
              [
                -3678,
                113
              ],
              [
                -3669,
                122
              ],
              [
                -3667,
                128
              ],
              [
                -3665,
                132
              ],
              [
                -3661,
                137
              ],
              [
                -3659,
                160
              ],
              [
                -3655,
                166
              ],
              [
                -3653,
                167
              ],
              [
                -3653,
                168
              ],
              [
                -3652,
                169
              ],
              [
                -3652,
                170
              ],
              [
                -3652,
                171
              ],
              [
                -3650,
                173
              ],
              [
                -3649,
                175
              ],
              [
                -3648,
                184
              ],
              [
                -3659,
                195
              ],
              [
                -3669,
                210
              ],
              [
                -3672,
                218
              ],
              [
                -3671,
                223
              ],
              [
                -3673,
                227
              ],
              [
                -3674,
                233
              ],
              [
                -3674,
                234
              ],
              [
                -3673,
                236
              ],
              [
                -3672,
                237
              ],
              [
                -3672,
                240
              ],
              [
                -3672,
                240
              ],
              [
                -3673,
                242
              ],
              [
                -3673,
                244
              ],
              [
                -3673,
                245
              ],
              [
                -3673,
                246
              ],
              [
                -3674,
                247
              ],
              [
                -3675,
                248
              ],
              [
                -3675,
                255
              ],
              [
                -3676,
                256
              ],
              [
                -3676,
                256
              ],
              [
                -3676,
                257
              ],
              [
                -3674,
                258
              ],
              [
                -3674,
                259
              ],
              [
                -3674,
                261
              ],
              [
                -3674,
                262
              ],
              [
                -3674,
                263
              ],
              [
                -3673,
                265
              ],
              [
                -3664,
                277
              ],
              [
                -3659,
                280
              ],
              [
                -3658,
                281
              ],
              [
                -3656,
                282
              ],
              [
                -3655,
                283
              ],
              [
                -3646,
                304
              ],
              [
                -3592,
                287
              ],
              [
                -3533,
                239
              ],
              [
                -3519,
                208
              ]
            ]
          ],
          [
            [
              [
                -101,
                2520
              ],
              [
                -95,
                2482
              ],
              [
                -138,
                2445
              ],
              [
                -149,
                2500
              ],
              [
                -101,
                2520
              ]
            ]
          ],
          [
            [
              [
                -306,
                2998
              ],
              [
                -275,
                2992
              ],
              [
                -172,
                2957
              ],
              [
                -206,
                2895
              ],
              [
                -205,
                2858
              ],
              [
                -207,
                2858
              ],
              [
                -209,
                2857
              ],
              [
                -209,
                2857
              ],
              [
                -211,
                2855
              ],
              [
                -211,
                2855
              ],
              [
                -210,
                2855
              ],
              [
                -210,
                2855
              ],
              [
                -210,
                2854
              ],
              [
                -210,
                2854
              ],
              [
                -211,
                2853
              ],
              [
                -211,
                2854
              ],
              [
                -212,
                2853
              ],
              [
                -212,
                2853
              ],
              [
                -211,
                2853
              ],
              [
                -211,
                2852
              ],
              [
                -211,
                2852
              ],
              [
                -211,
                2852
              ],
              [
                -211,
                2852
              ],
              [
                -211,
                2852
              ],
              [
                -211,
                2852
              ],
              [
                -212,
                2851
              ],
              [
                -214,
                2852
              ],
              [
                -214,
                2849
              ],
              [
                -215,
                2849
              ],
              [
                -215,
                2848
              ],
              [
                -217,
                2847
              ],
              [
                -236,
                2841
              ],
              [
                -270,
                2800
              ],
              [
                -279,
                2795
              ],
              [
                -287,
                2789
              ],
              [
                -291,
                2780
              ],
              [
                -286,
                2772
              ],
              [
                -278,
                2764
              ],
              [
                -277,
                2775
              ],
              [
                -276,
                2775
              ],
              [
                -272,
                2776
              ],
              [
                -267,
                2778
              ],
              [
                -260,
                2779
              ],
              [
                -251,
                2777
              ],
              [
                -251,
                2777
              ],
              [
                -250,
                2776
              ],
              [
                -250,
                2775
              ],
              [
                -250,
                2774
              ],
              [
                -252,
                2772
              ],
              [
                -252,
                2771
              ],
              [
                -251,
                2770
              ],
              [
                -249,
                2768
              ],
              [
                -248,
                2766
              ],
              [
                -246,
                2756
              ],
              [
                -246,
                2755
              ],
              [
                -246,
                2754
              ],
              [
                -246,
                2753
              ],
              [
                -247,
                2753
              ],
              [
                -246,
                2752
              ],
              [
                -246,
                2752
              ],
              [
                -246,
                2752
              ],
              [
                -246,
                2751
              ],
              [
                -247,
                2751
              ],
              [
                -246,
                2750
              ],
              [
                -246,
                2750
              ],
              [
                -245,
                2750
              ],
              [
                -245,
                2751
              ],
              [
                -244,
                2751
              ],
              [
                -243,
                2751
              ],
              [
                -237,
                2742
              ],
              [
                -247,
                2644
              ],
              [
                -210,
                2595
              ],
              [
                -287,
                2546
              ],
              [
                -409,
                2578
              ],
              [
                -460,
                2538
              ],
              [
                -452,
                2503
              ],
              [
                -534,
                2508
              ],
              [
                -550,
                2514
              ],
              [
                -632,
                2520
              ],
              [
                -701,
                2545
              ],
              [
                -730,
                2568
              ],
              [
                -712,
                2581
              ],
              [
                -688,
                2750
              ],
              [
                -730,
                2782
              ],
              [
                -741,
                2821
              ],
              [
                -827,
                2872
              ],
              [
                -878,
                2886
              ],
              [
                -877,
                2917
              ],
              [
                -826,
                2948
              ],
              [
                -779,
                2926
              ],
              [
                -713,
                2933
              ],
              [
                -735,
                2998
              ],
              [
                -642,
                2979
              ],
              [
                -616,
                3012
              ],
              [
                -564,
                3029
              ],
              [
                -541,
                3095
              ],
              [
                -489,
                3111
              ],
              [
                -474,
                3084
              ],
              [
                -399,
                3050
              ],
              [
                -395,
                3028
              ],
              [
                -306,
                2998
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Europe",
        "fullname": "France",
        "admin": "France",
        "woe_id": -90,
        "id": "FR",
        "abbrev": "Fr.",
        "subregion": "Western Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "FRA",
        "name": "FR",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -81,
              38
            ],
            [
              4,
              38
            ],
            [
              4,
              101
            ],
            [
              3,
              103
            ],
            [
              4,
              107
            ],
            [
              5,
              107
            ],
            [
              5,
              108
            ],
            [
              5,
              109
            ],
            [
              5,
              110
            ],
            [
              5,
              110
            ],
            [
              5,
              111
            ],
            [
              23,
              112
            ],
            [
              53,
              110
            ],
            [
              83,
              107
            ],
            [
              111,
              109
            ],
            [
              113,
              107
            ],
            [
              114,
              107
            ],
            [
              114,
              106
            ],
            [
              114,
              104
            ],
            [
              114,
              103
            ],
            [
              114,
              100
            ],
            [
              114,
              98
            ],
            [
              113,
              96
            ],
            [
              109,
              94
            ],
            [
              108,
              85
            ],
            [
              105,
              76
            ],
            [
              104,
              71
            ],
            [
              105,
              70
            ],
            [
              106,
              68
            ],
            [
              108,
              67
            ],
            [
              111,
              62
            ],
            [
              111,
              50
            ],
            [
              142,
              62
            ],
            [
              152,
              61
            ],
            [
              156,
              59
            ],
            [
              160,
              60
            ],
            [
              161,
              60
            ],
            [
              163,
              59
            ],
            [
              169,
              47
            ],
            [
              176,
              36
            ],
            [
              178,
              34
            ],
            [
              179,
              34
            ],
            [
              179,
              33
            ],
            [
              179,
              32
            ],
            [
              179,
              32
            ],
            [
              178,
              31
            ],
            [
              178,
              31
            ],
            [
              178,
              30
            ],
            [
              178,
              28
            ],
            [
              176,
              25
            ],
            [
              174,
              23
            ],
            [
              172,
              21
            ],
            [
              166,
              12
            ],
            [
              160,
              13
            ],
            [
              157,
              12
            ],
            [
              155,
              5
            ],
            [
              150,
              2
            ],
            [
              148,
              -3
            ],
            [
              146,
              -6
            ],
            [
              146,
              -7
            ],
            [
              146,
              -8
            ],
            [
              147,
              -10
            ],
            [
              147,
              -11
            ],
            [
              147,
              -15
            ],
            [
              149,
              -21
            ],
            [
              144,
              -29
            ],
            [
              160,
              -35
            ],
            [
              161,
              -37
            ],
            [
              162,
              -39
            ],
            [
              162,
              -43
            ],
            [
              170,
              -42
            ],
            [
              179,
              -49
            ],
            [
              181,
              -51
            ],
            [
              181,
              -53
            ],
            [
              181,
              -55
            ],
            [
              178,
              -60
            ],
            [
              179,
              -66
            ],
            [
              175,
              -75
            ],
            [
              179,
              -86
            ],
            [
              177,
              -93
            ],
            [
              180,
              -96
            ],
            [
              180,
              -97
            ],
            [
              180,
              -97
            ],
            [
              179,
              -98
            ],
            [
              179,
              -98
            ],
            [
              178,
              -99
            ],
            [
              178,
              -100
            ],
            [
              178,
              -102
            ],
            [
              174,
              -108
            ],
            [
              174,
              -125
            ],
            [
              174,
              -125
            ],
            [
              167,
              -128
            ],
            [
              166,
              -136
            ],
            [
              166,
              -148
            ],
            [
              166,
              -149
            ],
            [
              161,
              -152
            ],
            [
              161,
              -154
            ],
            [
              160,
              -155
            ],
            [
              159,
              -155
            ],
            [
              158,
              -158
            ],
            [
              147,
              -157
            ],
            [
              140,
              -136
            ],
            [
              130,
              -148
            ],
            [
              122,
              -154
            ],
            [
              120,
              -154
            ],
            [
              118,
              -154
            ],
            [
              117,
              -154
            ],
            [
              108,
              -150
            ],
            [
              106,
              -150
            ],
            [
              105,
              -151
            ],
            [
              105,
              -151
            ],
            [
              100,
              -149
            ],
            [
              98,
              -149
            ],
            [
              96,
              -151
            ],
            [
              96,
              -151
            ],
            [
              95,
              -150
            ],
            [
              96,
              -150
            ],
            [
              97,
              -148
            ],
            [
              97,
              -147
            ],
            [
              97,
              -146
            ],
            [
              98,
              -146
            ],
            [
              98,
              -145
            ],
            [
              97,
              -145
            ],
            [
              97,
              -145
            ],
            [
              97,
              -145
            ],
            [
              96,
              -143
            ],
            [
              94,
              -140
            ],
            [
              90,
              -133
            ],
            [
              86,
              -125
            ],
            [
              74,
              -120
            ],
            [
              69,
              -125
            ],
            [
              68,
              -125
            ],
            [
              67,
              -124
            ],
            [
              66,
              -123
            ],
            [
              65,
              -123
            ],
            [
              65,
              -124
            ],
            [
              65,
              -130
            ],
            [
              66,
              -132
            ],
            [
              67,
              -134
            ],
            [
              69,
              -135
            ],
            [
              69,
              -136
            ],
            [
              68,
              -138
            ],
            [
              67,
              -148
            ],
            [
              45,
              -153
            ],
            [
              38,
              -148
            ],
            [
              31,
              -151
            ],
            [
              29,
              -152
            ],
            [
              28,
              -153
            ],
            [
              23,
              -149
            ],
            [
              22,
              -149
            ],
            [
              19,
              -148
            ],
            [
              17,
              -148
            ],
            [
              17,
              -149
            ],
            [
              18,
              -153
            ],
            [
              18,
              -154
            ],
            [
              18,
              -159
            ],
            [
              20,
              -168
            ],
            [
              21,
              -176
            ],
            [
              28,
              -185
            ],
            [
              29,
              -187
            ],
            [
              25,
              -188
            ],
            [
              24,
              -195
            ],
            [
              25,
              -196
            ],
            [
              28,
              -197
            ],
            [
              31,
              -200
            ],
            [
              38,
              -203
            ],
            [
              32,
              -216
            ],
            [
              31,
              -225
            ],
            [
              28,
              -225
            ],
            [
              27,
              -224
            ],
            [
              22,
              -223
            ],
            [
              17,
              -216
            ],
            [
              -15,
              -226
            ],
            [
              -76,
              -169
            ],
            [
              -113,
              -118
            ],
            [
              -138,
              -59
            ],
            [
              -111,
              -41
            ],
            [
              -109,
              16
            ],
            [
              -81,
              38
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Africa",
        "fullname": "Gabon",
        "admin": "Gabon",
        "woe_id": 23424822,
        "id": "GA",
        "abbrev": "Gabon",
        "subregion": "Middle Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "GAB",
        "name": "GA",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -980,
                3337
              ],
              [
                -1068,
                3345
              ],
              [
                -1072,
                3380
              ],
              [
                -1035,
                3412
              ],
              [
                -971,
                3423
              ],
              [
                -933,
                3365
              ],
              [
                -980,
                3337
              ]
            ]
          ],
          [
            [
              [
                -817,
                3696
              ],
              [
                -825,
                3620
              ],
              [
                -732,
                3612
              ],
              [
                -754,
                3553
              ],
              [
                -795,
                3501
              ],
              [
                -721,
                3452
              ],
              [
                -694,
                3377
              ],
              [
                -643,
                3342
              ],
              [
                -598,
                3251
              ],
              [
                -558,
                3248
              ],
              [
                -531,
                3215
              ],
              [
                -574,
                3166
              ],
              [
                -609,
                3091
              ],
              [
                -703,
                3090
              ],
              [
                -740,
                3075
              ],
              [
                -790,
                3085
              ],
              [
                -841,
                3047
              ],
              [
                -898,
                3075
              ],
              [
                -845,
                3151
              ],
              [
                -872,
                3262
              ],
              [
                -801,
                3287
              ],
              [
                -798,
                3324
              ],
              [
                -843,
                3396
              ],
              [
                -873,
                3383
              ],
              [
                -910,
                3417
              ],
              [
                -946,
                3524
              ],
              [
                -936,
                3632
              ],
              [
                -908,
                3694
              ],
              [
                -817,
                3696
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Europe",
        "fullname": "United Kingdom",
        "admin": "United Kingdom",
        "woe_id": -90,
        "id": "GB",
        "abbrev": "U.K.",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "GBR",
        "name": "GB",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1966,
              2467
            ],
            [
              1959,
              2442
            ],
            [
              1886,
              2427
            ],
            [
              1799,
              2415
            ],
            [
              1750,
              2445
            ],
            [
              1692,
              2442
            ],
            [
              1706,
              2462
            ],
            [
              1691,
              2517
            ],
            [
              1606,
              2568
            ],
            [
              1643,
              2578
            ],
            [
              1694,
              2557
            ],
            [
              1761,
              2553
            ],
            [
              1819,
              2524
            ],
            [
              1879,
              2525
            ],
            [
              1966,
              2467
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Asia",
        "fullname": "Georgia",
        "admin": "Georgia",
        "woe_id": 23424823,
        "id": "GE",
        "abbrev": "Geo.",
        "subregion": "Western Asia",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "GEO",
        "name": "GE",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -639,
              607
            ],
            [
              -609,
              560
            ],
            [
              -595,
              458
            ],
            [
              -601,
              365
            ],
            [
              -563,
              324
            ],
            [
              -746,
              247
            ],
            [
              -785,
              270
            ],
            [
              -782,
              282
            ],
            [
              -782,
              283
            ],
            [
              -782,
              284
            ],
            [
              -784,
              287
            ],
            [
              -785,
              294
            ],
            [
              -796,
              297
            ],
            [
              -804,
              327
            ],
            [
              -808,
              333
            ],
            [
              -812,
              353
            ],
            [
              -812,
              353
            ],
            [
              -812,
              354
            ],
            [
              -810,
              355
            ],
            [
              -809,
              358
            ],
            [
              -809,
              358
            ],
            [
              -810,
              360
            ],
            [
              -811,
              361
            ],
            [
              -811,
              363
            ],
            [
              -810,
              366
            ],
            [
              -803,
              377
            ],
            [
              -803,
              377
            ],
            [
              -803,
              377
            ],
            [
              -800,
              378
            ],
            [
              -794,
              406
            ],
            [
              -789,
              417
            ],
            [
              -788,
              418
            ],
            [
              -788,
              419
            ],
            [
              -788,
              419
            ],
            [
              -788,
              420
            ],
            [
              -788,
              420
            ],
            [
              -785,
              426
            ],
            [
              -780,
              431
            ],
            [
              -776,
              437
            ],
            [
              -776,
              438
            ],
            [
              -776,
              439
            ],
            [
              -775,
              440
            ],
            [
              -773,
              439
            ],
            [
              -770,
              441
            ],
            [
              -770,
              442
            ],
            [
              -775,
              474
            ],
            [
              -776,
              482
            ],
            [
              -785,
              490
            ],
            [
              -778,
              502
            ],
            [
              -781,
              504
            ],
            [
              -779,
              507
            ],
            [
              -779,
              513
            ],
            [
              -780,
              514
            ],
            [
              -782,
              517
            ],
            [
              -784,
              518
            ],
            [
              -786,
              527
            ],
            [
              -783,
              533
            ],
            [
              -794,
              579
            ],
            [
              -783,
              599
            ],
            [
              -658,
              599
            ],
            [
              -639,
              607
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Ghana",
        "admin": "Ghana",
        "woe_id": 23424824,
        "id": "GH",
        "abbrev": "Ghana",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "GHA",
        "name": "GH",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -1266,
              679
            ],
            [
              -1228,
              650
            ],
            [
              -1173,
              657
            ],
            [
              -1132,
              678
            ],
            [
              -1124,
              637
            ],
            [
              -1094,
              599
            ],
            [
              -1093,
              573
            ],
            [
              -1078,
              562
            ],
            [
              -1075,
              557
            ],
            [
              -1075,
              555
            ],
            [
              -1076,
              552
            ],
            [
              -1079,
              548
            ],
            [
              -1083,
              546
            ],
            [
              -1085,
              543
            ],
            [
              -1084,
              541
            ],
            [
              -1085,
              540
            ],
            [
              -1087,
              540
            ],
            [
              -1087,
              539
            ],
            [
              -1086,
              539
            ],
            [
              -1086,
              538
            ],
            [
              -1085,
              538
            ],
            [
              -1085,
              535
            ],
            [
              -1084,
              535
            ],
            [
              -1084,
              534
            ],
            [
              -1086,
              519
            ],
            [
              -1086,
              519
            ],
            [
              -1086,
              518
            ],
            [
              -1085,
              515
            ],
            [
              -1082,
              510
            ],
            [
              -1075,
              474
            ],
            [
              -1060,
              464
            ],
            [
              -1060,
              461
            ],
            [
              -1059,
              459
            ],
            [
              -1059,
              458
            ],
            [
              -1059,
              456
            ],
            [
              -1060,
              455
            ],
            [
              -1064,
              452
            ],
            [
              -1078,
              437
            ],
            [
              -1090,
              405
            ],
            [
              -1094,
              407
            ],
            [
              -1099,
              407
            ],
            [
              -1100,
              408
            ],
            [
              -1101,
              408
            ],
            [
              -1104,
              405
            ],
            [
              -1157,
              398
            ],
            [
              -1171,
              458
            ],
            [
              -1204,
              457
            ],
            [
              -1210,
              458
            ],
            [
              -1221,
              447
            ],
            [
              -1222,
              476
            ],
            [
              -1226,
              504
            ],
            [
              -1260,
              543
            ],
            [
              -1295,
              542
            ],
            [
              -1329,
              535
            ],
            [
              -1340,
              510
            ],
            [
              -1354,
              502
            ],
            [
              -1358,
              492
            ],
            [
              -1363,
              489
            ],
            [
              -1368,
              491
            ],
            [
              -1372,
              490
            ],
            [
              -1373,
              489
            ],
            [
              -1374,
              491
            ],
            [
              -1372,
              493
            ],
            [
              -1374,
              497
            ],
            [
              -1374,
              506
            ],
            [
              -1391,
              527
            ],
            [
              -1438,
              561
            ],
            [
              -1466,
              601
            ],
            [
              -1451,
              628
            ],
            [
              -1428,
              637
            ],
            [
              -1413,
              635
            ],
            [
              -1405,
              639
            ],
            [
              -1402,
              638
            ],
            [
              -1399,
              638
            ],
            [
              -1398,
              639
            ],
            [
              -1397,
              640
            ],
            [
              -1397,
              640
            ],
            [
              -1397,
              643
            ],
            [
              -1397,
              644
            ],
            [
              -1397,
              650
            ],
            [
              -1397,
              653
            ],
            [
              -1397,
              655
            ],
            [
              -1397,
              656
            ],
            [
              -1398,
              657
            ],
            [
              -1402,
              658
            ],
            [
              -1404,
              661
            ],
            [
              -1410,
              664
            ],
            [
              -1411,
              665
            ],
            [
              -1411,
              666
            ],
            [
              -1410,
              667
            ],
            [
              -1409,
              668
            ],
            [
              -1403,
              672
            ],
            [
              -1402,
              670
            ],
            [
              -1398,
              671
            ],
            [
              -1396,
              671
            ],
            [
              -1395,
              673
            ],
            [
              -1395,
              675
            ],
            [
              -1395,
              679
            ],
            [
              -1394,
              681
            ],
            [
              -1393,
              682
            ],
            [
              -1393,
              683
            ],
            [
              -1394,
              684
            ],
            [
              -1397,
              688
            ],
            [
              -1397,
              689
            ],
            [
              -1398,
              690
            ],
            [
              -1397,
              694
            ],
            [
              -1361,
              692
            ],
            [
              -1321,
              673
            ],
            [
              -1266,
              679
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Guinea",
        "admin": "Guinea",
        "woe_id": 23424835,
        "id": "GN",
        "abbrev": "Gin.",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "GIN",
        "name": "GN",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -1394,
              681
            ],
            [
              -1395,
              679
            ],
            [
              -1395,
              675
            ],
            [
              -1395,
              673
            ],
            [
              -1396,
              671
            ],
            [
              -1398,
              671
            ],
            [
              -1402,
              670
            ],
            [
              -1403,
              672
            ],
            [
              -1409,
              668
            ],
            [
              -1410,
              667
            ],
            [
              -1411,
              666
            ],
            [
              -1411,
              665
            ],
            [
              -1410,
              664
            ],
            [
              -1408,
              664
            ],
            [
              -1404,
              661
            ],
            [
              -1402,
              658
            ],
            [
              -1398,
              657
            ],
            [
              -1397,
              656
            ],
            [
              -1397,
              655
            ],
            [
              -1397,
              653
            ],
            [
              -1397,
              650
            ],
            [
              -1397,
              644
            ],
            [
              -1397,
              643
            ],
            [
              -1397,
              640
            ],
            [
              -1397,
              640
            ],
            [
              -1398,
              639
            ],
            [
              -1399,
              638
            ],
            [
              -1402,
              638
            ],
            [
              -1405,
              639
            ],
            [
              -1413,
              635
            ],
            [
              -1428,
              637
            ],
            [
              -1451,
              628
            ],
            [
              -1491,
              610
            ],
            [
              -1501,
              648
            ],
            [
              -1522,
              643
            ],
            [
              -1543,
              656
            ],
            [
              -1545,
              667
            ],
            [
              -1551,
              667
            ],
            [
              -1556,
              672
            ],
            [
              -1558,
              672
            ],
            [
              -1561,
              675
            ],
            [
              -1563,
              675
            ],
            [
              -1564,
              674
            ],
            [
              -1565,
              675
            ],
            [
              -1565,
              675
            ],
            [
              -1564,
              676
            ],
            [
              -1562,
              676
            ],
            [
              -1537,
              682
            ],
            [
              -1506,
              681
            ],
            [
              -1479,
              694
            ],
            [
              -1397,
              694
            ],
            [
              -1398,
              690
            ],
            [
              -1397,
              689
            ],
            [
              -1397,
              688
            ],
            [
              -1394,
              684
            ],
            [
              -1393,
              683
            ],
            [
              -1393,
              682
            ],
            [
              -1394,
              681
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Africa",
        "fullname": "Guinea-Bissau",
        "admin": "Guinea Bissau",
        "woe_id": 23424929,
        "id": "GW",
        "abbrev": "GnB.",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "GNB",
        "name": "GW",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3,
              103
            ],
            [
              4,
              101
            ],
            [
              4,
              38
            ],
            [
              -81,
              38
            ],
            [
              -104,
              54
            ],
            [
              -82,
              88
            ],
            [
              -82,
              108
            ],
            [
              -84,
              111
            ],
            [
              -81,
              112
            ],
            [
              -81,
              110
            ],
            [
              -81,
              109
            ],
            [
              -79,
              107
            ],
            [
              -78,
              106
            ],
            [
              -71,
              103
            ],
            [
              3,
              103
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Africa",
        "fullname": "Eq. Guinea",
        "admin": "Equatorial Guinea",
        "woe_id": 23424804,
        "id": "GQ",
        "abbrev": "Eq. G.",
        "subregion": "Middle Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "GNQ",
        "name": "GQ",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              843,
              2455
            ],
            [
              860,
              2433
            ],
            [
              827,
              2390
            ],
            [
              771,
              2408
            ],
            [
              734,
              2403
            ],
            [
              694,
              2356
            ],
            [
              640,
              2375
            ],
            [
              632,
              2345
            ],
            [
              682,
              2213
            ],
            [
              662,
              2133
            ],
            [
              584,
              2135
            ],
            [
              566,
              2225
            ],
            [
              497,
              2311
            ],
            [
              543,
              2398
            ],
            [
              577,
              2399
            ],
            [
              595,
              2414
            ],
            [
              611,
              2418
            ],
            [
              622,
              2416
            ],
            [
              623,
              2415
            ],
            [
              624,
              2415
            ],
            [
              640,
              2417
            ],
            [
              642,
              2426
            ],
            [
              642,
              2429
            ],
            [
              643,
              2429
            ],
            [
              644,
              2430
            ],
            [
              663,
              2428
            ],
            [
              672,
              2434
            ],
            [
              673,
              2433
            ],
            [
              675,
              2432
            ],
            [
              680,
              2434
            ],
            [
              691,
              2432
            ],
            [
              741,
              2445
            ],
            [
              784,
              2424
            ],
            [
              799,
              2429
            ],
            [
              817,
              2428
            ],
            [
              825,
              2430
            ],
            [
              830,
              2431
            ],
            [
              831,
              2431
            ],
            [
              843,
              2455
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Europe",
        "fullname": "Greece",
        "admin": "Greece",
        "woe_id": 23424833,
        "id": "GR",
        "abbrev": "Greece",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "GRC",
        "name": "GR",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -3578,
                4706
              ],
              [
                -3545,
                4662
              ],
              [
                -3625,
                4638
              ],
              [
                -3690,
                4673
              ],
              [
                -3674,
                4745
              ],
              [
                -3608,
                4733
              ],
              [
                -3578,
                4706
              ]
            ]
          ],
          [
            [
              [
                -1968,
                5008
              ],
              [
                -1924,
                5004
              ],
              [
                -1857,
                4967
              ],
              [
                -1874,
                4929
              ],
              [
                -1971,
                4965
              ],
              [
                -1968,
                5008
              ]
            ]
          ],
          [
            [
              [
                -2430,
                6311
              ],
              [
                -2230,
                6298
              ],
              [
                -2040,
                6249
              ],
              [
                -1960,
                6195
              ],
              [
                -1896,
                6199
              ],
              [
                -1884,
                6135
              ],
              [
                -2182,
                6098
              ],
              [
                -2006,
                6088
              ],
              [
                -1942,
                6051
              ],
              [
                -1926,
                6092
              ],
              [
                -1825,
                6101
              ],
              [
                -1805,
                6059
              ],
              [
                -1857,
                5960
              ],
              [
                -1739,
                6029
              ],
              [
                -1588,
                6012
              ],
              [
                -1551,
                6056
              ],
              [
                -1408,
                6064
              ],
              [
                -1302,
                6041
              ],
              [
                -1310,
                6001
              ],
              [
                -1429,
                5929
              ],
              [
                -1549,
                5890
              ],
              [
                -1549,
                5857
              ],
              [
                -1629,
                5850
              ],
              [
                -1718,
                5862
              ],
              [
                -1696,
                5722
              ],
              [
                -1796,
                5668
              ],
              [
                -1802,
                5580
              ],
              [
                -1749,
                5547
              ],
              [
                -1671,
                5431
              ],
              [
                -1776,
                5459
              ],
              [
                -1852,
                5420
              ],
              [
                -1834,
                5376
              ],
              [
                -1735,
                5373
              ],
              [
                -1716,
                5263
              ],
              [
                -1785,
                5270
              ],
              [
                -1790,
                5219
              ],
              [
                -1847,
                5176
              ],
              [
                -1819,
                5070
              ],
              [
                -1877,
                5048
              ],
              [
                -1971,
                5085
              ],
              [
                -2044,
                5071
              ],
              [
                -2057,
                5004
              ],
              [
                -2002,
                4960
              ],
              [
                -1898,
                4912
              ],
              [
                -1886,
                4846
              ],
              [
                -1849,
                4864
              ],
              [
                -1839,
                4758
              ],
              [
                -1935,
                4757
              ],
              [
                -1967,
                4775
              ],
              [
                -2009,
                4841
              ],
              [
                -2051,
                4843
              ],
              [
                -2107,
                4811
              ],
              [
                -2101,
                4733
              ],
              [
                -2044,
                4755
              ],
              [
                -1918,
                4708
              ],
              [
                -2006,
                4641
              ],
              [
                -2099,
                4585
              ],
              [
                -2364,
                4523
              ],
              [
                -2430,
                4552
              ],
              [
                -2426,
                4504
              ],
              [
                -2478,
                4489
              ],
              [
                -2567,
                4366
              ],
              [
                -2619,
                4358
              ],
              [
                -2646,
                4325
              ],
              [
                -2835,
                4290
              ],
              [
                -2900,
                4209
              ],
              [
                -2897,
                4123
              ],
              [
                -2964,
                4049
              ],
              [
                -3006,
                4044
              ],
              [
                -2986,
                3978
              ],
              [
                -3026,
                3909
              ],
              [
                -3041,
                3814
              ],
              [
                -3089,
                3823
              ],
              [
                -3123,
                3806
              ],
              [
                -3142,
                3848
              ],
              [
                -3179,
                3847
              ],
              [
                -3202,
                3896
              ],
              [
                -3309,
                3878
              ],
              [
                -3324,
                3909
              ],
              [
                -3375,
                3933
              ],
              [
                -3400,
                3998
              ],
              [
                -3442,
                4019
              ],
              [
                -3481,
                4077
              ],
              [
                -3506,
                4154
              ],
              [
                -3545,
                4170
              ],
              [
                -3548,
                4255
              ],
              [
                -3603,
                4302
              ],
              [
                -3632,
                4364
              ],
              [
                -3642,
                4454
              ],
              [
                -3623,
                4504
              ],
              [
                -3571,
                4569
              ],
              [
                -3489,
                4573
              ],
              [
                -3468,
                4679
              ],
              [
                -3506,
                4710
              ],
              [
                -3640,
                4751
              ],
              [
                -3648,
                4796
              ],
              [
                -3562,
                4784
              ],
              [
                -3512,
                4756
              ],
              [
                -3507,
                4804
              ],
              [
                -3574,
                4883
              ],
              [
                -3631,
                4890
              ],
              [
                -3642,
                4862
              ],
              [
                -3733,
                4859
              ],
              [
                -3726,
                4927
              ],
              [
                -3701,
                4944
              ],
              [
                -3698,
                5023
              ],
              [
                -3727,
                5026
              ],
              [
                -3742,
                5116
              ],
              [
                -3792,
                5146
              ],
              [
                -3821,
                5229
              ],
              [
                -3881,
                5245
              ],
              [
                -3894,
                5300
              ],
              [
                -4033,
                5368
              ],
              [
                -4179,
                5393
              ],
              [
                -4301,
                5355
              ],
              [
                -4331,
                5382
              ],
              [
                -4381,
                5349
              ],
              [
                -4456,
                5359
              ],
              [
                -4482,
                5426
              ],
              [
                -4572,
                5440
              ],
              [
                -4617,
                5556
              ],
              [
                -4699,
                5596
              ],
              [
                -4688,
                5646
              ],
              [
                -4507,
                5680
              ],
              [
                -4489,
                5702
              ],
              [
                -4404,
                5722
              ],
              [
                -4319,
                5719
              ],
              [
                -4256,
                5767
              ],
              [
                -4260,
                5838
              ],
              [
                -4378,
                5837
              ],
              [
                -4362,
                5900
              ],
              [
                -4271,
                5929
              ],
              [
                -4250,
                5957
              ],
              [
                -4151,
                5987
              ],
              [
                -4071,
                5966
              ],
              [
                -4056,
                6065
              ],
              [
                -3907,
                6104
              ],
              [
                -3673,
                6140
              ],
              [
                -3624,
                6104
              ],
              [
                -3475,
                6073
              ],
              [
                -3487,
                6158
              ],
              [
                -3370,
                6151
              ],
              [
                -3202,
                6094
              ],
              [
                -3154,
                6061
              ],
              [
                -3121,
                6129
              ],
              [
                -3154,
                6248
              ],
              [
                -2984,
                6259
              ],
              [
                -2905,
                6224
              ],
              [
                -2835,
                6220
              ],
              [
                -2731,
                6296
              ],
              [
                -2570,
                6310
              ],
              [
                -2430,
                6311
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Americas",
        "fullname": "Greenland",
        "admin": "Greenland",
        "woe_id": 23424828,
        "id": "GL",
        "abbrev": "Grlnd.",
        "subregion": "Northern America",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "GRL",
        "name": "GL",
        "continent": "North America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -5615,
              988
            ],
            [
              -5614,
              943
            ],
            [
              -5616,
              912
            ],
            [
              -5619,
              878
            ],
            [
              -5563,
              868
            ],
            [
              -5563,
              867
            ],
            [
              -5567,
              865
            ],
            [
              -5572,
              859
            ],
            [
              -5604,
              835
            ],
            [
              -5615,
              829
            ],
            [
              -5616,
              826
            ],
            [
              -5615,
              825
            ],
            [
              -5615,
              824
            ],
            [
              -5617,
              821
            ],
            [
              -5618,
              820
            ],
            [
              -5618,
              819
            ],
            [
              -5619,
              818
            ],
            [
              -5618,
              817
            ],
            [
              -5614,
              810
            ],
            [
              -5614,
              809
            ],
            [
              -5614,
              808
            ],
            [
              -5614,
              808
            ],
            [
              -5615,
              803
            ],
            [
              -5619,
              802
            ],
            [
              -5624,
              797
            ],
            [
              -5625,
              797
            ],
            [
              -5626,
              796
            ],
            [
              -5626,
              795
            ],
            [
              -5626,
              793
            ],
            [
              -5628,
              795
            ],
            [
              -5637,
              793
            ],
            [
              -5638,
              792
            ],
            [
              -5639,
              792
            ],
            [
              -5638,
              790
            ],
            [
              -5638,
              790
            ],
            [
              -5638,
              789
            ],
            [
              -5639,
              789
            ],
            [
              -5639,
              788
            ],
            [
              -5639,
              787
            ],
            [
              -5637,
              787
            ],
            [
              -5636,
              786
            ],
            [
              -5636,
              784
            ],
            [
              -5635,
              784
            ],
            [
              -5635,
              783
            ],
            [
              -5635,
              782
            ],
            [
              -5655,
              772
            ],
            [
              -5663,
              766
            ],
            [
              -5664,
              764
            ],
            [
              -5666,
              762
            ],
            [
              -5667,
              761
            ],
            [
              -5735,
              767
            ],
            [
              -5787,
              801
            ],
            [
              -5785,
              843
            ],
            [
              -5758,
              888
            ],
            [
              -5724,
              888
            ],
            [
              -5717,
              888
            ],
            [
              -5717,
              888
            ],
            [
              -5689,
              888
            ],
            [
              -5688,
              888
            ],
            [
              -5687,
              888
            ],
            [
              -5687,
              888
            ],
            [
              -5686,
              889
            ],
            [
              -5686,
              889
            ],
            [
              -5686,
              890
            ],
            [
              -5686,
              890
            ],
            [
              -5686,
              890
            ],
            [
              -5687,
              889
            ],
            [
              -5687,
              889
            ],
            [
              -5687,
              890
            ],
            [
              -5686,
              892
            ],
            [
              -5687,
              892
            ],
            [
              -5687,
              893
            ],
            [
              -5685,
              893
            ],
            [
              -5686,
              894
            ],
            [
              -5687,
              899
            ],
            [
              -5687,
              899
            ],
            [
              -5686,
              899
            ],
            [
              -5686,
              899
            ],
            [
              -5685,
              899
            ],
            [
              -5686,
              899
            ],
            [
              -5686,
              900
            ],
            [
              -5686,
              900
            ],
            [
              -5685,
              901
            ],
            [
              -5686,
              901
            ],
            [
              -5686,
              901
            ],
            [
              -5684,
              904
            ],
            [
              -5698,
              914
            ],
            [
              -5703,
              927
            ],
            [
              -5708,
              930
            ],
            [
              -5712,
              931
            ],
            [
              -5721,
              936
            ],
            [
              -5721,
              938
            ],
            [
              -5722,
              938
            ],
            [
              -5724,
              942
            ],
            [
              -5727,
              943
            ],
            [
              -5734,
              952
            ],
            [
              -5738,
              952
            ],
            [
              -5738,
              953
            ],
            [
              -5739,
              954
            ],
            [
              -5741,
              954
            ],
            [
              -5742,
              955
            ],
            [
              -5742,
              956
            ],
            [
              -5741,
              956
            ],
            [
              -5717,
              956
            ],
            [
              -5715,
              988
            ],
            [
              -5688,
              988
            ],
            [
              -5676,
              988
            ],
            [
              -5651,
              988
            ],
            [
              -5615,
              988
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Americas",
        "fullname": "Guatemala",
        "admin": "Guatemala",
        "woe_id": 23424834,
        "id": "GT",
        "abbrev": "Guat.",
        "subregion": "Central America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "GTM",
        "name": "GT",
        "continent": "North America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -3787,
              91
            ],
            [
              -3822,
              95
            ],
            [
              -3942,
              59
            ],
            [
              -3971,
              89
            ],
            [
              -3984,
              133
            ],
            [
              -3958,
              202
            ],
            [
              -4026,
              273
            ],
            [
              -4061,
              313
            ],
            [
              -4052,
              351
            ],
            [
              -4008,
              371
            ],
            [
              -4025,
              404
            ],
            [
              -3974,
              446
            ],
            [
              -3985,
              462
            ],
            [
              -3937,
              434
            ],
            [
              -3876,
              364
            ],
            [
              -3826,
              323
            ],
            [
              -3825,
              298
            ],
            [
              -3830,
              289
            ],
            [
              -3835,
              281
            ],
            [
              -3835,
              280
            ],
            [
              -3835,
              280
            ],
            [
              -3835,
              279
            ],
            [
              -3834,
              279
            ],
            [
              -3833,
              279
            ],
            [
              -3833,
              275
            ],
            [
              -3830,
              277
            ],
            [
              -3827,
              273
            ],
            [
              -3827,
              272
            ],
            [
              -3827,
              271
            ],
            [
              -3828,
              271
            ],
            [
              -3829,
              270
            ],
            [
              -3833,
              271
            ],
            [
              -3834,
              267
            ],
            [
              -3834,
              267
            ],
            [
              -3834,
              266
            ],
            [
              -3834,
              265
            ],
            [
              -3832,
              263
            ],
            [
              -3833,
              263
            ],
            [
              -3834,
              263
            ],
            [
              -3838,
              262
            ],
            [
              -3844,
              261
            ],
            [
              -3857,
              261
            ],
            [
              -3868,
              252
            ],
            [
              -3863,
              242
            ],
            [
              -3870,
              229
            ],
            [
              -3870,
              222
            ],
            [
              -3875,
              217
            ],
            [
              -3876,
              215
            ],
            [
              -3876,
              214
            ],
            [
              -3875,
              212
            ],
            [
              -3874,
              205
            ],
            [
              -3863,
              187
            ],
            [
              -3848,
              169
            ],
            [
              -3837,
              170
            ],
            [
              -3834,
              172
            ],
            [
              -3832,
              169
            ],
            [
              -3832,
              166
            ],
            [
              -3833,
              165
            ],
            [
              -3833,
              165
            ],
            [
              -3832,
              164
            ],
            [
              -3832,
              164
            ],
            [
              -3832,
              163
            ],
            [
              -3832,
              162
            ],
            [
              -3833,
              161
            ],
            [
              -3832,
              161
            ],
            [
              -3832,
              159
            ],
            [
              -3832,
              157
            ],
            [
              -3826,
              140
            ],
            [
              -3800,
              96
            ],
            [
              -3793,
              95
            ],
            [
              -3788,
              92
            ],
            [
              -3788,
              91
            ],
            [
              -3787,
              91
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Americas",
        "fullname": "Guyana",
        "admin": "Guyana",
        "woe_id": 23424836,
        "id": "GY",
        "abbrev": "Guy.",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "GUY",
        "name": "GY",
        "continent": "South America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -5277,
              826
            ],
            [
              -5353,
              805
            ],
            [
              -5369,
              815
            ],
            [
              -5428,
              761
            ],
            [
              -5511,
              712
            ],
            [
              -5539,
              736
            ],
            [
              -5577,
              768
            ],
            [
              -5607,
              787
            ],
            [
              -5618,
              791
            ],
            [
              -5623,
              793
            ],
            [
              -5626,
              793
            ],
            [
              -5626,
              795
            ],
            [
              -5626,
              796
            ],
            [
              -5625,
              797
            ],
            [
              -5624,
              797
            ],
            [
              -5619,
              802
            ],
            [
              -5615,
              803
            ],
            [
              -5614,
              808
            ],
            [
              -5614,
              808
            ],
            [
              -5614,
              808
            ],
            [
              -5614,
              809
            ],
            [
              -5614,
              810
            ],
            [
              -5618,
              817
            ],
            [
              -5619,
              818
            ],
            [
              -5618,
              819
            ],
            [
              -5618,
              820
            ],
            [
              -5617,
              821
            ],
            [
              -5615,
              824
            ],
            [
              -5615,
              825
            ],
            [
              -5616,
              826
            ],
            [
              -5615,
              829
            ],
            [
              -5604,
              835
            ],
            [
              -5572,
              859
            ],
            [
              -5567,
              865
            ],
            [
              -5563,
              867
            ],
            [
              -5563,
              868
            ],
            [
              -5562,
              868
            ],
            [
              -5558,
              866
            ],
            [
              -5545,
              876
            ],
            [
              -5534,
              879
            ],
            [
              -5487,
              870
            ],
            [
              -5421,
              882
            ],
            [
              -5342,
              873
            ],
            [
              -5313,
              839
            ],
            [
              -5277,
              826
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Americas",
        "fullname": "Honduras",
        "admin": "Honduras",
        "woe_id": 23424841,
        "id": "HN",
        "abbrev": "Hond.",
        "subregion": "Central America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "HND",
        "name": "HN",
        "continent": "North America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              427,
              2742
            ],
            [
              424,
              2734
            ],
            [
              442,
              2698
            ],
            [
              434,
              2669
            ],
            [
              317,
              2697
            ],
            [
              249,
              2663
            ],
            [
              353,
              2538
            ],
            [
              314,
              2569
            ],
            [
              261,
              2587
            ],
            [
              217,
              2623
            ],
            [
              191,
              2715
            ],
            [
              217,
              2708
            ],
            [
              227,
              2709
            ],
            [
              224,
              2719
            ],
            [
              226,
              2729
            ],
            [
              228,
              2730
            ],
            [
              233,
              2732
            ],
            [
              234,
              2734
            ],
            [
              246,
              2735
            ],
            [
              247,
              2747
            ],
            [
              247,
              2747
            ],
            [
              247,
              2748
            ],
            [
              248,
              2749
            ],
            [
              248,
              2750
            ],
            [
              248,
              2750
            ],
            [
              247,
              2751
            ],
            [
              247,
              2751
            ],
            [
              244,
              2752
            ],
            [
              243,
              2753
            ],
            [
              242,
              2755
            ],
            [
              242,
              2757
            ],
            [
              243,
              2759
            ],
            [
              246,
              2762
            ],
            [
              276,
              2773
            ],
            [
              284,
              2785
            ],
            [
              312,
              2774
            ],
            [
              319,
              2762
            ],
            [
              327,
              2756
            ],
            [
              332,
              2756
            ],
            [
              335,
              2753
            ],
            [
              340,
              2744
            ],
            [
              400,
              2729
            ],
            [
              415,
              2741
            ],
            [
              419,
              2739
            ],
            [
              423,
              2741
            ],
            [
              423,
              2741
            ],
            [
              424,
              2741
            ],
            [
              426,
              2742
            ],
            [
              427,
              2742
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Europe",
        "fullname": "Croatia",
        "admin": "Croatia",
        "woe_id": 23424843,
        "id": "HR",
        "abbrev": "Cro.",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "HRV",
        "name": "HR",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -4642,
              1098
            ],
            [
              -4643,
              1001
            ],
            [
              -4697,
              1023
            ],
            [
              -4698,
              1111
            ],
            [
              -4642,
              1098
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Americas",
        "fullname": "Haiti",
        "admin": "Haiti",
        "woe_id": 23424839,
        "id": "HT",
        "abbrev": "Haiti",
        "subregion": "Caribbean",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "HTI",
        "name": "HT",
        "continent": "North America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              608,
              2917
            ],
            [
              609,
              2916
            ],
            [
              609,
              2916
            ],
            [
              610,
              2917
            ],
            [
              612,
              2918
            ],
            [
              616,
              2917
            ],
            [
              620,
              2905
            ],
            [
              625,
              2905
            ],
            [
              627,
              2905
            ],
            [
              627,
              2905
            ],
            [
              627,
              2905
            ],
            [
              627,
              2905
            ],
            [
              634,
              2895
            ],
            [
              650,
              2884
            ],
            [
              615,
              2868
            ],
            [
              552,
              2767
            ],
            [
              502,
              2755
            ],
            [
              499,
              2757
            ],
            [
              485,
              2759
            ],
            [
              477,
              2756
            ],
            [
              476,
              2756
            ],
            [
              473,
              2758
            ],
            [
              471,
              2759
            ],
            [
              463,
              2759
            ],
            [
              447,
              2746
            ],
            [
              441,
              2746
            ],
            [
              440,
              2747
            ],
            [
              440,
              2747
            ],
            [
              439,
              2748
            ],
            [
              436,
              2748
            ],
            [
              435,
              2745
            ],
            [
              426,
              2742
            ],
            [
              426,
              2742
            ],
            [
              424,
              2741
            ],
            [
              423,
              2741
            ],
            [
              423,
              2741
            ],
            [
              419,
              2739
            ],
            [
              400,
              2729
            ],
            [
              340,
              2744
            ],
            [
              335,
              2753
            ],
            [
              332,
              2756
            ],
            [
              327,
              2756
            ],
            [
              319,
              2762
            ],
            [
              312,
              2774
            ],
            [
              294,
              2782
            ],
            [
              270,
              2807
            ],
            [
              329,
              2888
            ],
            [
              367,
              2870
            ],
            [
              482,
              2897
            ],
            [
              516,
              2925
            ],
            [
              608,
              2917
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Europe",
        "fullname": "Hungary",
        "admin": "Hungary",
        "woe_id": 23424844,
        "id": "HU",
        "abbrev": "Hun.",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "HUN",
        "name": "HU",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                6080,
                -543
              ],
              [
                6124,
                -577
              ],
              [
                6088,
                -591
              ],
              [
                6080,
                -543
              ]
            ]
          ],
          [
            [
              [
                6363,
                -550
              ],
              [
                6327,
                -588
              ],
              [
                6276,
                -598
              ],
              [
                6305,
                -542
              ],
              [
                6328,
                -533
              ],
              [
                6355,
                -520
              ],
              [
                6363,
                -550
              ]
            ]
          ],
          [
            [
              [
                5966,
                -472
              ],
              [
                5985,
                -509
              ],
              [
                5912,
                -529
              ],
              [
                5900,
                -499
              ],
              [
                5966,
                -472
              ]
            ]
          ],
          [
            [
              [
                6246,
                -475
              ],
              [
                6237,
                -501
              ],
              [
                6139,
                -519
              ],
              [
                6070,
                -510
              ],
              [
                6095,
                -481
              ],
              [
                6164,
                -500
              ],
              [
                6208,
                -502
              ],
              [
                6246,
                -475
              ]
            ]
          ],
          [
            [
              [
                7136,
                -469
              ],
              [
                7111,
                -488
              ],
              [
                7066,
                -488
              ],
              [
                7088,
                -444
              ],
              [
                7131,
                -431
              ],
              [
                7144,
                -442
              ],
              [
                7136,
                -469
              ]
            ]
          ],
          [
            [
              [
                5306,
                -354
              ],
              [
                5421,
                -367
              ],
              [
                5443,
                -397
              ],
              [
                5540,
                -408
              ],
              [
                5561,
                -379
              ],
              [
                5665,
                -409
              ],
              [
                5705,
                -454
              ],
              [
                5770,
                -457
              ],
              [
                5755,
                -503
              ],
              [
                5699,
                -482
              ],
              [
                5670,
                -491
              ],
              [
                5547,
                -473
              ],
              [
                5485,
                -450
              ],
              [
                5427,
                -456
              ],
              [
                5326,
                -433
              ],
              [
                5295,
                -400
              ],
              [
                5306,
                -354
              ]
            ]
          ],
          [
            [
              [
                6613,
                -173
              ],
              [
                6671,
                -193
              ],
              [
                6684,
                -234
              ],
              [
                6613,
                -202
              ],
              [
                6613,
                -173
              ]
            ]
          ],
          [
            [
              [
                5290,
                -101
              ],
              [
                5331,
                -182
              ],
              [
                5296,
                -176
              ],
              [
                5267,
                -105
              ],
              [
                5290,
                -101
              ]
            ]
          ],
          [
            [
              [
                7252,
                -163
              ],
              [
                7252,
                -529
              ],
              [
                7194,
                -472
              ],
              [
                7133,
                -474
              ],
              [
                7142,
                -459
              ],
              [
                7147,
                -441
              ],
              [
                7132,
                -399
              ],
              [
                7081,
                -319
              ],
              [
                6973,
                -271
              ],
              [
                6929,
                -267
              ],
              [
                6839,
                -217
              ],
              [
                6821,
                -245
              ],
              [
                6769,
                -183
              ],
              [
                6808,
                -157
              ],
              [
                6755,
                -136
              ],
              [
                6706,
                -103
              ],
              [
                6708,
                -64
              ],
              [
                6790,
                -38
              ],
              [
                6861,
                -58
              ],
              [
                6878,
                -93
              ],
              [
                6873,
                -151
              ],
              [
                6899,
                -161
              ],
              [
                6917,
                -204
              ],
              [
                6945,
                -206
              ],
              [
                6997,
                -142
              ],
              [
                7074,
                -101
              ],
              [
                7190,
                -151
              ],
              [
                7252,
                -163
              ]
            ]
          ],
          [
            [
              [
                6363,
                76
              ],
              [
                6353,
                37
              ],
              [
                6321,
                4
              ],
              [
                6246,
                9
              ],
              [
                6101,
                9
              ],
              [
                6085,
                -55
              ],
              [
                6140,
                -97
              ],
              [
                6164,
                -66
              ],
              [
                6237,
                -70
              ],
              [
                6174,
                -125
              ],
              [
                6213,
                -193
              ],
              [
                6203,
                -217
              ],
              [
                6229,
                -249
              ],
              [
                6197,
                -271
              ],
              [
                6130,
                -216
              ],
              [
                6139,
                -172
              ],
              [
                6093,
                -183
              ],
              [
                6105,
                -226
              ],
              [
                6098,
                -326
              ],
              [
                6043,
                -318
              ],
              [
                6059,
                -259
              ],
              [
                6040,
                -210
              ],
              [
                6010,
                -173
              ],
              [
                6043,
                -126
              ],
              [
                6042,
                -86
              ],
              [
                6062,
                -59
              ],
              [
                6083,
                24
              ],
              [
                6111,
                26
              ],
              [
                6134,
                57
              ],
              [
                6171,
                41
              ],
              [
                6300,
                29
              ],
              [
                6363,
                76
              ]
            ]
          ],
          [
            [
              [
                6530,
                105
              ],
              [
                6519,
                84
              ],
              [
                6566,
                42
              ],
              [
                6503,
                6
              ],
              [
                6494,
                51
              ],
              [
                6530,
                105
              ]
            ]
          ],
          [
            [
              [
                5944,
                215
              ],
              [
                5954,
                186
              ],
              [
                5932,
                160
              ],
              [
                5971,
                114
              ],
              [
                5964,
                84
              ],
              [
                6024,
                36
              ],
              [
                5957,
                27
              ],
              [
                5941,
                -2
              ],
              [
                5945,
                -42
              ],
              [
                5913,
                -85
              ],
              [
                5874,
                -113
              ],
              [
                5890,
                -142
              ],
              [
                5854,
                -220
              ],
              [
                5784,
                -251
              ],
              [
                5771,
                -213
              ],
              [
                5724,
                -211
              ],
              [
                5698,
                -190
              ],
              [
                5667,
                -209
              ],
              [
                5593,
                -182
              ],
              [
                5535,
                -186
              ],
              [
                5524,
                -98
              ],
              [
                5480,
                -66
              ],
              [
                5474,
                -12
              ],
              [
                5457,
                29
              ],
              [
                5470,
                69
              ],
              [
                5501,
                99
              ],
              [
                5502,
                70
              ],
              [
                5551,
                30
              ],
              [
                5622,
                38
              ],
              [
                5642,
                63
              ],
              [
                5690,
                68
              ],
              [
                5725,
                51
              ],
              [
                5772,
                62
              ],
              [
                5826,
                152
              ],
              [
                5849,
                226
              ],
              [
                5924,
                226
              ],
              [
                5944,
                215
              ]
            ]
          ],
          [
            [
              [
                4710,
                296
              ],
              [
                4745,
                278
              ],
              [
                4821,
                276
              ],
              [
                4865,
                230
              ],
              [
                4867,
                210
              ],
              [
                4947,
                160
              ],
              [
                4993,
                103
              ],
              [
                5080,
                60
              ],
              [
                5087,
                37
              ],
              [
                5169,
                0
              ],
              [
                5150,
                -59
              ],
              [
                5206,
                -76
              ],
              [
                5234,
                -146
              ],
              [
                5276,
                -152
              ],
              [
                5300,
                -201
              ],
              [
                5285,
                -344
              ],
              [
                5202,
                -334
              ],
              [
                5091,
                -241
              ],
              [
                5023,
                -163
              ],
              [
                4978,
                -64
              ],
              [
                4911,
                17
              ],
              [
                4880,
                90
              ],
              [
                4830,
                117
              ],
              [
                4827,
                142
              ],
              [
                4706,
                252
              ],
              [
                4710,
                296
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Asia",
        "fullname": "Indonesia",
        "admin": "Indonesia",
        "woe_id": 23424846,
        "id": "ID",
        "abbrev": "Indo.",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "IDN",
        "name": "ID",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3899,
              1721
            ],
            [
              3898,
              1721
            ],
            [
              3895,
              1720
            ],
            [
              3890,
              1719
            ],
            [
              3890,
              1716
            ],
            [
              3875,
              1706
            ],
            [
              3870,
              1697
            ],
            [
              3869,
              1696
            ],
            [
              3865,
              1695
            ],
            [
              3864,
              1694
            ],
            [
              3863,
              1693
            ],
            [
              3863,
              1691
            ],
            [
              3865,
              1685
            ],
            [
              3855,
              1673
            ],
            [
              3859,
              1664
            ],
            [
              3845,
              1638
            ],
            [
              3907,
              1610
            ],
            [
              3958,
              1582
            ],
            [
              3997,
              1557
            ],
            [
              4099,
              1547
            ],
            [
              4169,
              1502
            ],
            [
              4169,
              1501
            ],
            [
              4170,
              1502
            ],
            [
              4171,
              1502
            ],
            [
              4175,
              1505
            ],
            [
              4176,
              1506
            ],
            [
              4177,
              1506
            ],
            [
              4179,
              1507
            ],
            [
              4196,
              1504
            ],
            [
              4219,
              1492
            ],
            [
              4253,
              1489
            ],
            [
              4256,
              1493
            ],
            [
              4260,
              1493
            ],
            [
              4261,
              1493
            ],
            [
              4267,
              1490
            ],
            [
              4283,
              1495
            ],
            [
              4289,
              1489
            ],
            [
              4291,
              1490
            ],
            [
              4293,
              1492
            ],
            [
              4295,
              1495
            ],
            [
              4296,
              1502
            ],
            [
              4299,
              1507
            ],
            [
              4300,
              1510
            ],
            [
              4300,
              1511
            ],
            [
              4300,
              1512
            ],
            [
              4299,
              1518
            ],
            [
              4299,
              1519
            ],
            [
              4298,
              1520
            ],
            [
              4297,
              1522
            ],
            [
              4296,
              1525
            ],
            [
              4295,
              1527
            ],
            [
              4294,
              1529
            ],
            [
              4293,
              1529
            ],
            [
              4292,
              1530
            ],
            [
              4291,
              1530
            ],
            [
              4290,
              1532
            ],
            [
              4289,
              1533
            ],
            [
              4289,
              1534
            ],
            [
              4289,
              1534
            ],
            [
              4289,
              1535
            ],
            [
              4290,
              1536
            ],
            [
              4290,
              1541
            ],
            [
              4292,
              1547
            ],
            [
              4292,
              1557
            ],
            [
              4297,
              1566
            ],
            [
              4299,
              1574
            ],
            [
              4299,
              1576
            ],
            [
              4300,
              1578
            ],
            [
              4300,
              1578
            ],
            [
              4299,
              1579
            ],
            [
              4298,
              1579
            ],
            [
              4297,
              1579
            ],
            [
              4296,
              1582
            ],
            [
              4298,
              1585
            ],
            [
              4312,
              1587
            ],
            [
              4325,
              1594
            ],
            [
              4340,
              1546
            ],
            [
              4344,
              1522
            ],
            [
              4392,
              1509
            ],
            [
              4516,
              1519
            ],
            [
              4494,
              1573
            ],
            [
              4549,
              1583
            ],
            [
              4595,
              1628
            ],
            [
              4669,
              1658
            ],
            [
              4746,
              1671
            ],
            [
              4792,
              1608
            ],
            [
              4812,
              1601
            ],
            [
              4780,
              1548
            ],
            [
              4746,
              1543
            ],
            [
              4689,
              1504
            ],
            [
              4690,
              1469
            ],
            [
              4666,
              1410
            ],
            [
              4626,
              1344
            ],
            [
              4596,
              1346
            ],
            [
              4579,
              1254
            ],
            [
              4546,
              1230
            ],
            [
              4523,
              1333
            ],
            [
              4488,
              1289
            ],
            [
              4466,
              1326
            ],
            [
              4479,
              1355
            ],
            [
              4520,
              1373
            ],
            [
              4514,
              1419
            ],
            [
              4423,
              1417
            ],
            [
              4391,
              1430
            ],
            [
              4393,
              1462
            ],
            [
              4316,
              1495
            ],
            [
              4295,
              1462
            ],
            [
              4320,
              1438
            ],
            [
              4292,
              1387
            ],
            [
              4332,
              1366
            ],
            [
              4321,
              1328
            ],
            [
              4350,
              1239
            ],
            [
              4333,
              1205
            ],
            [
              4289,
              1222
            ],
            [
              4246,
              1205
            ],
            [
              4235,
              1153
            ],
            [
              4194,
              1109
            ],
            [
              4116,
              1069
            ],
            [
              4071,
              1014
            ],
            [
              3972,
              943
            ],
            [
              3972,
              917
            ],
            [
              3917,
              905
            ],
            [
              3899,
              875
            ],
            [
              3863,
              871
            ],
            [
              3848,
              844
            ],
            [
              3862,
              724
            ],
            [
              3829,
              632
            ],
            [
              3836,
              564
            ],
            [
              3810,
              561
            ],
            [
              3785,
              502
            ],
            [
              3741,
              479
            ],
            [
              3704,
              434
            ],
            [
              3650,
              482
            ],
            [
              3610,
              605
            ],
            [
              3553,
              705
            ],
            [
              3542,
              760
            ],
            [
              3470,
              913
            ],
            [
              3432,
              1105
            ],
            [
              3449,
              1159
            ],
            [
              3402,
              1185
            ],
            [
              3339,
              1156
            ],
            [
              3287,
              1181
            ],
            [
              3225,
              1248
            ],
            [
              3242,
              1280
            ],
            [
              3203,
              1305
            ],
            [
              3183,
              1339
            ],
            [
              3213,
              1366
            ],
            [
              3283,
              1359
            ],
            [
              3344,
              1389
            ],
            [
              3288,
              1466
            ],
            [
              3293,
              1499
            ],
            [
              3260,
              1539
            ],
            [
              3303,
              1588
            ],
            [
              3331,
              1570
            ],
            [
              3388,
              1585
            ],
            [
              3417,
              1635
            ],
            [
              3446,
              1650
            ],
            [
              3473,
              1705
            ],
            [
              3526,
              1764
            ],
            [
              3554,
              1834
            ],
            [
              3580,
              1841
            ],
            [
              3508,
              1905
            ],
            [
              3506,
              1999
            ],
            [
              3524,
              2005
            ],
            [
              3607,
              1988
            ],
            [
              3678,
              2026
            ],
            [
              3720,
              2051
            ],
            [
              3772,
              1962
            ],
            [
              3775,
              1929
            ],
            [
              3815,
              1878
            ],
            [
              3784,
              1853
            ],
            [
              3773,
              1791
            ],
            [
              3798,
              1790
            ],
            [
              3899,
              1721
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Asia",
        "fullname": "India",
        "admin": "India",
        "woe_id": 23424848,
        "id": "IN",
        "abbrev": "India",
        "subregion": "Southern Asia",
        "region_wb": "South Asia",
        "iso_a3": "IND",
        "name": "IN",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -1035,
              3412
            ],
            [
              -1072,
              3380
            ],
            [
              -1068,
              3345
            ],
            [
              -980,
              3337
            ],
            [
              -965,
              3251
            ],
            [
              -990,
              3203
            ],
            [
              -1107,
              3153
            ],
            [
              -1170,
              3142
            ],
            [
              -1202,
              3168
            ],
            [
              -1148,
              3265
            ],
            [
              -1187,
              3296
            ],
            [
              -1177,
              3355
            ],
            [
              -1115,
              3350
            ],
            [
              -1092,
              3419
            ],
            [
              -1035,
              3412
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Europe",
        "fullname": "Ireland",
        "admin": "Ireland",
        "woe_id": 23424803,
        "id": "IE",
        "abbrev": "Ire.",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "IRL",
        "name": "IE",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1950,
              2267
            ],
            [
              1971,
              2268
            ],
            [
              2046,
              2321
            ],
            [
              2073,
              2301
            ],
            [
              2066,
              2259
            ],
            [
              2103,
              2239
            ],
            [
              2111,
              2194
            ],
            [
              2174,
              2173
            ],
            [
              2227,
              2130
            ],
            [
              2273,
              2120
            ],
            [
              2361,
              2140
            ],
            [
              2385,
              2169
            ],
            [
              2433,
              2180
            ],
            [
              2469,
              2216
            ],
            [
              2559,
              2223
            ],
            [
              2577,
              2209
            ],
            [
              2661,
              2190
            ],
            [
              2726,
              2148
            ],
            [
              2744,
              2124
            ],
            [
              2785,
              2124
            ],
            [
              2796,
              2059
            ],
            [
              2785,
              2008
            ],
            [
              2783,
              2007
            ],
            [
              2779,
              2002
            ],
            [
              2779,
              2001
            ],
            [
              2779,
              1999
            ],
            [
              2779,
              1997
            ],
            [
              2778,
              1997
            ],
            [
              2776,
              1996
            ],
            [
              2775,
              1996
            ],
            [
              2775,
              1996
            ],
            [
              2774,
              1995
            ],
            [
              2772,
              1992
            ],
            [
              2771,
              1992
            ],
            [
              2769,
              1991
            ],
            [
              2765,
              1990
            ],
            [
              2752,
              1962
            ],
            [
              2757,
              1903
            ],
            [
              2756,
              1898
            ],
            [
              2757,
              1894
            ],
            [
              2771,
              1847
            ],
            [
              2771,
              1842
            ],
            [
              2771,
              1837
            ],
            [
              2770,
              1836
            ],
            [
              2768,
              1834
            ],
            [
              2773,
              1800
            ],
            [
              2808,
              1795
            ],
            [
              2815,
              1794
            ],
            [
              2818,
              1794
            ],
            [
              2819,
              1793
            ],
            [
              2820,
              1792
            ],
            [
              2823,
              1789
            ],
            [
              2827,
              1772
            ],
            [
              2827,
              1770
            ],
            [
              2826,
              1768
            ],
            [
              2826,
              1767
            ],
            [
              2826,
              1763
            ],
            [
              2826,
              1761
            ],
            [
              2825,
              1760
            ],
            [
              2807,
              1739
            ],
            [
              2780,
              1709
            ],
            [
              2780,
              1709
            ],
            [
              2775,
              1704
            ],
            [
              2772,
              1700
            ],
            [
              2777,
              1695
            ],
            [
              2780,
              1692
            ],
            [
              2797,
              1673
            ],
            [
              2831,
              1620
            ],
            [
              2878,
              1587
            ],
            [
              2879,
              1506
            ],
            [
              2829,
              1482
            ],
            [
              2814,
              1420
            ],
            [
              2576,
              1454
            ],
            [
              2559,
              1518
            ],
            [
              2521,
              1540
            ],
            [
              2434,
              1497
            ],
            [
              2374,
              1510
            ],
            [
              2278,
              1578
            ],
            [
              2243,
              1587
            ],
            [
              2203,
              1675
            ],
            [
              2174,
              1705
            ],
            [
              2084,
              1707
            ],
            [
              2036,
              1770
            ],
            [
              2045,
              1817
            ],
            [
              2020,
              1853
            ],
            [
              1958,
              1892
            ],
            [
              1914,
              1953
            ],
            [
              1914,
              1994
            ],
            [
              1950,
              2028
            ],
            [
              1932,
              2071
            ],
            [
              1890,
              2110
            ],
            [
              1873,
              2156
            ],
            [
              1843,
              2203
            ],
            [
              1846,
              2266
            ],
            [
              1832,
              2301
            ],
            [
              1876,
              2318
            ],
            [
              1911,
              2276
            ],
            [
              1950,
              2267
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Asia",
        "fullname": "Iran",
        "admin": "Iran",
        "woe_id": 23424851,
        "id": "IR",
        "abbrev": "Iran",
        "subregion": "Southern Asia",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "IRN",
        "name": "IR",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1873,
              2156
            ],
            [
              1890,
              2110
            ],
            [
              1932,
              2071
            ],
            [
              1950,
              2028
            ],
            [
              1914,
              1994
            ],
            [
              1914,
              1953
            ],
            [
              1958,
              1892
            ],
            [
              2020,
              1853
            ],
            [
              2045,
              1817
            ],
            [
              2036,
              1770
            ],
            [
              2084,
              1707
            ],
            [
              2051,
              1709
            ],
            [
              2004,
              1707
            ],
            [
              1972,
              1654
            ],
            [
              1869,
              1660
            ],
            [
              1723,
              1775
            ],
            [
              1628,
              1828
            ],
            [
              1559,
              1839
            ],
            [
              1559,
              1840
            ],
            [
              1566,
              1845
            ],
            [
              1567,
              1847
            ],
            [
              1566,
              1851
            ],
            [
              1565,
              1853
            ],
            [
              1564,
              1854
            ],
            [
              1553,
              1851
            ],
            [
              1554,
              1863
            ],
            [
              1538,
              1917
            ],
            [
              1645,
              1977
            ],
            [
              1674,
              2005
            ],
            [
              1686,
              2117
            ],
            [
              1739,
              2154
            ],
            [
              1762,
              2171
            ],
            [
              1873,
              2156
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Asia",
        "fullname": "Iraq",
        "admin": "Iraq",
        "woe_id": 23424855,
        "id": "IQ",
        "abbrev": "Iraq",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "IRQ",
        "name": "IQ",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -1525,
              4381
            ],
            [
              -1506,
              4352
            ],
            [
              -1450,
              4336
            ],
            [
              -1393,
              4282
            ],
            [
              -1385,
              4246
            ],
            [
              -1443,
              4187
            ],
            [
              -1524,
              4164
            ],
            [
              -1558,
              4139
            ],
            [
              -1630,
              4126
            ],
            [
              -1677,
              4097
            ],
            [
              -1758,
              4110
            ],
            [
              -1862,
              4155
            ],
            [
              -1879,
              4219
            ],
            [
              -1871,
              4279
            ],
            [
              -1913,
              4295
            ],
            [
              -1966,
              4277
            ],
            [
              -1960,
              4338
            ],
            [
              -1892,
              4374
            ],
            [
              -1823,
              4333
            ],
            [
              -1820,
              4291
            ],
            [
              -1776,
              4285
            ],
            [
              -1749,
              4339
            ],
            [
              -1653,
              4348
            ],
            [
              -1613,
              4330
            ],
            [
              -1552,
              4347
            ],
            [
              -1525,
              4381
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Europe",
        "fullname": "Iceland",
        "admin": "Iceland",
        "woe_id": 23424845,
        "id": "IS",
        "abbrev": "Iceland",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "ISL",
        "name": "IS",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1370,
              1878
            ],
            [
              1359,
              1872
            ],
            [
              1359,
              1871
            ],
            [
              1359,
              1870
            ],
            [
              1359,
              1870
            ],
            [
              1359,
              1869
            ],
            [
              1359,
              1867
            ],
            [
              1359,
              1864
            ],
            [
              1360,
              1863
            ],
            [
              1359,
              1862
            ],
            [
              1359,
              1862
            ],
            [
              1359,
              1861
            ],
            [
              1359,
              1859
            ],
            [
              1358,
              1859
            ],
            [
              1358,
              1859
            ],
            [
              1358,
              1859
            ],
            [
              1358,
              1858
            ],
            [
              1358,
              1857
            ],
            [
              1358,
              1857
            ],
            [
              1358,
              1857
            ],
            [
              1358,
              1856
            ],
            [
              1358,
              1856
            ],
            [
              1359,
              1856
            ],
            [
              1354,
              1857
            ],
            [
              1353,
              1857
            ],
            [
              1350,
              1858
            ],
            [
              1349,
              1863
            ],
            [
              1339,
              1866
            ],
            [
              1324,
              1843
            ],
            [
              1323,
              1792
            ],
            [
              1340,
              1794
            ],
            [
              1353,
              1801
            ],
            [
              1353,
              1801
            ],
            [
              1353,
              1796
            ],
            [
              1353,
              1795
            ],
            [
              1349,
              1786
            ],
            [
              1352,
              1777
            ],
            [
              1335,
              1735
            ],
            [
              1335,
              1719
            ],
            [
              1332,
              1711
            ],
            [
              1330,
              1699
            ],
            [
              1327,
              1693
            ],
            [
              1327,
              1688
            ],
            [
              1325,
              1685
            ],
            [
              1325,
              1684
            ],
            [
              1325,
              1682
            ],
            [
              1325,
              1681
            ],
            [
              1321,
              1678
            ],
            [
              1285,
              1783
            ],
            [
              1298,
              1806
            ],
            [
              1333,
              1900
            ],
            [
              1338,
              1899
            ],
            [
              1343,
              1900
            ],
            [
              1346,
              1898
            ],
            [
              1350,
              1898
            ],
            [
              1352,
              1899
            ],
            [
              1354,
              1899
            ],
            [
              1356,
              1903
            ],
            [
              1356,
              1908
            ],
            [
              1366,
              1914
            ],
            [
              1367,
              1914
            ],
            [
              1368,
              1914
            ],
            [
              1369,
              1915
            ],
            [
              1371,
              1916
            ],
            [
              1373,
              1919
            ],
            [
              1373,
              1919
            ],
            [
              1373,
              1919
            ],
            [
              1373,
              1917
            ],
            [
              1372,
              1914
            ],
            [
              1372,
              1910
            ],
            [
              1377,
              1891
            ],
            [
              1370,
              1878
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Asia",
        "fullname": "Israel",
        "admin": "Israel",
        "woe_id": 23424852,
        "id": "IL",
        "abbrev": "Isr.",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "ISR",
        "name": "IL",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                245,
                2228
              ],
              [
                221,
                2195
              ],
              [
                227,
                2149
              ],
              [
                180,
                2134
              ],
              [
                65,
                2198
              ],
              [
                115,
                2225
              ],
              [
                141,
                2209
              ],
              [
                245,
                2228
              ]
            ]
          ],
          [
            [
              [
                -113,
                2424
              ],
              [
                -80,
                2376
              ],
              [
                -95,
                2285
              ],
              [
                -147,
                2269
              ],
              [
                -153,
                2396
              ],
              [
                -113,
                2424
              ]
            ]
          ],
          [
            [
              [
                -210,
                2595
              ],
              [
                -247,
                2644
              ],
              [
                -237,
                2742
              ],
              [
                -234,
                2739
              ],
              [
                -228,
                2739
              ],
              [
                -208,
                2746
              ],
              [
                -192,
                2741
              ],
              [
                -183,
                2747
              ],
              [
                -181,
                2753
              ],
              [
                -179,
                2754
              ],
              [
                -176,
                2756
              ],
              [
                -166,
                2776
              ],
              [
                -101,
                2773
              ],
              [
                -68,
                2762
              ],
              [
                -62,
                2764
              ],
              [
                -62,
                2765
              ],
              [
                -62,
                2767
              ],
              [
                -65,
                2769
              ],
              [
                -65,
                2769
              ],
              [
                -65,
                2770
              ],
              [
                -65,
                2771
              ],
              [
                -65,
                2772
              ],
              [
                -65,
                2772
              ],
              [
                -63,
                2773
              ],
              [
                -63,
                2774
              ],
              [
                -63,
                2776
              ],
              [
                -54,
                2786
              ],
              [
                -45,
                2805
              ],
              [
                -6,
                2814
              ],
              [
                3,
                2816
              ],
              [
                5,
                2816
              ],
              [
                7,
                2815
              ],
              [
                8,
                2815
              ],
              [
                11,
                2817
              ],
              [
                19,
                2817
              ],
              [
                24,
                2817
              ],
              [
                25,
                2815
              ],
              [
                27,
                2815
              ],
              [
                27,
                2815
              ],
              [
                31,
                2817
              ],
              [
                33,
                2818
              ],
              [
                36,
                2819
              ],
              [
                38,
                2820
              ],
              [
                48,
                2817
              ],
              [
                64,
                2795
              ],
              [
                110,
                2786
              ],
              [
                112,
                2786
              ],
              [
                118,
                2787
              ],
              [
                124,
                2786
              ],
              [
                131,
                2778
              ],
              [
                118,
                2767
              ],
              [
                132,
                2756
              ],
              [
                52,
                2696
              ],
              [
                62,
                2626
              ],
              [
                132,
                2577
              ],
              [
                157,
                2514
              ],
              [
                216,
                2470
              ],
              [
                266,
                2470
              ],
              [
                266,
                2436
              ],
              [
                377,
                2384
              ],
              [
                324,
                2376
              ],
              [
                294,
                2321
              ],
              [
                330,
                2303
              ],
              [
                328,
                2271
              ],
              [
                275,
                2260
              ],
              [
                243,
                2346
              ],
              [
                204,
                2357
              ],
              [
                138,
                2424
              ],
              [
                100,
                2423
              ],
              [
                -30,
                2528
              ],
              [
                -65,
                2610
              ],
              [
                -140,
                2639
              ],
              [
                -210,
                2595
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Europe",
        "fullname": "Italy",
        "admin": "Italy",
        "woe_id": 23424853,
        "id": "IT",
        "abbrev": "Italy",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "ITA",
        "name": "IT",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1553,
              1851
            ],
            [
              1564,
              1854
            ],
            [
              1565,
              1853
            ],
            [
              1566,
              1851
            ],
            [
              1567,
              1847
            ],
            [
              1566,
              1845
            ],
            [
              1559,
              1840
            ],
            [
              1551,
              1832
            ],
            [
              1549,
              1832
            ],
            [
              1549,
              1832
            ],
            [
              1492,
              1816
            ],
            [
              1465,
              1808
            ],
            [
              1451,
              1804
            ],
            [
              1439,
              1801
            ],
            [
              1437,
              1801
            ],
            [
              1451,
              1786
            ],
            [
              1466,
              1771
            ],
            [
              1473,
              1763
            ],
            [
              1479,
              1756
            ],
            [
              1485,
              1749
            ],
            [
              1491,
              1743
            ],
            [
              1494,
              1740
            ],
            [
              1494,
              1740
            ],
            [
              1494,
              1740
            ],
            [
              1494,
              1739
            ],
            [
              1477,
              1730
            ],
            [
              1475,
              1729
            ],
            [
              1475,
              1728
            ],
            [
              1465,
              1709
            ],
            [
              1425,
              1701
            ],
            [
              1410,
              1678
            ],
            [
              1387,
              1660
            ],
            [
              1386,
              1660
            ],
            [
              1384,
              1660
            ],
            [
              1331,
              1668
            ],
            [
              1324,
              1670
            ],
            [
              1324,
              1670
            ],
            [
              1325,
              1670
            ],
            [
              1326,
              1676
            ],
            [
              1327,
              1681
            ],
            [
              1326,
              1682
            ],
            [
              1325,
              1682
            ],
            [
              1325,
              1682
            ],
            [
              1325,
              1682
            ],
            [
              1325,
              1682
            ],
            [
              1325,
              1684
            ],
            [
              1325,
              1685
            ],
            [
              1327,
              1688
            ],
            [
              1327,
              1693
            ],
            [
              1330,
              1699
            ],
            [
              1332,
              1711
            ],
            [
              1335,
              1719
            ],
            [
              1335,
              1735
            ],
            [
              1352,
              1777
            ],
            [
              1349,
              1786
            ],
            [
              1353,
              1795
            ],
            [
              1353,
              1796
            ],
            [
              1353,
              1801
            ],
            [
              1353,
              1801
            ],
            [
              1353,
              1801
            ],
            [
              1353,
              1801
            ],
            [
              1353,
              1805
            ],
            [
              1354,
              1810
            ],
            [
              1355,
              1813
            ],
            [
              1357,
              1816
            ],
            [
              1359,
              1817
            ],
            [
              1357,
              1821
            ],
            [
              1358,
              1822
            ],
            [
              1357,
              1827
            ],
            [
              1356,
              1836
            ],
            [
              1359,
              1847
            ],
            [
              1358,
              1852
            ],
            [
              1358,
              1854
            ],
            [
              1358,
              1855
            ],
            [
              1358,
              1855
            ],
            [
              1359,
              1855
            ],
            [
              1359,
              1856
            ],
            [
              1358,
              1856
            ],
            [
              1358,
              1856
            ],
            [
              1358,
              1857
            ],
            [
              1358,
              1857
            ],
            [
              1358,
              1859
            ],
            [
              1359,
              1859
            ],
            [
              1359,
              1861
            ],
            [
              1359,
              1861
            ],
            [
              1359,
              1861
            ],
            [
              1359,
              1861
            ],
            [
              1359,
              1861
            ],
            [
              1359,
              1862
            ],
            [
              1359,
              1862
            ],
            [
              1360,
              1863
            ],
            [
              1359,
              1864
            ],
            [
              1359,
              1867
            ],
            [
              1359,
              1869
            ],
            [
              1359,
              1870
            ],
            [
              1359,
              1870
            ],
            [
              1359,
              1871
            ],
            [
              1359,
              1872
            ],
            [
              1377,
              1876
            ],
            [
              1384,
              1870
            ],
            [
              1384,
              1869
            ],
            [
              1387,
              1865
            ],
            [
              1387,
              1864
            ],
            [
              1388,
              1864
            ],
            [
              1389,
              1864
            ],
            [
              1391,
              1864
            ],
            [
              1391,
              1864
            ],
            [
              1392,
              1864
            ],
            [
              1393,
              1865
            ],
            [
              1404,
              1856
            ],
            [
              1429,
              1852
            ],
            [
              1538,
              1917
            ],
            [
              1554,
              1863
            ],
            [
              1550,
              1861
            ],
            [
              1553,
              1851
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Asia",
        "fullname": "Jordan",
        "admin": "Jordan",
        "woe_id": 23424860,
        "id": "JO",
        "abbrev": "Jord.",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "JOR",
        "name": "JO",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                6694,
                1950
              ],
              [
                6735,
                1930
              ],
              [
                6750,
                1887
              ],
              [
                6690,
                1777
              ],
              [
                6651,
                1785
              ],
              [
                6648,
                1819
              ],
              [
                6675,
                1870
              ],
              [
                6633,
                1873
              ],
              [
                6615,
                1917
              ],
              [
                6694,
                1950
              ]
            ]
          ],
          [
            [
              [
                6871,
                1979
              ],
              [
                6878,
                1926
              ],
              [
                6821,
                1916
              ],
              [
                6796,
                1878
              ],
              [
                6772,
                1923
              ],
              [
                6794,
                1956
              ],
              [
                6835,
                1954
              ],
              [
                6871,
                1979
              ]
            ]
          ],
          [
            [
              [
                7269,
                2431
              ],
              [
                7313,
                2311
              ],
              [
                7301,
                2278
              ],
              [
                7250,
                2214
              ],
              [
                7256,
                2171
              ],
              [
                7229,
                2101
              ],
              [
                7245,
                2067
              ],
              [
                7220,
                2032
              ],
              [
                7179,
                2043
              ],
              [
                7147,
                2010
              ],
              [
                7054,
                1999
              ],
              [
                6991,
                1966
              ],
              [
                6961,
                1924
              ],
              [
                6922,
                1949
              ],
              [
                6941,
                1992
              ],
              [
                6904,
                2005
              ],
              [
                6859,
                1985
              ],
              [
                6778,
                1979
              ],
              [
                6756,
                1949
              ],
              [
                6718,
                1983
              ],
              [
                6786,
                2047
              ],
              [
                6914,
                2060
              ],
              [
                6959,
                2051
              ],
              [
                6972,
                2081
              ],
              [
                7030,
                2136
              ],
              [
                7100,
                2159
              ],
              [
                7166,
                2222
              ],
              [
                7201,
                2309
              ],
              [
                7188,
                2339
              ],
              [
                7214,
                2399
              ],
              [
                7269,
                2431
              ]
            ]
          ],
          [
            [
              [
                7311,
                2709
              ],
              [
                7364,
                2649
              ],
              [
                7409,
                2616
              ],
              [
                7482,
                2593
              ],
              [
                7494,
                2553
              ],
              [
                7401,
                2519
              ],
              [
                7378,
                2470
              ],
              [
                7294,
                2516
              ],
              [
                7251,
                2495
              ],
              [
                7209,
                2434
              ],
              [
                7191,
                2519
              ],
              [
                7278,
                2566
              ],
              [
                7311,
                2709
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Asia",
        "fullname": "Japan",
        "admin": "Japan",
        "woe_id": 23424856,
        "id": "JP",
        "abbrev": "Japan",
        "subregion": "Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "JPN",
        "name": "JP",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4253,
              2965
            ],
            [
              4210,
              2925
            ],
            [
              4167,
              2917
            ],
            [
              4151,
              2821
            ],
            [
              4062,
              2815
            ],
            [
              4019,
              2832
            ],
            [
              3971,
              2715
            ],
            [
              3847,
              2679
            ],
            [
              3886,
              2549
            ],
            [
              3864,
              2543
            ],
            [
              3855,
              2487
            ],
            [
              3839,
              2503
            ],
            [
              3826,
              2505
            ],
            [
              3814,
              2505
            ],
            [
              3789,
              2525
            ],
            [
              3759,
              2533
            ],
            [
              3607,
              2536
            ],
            [
              3600,
              2528
            ],
            [
              3598,
              2528
            ],
            [
              3595,
              2530
            ],
            [
              3591,
              2530
            ],
            [
              3579,
              2531
            ],
            [
              3575,
              2531
            ],
            [
              3574,
              2531
            ],
            [
              3548,
              2541
            ],
            [
              3542,
              2545
            ],
            [
              3539,
              2549
            ],
            [
              3522,
              2556
            ],
            [
              3519,
              2555
            ],
            [
              3510,
              2554
            ],
            [
              3487,
              2546
            ],
            [
              3479,
              2534
            ],
            [
              3480,
              2528
            ],
            [
              3475,
              2514
            ],
            [
              3470,
              2507
            ],
            [
              3469,
              2508
            ],
            [
              3467,
              2509
            ],
            [
              3462,
              2509
            ],
            [
              3458,
              2511
            ],
            [
              3449,
              2510
            ],
            [
              3447,
              2510
            ],
            [
              3445,
              2511
            ],
            [
              3443,
              2512
            ],
            [
              3437,
              2518
            ],
            [
              3412,
              2525
            ],
            [
              3402,
              2525
            ],
            [
              3394,
              2528
            ],
            [
              3390,
              2529
            ],
            [
              3389,
              2529
            ],
            [
              3387,
              2530
            ],
            [
              3355,
              2525
            ],
            [
              3352,
              2522
            ],
            [
              3351,
              2521
            ],
            [
              3349,
              2520
            ],
            [
              3348,
              2520
            ],
            [
              3342,
              2513
            ],
            [
              3340,
              2512
            ],
            [
              3341,
              2510
            ],
            [
              3343,
              2507
            ],
            [
              3341,
              2505
            ],
            [
              3339,
              2506
            ],
            [
              3337,
              2500
            ],
            [
              3337,
              2491
            ],
            [
              3335,
              2489
            ],
            [
              3333,
              2488
            ],
            [
              3329,
              2487
            ],
            [
              3325,
              2487
            ],
            [
              3310,
              2481
            ],
            [
              3277,
              2454
            ],
            [
              3231,
              2433
            ],
            [
              3198,
              2380
            ],
            [
              3169,
              2421
            ],
            [
              3099,
              2421
            ],
            [
              3089,
              2474
            ],
            [
              3061,
              2474
            ],
            [
              3066,
              2541
            ],
            [
              2996,
              2590
            ],
            [
              2973,
              2579
            ],
            [
              2904,
              2584
            ],
            [
              2838,
              2574
            ],
            [
              2783,
              2636
            ],
            [
              2646,
              2716
            ],
            [
              2500,
              2678
            ],
            [
              2500,
              2429
            ],
            [
              2469,
              2427
            ],
            [
              2431,
              2477
            ],
            [
              2392,
              2497
            ],
            [
              2332,
              2483
            ],
            [
              2302,
              2457
            ],
            [
              2319,
              2518
            ],
            [
              2274,
              2530
            ],
            [
              2213,
              2622
            ],
            [
              2244,
              2701
            ],
            [
              2309,
              2706
            ],
            [
              2338,
              2749
            ],
            [
              2338,
              2807
            ],
            [
              2291,
              2804
            ],
            [
              2234,
              2826
            ],
            [
              2123,
              2770
            ],
            [
              2083,
              2846
            ],
            [
              2057,
              2871
            ],
            [
              2008,
              2871
            ],
            [
              1984,
              2953
            ],
            [
              1993,
              3019
            ],
            [
              2029,
              3065
            ],
            [
              2077,
              3018
            ],
            [
              2101,
              3075
            ],
            [
              2184,
              3127
            ],
            [
              2208,
              3157
            ],
            [
              2258,
              3138
            ],
            [
              2368,
              3133
            ],
            [
              2482,
              3070
            ],
            [
              2530,
              3110
            ],
            [
              2630,
              3116
            ],
            [
              2665,
              3081
            ],
            [
              2712,
              3071
            ],
            [
              2802,
              3089
            ],
            [
              2807,
              3136
            ],
            [
              2754,
              3150
            ],
            [
              2783,
              3204
            ],
            [
              2808,
              3330
            ],
            [
              2897,
              3336
            ],
            [
              3182,
              3403
            ],
            [
              3235,
              3437
            ],
            [
              3339,
              3412
            ],
            [
              3350,
              3337
            ],
            [
              3449,
              3339
            ],
            [
              3520,
              3299
            ],
            [
              3599,
              3337
            ],
            [
              3649,
              3327
            ],
            [
              3724,
              3274
            ],
            [
              3843,
              3089
            ],
            [
              3881,
              3127
            ],
            [
              3959,
              3084
            ],
            [
              4035,
              3104
            ],
            [
              4080,
              3052
            ],
            [
              4121,
              3037
            ],
            [
              4137,
              3001
            ],
            [
              4175,
              2995
            ],
            [
              4213,
              3017
            ],
            [
              4253,
              2965
            ]
          ],
          [
            [
              2882,
              2751
            ],
            [
              2905,
              2718
            ],
            [
              2935,
              2724
            ],
            [
              2935,
              2768
            ],
            [
              2882,
              2751
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Asia",
        "fullname": "Kazakhstan",
        "admin": "Kazakhstan",
        "woe_id": -90,
        "id": "KZ",
        "abbrev": "Kaz.",
        "subregion": "Central Asia",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "KAZ",
        "name": "KZ",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1379,
              241
            ],
            [
              1430,
              230
            ],
            [
              1501,
              184
            ],
            [
              1575,
              176
            ],
            [
              1650,
              222
            ],
            [
              1669,
              204
            ],
            [
              1687,
              203
            ],
            [
              1702,
              206
            ],
            [
              1707,
              203
            ],
            [
              1709,
              203
            ],
            [
              1712,
              205
            ],
            [
              1708,
              198
            ],
            [
              1682,
              161
            ],
            [
              1662,
              141
            ],
            [
              1662,
              -67
            ],
            [
              1693,
              -113
            ],
            [
              1617,
              -172
            ],
            [
              1614,
              -200
            ],
            [
              1582,
              -264
            ],
            [
              1562,
              -280
            ],
            [
              1482,
              -222
            ],
            [
              1475,
              -188
            ],
            [
              1266,
              -74
            ],
            [
              1266,
              -63
            ],
            [
              1265,
              -55
            ],
            [
              1269,
              -27
            ],
            [
              1269,
              -24
            ],
            [
              1267,
              -19
            ],
            [
              1265,
              -13
            ],
            [
              1265,
              -12
            ],
            [
              1276,
              3
            ],
            [
              1276,
              6
            ],
            [
              1277,
              8
            ],
            [
              1279,
              14
            ],
            [
              1287,
              20
            ],
            [
              1288,
              23
            ],
            [
              1288,
              24
            ],
            [
              1288,
              25
            ],
            [
              1289,
              25
            ],
            [
              1292,
              27
            ],
            [
              1293,
              28
            ],
            [
              1294,
              30
            ],
            [
              1295,
              32
            ],
            [
              1297,
              36
            ],
            [
              1297,
              37
            ],
            [
              1297,
              39
            ],
            [
              1298,
              40
            ],
            [
              1298,
              42
            ],
            [
              1299,
              43
            ],
            [
              1300,
              44
            ],
            [
              1326,
              76
            ],
            [
              1323,
              121
            ],
            [
              1322,
              122
            ],
            [
              1321,
              123
            ],
            [
              1320,
              127
            ],
            [
              1317,
              127
            ],
            [
              1312,
              142
            ],
            [
              1296,
              160
            ],
            [
              1287,
              190
            ],
            [
              1275,
              206
            ],
            [
              1275,
              211
            ],
            [
              1275,
              211
            ],
            [
              1274,
              212
            ],
            [
              1274,
              213
            ],
            [
              1274,
              213
            ],
            [
              1274,
              215
            ],
            [
              1273,
              216
            ],
            [
              1272,
              217
            ],
            [
              1270,
              218
            ],
            [
              1341,
              261
            ],
            [
              1359,
              256
            ],
            [
              1361,
              241
            ],
            [
              1366,
              239
            ],
            [
              1371,
              241
            ],
            [
              1375,
              241
            ],
            [
              1379,
              241
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Africa",
        "fullname": "Kenya",
        "admin": "Kenya",
        "woe_id": 23424863,
        "id": "KE",
        "abbrev": "Ken.",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "KEN",
        "name": "KE",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3575,
              2531
            ],
            [
              3579,
              2531
            ],
            [
              3591,
              2530
            ],
            [
              3595,
              2530
            ],
            [
              3598,
              2528
            ],
            [
              3600,
              2528
            ],
            [
              3607,
              2536
            ],
            [
              3759,
              2533
            ],
            [
              3789,
              2525
            ],
            [
              3796,
              2527
            ],
            [
              3814,
              2505
            ],
            [
              3826,
              2505
            ],
            [
              3839,
              2503
            ],
            [
              3853,
              2476
            ],
            [
              3769,
              2443
            ],
            [
              3752,
              2433
            ],
            [
              3750,
              2430
            ],
            [
              3751,
              2428
            ],
            [
              3751,
              2427
            ],
            [
              3736,
              2410
            ],
            [
              3702,
              2406
            ],
            [
              3676,
              2411
            ],
            [
              3670,
              2409
            ],
            [
              3668,
              2408
            ],
            [
              3655,
              2390
            ],
            [
              3654,
              2383
            ],
            [
              3645,
              2369
            ],
            [
              3638,
              2364
            ],
            [
              3637,
              2364
            ],
            [
              3636,
              2365
            ],
            [
              3633,
              2371
            ],
            [
              3615,
              2362
            ],
            [
              3606,
              2362
            ],
            [
              3603,
              2361
            ],
            [
              3602,
              2361
            ],
            [
              3554,
              2375
            ],
            [
              3508,
              2346
            ],
            [
              3506,
              2344
            ],
            [
              3506,
              2344
            ],
            [
              3505,
              2343
            ],
            [
              3498,
              2329
            ],
            [
              3502,
              2323
            ],
            [
              3504,
              2315
            ],
            [
              3498,
              2307
            ],
            [
              3487,
              2305
            ],
            [
              3472,
              2305
            ],
            [
              3461,
              2299
            ],
            [
              3459,
              2299
            ],
            [
              3457,
              2300
            ],
            [
              3457,
              2300
            ],
            [
              3456,
              2300
            ],
            [
              3443,
              2299
            ],
            [
              3432,
              2302
            ],
            [
              3414,
              2298
            ],
            [
              3399,
              2300
            ],
            [
              3393,
              2298
            ],
            [
              3387,
              2295
            ],
            [
              3385,
              2294
            ],
            [
              3383,
              2294
            ],
            [
              3380,
              2298
            ],
            [
              3381,
              2305
            ],
            [
              3366,
              2316
            ],
            [
              3341,
              2303
            ],
            [
              3319,
              2313
            ],
            [
              3310,
              2315
            ],
            [
              3303,
              2314
            ],
            [
              3298,
              2310
            ],
            [
              3296,
              2310
            ],
            [
              3266,
              2314
            ],
            [
              3261,
              2314
            ],
            [
              3256,
              2311
            ],
            [
              3251,
              2310
            ],
            [
              3250,
              2311
            ],
            [
              3249,
              2312
            ],
            [
              3248,
              2312
            ],
            [
              3248,
              2312
            ],
            [
              3248,
              2311
            ],
            [
              3247,
              2311
            ],
            [
              3245,
              2310
            ],
            [
              3244,
              2311
            ],
            [
              3244,
              2319
            ],
            [
              3241,
              2325
            ],
            [
              3242,
              2330
            ],
            [
              3244,
              2337
            ],
            [
              3245,
              2340
            ],
            [
              3246,
              2340
            ],
            [
              3246,
              2341
            ],
            [
              3246,
              2341
            ],
            [
              3247,
              2341
            ],
            [
              3248,
              2339
            ],
            [
              3251,
              2335
            ],
            [
              3260,
              2348
            ],
            [
              3282,
              2355
            ],
            [
              3320,
              2347
            ],
            [
              3353,
              2363
            ],
            [
              3360,
              2361
            ],
            [
              3367,
              2356
            ],
            [
              3373,
              2355
            ],
            [
              3378,
              2351
            ],
            [
              3379,
              2351
            ],
            [
              3380,
              2351
            ],
            [
              3382,
              2353
            ],
            [
              3383,
              2353
            ],
            [
              3384,
              2354
            ],
            [
              3385,
              2356
            ],
            [
              3387,
              2358
            ],
            [
              3401,
              2370
            ],
            [
              3410,
              2372
            ],
            [
              3411,
              2370
            ],
            [
              3415,
              2367
            ],
            [
              3417,
              2367
            ],
            [
              3431,
              2400
            ],
            [
              3405,
              2419
            ],
            [
              3389,
              2421
            ],
            [
              3388,
              2428
            ],
            [
              3382,
              2437
            ],
            [
              3381,
              2438
            ],
            [
              3380,
              2436
            ],
            [
              3380,
              2437
            ],
            [
              3379,
              2437
            ],
            [
              3378,
              2443
            ],
            [
              3327,
              2423
            ],
            [
              3294,
              2446
            ],
            [
              3330,
              2470
            ],
            [
              3331,
              2476
            ],
            [
              3334,
              2477
            ],
            [
              3336,
              2477
            ],
            [
              3339,
              2477
            ],
            [
              3347,
              2482
            ],
            [
              3353,
              2485
            ],
            [
              3354,
              2487
            ],
            [
              3352,
              2488
            ],
            [
              3344,
              2493
            ],
            [
              3341,
              2494
            ],
            [
              3338,
              2492
            ],
            [
              3337,
              2491
            ],
            [
              3335,
              2491
            ],
            [
              3334,
              2492
            ],
            [
              3332,
              2494
            ],
            [
              3337,
              2500
            ],
            [
              3339,
              2506
            ],
            [
              3341,
              2505
            ],
            [
              3344,
              2506
            ],
            [
              3344,
              2507
            ],
            [
              3343,
              2507
            ],
            [
              3342,
              2513
            ],
            [
              3347,
              2514
            ],
            [
              3348,
              2520
            ],
            [
              3349,
              2520
            ],
            [
              3351,
              2521
            ],
            [
              3352,
              2522
            ],
            [
              3355,
              2525
            ],
            [
              3387,
              2530
            ],
            [
              3389,
              2529
            ],
            [
              3390,
              2529
            ],
            [
              3394,
              2528
            ],
            [
              3402,
              2525
            ],
            [
              3437,
              2518
            ],
            [
              3443,
              2512
            ],
            [
              3445,
              2511
            ],
            [
              3447,
              2510
            ],
            [
              3449,
              2510
            ],
            [
              3458,
              2511
            ],
            [
              3462,
              2509
            ],
            [
              3467,
              2509
            ],
            [
              3469,
              2508
            ],
            [
              3470,
              2507
            ],
            [
              3480,
              2502
            ],
            [
              3475,
              2514
            ],
            [
              3480,
              2528
            ],
            [
              3479,
              2534
            ],
            [
              3487,
              2546
            ],
            [
              3510,
              2554
            ],
            [
              3519,
              2555
            ],
            [
              3522,
              2556
            ],
            [
              3539,
              2549
            ],
            [
              3542,
              2545
            ],
            [
              3548,
              2541
            ],
            [
              3556,
              2540
            ],
            [
              3562,
              2537
            ],
            [
              3567,
              2535
            ],
            [
              3575,
              2531
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Asia",
        "fullname": "Kyrgyzstan",
        "admin": "Kyrgyzstan",
        "woe_id": 23424864,
        "id": "KG",
        "abbrev": "Kgz.",
        "subregion": "Central Asia",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "KGZ",
        "name": "KG",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              5210,
              567
            ],
            [
              5134,
              596
            ],
            [
              5124,
              636
            ],
            [
              5092,
              728
            ],
            [
              5134,
              786
            ],
            [
              5251,
              789
            ],
            [
              5290,
              766
            ],
            [
              5305,
              774
            ],
            [
              5298,
              782
            ],
            [
              5297,
              786
            ],
            [
              5295,
              788
            ],
            [
              5295,
              790
            ],
            [
              5295,
              790
            ],
            [
              5297,
              790
            ],
            [
              5299,
              789
            ],
            [
              5300,
              791
            ],
            [
              5305,
              790
            ],
            [
              5310,
              797
            ],
            [
              5326,
              803
            ],
            [
              5341,
              788
            ],
            [
              5350,
              788
            ],
            [
              5365,
              798
            ],
            [
              5369,
              804
            ],
            [
              5372,
              802
            ],
            [
              5376,
              801
            ],
            [
              5377,
              801
            ],
            [
              5377,
              802
            ],
            [
              5377,
              803
            ],
            [
              5377,
              803
            ],
            [
              5377,
              803
            ],
            [
              5377,
              804
            ],
            [
              5378,
              804
            ],
            [
              5378,
              806
            ],
            [
              5378,
              806
            ],
            [
              5379,
              806
            ],
            [
              5381,
              809
            ],
            [
              5382,
              810
            ],
            [
              5383,
              808
            ],
            [
              5381,
              800
            ],
            [
              5374,
              794
            ],
            [
              5371,
              776
            ],
            [
              5386,
              743
            ],
            [
              5382,
              675
            ],
            [
              5319,
              654
            ],
            [
              5289,
              630
            ],
            [
              5283,
              600
            ],
            [
              5210,
              567
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Asia",
        "fullname": "Cambodia",
        "admin": "Cambodia",
        "woe_id": 23424776,
        "id": "KH",
        "abbrev": "Camb.",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "KHM",
        "name": "KH",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              6452,
              2200
            ],
            [
              6480,
              2231
            ],
            [
              6529,
              2231
            ],
            [
              6547,
              2252
            ],
            [
              6601,
              2165
            ],
            [
              6608,
              2052
            ],
            [
              6594,
              2029
            ],
            [
              6517,
              2016
            ],
            [
              6489,
              1985
            ],
            [
              6445,
              1975
            ],
            [
              6435,
              2037
            ],
            [
              6456,
              2074
            ],
            [
              6442,
              2124
            ],
            [
              6452,
              2200
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Asia",
        "fullname": "Korea",
        "admin": "South Korea",
        "woe_id": 23424868,
        "id": "KR",
        "abbrev": "S.K.",
        "subregion": "Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "KOR",
        "name": "KR",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              576,
              2491
            ],
            [
              520,
              2466
            ],
            [
              492,
              2511
            ],
            [
              508,
              2530
            ],
            [
              536,
              2553
            ],
            [
              576,
              2491
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Europe",
        "fullname": "Kosovo",
        "admin": "Kosovo",
        "woe_id": -90,
        "id": "Kosovo",
        "abbrev": "Kos.",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "-99",
        "name": "-99",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2078,
              1620
            ],
            [
              2036,
              1620
            ],
            [
              2022,
              1648
            ],
            [
              1972,
              1654
            ],
            [
              2004,
              1707
            ],
            [
              2051,
              1709
            ],
            [
              2078,
              1620
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Asia",
        "fullname": "Kuwait",
        "admin": "Kuwait",
        "woe_id": 23424870,
        "id": "KW",
        "abbrev": "Kwt.",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "KWT",
        "name": "KW",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              5066,
              1253
            ],
            [
              5067,
              1256
            ],
            [
              5068,
              1257
            ],
            [
              5071,
              1257
            ],
            [
              5072,
              1257
            ],
            [
              5074,
              1257
            ],
            [
              5077,
              1256
            ],
            [
              5078,
              1256
            ],
            [
              5079,
              1256
            ],
            [
              5079,
              1255
            ],
            [
              5079,
              1255
            ],
            [
              5079,
              1254
            ],
            [
              5080,
              1254
            ],
            [
              5106,
              1225
            ],
            [
              5126,
              1216
            ],
            [
              5136,
              1165
            ],
            [
              5188,
              1170
            ],
            [
              5219,
              1153
            ],
            [
              5209,
              1138
            ],
            [
              5223,
              1134
            ],
            [
              5239,
              1120
            ],
            [
              5230,
              1102
            ],
            [
              5222,
              1097
            ],
            [
              5220,
              1092
            ],
            [
              5219,
              1092
            ],
            [
              5219,
              1092
            ],
            [
              5218,
              1092
            ],
            [
              5217,
              1092
            ],
            [
              5217,
              1092
            ],
            [
              5216,
              1091
            ],
            [
              5214,
              1091
            ],
            [
              5213,
              1091
            ],
            [
              5198,
              1097
            ],
            [
              5190,
              1084
            ],
            [
              5187,
              1080
            ],
            [
              5182,
              1079
            ],
            [
              5181,
              1077
            ],
            [
              5180,
              1076
            ],
            [
              5178,
              1075
            ],
            [
              5177,
              1075
            ],
            [
              5177,
              1074
            ],
            [
              5177,
              1074
            ],
            [
              5179,
              1073
            ],
            [
              5180,
              1072
            ],
            [
              5180,
              1072
            ],
            [
              5180,
              1071
            ],
            [
              5184,
              1069
            ],
            [
              5187,
              1070
            ],
            [
              5193,
              1067
            ],
            [
              5225,
              1044
            ],
            [
              5320,
              940
            ],
            [
              5339,
              908
            ],
            [
              5359,
              898
            ],
            [
              5361,
              893
            ],
            [
              5362,
              893
            ],
            [
              5365,
              892
            ],
            [
              5366,
              891
            ],
            [
              5368,
              889
            ],
            [
              5369,
              887
            ],
            [
              5377,
              888
            ],
            [
              5375,
              880
            ],
            [
              5390,
              843
            ],
            [
              5390,
              842
            ],
            [
              5390,
              841
            ],
            [
              5389,
              838
            ],
            [
              5385,
              829
            ],
            [
              5378,
              828
            ],
            [
              5384,
              819
            ],
            [
              5383,
              818
            ],
            [
              5381,
              816
            ],
            [
              5380,
              815
            ],
            [
              5382,
              811
            ],
            [
              5382,
              810
            ],
            [
              5381,
              809
            ],
            [
              5379,
              806
            ],
            [
              5378,
              806
            ],
            [
              5378,
              806
            ],
            [
              5378,
              804
            ],
            [
              5377,
              804
            ],
            [
              5377,
              803
            ],
            [
              5377,
              803
            ],
            [
              5377,
              803
            ],
            [
              5377,
              802
            ],
            [
              5377,
              801
            ],
            [
              5376,
              801
            ],
            [
              5350,
              788
            ],
            [
              5341,
              788
            ],
            [
              5326,
              803
            ],
            [
              5320,
              796
            ],
            [
              5310,
              797
            ],
            [
              5305,
              790
            ],
            [
              5300,
              791
            ],
            [
              5299,
              789
            ],
            [
              5297,
              790
            ],
            [
              5295,
              790
            ],
            [
              5295,
              790
            ],
            [
              5295,
              788
            ],
            [
              5264,
              794
            ],
            [
              5269,
              800
            ],
            [
              5269,
              803
            ],
            [
              5269,
              807
            ],
            [
              5268,
              814
            ],
            [
              5274,
              825
            ],
            [
              5272,
              842
            ],
            [
              5272,
              845
            ],
            [
              5268,
              846
            ],
            [
              5268,
              847
            ],
            [
              5268,
              848
            ],
            [
              5269,
              849
            ],
            [
              5272,
              850
            ],
            [
              5274,
              851
            ],
            [
              5275,
              853
            ],
            [
              5275,
              855
            ],
            [
              5275,
              856
            ],
            [
              5277,
              863
            ],
            [
              5277,
              866
            ],
            [
              5275,
              868
            ],
            [
              5266,
              870
            ],
            [
              5264,
              885
            ],
            [
              5227,
              914
            ],
            [
              5231,
              963
            ],
            [
              5212,
              978
            ],
            [
              5191,
              1005
            ],
            [
              5185,
              1017
            ],
            [
              5151,
              1024
            ],
            [
              5151,
              1024
            ],
            [
              5146,
              1023
            ],
            [
              5144,
              1022
            ],
            [
              5107,
              990
            ],
            [
              5105,
              997
            ],
            [
              5100,
              997
            ],
            [
              5098,
              997
            ],
            [
              5096,
              998
            ],
            [
              5090,
              1002
            ],
            [
              5078,
              1011
            ],
            [
              5077,
              1011
            ],
            [
              5076,
              1010
            ],
            [
              5068,
              1001
            ],
            [
              5058,
              994
            ],
            [
              5050,
              991
            ],
            [
              5025,
              968
            ],
            [
              5031,
              1038
            ],
            [
              5028,
              1083
            ],
            [
              5010,
              1092
            ],
            [
              4987,
              1088
            ],
            [
              4992,
              1118
            ],
            [
              4982,
              1136
            ],
            [
              4979,
              1138
            ],
            [
              4971,
              1131
            ],
            [
              4970,
              1128
            ],
            [
              4968,
              1129
            ],
            [
              4967,
              1131
            ],
            [
              4967,
              1133
            ],
            [
              4967,
              1135
            ],
            [
              4968,
              1137
            ],
            [
              4969,
              1141
            ],
            [
              4970,
              1146
            ],
            [
              4971,
              1150
            ],
            [
              4971,
              1151
            ],
            [
              4971,
              1152
            ],
            [
              4973,
              1155
            ],
            [
              4974,
              1156
            ],
            [
              4975,
              1157
            ],
            [
              4978,
              1160
            ],
            [
              4983,
              1163
            ],
            [
              4990,
              1166
            ],
            [
              5002,
              1191
            ],
            [
              5058,
              1190
            ],
            [
              5059,
              1220
            ],
            [
              5046,
              1244
            ],
            [
              5066,
              1253
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Asia",
        "fullname": "Lao PDR",
        "admin": "Laos",
        "woe_id": 23424872,
        "id": "LA",
        "abbrev": "Laos",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "LAO",
        "name": "LA",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1405,
              1996
            ],
            [
              1405,
              1991
            ],
            [
              1402,
              1990
            ],
            [
              1401,
              1989
            ],
            [
              1402,
              1988
            ],
            [
              1402,
              1988
            ],
            [
              1413,
              1983
            ],
            [
              1412,
              1979
            ],
            [
              1416,
              1975
            ],
            [
              1415,
              1973
            ],
            [
              1415,
              1971
            ],
            [
              1417,
              1970
            ],
            [
              1417,
              1969
            ],
            [
              1417,
              1969
            ],
            [
              1415,
              1967
            ],
            [
              1414,
              1965
            ],
            [
              1410,
              1962
            ],
            [
              1405,
              1959
            ],
            [
              1403,
              1957
            ],
            [
              1401,
              1955
            ],
            [
              1400,
              1954
            ],
            [
              1399,
              1953
            ],
            [
              1398,
              1951
            ],
            [
              1399,
              1950
            ],
            [
              1399,
              1950
            ],
            [
              1401,
              1949
            ],
            [
              1404,
              1948
            ],
            [
              1402,
              1945
            ],
            [
              1400,
              1946
            ],
            [
              1397,
              1947
            ],
            [
              1396,
              1947
            ],
            [
              1395,
              1947
            ],
            [
              1395,
              1946
            ],
            [
              1394,
              1946
            ],
            [
              1392,
              1946
            ],
            [
              1386,
              1945
            ],
            [
              1383,
              1942
            ],
            [
              1382,
              1940
            ],
            [
              1381,
              1939
            ],
            [
              1380,
              1938
            ],
            [
              1380,
              1938
            ],
            [
              1379,
              1934
            ],
            [
              1379,
              1923
            ],
            [
              1377,
              1922
            ],
            [
              1376,
              1921
            ],
            [
              1375,
              1920
            ],
            [
              1373,
              1919
            ],
            [
              1371,
              1916
            ],
            [
              1369,
              1915
            ],
            [
              1368,
              1914
            ],
            [
              1367,
              1914
            ],
            [
              1366,
              1914
            ],
            [
              1356,
              1908
            ],
            [
              1356,
              1903
            ],
            [
              1354,
              1900
            ],
            [
              1354,
              1899
            ],
            [
              1350,
              1898
            ],
            [
              1346,
              1898
            ],
            [
              1333,
              1900
            ],
            [
              1333,
              1900
            ],
            [
              1333,
              1900
            ],
            [
              1333,
              1900
            ],
            [
              1334,
              1902
            ],
            [
              1337,
              1905
            ],
            [
              1339,
              1908
            ],
            [
              1347,
              1926
            ],
            [
              1360,
              1951
            ],
            [
              1385,
              1996
            ],
            [
              1398,
              1996
            ],
            [
              1398,
              1996
            ],
            [
              1399,
              1997
            ],
            [
              1399,
              1997
            ],
            [
              1399,
              1999
            ],
            [
              1399,
              1999
            ],
            [
              1400,
              2000
            ],
            [
              1401,
              1999
            ],
            [
              1405,
              1996
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Asia",
        "fullname": "Lebanon",
        "admin": "Lebanon",
        "woe_id": 23424873,
        "id": "LB",
        "abbrev": "Leb.",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "LBN",
        "name": "LB",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -1104,
              405
            ],
            [
              -1109,
              349
            ],
            [
              -1073,
              333
            ],
            [
              -1046,
              309
            ],
            [
              -1055,
              256
            ],
            [
              -1053,
              246
            ],
            [
              -1053,
              234
            ],
            [
              -1053,
              231
            ],
            [
              -1053,
              230
            ],
            [
              -1052,
              230
            ],
            [
              -1053,
              229
            ],
            [
              -1053,
              228
            ],
            [
              -1053,
              228
            ],
            [
              -1053,
              227
            ],
            [
              -1054,
              225
            ],
            [
              -1061,
              226
            ],
            [
              -1068,
              231
            ],
            [
              -1091,
              238
            ],
            [
              -1148,
              270
            ],
            [
              -1211,
              328
            ],
            [
              -1264,
              357
            ],
            [
              -1265,
              382
            ],
            [
              -1223,
              417
            ],
            [
              -1209,
              439
            ],
            [
              -1208,
              439
            ],
            [
              -1207,
              441
            ],
            [
              -1206,
              442
            ],
            [
              -1207,
              443
            ],
            [
              -1207,
              444
            ],
            [
              -1207,
              446
            ],
            [
              -1206,
              448
            ],
            [
              -1204,
              455
            ],
            [
              -1204,
              457
            ],
            [
              -1171,
              458
            ],
            [
              -1157,
              398
            ],
            [
              -1104,
              405
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Africa",
        "fullname": "Liberia",
        "admin": "Liberia",
        "woe_id": 23424876,
        "id": "LR",
        "abbrev": "Liberia",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "LBR",
        "name": "LR",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              711,
              1085
            ],
            [
              264,
              1316
            ],
            [
              208,
              1290
            ],
            [
              166,
              1267
            ],
            [
              124,
              1300
            ],
            [
              40,
              1320
            ],
            [
              17,
              1365
            ],
            [
              -69,
              1399
            ],
            [
              -72,
              1432
            ],
            [
              -104,
              1474
            ],
            [
              -80,
              1498
            ],
            [
              -86,
              1545
            ],
            [
              -74,
              1580
            ],
            [
              -80,
              1656
            ],
            [
              -110,
              1716
            ],
            [
              -97,
              1723
            ],
            [
              -85,
              1729
            ],
            [
              -78,
              1731
            ],
            [
              -60,
              1754
            ],
            [
              -56,
              1761
            ],
            [
              -55,
              1763
            ],
            [
              -55,
              1765
            ],
            [
              -57,
              1769
            ],
            [
              -57,
              1775
            ],
            [
              -64,
              1796
            ],
            [
              -65,
              1797
            ],
            [
              -64,
              1801
            ],
            [
              -56,
              1812
            ],
            [
              -42,
              1817
            ],
            [
              -40,
              1820
            ],
            [
              -38,
              1822
            ],
            [
              -37,
              1829
            ],
            [
              -22,
              1840
            ],
            [
              -4,
              1848
            ],
            [
              10,
              1855
            ],
            [
              14,
              1857
            ],
            [
              15,
              1858
            ],
            [
              16,
              1859
            ],
            [
              17,
              1861
            ],
            [
              14,
              1905
            ],
            [
              61,
              1884
            ],
            [
              117,
              1888
            ],
            [
              220,
              1856
            ],
            [
              236,
              1811
            ],
            [
              267,
              1787
            ],
            [
              341,
              1776
            ],
            [
              436,
              1725
            ],
            [
              475,
              1740
            ],
            [
              497,
              1784
            ],
            [
              484,
              1815
            ],
            [
              520,
              1867
            ],
            [
              579,
              1890
            ],
            [
              661,
              1872
            ],
            [
              662,
              1852
            ],
            [
              717,
              1832
            ],
            [
              767,
              1830
            ],
            [
              760,
              1794
            ],
            [
              760,
              1779
            ],
            [
              762,
              1771
            ],
            [
              764,
              1768
            ],
            [
              765,
              1765
            ],
            [
              767,
              1760
            ],
            [
              768,
              1757
            ],
            [
              768,
              1755
            ],
            [
              766,
              1750
            ],
            [
              765,
              1747
            ],
            [
              764,
              1743
            ],
            [
              764,
              1742
            ],
            [
              763,
              1740
            ],
            [
              755,
              1729
            ],
            [
              751,
              1720
            ],
            [
              751,
              1718
            ],
            [
              753,
              1714
            ],
            [
              758,
              1702
            ],
            [
              767,
              1659
            ],
            [
              767,
              1115
            ],
            [
              766,
              1114
            ],
            [
              761,
              1114
            ],
            [
              753,
              1114
            ],
            [
              746,
              1114
            ],
            [
              720,
              1114
            ],
            [
              711,
              1114
            ],
            [
              711,
              1085
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Libya",
        "admin": "Libya",
        "woe_id": 23424882,
        "id": "LY",
        "abbrev": "Libya",
        "subregion": "Northern Africa",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "LBY",
        "name": "LY",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3869,
              519
            ],
            [
              3920,
              457
            ],
            [
              3948,
              392
            ],
            [
              3937,
              344
            ],
            [
              3876,
              314
            ],
            [
              3849,
              325
            ],
            [
              3836,
              363
            ],
            [
              3832,
              434
            ],
            [
              3846,
              509
            ],
            [
              3869,
              519
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Asia",
        "fullname": "Sri Lanka",
        "admin": "Sri Lanka",
        "woe_id": 23424778,
        "id": "LK",
        "abbrev": "Sri L.",
        "subregion": "Southern Asia",
        "region_wb": "South Asia",
        "iso_a3": "LKA",
        "name": "LK",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              991,
              -1682
            ],
            [
              999,
              -1687
            ],
            [
              1003,
              -1691
            ],
            [
              1005,
              -1692
            ],
            [
              1008,
              -1692
            ],
            [
              1009,
              -1693
            ],
            [
              1010,
              -1696
            ],
            [
              1016,
              -1708
            ],
            [
              1001,
              -1728
            ],
            [
              998,
              -1733
            ],
            [
              998,
              -1736
            ],
            [
              998,
              -1738
            ],
            [
              999,
              -1739
            ],
            [
              999,
              -1740
            ],
            [
              999,
              -1741
            ],
            [
              1000,
              -1742
            ],
            [
              1000,
              -1743
            ],
            [
              1000,
              -1743
            ],
            [
              984,
              -1752
            ],
            [
              980,
              -1754
            ],
            [
              977,
              -1754
            ],
            [
              972,
              -1756
            ],
            [
              956,
              -1758
            ],
            [
              939,
              -1788
            ],
            [
              922,
              -1785
            ],
            [
              906,
              -1768
            ],
            [
              905,
              -1767
            ],
            [
              904,
              -1767
            ],
            [
              903,
              -1768
            ],
            [
              900,
              -1767
            ],
            [
              881,
              -1725
            ],
            [
              894,
              -1721
            ],
            [
              922,
              -1682
            ],
            [
              928,
              -1682
            ],
            [
              930,
              -1681
            ],
            [
              931,
              -1679
            ],
            [
              937,
              -1678
            ],
            [
              945,
              -1669
            ],
            [
              949,
              -1669
            ],
            [
              951,
              -1669
            ],
            [
              953,
              -1669
            ],
            [
              954,
              -1669
            ],
            [
              956,
              -1665
            ],
            [
              957,
              -1664
            ],
            [
              958,
              -1664
            ],
            [
              973,
              -1663
            ],
            [
              974,
              -1665
            ],
            [
              975,
              -1668
            ],
            [
              985,
              -1675
            ],
            [
              988,
              -1679
            ],
            [
              991,
              -1682
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Africa",
        "fullname": "Lesotho",
        "admin": "Lesotho",
        "woe_id": 23424880,
        "id": "LS",
        "abbrev": "Les.",
        "subregion": "Southern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "LSO",
        "name": "LS",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              643,
              3357
            ],
            [
              646,
              3398
            ],
            [
              559,
              3426
            ],
            [
              547,
              3474
            ],
            [
              606,
              3517
            ],
            [
              804,
              3496
            ],
            [
              806,
              3495
            ],
            [
              807,
              3492
            ],
            [
              808,
              3491
            ],
            [
              834,
              3473
            ],
            [
              857,
              3458
            ],
            [
              857,
              3451
            ],
            [
              849,
              3433
            ],
            [
              841,
              3418
            ],
            [
              832,
              3404
            ],
            [
              800,
              3353
            ],
            [
              807,
              3354
            ],
            [
              810,
              3341
            ],
            [
              799,
              3340
            ],
            [
              795,
              3352
            ],
            [
              794,
              3352
            ],
            [
              792,
              3352
            ],
            [
              789,
              3349
            ],
            [
              779,
              3348
            ],
            [
              777,
              3343
            ],
            [
              775,
              3341
            ],
            [
              772,
              3340
            ],
            [
              760,
              3341
            ],
            [
              760,
              3341
            ],
            [
              758,
              3340
            ],
            [
              758,
              3338
            ],
            [
              756,
              3337
            ],
            [
              750,
              3329
            ],
            [
              733,
              3321
            ],
            [
              730,
              3321
            ],
            [
              728,
              3321
            ],
            [
              727,
              3321
            ],
            [
              724,
              3326
            ],
            [
              708,
              3326
            ],
            [
              703,
              3323
            ],
            [
              702,
              3324
            ],
            [
              701,
              3324
            ],
            [
              700,
              3324
            ],
            [
              692,
              3322
            ],
            [
              681,
              3343
            ],
            [
              643,
              3357
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Europe",
        "fullname": "Lithuania",
        "admin": "Lithuania",
        "woe_id": 23424875,
        "id": "LT",
        "abbrev": "Lith.",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "LTU",
        "name": "LT",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              944,
              3496
            ],
            [
              913,
              3468
            ],
            [
              857,
              3458
            ],
            [
              840,
              3464
            ],
            [
              834,
              3473
            ],
            [
              808,
              3491
            ],
            [
              807,
              3492
            ],
            [
              806,
              3495
            ],
            [
              804,
              3496
            ],
            [
              606,
              3517
            ],
            [
              543,
              3504
            ],
            [
              548,
              3551
            ],
            [
              584,
              3608
            ],
            [
              633,
              3624
            ],
            [
              695,
              3561
            ],
            [
              733,
              3582
            ],
            [
              729,
              3633
            ],
            [
              787,
              3646
            ],
            [
              812,
              3632
            ],
            [
              824,
              3631
            ],
            [
              825,
              3625
            ],
            [
              856,
              3605
            ],
            [
              863,
              3609
            ],
            [
              868,
              3609
            ],
            [
              873,
              3613
            ],
            [
              898,
              3605
            ],
            [
              910,
              3606
            ],
            [
              909,
              3603
            ],
            [
              909,
              3601
            ],
            [
              909,
              3599
            ],
            [
              909,
              3598
            ],
            [
              910,
              3597
            ],
            [
              916,
              3594
            ],
            [
              920,
              3591
            ],
            [
              927,
              3587
            ],
            [
              926,
              3576
            ],
            [
              916,
              3554
            ],
            [
              944,
              3496
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Europe",
        "fullname": "Latvia",
        "admin": "Latvia",
        "woe_id": 23424874,
        "id": "LV",
        "abbrev": "Lat.",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "LVA",
        "name": "LV",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -931,
              2078
            ],
            [
              -928,
              2073
            ],
            [
              -874,
              2029
            ],
            [
              -794,
              2040
            ],
            [
              -792,
              2037
            ],
            [
              -765,
              2029
            ],
            [
              -754,
              2025
            ],
            [
              -754,
              2023
            ],
            [
              -753,
              2021
            ],
            [
              -752,
              2020
            ],
            [
              -730,
              2004
            ],
            [
              -729,
              2002
            ],
            [
              -731,
              1999
            ],
            [
              -734,
              1995
            ],
            [
              -734,
              1994
            ],
            [
              -713,
              1898
            ],
            [
              -699,
              1837
            ],
            [
              -759,
              1840
            ],
            [
              -763,
              1840
            ],
            [
              -770,
              1840
            ],
            [
              -788,
              1819
            ],
            [
              -797,
              1818
            ],
            [
              -810,
              1815
            ],
            [
              -834,
              1810
            ],
            [
              -835,
              1794
            ],
            [
              -839,
              1794
            ],
            [
              -842,
              1792
            ],
            [
              -843,
              1791
            ],
            [
              -843,
              1790
            ],
            [
              -843,
              1784
            ],
            [
              -842,
              1780
            ],
            [
              -840,
              1781
            ],
            [
              -839,
              1781
            ],
            [
              -833,
              1776
            ],
            [
              -831,
              1772
            ],
            [
              -828,
              1768
            ],
            [
              -833,
              1753
            ],
            [
              -844,
              1747
            ],
            [
              -874,
              1740
            ],
            [
              -951,
              1686
            ],
            [
              -1004,
              1680
            ],
            [
              -1009,
              1675
            ],
            [
              -1029,
              1679
            ],
            [
              -1056,
              1672
            ],
            [
              -1115,
              1628
            ],
            [
              -1115,
              1567
            ],
            [
              -1119,
              1567
            ],
            [
              -1123,
              1567
            ],
            [
              -1119,
              1539
            ],
            [
              -1119,
              1536
            ],
            [
              -1121,
              1535
            ],
            [
              -1127,
              1534
            ],
            [
              -1133,
              1533
            ],
            [
              -1156,
              1533
            ],
            [
              -1174,
              1519
            ],
            [
              -1203,
              1519
            ],
            [
              -1208,
              1521
            ],
            [
              -1215,
              1525
            ],
            [
              -1219,
              1527
            ],
            [
              -1240,
              1528
            ],
            [
              -1302,
              1469
            ],
            [
              -1318,
              1415
            ],
            [
              -1321,
              1406
            ],
            [
              -1323,
              1401
            ],
            [
              -1325,
              1398
            ],
            [
              -1413,
              1314
            ],
            [
              -1415,
              1310
            ],
            [
              -1418,
              1296
            ],
            [
              -1422,
              1276
            ],
            [
              -1425,
              1249
            ],
            [
              -1459,
              1199
            ],
            [
              -1494,
              1199
            ],
            [
              -1502,
              1200
            ],
            [
              -1510,
              1201
            ],
            [
              -1565,
              1200
            ],
            [
              -1581,
              1197
            ],
            [
              -1564,
              1247
            ],
            [
              -1503,
              1350
            ],
            [
              -1463,
              1389
            ],
            [
              -1435,
              1483
            ],
            [
              -1391,
              1509
            ],
            [
              -1355,
              1582
            ],
            [
              -1272,
              1607
            ],
            [
              -1221,
              1648
            ],
            [
              -1169,
              1717
            ],
            [
              -1180,
              1795
            ],
            [
              -1147,
              1868
            ],
            [
              -1106,
              1911
            ],
            [
              -1011,
              1959
            ],
            [
              -960,
              2070
            ],
            [
              -931,
              2078
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Morocco",
        "admin": "Morocco",
        "woe_id": 23424893,
        "id": "MA",
        "abbrev": "Mor.",
        "subregion": "Northern Africa",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "MAR",
        "name": "MA",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              947,
              2710
            ],
            [
              944,
              2717
            ],
            [
              945,
              2722
            ],
            [
              945,
              2722
            ],
            [
              945,
              2724
            ],
            [
              945,
              2731
            ],
            [
              943,
              2741
            ],
            [
              942,
              2742
            ],
            [
              942,
              2743
            ],
            [
              942,
              2744
            ],
            [
              941,
              2747
            ],
            [
              941,
              2749
            ],
            [
              942,
              2753
            ],
            [
              943,
              2757
            ],
            [
              943,
              2758
            ],
            [
              944,
              2760
            ],
            [
              946,
              2767
            ],
            [
              947,
              2769
            ],
            [
              947,
              2769
            ],
            [
              947,
              2770
            ],
            [
              946,
              2771
            ],
            [
              947,
              2772
            ],
            [
              947,
              2772
            ],
            [
              947,
              2772
            ],
            [
              948,
              2774
            ],
            [
              950,
              2778
            ],
            [
              950,
              2780
            ],
            [
              950,
              2780
            ],
            [
              948,
              2782
            ],
            [
              950,
              2790
            ],
            [
              950,
              2790
            ],
            [
              950,
              2791
            ],
            [
              949,
              2793
            ],
            [
              943,
              2805
            ],
            [
              938,
              2818
            ],
            [
              895,
              2868
            ],
            [
              883,
              2896
            ],
            [
              881,
              2897
            ],
            [
              880,
              2897
            ],
            [
              878,
              2898
            ],
            [
              877,
              2902
            ],
            [
              869,
              2906
            ],
            [
              867,
              2906
            ],
            [
              863,
              2907
            ],
            [
              861,
              2907
            ],
            [
              859,
              2906
            ],
            [
              859,
              2907
            ],
            [
              859,
              2908
            ],
            [
              860,
              2909
            ],
            [
              863,
              2911
            ],
            [
              868,
              2914
            ],
            [
              871,
              2916
            ],
            [
              922,
              2920
            ],
            [
              981,
              2896
            ],
            [
              1022,
              2832
            ],
            [
              1032,
              2779
            ],
            [
              1003,
              2772
            ],
            [
              947,
              2710
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Europe",
        "fullname": "Moldova",
        "admin": "Moldova",
        "woe_id": 23424885,
        "id": "MD",
        "abbrev": "Mda.",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "MDA",
        "name": "MD",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2130,
              -697
            ],
            [
              2158,
              -742
            ],
            [
              2194,
              -881
            ],
            [
              2134,
              -994
            ],
            [
              2130,
              -1060
            ],
            [
              2114,
              -1097
            ],
            [
              2030,
              -1378
            ],
            [
              2006,
              -1441
            ],
            [
              1914,
              -1480
            ],
            [
              1851,
              -1461
            ],
            [
              1811,
              -1398
            ],
            [
              1807,
              -1332
            ],
            [
              1787,
              -1284
            ],
            [
              1801,
              -1234
            ],
            [
              1857,
              -1147
            ],
            [
              1833,
              -1060
            ],
            [
              1827,
              -1004
            ],
            [
              1878,
              -933
            ],
            [
              1943,
              -909
            ],
            [
              2040,
              -840
            ],
            [
              2047,
              -794
            ],
            [
              2096,
              -772
            ],
            [
              2102,
              -716
            ],
            [
              2130,
              -697
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Madagascar",
        "admin": "Madagascar",
        "woe_id": 23424883,
        "id": "MG",
        "abbrev": "Mad.",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "MDG",
        "name": "MG",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -5567,
              1026
            ],
            [
              -5615,
              988
            ],
            [
              -5651,
              988
            ],
            [
              -5676,
              988
            ],
            [
              -5688,
              988
            ],
            [
              -5715,
              988
            ],
            [
              -5717,
              956
            ],
            [
              -5741,
              956
            ],
            [
              -5742,
              956
            ],
            [
              -5742,
              955
            ],
            [
              -5741,
              954
            ],
            [
              -5739,
              954
            ],
            [
              -5738,
              953
            ],
            [
              -5738,
              952
            ],
            [
              -5734,
              952
            ],
            [
              -5727,
              943
            ],
            [
              -5724,
              942
            ],
            [
              -5722,
              938
            ],
            [
              -5721,
              938
            ],
            [
              -5721,
              936
            ],
            [
              -5712,
              931
            ],
            [
              -5708,
              930
            ],
            [
              -5703,
              927
            ],
            [
              -5698,
              914
            ],
            [
              -5688,
              908
            ],
            [
              -5684,
              908
            ],
            [
              -5684,
              904
            ],
            [
              -5685,
              902
            ],
            [
              -5686,
              902
            ],
            [
              -5686,
              901
            ],
            [
              -5686,
              900
            ],
            [
              -5686,
              899
            ],
            [
              -5685,
              899
            ],
            [
              -5686,
              899
            ],
            [
              -5686,
              899
            ],
            [
              -5687,
              899
            ],
            [
              -5687,
              899
            ],
            [
              -5687,
              895
            ],
            [
              -5686,
              894
            ],
            [
              -5685,
              893
            ],
            [
              -5687,
              893
            ],
            [
              -5686,
              892
            ],
            [
              -5687,
              890
            ],
            [
              -5687,
              889
            ],
            [
              -5686,
              890
            ],
            [
              -5686,
              889
            ],
            [
              -5686,
              889
            ],
            [
              -5687,
              888
            ],
            [
              -5687,
              888
            ],
            [
              -5688,
              888
            ],
            [
              -5689,
              888
            ],
            [
              -5717,
              888
            ],
            [
              -5717,
              888
            ],
            [
              -5724,
              888
            ],
            [
              -5758,
              888
            ],
            [
              -5785,
              843
            ],
            [
              -5819,
              836
            ],
            [
              -5892,
              896
            ],
            [
              -5933,
              908
            ],
            [
              -5965,
              882
            ],
            [
              -6028,
              864
            ],
            [
              -6096,
              883
            ],
            [
              -6157,
              914
            ],
            [
              -6277,
              956
            ],
            [
              -6343,
              994
            ],
            [
              -6416,
              1018
            ],
            [
              -6443,
              1049
            ],
            [
              -6501,
              1078
            ],
            [
              -6539,
              1138
            ],
            [
              -6510,
              1198
            ],
            [
              -6545,
              1269
            ],
            [
              -6614,
              1348
            ],
            [
              -6693,
              1424
            ],
            [
              -6748,
              1455
            ],
            [
              -6752,
              1511
            ],
            [
              -6850,
              1591
            ],
            [
              -6900,
              1646
            ],
            [
              -6955,
              1759
            ],
            [
              -6950,
              1780
            ],
            [
              -7045,
              1820
            ],
            [
              -7053,
              1780
            ],
            [
              -7040,
              1720
            ],
            [
              -6990,
              1673
            ],
            [
              -6935,
              1579
            ],
            [
              -6867,
              1509
            ],
            [
              -6853,
              1454
            ],
            [
              -6789,
              1364
            ],
            [
              -6750,
              1323
            ],
            [
              -6774,
              1282
            ],
            [
              -6797,
              1322
            ],
            [
              -6896,
              1395
            ],
            [
              -6896,
              1450
            ],
            [
              -6911,
              1478
            ],
            [
              -6963,
              1512
            ],
            [
              -7031,
              1542
            ],
            [
              -7006,
              1615
            ],
            [
              -7039,
              1654
            ],
            [
              -7098,
              1694
            ],
            [
              -7119,
              1759
            ],
            [
              -7153,
              1804
            ],
            [
              -7178,
              1865
            ],
            [
              -7174,
              1865
            ],
            [
              -7044,
              1876
            ],
            [
              -7049,
              1863
            ],
            [
              -6836,
              1790
            ],
            [
              -6680,
              1790
            ],
            [
              -6680,
              1818
            ],
            [
              -6585,
              1818
            ],
            [
              -6501,
              1750
            ],
            [
              -6500,
              1750
            ],
            [
              -6499,
              1747
            ],
            [
              -6494,
              1743
            ],
            [
              -6492,
              1734
            ],
            [
              -6482,
              1721
            ],
            [
              -6482,
              1706
            ],
            [
              -6474,
              1689
            ],
            [
              -6473,
              1688
            ],
            [
              -6470,
              1686
            ],
            [
              -6470,
              1685
            ],
            [
              -6469,
              1685
            ],
            [
              -6469,
              1685
            ],
            [
              -6469,
              1684
            ],
            [
              -6469,
              1684
            ],
            [
              -6468,
              1684
            ],
            [
              -6464,
              1681
            ],
            [
              -6459,
              1680
            ],
            [
              -6447,
              1669
            ],
            [
              -6397,
              1647
            ],
            [
              -6371,
              1693
            ],
            [
              -6350,
              1703
            ],
            [
              -6331,
              1698
            ],
            [
              -6312,
              1695
            ],
            [
              -6307,
              1697
            ],
            [
              -6303,
              1696
            ],
            [
              -6301,
              1695
            ],
            [
              -6300,
              1695
            ],
            [
              -6299,
              1696
            ],
            [
              -6299,
              1695
            ],
            [
              -6299,
              1694
            ],
            [
              -6297,
              1689
            ],
            [
              -6296,
              1689
            ],
            [
              -6296,
              1688
            ],
            [
              -6295,
              1688
            ],
            [
              -6293,
              1688
            ],
            [
              -6291,
              1686
            ],
            [
              -6290,
              1686
            ],
            [
              -6289,
              1685
            ],
            [
              -6289,
              1684
            ],
            [
              -6289,
              1682
            ],
            [
              -6289,
              1681
            ],
            [
              -6288,
              1680
            ],
            [
              -6287,
              1680
            ],
            [
              -6286,
              1679
            ],
            [
              -6279,
              1676
            ],
            [
              -6266,
              1665
            ],
            [
              -6265,
              1664
            ],
            [
              -6264,
              1663
            ],
            [
              -6263,
              1661
            ],
            [
              -6264,
              1660
            ],
            [
              -6263,
              1659
            ],
            [
              -6260,
              1656
            ],
            [
              -6258,
              1655
            ],
            [
              -6258,
              1654
            ],
            [
              -6257,
              1651
            ],
            [
              -6256,
              1647
            ],
            [
              -6255,
              1644
            ],
            [
              -6236,
              1606
            ],
            [
              -6213,
              1576
            ],
            [
              -6213,
              1576
            ],
            [
              -6212,
              1575
            ],
            [
              -6211,
              1574
            ],
            [
              -6210,
              1574
            ],
            [
              -6206,
              1571
            ],
            [
              -6206,
              1570
            ],
            [
              -6206,
              1569
            ],
            [
              -6204,
              1567
            ],
            [
              -6202,
              1567
            ],
            [
              -6201,
              1565
            ],
            [
              -6198,
              1566
            ],
            [
              -6195,
              1564
            ],
            [
              -6193,
              1563
            ],
            [
              -6193,
              1562
            ],
            [
              -6193,
              1557
            ],
            [
              -6191,
              1553
            ],
            [
              -6192,
              1548
            ],
            [
              -6190,
              1531
            ],
            [
              -6179,
              1517
            ],
            [
              -6174,
              1500
            ],
            [
              -6173,
              1499
            ],
            [
              -6171,
              1499
            ],
            [
              -6170,
              1498
            ],
            [
              -6170,
              1496
            ],
            [
              -6171,
              1494
            ],
            [
              -6171,
              1493
            ],
            [
              -6169,
              1492
            ],
            [
              -6163,
              1491
            ],
            [
              -6153,
              1490
            ],
            [
              -6117,
              1471
            ],
            [
              -6098,
              1471
            ],
            [
              -6082,
              1462
            ],
            [
              -6073,
              1462
            ],
            [
              -6067,
              1465
            ],
            [
              -6061,
              1466
            ],
            [
              -6061,
              1466
            ],
            [
              -6062,
              1457
            ],
            [
              -6062,
              1451
            ],
            [
              -6089,
              1379
            ],
            [
              -6103,
              1268
            ],
            [
              -6061,
              1152
            ],
            [
              -6022,
              1107
            ],
            [
              -5989,
              1040
            ],
            [
              -5953,
              1040
            ],
            [
              -5918,
              1010
            ],
            [
              -5811,
              1035
            ],
            [
              -5736,
              1032
            ],
            [
              -5688,
              1110
            ],
            [
              -5682,
              1173
            ],
            [
              -5649,
              1189
            ],
            [
              -5548,
              1208
            ],
            [
              -5479,
              1182
            ],
            [
              -5519,
              1126
            ],
            [
              -5517,
              1089
            ],
            [
              -5537,
              1022
            ],
            [
              -5567,
              1026
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Americas",
        "fullname": "Mexico",
        "admin": "Mexico",
        "woe_id": 23424900,
        "id": "MX",
        "abbrev": "Mex.",
        "subregion": "Central America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "MEX",
        "name": "MX",
        "continent": "North America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              620,
              2495
            ],
            [
              648,
              2475
            ],
            [
              657,
              2455
            ],
            [
              652,
              2430
            ],
            [
              644,
              2430
            ],
            [
              643,
              2429
            ],
            [
              642,
              2429
            ],
            [
              642,
              2426
            ],
            [
              640,
              2417
            ],
            [
              624,
              2415
            ],
            [
              623,
              2415
            ],
            [
              622,
              2416
            ],
            [
              617,
              2417
            ],
            [
              611,
              2418
            ],
            [
              595,
              2414
            ],
            [
              543,
              2398
            ],
            [
              521,
              2414
            ],
            [
              520,
              2466
            ],
            [
              576,
              2491
            ],
            [
              620,
              2495
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Europe",
        "fullname": "Macedonia",
        "admin": "Macedonia",
        "woe_id": 23424890,
        "id": "MK",
        "abbrev": "Mkd.",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "MKD",
        "name": "MK",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -393,
              1065
            ],
            [
              -396,
              908
            ],
            [
              -433,
              847
            ],
            [
              -555,
              843
            ],
            [
              -617,
              821
            ],
            [
              -672,
              831
            ],
            [
              -743,
              781
            ],
            [
              -788,
              772
            ],
            [
              -811,
              729
            ],
            [
              -852,
              735
            ],
            [
              -876,
              673
            ],
            [
              -919,
              653
            ],
            [
              -921,
              623
            ],
            [
              -933,
              591
            ],
            [
              -935,
              576
            ],
            [
              -938,
              571
            ],
            [
              -938,
              568
            ],
            [
              -938,
              567
            ],
            [
              -940,
              567
            ],
            [
              -941,
              568
            ],
            [
              -942,
              568
            ],
            [
              -954,
              566
            ],
            [
              -975,
              555
            ],
            [
              -1019,
              553
            ],
            [
              -1061,
              566
            ],
            [
              -1067,
              555
            ],
            [
              -1074,
              552
            ],
            [
              -1075,
              552
            ],
            [
              -1076,
              552
            ],
            [
              -1075,
              555
            ],
            [
              -1075,
              557
            ],
            [
              -1078,
              562
            ],
            [
              -1093,
              573
            ],
            [
              -1094,
              599
            ],
            [
              -1124,
              637
            ],
            [
              -1132,
              678
            ],
            [
              -1173,
              657
            ],
            [
              -1228,
              650
            ],
            [
              -1266,
              679
            ],
            [
              -1270,
              717
            ],
            [
              -1306,
              753
            ],
            [
              -1315,
              814
            ],
            [
              -1313,
              813
            ],
            [
              -1308,
              814
            ],
            [
              -1299,
              814
            ],
            [
              -1291,
              820
            ],
            [
              -1285,
              857
            ],
            [
              -1179,
              848
            ],
            [
              -1152,
              855
            ],
            [
              -1078,
              855
            ],
            [
              -1021,
              855
            ],
            [
              -970,
              855
            ],
            [
              -966,
              855
            ],
            [
              -954,
              855
            ],
            [
              -947,
              855
            ],
            [
              -938,
              855
            ],
            [
              -938,
              855
            ],
            [
              -938,
              856
            ],
            [
              -937,
              856
            ],
            [
              -936,
              862
            ],
            [
              -933,
              878
            ],
            [
              -932,
              884
            ],
            [
              -929,
              900
            ],
            [
              -929,
              902
            ],
            [
              -929,
              902
            ],
            [
              -929,
              902
            ],
            [
              -930,
              903
            ],
            [
              -936,
              908
            ],
            [
              -941,
              911
            ],
            [
              -943,
              912
            ],
            [
              -944,
              914
            ],
            [
              -962,
              1085
            ],
            [
              -994,
              1370
            ],
            [
              -995,
              1384
            ],
            [
              -997,
              1393
            ],
            [
              -998,
              1403
            ],
            [
              -998,
              1408
            ],
            [
              -986,
              1408
            ],
            [
              -973,
              1408
            ],
            [
              -961,
              1408
            ],
            [
              -949,
              1408
            ],
            [
              -912,
              1408
            ],
            [
              -899,
              1408
            ],
            [
              -565,
              1179
            ],
            [
              -524,
              1128
            ],
            [
              -450,
              1102
            ],
            [
              -445,
              1055
            ],
            [
              -393,
              1065
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Mali",
        "admin": "Mali",
        "woe_id": 23424891,
        "id": "ML",
        "abbrev": "Mali",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "MLI",
        "name": "ML",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              5026,
              1205
            ],
            [
              5002,
              1191
            ],
            [
              4973,
              1155
            ],
            [
              4973,
              1154
            ],
            [
              4973,
              1153
            ],
            [
              4971,
              1152
            ],
            [
              4971,
              1151
            ],
            [
              4971,
              1150
            ],
            [
              4970,
              1146
            ],
            [
              4969,
              1141
            ],
            [
              4968,
              1137
            ],
            [
              4967,
              1133
            ],
            [
              4965,
              1134
            ],
            [
              4963,
              1136
            ],
            [
              4961,
              1137
            ],
            [
              4958,
              1140
            ],
            [
              4906,
              1105
            ],
            [
              4841,
              1089
            ],
            [
              4837,
              980
            ],
            [
              4885,
              910
            ],
            [
              4881,
              847
            ],
            [
              4859,
              834
            ],
            [
              4914,
              753
            ],
            [
              4917,
              698
            ],
            [
              4941,
              646
            ],
            [
              4892,
              582
            ],
            [
              4891,
              563
            ],
            [
              4888,
              696
            ],
            [
              4838,
              820
            ],
            [
              4824,
              915
            ],
            [
              4798,
              956
            ],
            [
              4772,
              913
            ],
            [
              4724,
              892
            ],
            [
              4704,
              868
            ],
            [
              4638,
              893
            ],
            [
              4659,
              977
            ],
            [
              4623,
              1083
            ],
            [
              4591,
              1120
            ],
            [
              4557,
              1126
            ],
            [
              4529,
              1176
            ],
            [
              4546,
              1230
            ],
            [
              4579,
              1254
            ],
            [
              4596,
              1346
            ],
            [
              4626,
              1344
            ],
            [
              4666,
              1410
            ],
            [
              4690,
              1469
            ],
            [
              4689,
              1504
            ],
            [
              4746,
              1543
            ],
            [
              4780,
              1548
            ],
            [
              4812,
              1601
            ],
            [
              4823,
              1620
            ],
            [
              4888,
              1562
            ],
            [
              4892,
              1505
            ],
            [
              4868,
              1442
            ],
            [
              4824,
              1393
            ],
            [
              4833,
              1356
            ],
            [
              4878,
              1356
            ],
            [
              4959,
              1215
            ],
            [
              5026,
              1205
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Asia",
        "fullname": "Myanmar",
        "admin": "Myanmar",
        "woe_id": 23424763,
        "id": "MM",
        "abbrev": "Myan.",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "MMR",
        "name": "MM",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              508,
              2530
            ],
            [
              492,
              2511
            ],
            [
              453,
              2464
            ],
            [
              402,
              2506
            ],
            [
              402,
              2541
            ],
            [
              415,
              2558
            ],
            [
              427,
              2565
            ],
            [
              428,
              2576
            ],
            [
              431,
              2578
            ],
            [
              433,
              2579
            ],
            [
              434,
              2578
            ],
            [
              434,
              2577
            ],
            [
              435,
              2576
            ],
            [
              440,
              2578
            ],
            [
              445,
              2571
            ],
            [
              453,
              2568
            ],
            [
              467,
              2553
            ],
            [
              508,
              2530
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Europe",
        "fullname": "Montenegro",
        "admin": "Montenegro",
        "woe_id": 20069817,
        "id": "ME",
        "abbrev": "Mont.",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "MNE",
        "name": "ME",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4962,
              3159
            ],
            [
              5076,
              3133
            ],
            [
              5092,
              3071
            ],
            [
              5166,
              3041
            ],
            [
              5259,
              3066
            ],
            [
              5334,
              3055
            ],
            [
              5373,
              3030
            ],
            [
              5394,
              3030
            ],
            [
              5397,
              3028
            ],
            [
              5398,
              3027
            ],
            [
              5400,
              3028
            ],
            [
              5400,
              3028
            ],
            [
              5406,
              3027
            ],
            [
              5406,
              3026
            ],
            [
              5406,
              3024
            ],
            [
              5405,
              3021
            ],
            [
              5406,
              3013
            ],
            [
              5411,
              3002
            ],
            [
              5439,
              2983
            ],
            [
              5561,
              2969
            ],
            [
              5568,
              2971
            ],
            [
              5574,
              2974
            ],
            [
              5595,
              2986
            ],
            [
              5634,
              2989
            ],
            [
              5656,
              2997
            ],
            [
              5673,
              2995
            ],
            [
              5681,
              2998
            ],
            [
              5684,
              3000
            ],
            [
              5685,
              3001
            ],
            [
              5691,
              3002
            ],
            [
              5692,
              3002
            ],
            [
              5692,
              3004
            ],
            [
              5693,
              3008
            ],
            [
              5700,
              3019
            ],
            [
              5721,
              3033
            ],
            [
              5736,
              3037
            ],
            [
              5743,
              3043
            ],
            [
              5751,
              3048
            ],
            [
              5755,
              3050
            ],
            [
              5760,
              3052
            ],
            [
              5821,
              3024
            ],
            [
              5894,
              3019
            ],
            [
              5829,
              2896
            ],
            [
              5831,
              2883
            ],
            [
              5848,
              2867
            ],
            [
              5904,
              2879
            ],
            [
              5932,
              2863
            ],
            [
              5934,
              2865
            ],
            [
              5936,
              2869
            ],
            [
              5953,
              2886
            ],
            [
              5978,
              2890
            ],
            [
              5979,
              2889
            ],
            [
              5982,
              2888
            ],
            [
              5983,
              2888
            ],
            [
              5998,
              2885
            ],
            [
              6063,
              2828
            ],
            [
              6074,
              2806
            ],
            [
              6074,
              2797
            ],
            [
              6074,
              2796
            ],
            [
              6073,
              2796
            ],
            [
              6072,
              2794
            ],
            [
              6071,
              2793
            ],
            [
              6070,
              2793
            ],
            [
              6062,
              2788
            ],
            [
              6041,
              2790
            ],
            [
              6027,
              2793
            ],
            [
              6022,
              2799
            ],
            [
              6018,
              2797
            ],
            [
              6013,
              2800
            ],
            [
              5981,
              2797
            ],
            [
              5890,
              2769
            ],
            [
              5836,
              2709
            ],
            [
              5826,
              2706
            ],
            [
              5819,
              2705
            ],
            [
              5797,
              2704
            ],
            [
              5774,
              2704
            ],
            [
              5747,
              2674
            ],
            [
              5738,
              2672
            ],
            [
              5735,
              2669
            ],
            [
              5729,
              2665
            ],
            [
              5726,
              2662
            ],
            [
              5724,
              2660
            ],
            [
              5722,
              2660
            ],
            [
              5718,
              2660
            ],
            [
              5717,
              2661
            ],
            [
              5716,
              2662
            ],
            [
              5715,
              2662
            ],
            [
              5694,
              2664
            ],
            [
              5674,
              2669
            ],
            [
              5667,
              2672
            ],
            [
              5633,
              2684
            ],
            [
              5618,
              2676
            ],
            [
              5599,
              2638
            ],
            [
              5605,
              2622
            ],
            [
              5612,
              2614
            ],
            [
              5614,
              2612
            ],
            [
              5620,
              2608
            ],
            [
              5623,
              2605
            ],
            [
              5628,
              2597
            ],
            [
              5629,
              2595
            ],
            [
              5629,
              2593
            ],
            [
              5629,
              2591
            ],
            [
              5629,
              2589
            ],
            [
              5627,
              2588
            ],
            [
              5626,
              2588
            ],
            [
              5624,
              2587
            ],
            [
              5619,
              2586
            ],
            [
              5610,
              2577
            ],
            [
              5600,
              2574
            ],
            [
              5595,
              2571
            ],
            [
              5582,
              2566
            ],
            [
              5573,
              2561
            ],
            [
              5565,
              2552
            ],
            [
              5559,
              2545
            ],
            [
              5558,
              2544
            ],
            [
              5556,
              2541
            ],
            [
              5555,
              2538
            ],
            [
              5552,
              2535
            ],
            [
              5547,
              2532
            ],
            [
              5546,
              2530
            ],
            [
              5544,
              2527
            ],
            [
              5543,
              2526
            ],
            [
              5502,
              2511
            ],
            [
              5494,
              2506
            ],
            [
              5492,
              2504
            ],
            [
              5419,
              2505
            ],
            [
              5340,
              2493
            ],
            [
              5252,
              2457
            ],
            [
              5213,
              2466
            ],
            [
              5191,
              2462
            ],
            [
              5169,
              2458
            ],
            [
              5075,
              2487
            ],
            [
              5072,
              2488
            ],
            [
              5067,
              2493
            ],
            [
              5053,
              2509
            ],
            [
              4962,
              2520
            ],
            [
              4960,
              2520
            ],
            [
              4940,
              2514
            ],
            [
              4932,
              2512
            ],
            [
              4804,
              2527
            ],
            [
              4757,
              2524
            ],
            [
              4754,
              2537
            ],
            [
              4733,
              2556
            ],
            [
              4731,
              2558
            ],
            [
              4730,
              2561
            ],
            [
              4728,
              2569
            ],
            [
              4710,
              2608
            ],
            [
              4708,
              2608
            ],
            [
              4705,
              2609
            ],
            [
              4700,
              2610
            ],
            [
              4703,
              2629
            ],
            [
              4684,
              2627
            ],
            [
              4599,
              2675
            ],
            [
              4579,
              2678
            ],
            [
              4578,
              2679
            ],
            [
              4574,
              2678
            ],
            [
              4573,
              2678
            ],
            [
              4567,
              2678
            ],
            [
              4556,
              2681
            ],
            [
              4495,
              2682
            ],
            [
              4466,
              2692
            ],
            [
              4457,
              2693
            ],
            [
              4453,
              2691
            ],
            [
              4451,
              2691
            ],
            [
              4450,
              2693
            ],
            [
              4451,
              2695
            ],
            [
              4450,
              2697
            ],
            [
              4447,
              2698
            ],
            [
              4447,
              2699
            ],
            [
              4447,
              2701
            ],
            [
              4444,
              2708
            ],
            [
              4440,
              2711
            ],
            [
              4439,
              2712
            ],
            [
              4441,
              2728
            ],
            [
              4442,
              2730
            ],
            [
              4454,
              2744
            ],
            [
              4457,
              2747
            ],
            [
              4458,
              2749
            ],
            [
              4458,
              2755
            ],
            [
              4452,
              2768
            ],
            [
              4458,
              2799
            ],
            [
              4449,
              2816
            ],
            [
              4442,
              2818
            ],
            [
              4429,
              2839
            ],
            [
              4428,
              2839
            ],
            [
              4429,
              2840
            ],
            [
              4429,
              2840
            ],
            [
              4429,
              2841
            ],
            [
              4430,
              2843
            ],
            [
              4430,
              2844
            ],
            [
              4427,
              2846
            ],
            [
              4427,
              2852
            ],
            [
              4424,
              2854
            ],
            [
              4424,
              2855
            ],
            [
              4422,
              2859
            ],
            [
              4421,
              2860
            ],
            [
              4421,
              2861
            ],
            [
              4421,
              2862
            ],
            [
              4422,
              2863
            ],
            [
              4422,
              2864
            ],
            [
              4420,
              2864
            ],
            [
              4419,
              2865
            ],
            [
              4414,
              2866
            ],
            [
              4407,
              2870
            ],
            [
              4405,
              2874
            ],
            [
              4406,
              2877
            ],
            [
              4406,
              2878
            ],
            [
              4405,
              2879
            ],
            [
              4400,
              2880
            ],
            [
              4399,
              2879
            ],
            [
              4399,
              2879
            ],
            [
              4399,
              2878
            ],
            [
              4399,
              2877
            ],
            [
              4398,
              2876
            ],
            [
              4396,
              2875
            ],
            [
              4388,
              2875
            ],
            [
              4377,
              2890
            ],
            [
              4349,
              2887
            ],
            [
              4291,
              2926
            ],
            [
              4280,
              2945
            ],
            [
              4276,
              2950
            ],
            [
              4277,
              2954
            ],
            [
              4281,
              2961
            ],
            [
              4281,
              2967
            ],
            [
              4281,
              2968
            ],
            [
              4280,
              2970
            ],
            [
              4280,
              2971
            ],
            [
              4281,
              2971
            ],
            [
              4282,
              2971
            ],
            [
              4288,
              2971
            ],
            [
              4289,
              2972
            ],
            [
              4292,
              2975
            ],
            [
              4299,
              2979
            ],
            [
              4297,
              2985
            ],
            [
              4300,
              2990
            ],
            [
              4302,
              2992
            ],
            [
              4312,
              2994
            ],
            [
              4321,
              2993
            ],
            [
              4326,
              2995
            ],
            [
              4329,
              2991
            ],
            [
              4331,
              2991
            ],
            [
              4339,
              2991
            ],
            [
              4360,
              3005
            ],
            [
              4371,
              3006
            ],
            [
              4373,
              3006
            ],
            [
              4375,
              3007
            ],
            [
              4381,
              3009
            ],
            [
              4385,
              3010
            ],
            [
              4386,
              3012
            ],
            [
              4386,
              3014
            ],
            [
              4382,
              3015
            ],
            [
              4382,
              3019
            ],
            [
              4381,
              3020
            ],
            [
              4381,
              3020
            ],
            [
              4381,
              3021
            ],
            [
              4381,
              3024
            ],
            [
              4381,
              3024
            ],
            [
              4382,
              3025
            ],
            [
              4384,
              3026
            ],
            [
              4387,
              3027
            ],
            [
              4395,
              3027
            ],
            [
              4401,
              3029
            ],
            [
              4405,
              3038
            ],
            [
              4429,
              3047
            ],
            [
              4441,
              3047
            ],
            [
              4459,
              3063
            ],
            [
              4531,
              3093
            ],
            [
              4532,
              3094
            ],
            [
              4533,
              3095
            ],
            [
              4534,
              3095
            ],
            [
              4539,
              3089
            ],
            [
              4550,
              3081
            ],
            [
              4639,
              3073
            ],
            [
              4661,
              3033
            ],
            [
              4730,
              3033
            ],
            [
              4811,
              3012
            ],
            [
              4825,
              3026
            ],
            [
              4840,
              3026
            ],
            [
              4844,
              3030
            ],
            [
              4852,
              3033
            ],
            [
              4855,
              3036
            ],
            [
              4866,
              3069
            ],
            [
              4851,
              3078
            ],
            [
              4848,
              3084
            ],
            [
              4846,
              3088
            ],
            [
              4847,
              3090
            ],
            [
              4849,
              3093
            ],
            [
              4849,
              3093
            ],
            [
              4847,
              3095
            ],
            [
              4845,
              3097
            ],
            [
              4842,
              3099
            ],
            [
              4840,
              3100
            ],
            [
              4839,
              3103
            ],
            [
              4839,
              3105
            ],
            [
              4842,
              3111
            ],
            [
              4846,
              3121
            ],
            [
              4852,
              3139
            ],
            [
              4862,
              3140
            ],
            [
              4868,
              3158
            ],
            [
              4873,
              3160
            ],
            [
              4884,
              3162
            ],
            [
              4889,
              3167
            ],
            [
              4899,
              3188
            ],
            [
              4962,
              3159
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Asia",
        "fullname": "Mongolia",
        "admin": "Mongolia",
        "woe_id": 23424887,
        "id": "MN",
        "abbrev": "Mong.",
        "subregion": "Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "MNG",
        "name": "MN",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1209,
              -1557
            ],
            [
              1179,
              -1557
            ],
            [
              1172,
              -1556
            ],
            [
              1168,
              -1557
            ],
            [
              1166,
              -1556
            ],
            [
              1166,
              -1541
            ],
            [
              1163,
              -1531
            ],
            [
              1162,
              -1529
            ],
            [
              1162,
              -1523
            ],
            [
              1164,
              -1516
            ],
            [
              1164,
              -1515
            ],
            [
              1163,
              -1508
            ],
            [
              1157,
              -1503
            ],
            [
              1159,
              -1412
            ],
            [
              1133,
              -1352
            ],
            [
              1120,
              -1292
            ],
            [
              1128,
              -1286
            ],
            [
              1182,
              -1227
            ],
            [
              1185,
              -1228
            ],
            [
              1184,
              -1228
            ],
            [
              1184,
              -1228
            ],
            [
              1179,
              -1218
            ],
            [
              1186,
              -1192
            ],
            [
              1188,
              -1185
            ],
            [
              1196,
              -1184
            ],
            [
              1197,
              -1183
            ],
            [
              1199,
              -1179
            ],
            [
              1201,
              -1176
            ],
            [
              1204,
              -1171
            ],
            [
              1207,
              -1168
            ],
            [
              1207,
              -1168
            ],
            [
              1208,
              -1167
            ],
            [
              1208,
              -1165
            ],
            [
              1208,
              -1163
            ],
            [
              1209,
              -1158
            ],
            [
              1217,
              -1139
            ],
            [
              1203,
              -1115
            ],
            [
              1215,
              -1063
            ],
            [
              1217,
              -999
            ],
            [
              1214,
              -995
            ],
            [
              1214,
              -991
            ],
            [
              1213,
              -988
            ],
            [
              1206,
              -974
            ],
            [
              1212,
              -967
            ],
            [
              1213,
              -961
            ],
            [
              1214,
              -960
            ],
            [
              1213,
              -960
            ],
            [
              1212,
              -960
            ],
            [
              1209,
              -961
            ],
            [
              1199,
              -960
            ],
            [
              1197,
              -955
            ],
            [
              1190,
              -952
            ],
            [
              1182,
              -949
            ],
            [
              1176,
              -946
            ],
            [
              1171,
              -946
            ],
            [
              1160,
              -946
            ],
            [
              1155,
              -945
            ],
            [
              1153,
              -944
            ],
            [
              1153,
              -942
            ],
            [
              1152,
              -940
            ],
            [
              1142,
              -932
            ],
            [
              1118,
              -922
            ],
            [
              1102,
              -924
            ],
            [
              1096,
              -920
            ],
            [
              1081,
              -920
            ],
            [
              1077,
              -920
            ],
            [
              1070,
              -921
            ],
            [
              1070,
              -917
            ],
            [
              1070,
              -910
            ],
            [
              1070,
              -900
            ],
            [
              1070,
              -897
            ],
            [
              1070,
              -891
            ],
            [
              1068,
              -885
            ],
            [
              1066,
              -881
            ],
            [
              1066,
              -880
            ],
            [
              1064,
              -879
            ],
            [
              1062,
              -876
            ],
            [
              1060,
              -869
            ],
            [
              1060,
              -867
            ],
            [
              1060,
              -864
            ],
            [
              1060,
              -862
            ],
            [
              1060,
              -862
            ],
            [
              1060,
              -862
            ],
            [
              1061,
              -862
            ],
            [
              1066,
              -861
            ],
            [
              1093,
              -850
            ],
            [
              1108,
              -847
            ],
            [
              1114,
              -845
            ],
            [
              1121,
              -844
            ],
            [
              1127,
              -842
            ],
            [
              1140,
              -836
            ],
            [
              1172,
              -825
            ],
            [
              1201,
              -816
            ],
            [
              1211,
              -812
            ],
            [
              1218,
              -810
            ],
            [
              1227,
              -807
            ],
            [
              1259,
              -836
            ],
            [
              1291,
              -828
            ],
            [
              1303,
              -879
            ],
            [
              1293,
              -931
            ],
            [
              1339,
              -948
            ],
            [
              1372,
              -921
            ],
            [
              1375,
              -844
            ],
            [
              1302,
              -768
            ],
            [
              1291,
              -704
            ],
            [
              1325,
              -668
            ],
            [
              1463,
              -676
            ],
            [
              1488,
              -654
            ],
            [
              1523,
              -659
            ],
            [
              1607,
              -625
            ],
            [
              1631,
              -606
            ],
            [
              1637,
              -787
            ],
            [
              1654,
              -833
            ],
            [
              1639,
              -892
            ],
            [
              1590,
              -951
            ],
            [
              1556,
              -977
            ],
            [
              1490,
              -998
            ],
            [
              1444,
              -1028
            ],
            [
              1397,
              -1087
            ],
            [
              1368,
              -1098
            ],
            [
              1313,
              -1145
            ],
            [
              1313,
              -1184
            ],
            [
              1334,
              -1208
            ],
            [
              1356,
              -1322
            ],
            [
              1355,
              -1393
            ],
            [
              1333,
              -1422
            ],
            [
              1208,
              -1479
            ],
            [
              1209,
              -1557
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Mozambique",
        "admin": "Mozambique",
        "woe_id": 23424902,
        "id": "MZ",
        "abbrev": "Moz.",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "MOZ",
        "name": "MZ",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -973,
              1185
            ],
            [
              -962,
              1085
            ],
            [
              -944,
              914
            ],
            [
              -943,
              912
            ],
            [
              -941,
              911
            ],
            [
              -930,
              903
            ],
            [
              -929,
              902
            ],
            [
              -929,
              902
            ],
            [
              -929,
              902
            ],
            [
              -929,
              900
            ],
            [
              -932,
              884
            ],
            [
              -933,
              878
            ],
            [
              -936,
              862
            ],
            [
              -937,
              856
            ],
            [
              -938,
              855
            ],
            [
              -938,
              855
            ],
            [
              -947,
              855
            ],
            [
              -954,
              855
            ],
            [
              -966,
              855
            ],
            [
              -970,
              855
            ],
            [
              -1021,
              855
            ],
            [
              -1152,
              855
            ],
            [
              -1179,
              848
            ],
            [
              -1285,
              857
            ],
            [
              -1291,
              820
            ],
            [
              -1299,
              814
            ],
            [
              -1308,
              814
            ],
            [
              -1313,
              813
            ],
            [
              -1322,
              818
            ],
            [
              -1327,
              826
            ],
            [
              -1348,
              839
            ],
            [
              -1354,
              856
            ],
            [
              -1361,
              855
            ],
            [
              -1363,
              861
            ],
            [
              -1365,
              862
            ],
            [
              -1366,
              862
            ],
            [
              -1366,
              862
            ],
            [
              -1367,
              863
            ],
            [
              -1370,
              862
            ],
            [
              -1374,
              879
            ],
            [
              -1377,
              883
            ],
            [
              -1378,
              885
            ],
            [
              -1378,
              887
            ],
            [
              -1384,
              893
            ],
            [
              -1432,
              920
            ],
            [
              -1464,
              921
            ],
            [
              -1496,
              916
            ],
            [
              -1499,
              913
            ],
            [
              -1502,
              914
            ],
            [
              -1504,
              912
            ],
            [
              -1505,
              912
            ],
            [
              -1506,
              911
            ],
            [
              -1509,
              912
            ],
            [
              -1511,
              912
            ],
            [
              -1514,
              913
            ],
            [
              -1527,
              912
            ],
            [
              -1532,
              916
            ],
            [
              -1542,
              911
            ],
            [
              -1550,
              921
            ],
            [
              -1525,
              996
            ],
            [
              -1551,
              1072
            ],
            [
              -1535,
              1127
            ],
            [
              -1561,
              1153
            ],
            [
              -1576,
              1181
            ],
            [
              -1581,
              1174
            ],
            [
              -1580,
              1169
            ],
            [
              -1585,
              1165
            ],
            [
              -1583,
              1174
            ],
            [
              -1580,
              1181
            ],
            [
              -1579,
              1187
            ],
            [
              -1578,
              1192
            ],
            [
              -1578,
              1192
            ],
            [
              -1539,
              1192
            ],
            [
              -1524,
              1192
            ],
            [
              -1488,
              1192
            ],
            [
              -1468,
              1192
            ],
            [
              -1449,
              1192
            ],
            [
              -1357,
              1192
            ],
            [
              -1357,
              1291
            ],
            [
              -1335,
              1306
            ],
            [
              -1320,
              1309
            ],
            [
              -1308,
              1314
            ],
            [
              -1301,
              1319
            ],
            [
              -1301,
              1467
            ],
            [
              -1116,
              1467
            ],
            [
              -1115,
              1468
            ],
            [
              -1115,
              1527
            ],
            [
              -1115,
              1545
            ],
            [
              -938,
              1433
            ],
            [
              -919,
              1420
            ],
            [
              -909,
              1414
            ],
            [
              -899,
              1408
            ],
            [
              -912,
              1408
            ],
            [
              -949,
              1408
            ],
            [
              -961,
              1408
            ],
            [
              -973,
              1408
            ],
            [
              -986,
              1408
            ],
            [
              -998,
              1408
            ],
            [
              -998,
              1403
            ],
            [
              -997,
              1393
            ],
            [
              -995,
              1384
            ],
            [
              -994,
              1370
            ],
            [
              -973,
              1185
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Mauritania",
        "admin": "Mauritania",
        "woe_id": 23424896,
        "id": "MR",
        "abbrev": "Mrt.",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "MRT",
        "name": "MR",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1325,
              -668
            ],
            [
              1291,
              -704
            ],
            [
              1302,
              -768
            ],
            [
              1375,
              -844
            ],
            [
              1372,
              -921
            ],
            [
              1339,
              -948
            ],
            [
              1293,
              -931
            ],
            [
              1303,
              -879
            ],
            [
              1291,
              -828
            ],
            [
              1259,
              -836
            ],
            [
              1227,
              -807
            ],
            [
              1226,
              -806
            ],
            [
              1224,
              -802
            ],
            [
              1223,
              -802
            ],
            [
              1223,
              -802
            ],
            [
              1222,
              -804
            ],
            [
              1218,
              -806
            ],
            [
              1216,
              -809
            ],
            [
              1215,
              -809
            ],
            [
              1214,
              -806
            ],
            [
              1214,
              -803
            ],
            [
              1214,
              -803
            ],
            [
              1208,
              -795
            ],
            [
              1200,
              -782
            ],
            [
              1217,
              -727
            ],
            [
              1218,
              -726
            ],
            [
              1219,
              -725
            ],
            [
              1219,
              -725
            ],
            [
              1219,
              -726
            ],
            [
              1220,
              -726
            ],
            [
              1220,
              -727
            ],
            [
              1220,
              -726
            ],
            [
              1221,
              -726
            ],
            [
              1222,
              -725
            ],
            [
              1223,
              -725
            ],
            [
              1224,
              -726
            ],
            [
              1224,
              -727
            ],
            [
              1225,
              -727
            ],
            [
              1226,
              -726
            ],
            [
              1226,
              -726
            ],
            [
              1226,
              -726
            ],
            [
              1227,
              -726
            ],
            [
              1227,
              -726
            ],
            [
              1228,
              -725
            ],
            [
              1229,
              -723
            ],
            [
              1230,
              -722
            ],
            [
              1236,
              -722
            ],
            [
              1230,
              -696
            ],
            [
              1232,
              -691
            ],
            [
              1233,
              -686
            ],
            [
              1232,
              -670
            ],
            [
              1228,
              -659
            ],
            [
              1253,
              -612
            ],
            [
              1211,
              -546
            ],
            [
              1272,
              -549
            ],
            [
              1298,
              -576
            ],
            [
              1305,
              -636
            ],
            [
              1325,
              -668
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Africa",
        "fullname": "Malawi",
        "admin": "Malawi",
        "woe_id": 23424889,
        "id": "MW",
        "abbrev": "Mal.",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "MWI",
        "name": "MW",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                5077,
                332
              ],
              [
                5136,
                283
              ],
              [
                5154,
                250
              ],
              [
                5153,
                148
              ],
              [
                5182,
                113
              ],
              [
                5202,
                63
              ],
              [
                5150,
                68
              ],
              [
                5033,
                145
              ],
              [
                5002,
                198
              ],
              [
                4981,
                319
              ],
              [
                4969,
                343
              ],
              [
                5022,
                332
              ],
              [
                5023,
                298
              ],
              [
                5077,
                332
              ]
            ]
          ],
          [
            [
              [
                5944,
                215
              ],
              [
                5924,
                226
              ],
              [
                5849,
                226
              ],
              [
                5826,
                152
              ],
              [
                5772,
                62
              ],
              [
                5725,
                51
              ],
              [
                5690,
                68
              ],
              [
                5642,
                63
              ],
              [
                5622,
                38
              ],
              [
                5551,
                30
              ],
              [
                5502,
                70
              ],
              [
                5501,
                99
              ],
              [
                5516,
                77
              ],
              [
                5575,
                66
              ],
              [
                5601,
                132
              ],
              [
                5687,
                158
              ],
              [
                5741,
                221
              ],
              [
                5744,
                240
              ],
              [
                5777,
                207
              ],
              [
                5799,
                256
              ],
              [
                5802,
                252
              ],
              [
                5808,
                257
              ],
              [
                5848,
                293
              ],
              [
                5867,
                330
              ],
              [
                5910,
                358
              ],
              [
                5953,
                340
              ],
              [
                6032,
                268
              ],
              [
                5975,
                255
              ],
              [
                5998,
                226
              ],
              [
                5944,
                215
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Asia",
        "fullname": "Malaysia",
        "admin": "Malaysia",
        "woe_id": 23424901,
        "id": "MY",
        "abbrev": "Malay.",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "MYS",
        "name": "MY",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              678,
              -1015
            ],
            [
              725,
              -1006
            ],
            [
              783,
              -1024
            ],
            [
              722,
              -1037
            ],
            [
              692,
              -1063
            ],
            [
              674,
              -1036
            ],
            [
              543,
              -1054
            ],
            [
              544,
              -1266
            ],
            [
              487,
              -1269
            ],
            [
              488,
              -1431
            ],
            [
              488,
              -1650
            ],
            [
              437,
              -1685
            ],
            [
              418,
              -1677
            ],
            [
              408,
              -1679
            ],
            [
              405,
              -1680
            ],
            [
              404,
              -1680
            ],
            [
              401,
              -1680
            ],
            [
              401,
              -1680
            ],
            [
              400,
              -1680
            ],
            [
              399,
              -1681
            ],
            [
              386,
              -1681
            ],
            [
              355,
              -1672
            ],
            [
              340,
              -1640
            ],
            [
              332,
              -1639
            ],
            [
              332,
              -1633
            ],
            [
              331,
              -1632
            ],
            [
              330,
              -1632
            ],
            [
              329,
              -1631
            ],
            [
              326,
              -1628
            ],
            [
              315,
              -1631
            ],
            [
              311,
              -1642
            ],
            [
              309,
              -1642
            ],
            [
              308,
              -1642
            ],
            [
              308,
              -1643
            ],
            [
              308,
              -1653
            ],
            [
              303,
              -1654
            ],
            [
              288,
              -1663
            ],
            [
              226,
              -1585
            ],
            [
              200,
              -1492
            ],
            [
              197,
              -1434
            ],
            [
              180,
              -1396
            ],
            [
              182,
              -1301
            ],
            [
              119,
              -1199
            ],
            [
              106,
              -1161
            ],
            [
              33,
              -1044
            ],
            [
              28,
              -993
            ],
            [
              28,
              -992
            ],
            [
              29,
              -993
            ],
            [
              30,
              -993
            ],
            [
              31,
              -993
            ],
            [
              32,
              -992
            ],
            [
              35,
              -990
            ],
            [
              38,
              -988
            ],
            [
              47,
              -986
            ],
            [
              72,
              -992
            ],
            [
              118,
              -976
            ],
            [
              150,
              -1001
            ],
            [
              165,
              -1000
            ],
            [
              255,
              -1000
            ],
            [
              284,
              -1000
            ],
            [
              341,
              -1000
            ],
            [
              356,
              -1000
            ],
            [
              370,
              -1000
            ],
            [
              377,
              -1000
            ],
            [
              384,
              -1000
            ],
            [
              399,
              -1000
            ],
            [
              402,
              -1000
            ],
            [
              408,
              -1009
            ],
            [
              408,
              -1009
            ],
            [
              409,
              -1010
            ],
            [
              411,
              -1012
            ],
            [
              419,
              -1021
            ],
            [
              422,
              -1022
            ],
            [
              427,
              -1024
            ],
            [
              434,
              -1025
            ],
            [
              491,
              -1030
            ],
            [
              507,
              -1027
            ],
            [
              534,
              -1037
            ],
            [
              567,
              -1036
            ],
            [
              571,
              -1035
            ],
            [
              585,
              -1033
            ],
            [
              599,
              -1030
            ],
            [
              627,
              -1025
            ],
            [
              662,
              -1018
            ],
            [
              673,
              -1016
            ],
            [
              678,
              -1015
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Namibia",
        "admin": "Namibia",
        "woe_id": 23424987,
        "id": "NA",
        "abbrev": "Nam.",
        "subregion": "Southern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "NAM",
        "name": "NA",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              8540,
              -1156
            ],
            [
              8608,
              -1196
            ],
            [
              8630,
              -1226
            ],
            [
              8704,
              -1273
            ],
            [
              8695,
              -1292
            ],
            [
              8612,
              -1244
            ],
            [
              8560,
              -1196
            ],
            [
              8540,
              -1156
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Oceania",
        "fullname": "New Caledonia",
        "admin": "New Caledonia",
        "woe_id": 23424903,
        "id": "NC",
        "abbrev": "New C.",
        "subregion": "Melanesia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "NCL",
        "name": "NC",
        "continent": "Oceania"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              208,
              1290
            ],
            [
              219,
              1202
            ],
            [
              262,
              1136
            ],
            [
              250,
              1109
            ],
            [
              235,
              936
            ],
            [
              174,
              869
            ],
            [
              122,
              791
            ],
            [
              124,
              784
            ],
            [
              128,
              765
            ],
            [
              130,
              760
            ],
            [
              130,
              758
            ],
            [
              131,
              755
            ],
            [
              131,
              753
            ],
            [
              68,
              716
            ],
            [
              -33,
              734
            ],
            [
              -91,
              703
            ],
            [
              -144,
              708
            ],
            [
              -196,
              731
            ],
            [
              -243,
              713
            ],
            [
              -273,
              748
            ],
            [
              -287,
              750
            ],
            [
              -319,
              762
            ],
            [
              -321,
              763
            ],
            [
              -329,
              761
            ],
            [
              -335,
              755
            ],
            [
              -378,
              752
            ],
            [
              -426,
              686
            ],
            [
              -425,
              671
            ],
            [
              -427,
              661
            ],
            [
              -425,
              655
            ],
            [
              -426,
              653
            ],
            [
              -428,
              652
            ],
            [
              -428,
              651
            ],
            [
              -428,
              647
            ],
            [
              -426,
              644
            ],
            [
              -425,
              643
            ],
            [
              -425,
              642
            ],
            [
              -425,
              641
            ],
            [
              -426,
              640
            ],
            [
              -429,
              639
            ],
            [
              -429,
              639
            ],
            [
              -429,
              640
            ],
            [
              -430,
              640
            ],
            [
              -431,
              643
            ],
            [
              -434,
              646
            ],
            [
              -445,
              651
            ],
            [
              -448,
              657
            ],
            [
              -471,
              679
            ],
            [
              -496,
              650
            ],
            [
              -575,
              716
            ],
            [
              -621,
              801
            ],
            [
              -617,
              821
            ],
            [
              -555,
              843
            ],
            [
              -433,
              847
            ],
            [
              -396,
              908
            ],
            [
              -393,
              1065
            ],
            [
              -303,
              1084
            ],
            [
              -211,
              1165
            ],
            [
              40,
              1320
            ],
            [
              124,
              1300
            ],
            [
              166,
              1267
            ],
            [
              208,
              1290
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Niger",
        "admin": "Niger",
        "woe_id": 23424906,
        "id": "NE",
        "abbrev": "Niger",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "NER",
        "name": "NE",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -303,
              756
            ],
            [
              -273,
              748
            ],
            [
              -243,
              713
            ],
            [
              -196,
              731
            ],
            [
              -144,
              708
            ],
            [
              -91,
              703
            ],
            [
              -33,
              734
            ],
            [
              68,
              716
            ],
            [
              91,
              741
            ],
            [
              110,
              744
            ],
            [
              117,
              753
            ],
            [
              131,
              753
            ],
            [
              157,
              717
            ],
            [
              160,
              702
            ],
            [
              163,
              687
            ],
            [
              163,
              678
            ],
            [
              165,
              676
            ],
            [
              182,
              674
            ],
            [
              184,
              670
            ],
            [
              187,
              668
            ],
            [
              190,
              667
            ],
            [
              190,
              667
            ],
            [
              191,
              666
            ],
            [
              191,
              665
            ],
            [
              190,
              664
            ],
            [
              189,
              663
            ],
            [
              187,
              658
            ],
            [
              188,
              651
            ],
            [
              184,
              640
            ],
            [
              186,
              627
            ],
            [
              152,
              615
            ],
            [
              90,
              508
            ],
            [
              86,
              477
            ],
            [
              52,
              429
            ],
            [
              14,
              352
            ],
            [
              -37,
              377
            ],
            [
              -64,
              374
            ],
            [
              -134,
              309
            ],
            [
              -138,
              271
            ],
            [
              -166,
              237
            ],
            [
              -223,
              238
            ],
            [
              -289,
              221
            ],
            [
              -317,
              241
            ],
            [
              -350,
              309
            ],
            [
              -383,
              338
            ],
            [
              -478,
              339
            ],
            [
              -478,
              342
            ],
            [
              -478,
              346
            ],
            [
              -477,
              349
            ],
            [
              -476,
              350
            ],
            [
              -477,
              353
            ],
            [
              -477,
              354
            ],
            [
              -476,
              355
            ],
            [
              -475,
              356
            ],
            [
              -474,
              357
            ],
            [
              -474,
              377
            ],
            [
              -476,
              397
            ],
            [
              -473,
              401
            ],
            [
              -477,
              407
            ],
            [
              -478,
              409
            ],
            [
              -478,
              411
            ],
            [
              -477,
              419
            ],
            [
              -477,
              419
            ],
            [
              -478,
              420
            ],
            [
              -479,
              422
            ],
            [
              -480,
              423
            ],
            [
              -480,
              424
            ],
            [
              -480,
              424
            ],
            [
              -480,
              424
            ],
            [
              -480,
              425
            ],
            [
              -479,
              426
            ],
            [
              -478,
              438
            ],
            [
              -477,
              441
            ],
            [
              -477,
              442
            ],
            [
              -478,
              446
            ],
            [
              -479,
              447
            ],
            [
              -479,
              448
            ],
            [
              -477,
              474
            ],
            [
              -475,
              490
            ],
            [
              -433,
              534
            ],
            [
              -425,
              553
            ],
            [
              -430,
              559
            ],
            [
              -420,
              566
            ],
            [
              -417,
              568
            ],
            [
              -417,
              571
            ],
            [
              -415,
              577
            ],
            [
              -415,
              578
            ],
            [
              -415,
              580
            ],
            [
              -416,
              583
            ],
            [
              -421,
              589
            ],
            [
              -425,
              607
            ],
            [
              -435,
              622
            ],
            [
              -436,
              623
            ],
            [
              -436,
              624
            ],
            [
              -434,
              631
            ],
            [
              -430,
              637
            ],
            [
              -429,
              639
            ],
            [
              -426,
              640
            ],
            [
              -425,
              641
            ],
            [
              -425,
              642
            ],
            [
              -425,
              643
            ],
            [
              -426,
              644
            ],
            [
              -428,
              647
            ],
            [
              -428,
              651
            ],
            [
              -426,
              686
            ],
            [
              -378,
              752
            ],
            [
              -335,
              755
            ],
            [
              -329,
              761
            ],
            [
              -321,
              763
            ],
            [
              -319,
              762
            ],
            [
              -303,
              756
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Africa",
        "fullname": "Nigeria",
        "admin": "Nigeria",
        "woe_id": 23424908,
        "id": "NG",
        "abbrev": "Nigeria",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "NGA",
        "name": "NG",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -5277,
              826
            ],
            [
              -5294,
              767
            ],
            [
              -5298,
              707
            ],
            [
              -5313,
              687
            ],
            [
              -5306,
              595
            ],
            [
              -5421,
              604
            ],
            [
              -5519,
              698
            ],
            [
              -5511,
              712
            ],
            [
              -5428,
              761
            ],
            [
              -5369,
              815
            ],
            [
              -5353,
              805
            ],
            [
              -5277,
              826
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Americas",
        "fullname": "Nicaragua",
        "admin": "Nicaragua",
        "woe_id": 23424915,
        "id": "NI",
        "abbrev": "Nic.",
        "subregion": "Central America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "NIC",
        "name": "NI",
        "continent": "North America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -227,
              3272
            ],
            [
              -236,
              3206
            ],
            [
              -283,
              3142
            ],
            [
              -294,
              3086
            ],
            [
              -304,
              3112
            ],
            [
              -363,
              3141
            ],
            [
              -391,
              3132
            ],
            [
              -404,
              3173
            ],
            [
              -355,
              3247
            ],
            [
              -317,
              3277
            ],
            [
              -253,
              3289
            ],
            [
              -227,
              3272
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Europe",
        "fullname": "Netherlands",
        "admin": "Netherlands",
        "woe_id": -90,
        "id": "NL",
        "abbrev": "Neth.",
        "subregion": "Western Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "NLD",
        "name": "NL",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                1095,
                4694
              ],
              [
                989,
                4618
              ],
              [
                999,
                4681
              ],
              [
                930,
                4720
              ],
              [
                852,
                4705
              ],
              [
                826,
                4683
              ],
              [
                805,
                4604
              ],
              [
                760,
                4573
              ],
              [
                708,
                4597
              ],
              [
                624,
                4587
              ],
              [
                582,
                4641
              ],
              [
                523,
                4619
              ],
              [
                485,
                4552
              ],
              [
                386,
                4570
              ],
              [
                371,
                4516
              ],
              [
                339,
                4529
              ],
              [
                272,
                4464
              ],
              [
                288,
                4429
              ],
              [
                183,
                4343
              ],
              [
                182,
                4269
              ],
              [
                133,
                4203
              ],
              [
                150,
                4152
              ],
              [
                79,
                4147
              ],
              [
                41,
                4088
              ],
              [
                59,
                3996
              ],
              [
                50,
                3953
              ],
              [
                90,
                3922
              ],
              [
                70,
                3817
              ],
              [
                38,
                3799
              ],
              [
                10,
                3724
              ],
              [
                -24,
                3738
              ],
              [
                -83,
                3729
              ],
              [
                -175,
                3652
              ],
              [
                -242,
                3649
              ],
              [
                -322,
                3703
              ],
              [
                -332,
                3822
              ],
              [
                -351,
                3926
              ],
              [
                -326,
                3992
              ],
              [
                -276,
                4029
              ],
              [
                -87,
                4119
              ],
              [
                -45,
                4182
              ],
              [
                6,
                4188
              ],
              [
                8,
                4233
              ],
              [
                68,
                4248
              ],
              [
                62,
                4299
              ],
              [
                100,
                4345
              ],
              [
                112,
                4398
              ],
              [
                166,
                4422
              ],
              [
                198,
                4474
              ],
              [
                253,
                4514
              ],
              [
                279,
                4509
              ],
              [
                300,
                4580
              ],
              [
                323,
                4584
              ],
              [
                394,
                4663
              ],
              [
                498,
                4708
              ],
              [
                517,
                4691
              ],
              [
                655,
                4734
              ],
              [
                789,
                4811
              ],
              [
                793,
                4747
              ],
              [
                858,
                4809
              ],
              [
                947,
                4822
              ],
              [
                1047,
                4785
              ],
              [
                1055,
                4721
              ],
              [
                1095,
                4694
              ]
            ]
          ],
          [
            [
              [
                675,
                5607
              ],
              [
                706,
                5525
              ],
              [
                640,
                5493
              ],
              [
                583,
                5609
              ],
              [
                675,
                5607
              ]
            ]
          ],
          [
            [
              [
                311,
                5814
              ],
              [
                352,
                5816
              ],
              [
                417,
                5772
              ],
              [
                430,
                5724
              ],
              [
                477,
                5725
              ],
              [
                475,
                5659
              ],
              [
                432,
                5640
              ],
              [
                388,
                5523
              ],
              [
                348,
                5501
              ],
              [
                320,
                5419
              ],
              [
                298,
                5416
              ],
              [
                211,
                5481
              ],
              [
                174,
                5488
              ],
              [
                98,
                5608
              ],
              [
                35,
                5661
              ],
              [
                -31,
                5774
              ],
              [
                31,
                5810
              ],
              [
                71,
                5797
              ],
              [
                142,
                5814
              ],
              [
                224,
                5781
              ],
              [
                269,
                5833
              ],
              [
                311,
                5814
              ]
            ]
          ],
          [
            [
              [
                664,
                5878
              ],
              [
                869,
                5851
              ],
              [
                804,
                5754
              ],
              [
                652,
                5732
              ],
              [
                624,
                5755
              ],
              [
                534,
                5751
              ],
              [
                515,
                5790
              ],
              [
                421,
                5794
              ],
              [
                489,
                5889
              ],
              [
                538,
                5856
              ],
              [
                626,
                5885
              ],
              [
                664,
                5878
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Europe",
        "fullname": "Norway",
        "admin": "Norway",
        "woe_id": -90,
        "id": "NO",
        "abbrev": "Nor.",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "NOR",
        "name": "NO",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3943,
              1731
            ],
            [
              3963,
              1713
            ],
            [
              3977,
              1709
            ],
            [
              3978,
              1709
            ],
            [
              3980,
              1707
            ],
            [
              3982,
              1706
            ],
            [
              3985,
              1704
            ],
            [
              4002,
              1688
            ],
            [
              4040,
              1660
            ],
            [
              4127,
              1607
            ],
            [
              4157,
              1603
            ],
            [
              4170,
              1597
            ],
            [
              4178,
              1581
            ],
            [
              4185,
              1583
            ],
            [
              4183,
              1592
            ],
            [
              4187,
              1597
            ],
            [
              4189,
              1594
            ],
            [
              4188,
              1592
            ],
            [
              4189,
              1590
            ],
            [
              4190,
              1588
            ],
            [
              4190,
              1588
            ],
            [
              4191,
              1587
            ],
            [
              4191,
              1587
            ],
            [
              4192,
              1587
            ],
            [
              4194,
              1587
            ],
            [
              4203,
              1582
            ],
            [
              4206,
              1584
            ],
            [
              4207,
              1585
            ],
            [
              4208,
              1586
            ],
            [
              4208,
              1588
            ],
            [
              4209,
              1593
            ],
            [
              4216,
              1594
            ],
            [
              4220,
              1589
            ],
            [
              4222,
              1588
            ],
            [
              4226,
              1589
            ],
            [
              4229,
              1585
            ],
            [
              4236,
              1584
            ],
            [
              4243,
              1577
            ],
            [
              4275,
              1576
            ],
            [
              4281,
              1582
            ],
            [
              4289,
              1581
            ],
            [
              4292,
              1581
            ],
            [
              4296,
              1579
            ],
            [
              4298,
              1579
            ],
            [
              4299,
              1579
            ],
            [
              4300,
              1578
            ],
            [
              4300,
              1578
            ],
            [
              4299,
              1576
            ],
            [
              4299,
              1574
            ],
            [
              4297,
              1566
            ],
            [
              4292,
              1557
            ],
            [
              4292,
              1547
            ],
            [
              4290,
              1541
            ],
            [
              4290,
              1536
            ],
            [
              4289,
              1535
            ],
            [
              4289,
              1534
            ],
            [
              4289,
              1534
            ],
            [
              4289,
              1533
            ],
            [
              4290,
              1532
            ],
            [
              4291,
              1530
            ],
            [
              4292,
              1530
            ],
            [
              4293,
              1529
            ],
            [
              4294,
              1529
            ],
            [
              4295,
              1527
            ],
            [
              4296,
              1525
            ],
            [
              4297,
              1522
            ],
            [
              4298,
              1520
            ],
            [
              4299,
              1519
            ],
            [
              4299,
              1518
            ],
            [
              4300,
              1512
            ],
            [
              4300,
              1511
            ],
            [
              4300,
              1510
            ],
            [
              4299,
              1507
            ],
            [
              4296,
              1502
            ],
            [
              4295,
              1499
            ],
            [
              4295,
              1495
            ],
            [
              4293,
              1492
            ],
            [
              4291,
              1490
            ],
            [
              4289,
              1489
            ],
            [
              4283,
              1495
            ],
            [
              4267,
              1490
            ],
            [
              4261,
              1493
            ],
            [
              4260,
              1493
            ],
            [
              4256,
              1493
            ],
            [
              4253,
              1489
            ],
            [
              4237,
              1502
            ],
            [
              4196,
              1504
            ],
            [
              4179,
              1507
            ],
            [
              4177,
              1506
            ],
            [
              4176,
              1506
            ],
            [
              4175,
              1505
            ],
            [
              4171,
              1502
            ],
            [
              4170,
              1502
            ],
            [
              4169,
              1501
            ],
            [
              4169,
              1502
            ],
            [
              4099,
              1547
            ],
            [
              3997,
              1557
            ],
            [
              3907,
              1610
            ],
            [
              3845,
              1638
            ],
            [
              3859,
              1664
            ],
            [
              3855,
              1673
            ],
            [
              3865,
              1685
            ],
            [
              3863,
              1691
            ],
            [
              3863,
              1693
            ],
            [
              3864,
              1694
            ],
            [
              3865,
              1695
            ],
            [
              3869,
              1696
            ],
            [
              3870,
              1697
            ],
            [
              3875,
              1706
            ],
            [
              3890,
              1716
            ],
            [
              3890,
              1719
            ],
            [
              3893,
              1722
            ],
            [
              3895,
              1720
            ],
            [
              3898,
              1721
            ],
            [
              3899,
              1721
            ],
            [
              3899,
              1721
            ],
            [
              3921,
              1732
            ],
            [
              3943,
              1731
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Asia",
        "fullname": "Nepal",
        "admin": "Nepal",
        "woe_id": 23424911,
        "id": "NP",
        "abbrev": "Nepal",
        "subregion": "Southern Asia",
        "region_wb": "South Asia",
        "iso_a3": "NPL",
        "name": "NP",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                9037,
                -2417
              ],
              [
                9055,
                -2470
              ],
              [
                9100,
                -2456
              ],
              [
                9110,
                -2509
              ],
              [
                9015,
                -2634
              ],
              [
                8950,
                -2672
              ],
              [
                8914,
                -2785
              ],
              [
                8855,
                -2832
              ],
              [
                8787,
                -2834
              ],
              [
                8746,
                -2805
              ],
              [
                8694,
                -2807
              ],
              [
                8679,
                -2778
              ],
              [
                8719,
                -2719
              ],
              [
                8782,
                -2661
              ],
              [
                8860,
                -2623
              ],
              [
                8938,
                -2557
              ],
              [
                8958,
                -2506
              ],
              [
                8991,
                -2478
              ],
              [
                8993,
                -2443
              ],
              [
                9037,
                -2417
              ]
            ]
          ],
          [
            [
              [
                9047,
                -2023
              ],
              [
                9050,
                -2048
              ],
              [
                9092,
                -2060
              ],
              [
                9131,
                -2098
              ],
              [
                9145,
                -2177
              ],
              [
                9199,
                -2169
              ],
              [
                9210,
                -2229
              ],
              [
                9275,
                -2254
              ],
              [
                9338,
                -2224
              ],
              [
                9339,
                -2288
              ],
              [
                9316,
                -2324
              ],
              [
                9261,
                -2347
              ],
              [
                9257,
                -2396
              ],
              [
                9209,
                -2467
              ],
              [
                9173,
                -2491
              ],
              [
                9133,
                -2470
              ],
              [
                9163,
                -2428
              ],
              [
                9153,
                -2378
              ],
              [
                9087,
                -2344
              ],
              [
                9131,
                -2307
              ],
              [
                9137,
                -2209
              ],
              [
                9122,
                -2173
              ],
              [
                9048,
                -2074
              ],
              [
                9047,
                -2023
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Oceania",
        "fullname": "New Zealand",
        "admin": "New Zealand",
        "woe_id": 23424916,
        "id": "NZ",
        "abbrev": "N.Z.",
        "subregion": "Australia and New Zealand",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "NZL",
        "name": "NZ",
        "continent": "Oceania"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2339,
              921
            ],
            [
              2277,
              1056
            ],
            [
              2444,
              1114
            ],
            [
              2481,
              1230
            ],
            [
              2456,
              1272
            ],
            [
              2491,
              1369
            ],
            [
              2523,
              1407
            ],
            [
              2566,
              1346
            ],
            [
              2657,
              1320
            ],
            [
              2713,
              1243
            ],
            [
              2642,
              1139
            ],
            [
              2605,
              1130
            ],
            [
              2597,
              1053
            ],
            [
              2538,
              1033
            ],
            [
              2533,
              1007
            ],
            [
              2470,
              990
            ],
            [
              2447,
              942
            ],
            [
              2391,
              940
            ],
            [
              2339,
              921
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Asia",
        "fullname": "Oman",
        "admin": "Oman",
        "woe_id": 23424898,
        "id": "OM",
        "abbrev": "Oman",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "OMN",
        "name": "OM",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3663,
              2060
            ],
            [
              3678,
              2026
            ],
            [
              3607,
              1988
            ],
            [
              3524,
              2005
            ],
            [
              3506,
              1999
            ],
            [
              3508,
              1905
            ],
            [
              3580,
              1841
            ],
            [
              3554,
              1834
            ],
            [
              3526,
              1764
            ],
            [
              3473,
              1705
            ],
            [
              3446,
              1650
            ],
            [
              3417,
              1635
            ],
            [
              3388,
              1585
            ],
            [
              3331,
              1570
            ],
            [
              3303,
              1588
            ],
            [
              3260,
              1539
            ],
            [
              3293,
              1499
            ],
            [
              3288,
              1466
            ],
            [
              3344,
              1389
            ],
            [
              3283,
              1359
            ],
            [
              3213,
              1366
            ],
            [
              3183,
              1339
            ],
            [
              3152,
              1337
            ],
            [
              3126,
              1394
            ],
            [
              3075,
              1436
            ],
            [
              2986,
              1422
            ],
            [
              2814,
              1420
            ],
            [
              2829,
              1482
            ],
            [
              2879,
              1506
            ],
            [
              2878,
              1587
            ],
            [
              2831,
              1620
            ],
            [
              2797,
              1673
            ],
            [
              2780,
              1692
            ],
            [
              2777,
              1695
            ],
            [
              2772,
              1700
            ],
            [
              2774,
              1700
            ],
            [
              2858,
              1674
            ],
            [
              2864,
              1673
            ],
            [
              2953,
              1672
            ],
            [
              2975,
              1683
            ],
            [
              2995,
              1683
            ],
            [
              3004,
              1681
            ],
            [
              3007,
              1681
            ],
            [
              3071,
              1699
            ],
            [
              3080,
              1706
            ],
            [
              3075,
              1710
            ],
            [
              3073,
              1712
            ],
            [
              3073,
              1713
            ],
            [
              3073,
              1714
            ],
            [
              3074,
              1716
            ],
            [
              3077,
              1723
            ],
            [
              3077,
              1728
            ],
            [
              3078,
              1736
            ],
            [
              3078,
              1737
            ],
            [
              3078,
              1738
            ],
            [
              3075,
              1743
            ],
            [
              3075,
              1746
            ],
            [
              3081,
              1766
            ],
            [
              3082,
              1767
            ],
            [
              3090,
              1768
            ],
            [
              3091,
              1769
            ],
            [
              3096,
              1774
            ],
            [
              3098,
              1775
            ],
            [
              3099,
              1782
            ],
            [
              3101,
              1783
            ],
            [
              3103,
              1784
            ],
            [
              3104,
              1785
            ],
            [
              3106,
              1786
            ],
            [
              3107,
              1787
            ],
            [
              3113,
              1790
            ],
            [
              3136,
              1783
            ],
            [
              3181,
              1821
            ],
            [
              3192,
              1817
            ],
            [
              3200,
              1815
            ],
            [
              3201,
              1815
            ],
            [
              3202,
              1816
            ],
            [
              3203,
              1817
            ],
            [
              3203,
              1817
            ],
            [
              3201,
              1817
            ],
            [
              3198,
              1817
            ],
            [
              3196,
              1818
            ],
            [
              3197,
              1819
            ],
            [
              3199,
              1821
            ],
            [
              3202,
              1821
            ],
            [
              3210,
              1818
            ],
            [
              3216,
              1808
            ],
            [
              3219,
              1808
            ],
            [
              3223,
              1809
            ],
            [
              3225,
              1810
            ],
            [
              3227,
              1810
            ],
            [
              3228,
              1810
            ],
            [
              3230,
              1812
            ],
            [
              3232,
              1813
            ],
            [
              3233,
              1814
            ],
            [
              3234,
              1815
            ],
            [
              3235,
              1816
            ],
            [
              3241,
              1825
            ],
            [
              3242,
              1826
            ],
            [
              3245,
              1828
            ],
            [
              3245,
              1828
            ],
            [
              3245,
              1829
            ],
            [
              3245,
              1830
            ],
            [
              3242,
              1840
            ],
            [
              3241,
              1858
            ],
            [
              3244,
              1865
            ],
            [
              3245,
              1866
            ],
            [
              3247,
              1866
            ],
            [
              3248,
              1867
            ],
            [
              3252,
              1873
            ],
            [
              3250,
              1881
            ],
            [
              3252,
              1882
            ],
            [
              3253,
              1884
            ],
            [
              3255,
              1885
            ],
            [
              3255,
              1885
            ],
            [
              3255,
              1887
            ],
            [
              3254,
              1894
            ],
            [
              3257,
              1898
            ],
            [
              3259,
              1899
            ],
            [
              3271,
              1901
            ],
            [
              3277,
              1900
            ],
            [
              3285,
              1903
            ],
            [
              3287,
              1906
            ],
            [
              3287,
              1906
            ],
            [
              3290,
              1906
            ],
            [
              3291,
              1906
            ],
            [
              3301,
              1914
            ],
            [
              3284,
              1959
            ],
            [
              3313,
              1953
            ],
            [
              3332,
              1954
            ],
            [
              3333,
              1956
            ],
            [
              3334,
              1957
            ],
            [
              3337,
              1957
            ],
            [
              3339,
              1958
            ],
            [
              3340,
              1959
            ],
            [
              3343,
              1959
            ],
            [
              3344,
              1960
            ],
            [
              3344,
              1961
            ],
            [
              3344,
              1963
            ],
            [
              3344,
              1964
            ],
            [
              3346,
              1966
            ],
            [
              3345,
              1973
            ],
            [
              3347,
              1977
            ],
            [
              3347,
              1979
            ],
            [
              3345,
              1999
            ],
            [
              3351,
              2004
            ],
            [
              3354,
              2008
            ],
            [
              3355,
              2010
            ],
            [
              3356,
              2012
            ],
            [
              3358,
              2013
            ],
            [
              3366,
              2016
            ],
            [
              3368,
              2024
            ],
            [
              3370,
              2026
            ],
            [
              3374,
              2028
            ],
            [
              3375,
              2030
            ],
            [
              3376,
              2031
            ],
            [
              3375,
              2032
            ],
            [
              3370,
              2039
            ],
            [
              3370,
              2040
            ],
            [
              3371,
              2042
            ],
            [
              3374,
              2045
            ],
            [
              3375,
              2045
            ],
            [
              3375,
              2047
            ],
            [
              3373,
              2054
            ],
            [
              3369,
              2063
            ],
            [
              3359,
              2080
            ],
            [
              3381,
              2108
            ],
            [
              3384,
              2109
            ],
            [
              3383,
              2111
            ],
            [
              3383,
              2114
            ],
            [
              3384,
              2114
            ],
            [
              3399,
              2122
            ],
            [
              3408,
              2129
            ],
            [
              3428,
              2135
            ],
            [
              3488,
              2140
            ],
            [
              3500,
              2139
            ],
            [
              3505,
              2136
            ],
            [
              3513,
              2136
            ],
            [
              3538,
              2148
            ],
            [
              3583,
              2141
            ],
            [
              3618,
              2112
            ],
            [
              3629,
              2071
            ],
            [
              3663,
              2060
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Asia",
        "fullname": "Pakistan",
        "admin": "Pakistan",
        "woe_id": 23424922,
        "id": "PK",
        "abbrev": "Pak.",
        "subregion": "Southern Asia",
        "region_wb": "South Asia",
        "iso_a3": "PAK",
        "name": "PK",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -4956,
              468
            ],
            [
              -4946,
              429
            ],
            [
              -4985,
              387
            ],
            [
              -5007,
              413
            ],
            [
              -5008,
              456
            ],
            [
              -5073,
              486
            ],
            [
              -5129,
              443
            ],
            [
              -5102,
              402
            ],
            [
              -5151,
              386
            ],
            [
              -5198,
              438
            ],
            [
              -5251,
              448
            ],
            [
              -5264,
              432
            ],
            [
              -5246,
              519
            ],
            [
              -5220,
              483
            ],
            [
              -5148,
              480
            ],
            [
              -5077,
              522
            ],
            [
              -4992,
              499
            ],
            [
              -4956,
              468
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Americas",
        "fullname": "Panama",
        "admin": "Panama",
        "woe_id": 23424924,
        "id": "PA",
        "abbrev": "Pan.",
        "subregion": "Central America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "PAN",
        "name": "PA",
        "continent": "North America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -4541,
              -255
            ],
            [
              -4590,
              -252
            ],
            [
              -4642,
              -269
            ],
            [
              -4706,
              -305
            ],
            [
              -4718,
              -378
            ],
            [
              -4754,
              -405
            ],
            [
              -4740,
              -487
            ],
            [
              -4679,
              -550
            ],
            [
              -4666,
              -579
            ],
            [
              -4621,
              -579
            ],
            [
              -4581,
              -552
            ],
            [
              -4579,
              -636
            ],
            [
              -4520,
              -633
            ],
            [
              -4519,
              -633
            ],
            [
              -4519,
              -634
            ],
            [
              -4519,
              -634
            ],
            [
              -4512,
              -646
            ],
            [
              -4506,
              -656
            ],
            [
              -4471,
              -718
            ],
            [
              -4470,
              -720
            ],
            [
              -4470,
              -721
            ],
            [
              -4470,
              -721
            ],
            [
              -4472,
              -724
            ],
            [
              -4475,
              -726
            ],
            [
              -4476,
              -727
            ],
            [
              -4476,
              -728
            ],
            [
              -4475,
              -729
            ],
            [
              -4474,
              -729
            ],
            [
              -4473,
              -730
            ],
            [
              -4474,
              -733
            ],
            [
              -4475,
              -733
            ],
            [
              -4479,
              -734
            ],
            [
              -4482,
              -738
            ],
            [
              -4486,
              -742
            ],
            [
              -4486,
              -752
            ],
            [
              -4486,
              -758
            ],
            [
              -4486,
              -761
            ],
            [
              -4485,
              -765
            ],
            [
              -4486,
              -776
            ],
            [
              -4487,
              -780
            ],
            [
              -4489,
              -783
            ],
            [
              -4489,
              -784
            ],
            [
              -4489,
              -785
            ],
            [
              -4492,
              -789
            ],
            [
              -4488,
              -792
            ],
            [
              -4486,
              -804
            ],
            [
              -4483,
              -807
            ],
            [
              -4480,
              -815
            ],
            [
              -4481,
              -818
            ],
            [
              -4487,
              -828
            ],
            [
              -4488,
              -829
            ],
            [
              -4492,
              -831
            ],
            [
              -4493,
              -831
            ],
            [
              -4497,
              -835
            ],
            [
              -4501,
              -840
            ],
            [
              -4509,
              -861
            ],
            [
              -4503,
              -869
            ],
            [
              -4503,
              -870
            ],
            [
              -4499,
              -872
            ],
            [
              -4497,
              -875
            ],
            [
              -4496,
              -876
            ],
            [
              -4496,
              -878
            ],
            [
              -4497,
              -878
            ],
            [
              -4498,
              -878
            ],
            [
              -4499,
              -878
            ],
            [
              -4500,
              -880
            ],
            [
              -4504,
              -883
            ],
            [
              -4504,
              -884
            ],
            [
              -4503,
              -886
            ],
            [
              -4503,
              -886
            ],
            [
              -4504,
              -887
            ],
            [
              -4511,
              -897
            ],
            [
              -4511,
              -899
            ],
            [
              -4511,
              -901
            ],
            [
              -4503,
              -920
            ],
            [
              -4501,
              -926
            ],
            [
              -4500,
              -929
            ],
            [
              -4498,
              -932
            ],
            [
              -4497,
              -933
            ],
            [
              -4495,
              -933
            ],
            [
              -4486,
              -933
            ],
            [
              -4479,
              -938
            ],
            [
              -4479,
              -941
            ],
            [
              -4487,
              -945
            ],
            [
              -4489,
              -959
            ],
            [
              -4494,
              -961
            ],
            [
              -4495,
              -962
            ],
            [
              -4497,
              -962
            ],
            [
              -4498,
              -963
            ],
            [
              -4511,
              -983
            ],
            [
              -4522,
              -989
            ],
            [
              -4524,
              -993
            ],
            [
              -4525,
              -995
            ],
            [
              -4521,
              -995
            ],
            [
              -4517,
              -999
            ],
            [
              -4516,
              -1001
            ],
            [
              -4516,
              -1003
            ],
            [
              -4516,
              -1007
            ],
            [
              -4516,
              -1007
            ],
            [
              -4516,
              -1007
            ],
            [
              -4523,
              -1014
            ],
            [
              -4526,
              -1016
            ],
            [
              -4535,
              -1020
            ],
            [
              -4542,
              -1050
            ],
            [
              -4565,
              -1055
            ],
            [
              -4628,
              -994
            ],
            [
              -4758,
              -929
            ],
            [
              -4832,
              -886
            ],
            [
              -4893,
              -814
            ],
            [
              -4891,
              -769
            ],
            [
              -4944,
              -696
            ],
            [
              -4953,
              -664
            ],
            [
              -4994,
              -599
            ],
            [
              -5046,
              -478
            ],
            [
              -5100,
              -398
            ],
            [
              -5164,
              -358
            ],
            [
              -5172,
              -255
            ],
            [
              -5121,
              -208
            ],
            [
              -5107,
              -259
            ],
            [
              -5047,
              -295
            ],
            [
              -5011,
              -213
            ],
            [
              -4982,
              -185
            ],
            [
              -4917,
              -162
            ],
            [
              -4854,
              -102
            ],
            [
              -4839,
              -24
            ],
            [
              -4813,
              -27
            ],
            [
              -4783,
              -71
            ],
            [
              -4747,
              -88
            ],
            [
              -4708,
              -153
            ],
            [
              -4642,
              -138
            ],
            [
              -4592,
              -143
            ],
            [
              -4546,
              -170
            ],
            [
              -4584,
              -229
            ],
            [
              -4541,
              -255
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Americas",
        "fullname": "Peru",
        "admin": "Peru",
        "woe_id": 23424919,
        "id": "PE",
        "abbrev": "Peru",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "PER",
        "name": "PE",
        "continent": "South America"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                6395,
                521
              ],
              [
                6433,
                484
              ],
              [
                6449,
                389
              ],
              [
                6399,
                326
              ],
              [
                6369,
                307
              ],
              [
                6317,
                328
              ],
              [
                6301,
                364
              ],
              [
                6317,
                397
              ],
              [
                6280,
                421
              ],
              [
                6247,
                400
              ],
              [
                6216,
                408
              ],
              [
                6250,
                459
              ],
              [
                6290,
                464
              ],
              [
                6316,
                442
              ],
              [
                6347,
                484
              ],
              [
                6385,
                486
              ],
              [
                6395,
                521
              ]
            ]
          ],
          [
            [
              [
                6208,
                642
              ],
              [
                6257,
                626
              ],
              [
                6224,
                582
              ],
              [
                6194,
                600
              ],
              [
                6208,
                642
              ]
            ]
          ],
          [
            [
              [
                6355,
                688
              ],
              [
                6387,
                670
              ],
              [
                6359,
                635
              ],
              [
                6325,
                669
              ],
              [
                6355,
                688
              ]
            ]
          ],
          [
            [
              [
                6134,
                743
              ],
              [
                6166,
                692
              ],
              [
                6143,
                670
              ],
              [
                6106,
                730
              ],
              [
                6134,
                743
              ]
            ]
          ],
          [
            [
              [
                6130,
                1031
              ],
              [
                6201,
                1006
              ],
              [
                6221,
                946
              ],
              [
                6203,
                899
              ],
              [
                6168,
                880
              ],
              [
                6157,
                844
              ],
              [
                6176,
                768
              ],
              [
                6151,
                746
              ],
              [
                6085,
                815
              ],
              [
                6074,
                898
              ],
              [
                6103,
                939
              ],
              [
                6098,
                973
              ],
              [
                6130,
                1031
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Asia",
        "fullname": "Philippines",
        "admin": "Philippines",
        "woe_id": 23424934,
        "id": "PH",
        "abbrev": "Phil.",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "PHL",
        "name": "PH",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                8026,
                -325
              ],
              [
                8040,
                -329
              ],
              [
                8091,
                -395
              ],
              [
                8057,
                -396
              ],
              [
                8023,
                -351
              ],
              [
                8026,
                -325
              ]
            ]
          ],
          [
            [
              [
                7882,
                -252
              ],
              [
                7874,
                -323
              ],
              [
                7783,
                -369
              ],
              [
                7723,
                -331
              ],
              [
                7797,
                -328
              ],
              [
                7852,
                -288
              ],
              [
                7882,
                -252
              ]
            ]
          ],
          [
            [
              [
                7252,
                -529
              ],
              [
                7252,
                -163
              ],
              [
                7320,
                -190
              ],
              [
                7450,
                -231
              ],
              [
                7523,
                -290
              ],
              [
                7518,
                -321
              ],
              [
                7614,
                -352
              ],
              [
                7637,
                -393
              ],
              [
                7588,
                -395
              ],
              [
                7598,
                -432
              ],
              [
                7651,
                -469
              ],
              [
                7676,
                -524
              ],
              [
                7754,
                -583
              ],
              [
                7727,
                -599
              ],
              [
                7632,
                -582
              ],
              [
                7592,
                -549
              ],
              [
                7538,
                -471
              ],
              [
                7446,
                -444
              ],
              [
                7399,
                -480
              ],
              [
                7388,
                -520
              ],
              [
                7346,
                -541
              ],
              [
                7252,
                -529
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Oceania",
        "fullname": "Papua New Guinea",
        "admin": "Papua New Guinea",
        "woe_id": 23424926,
        "id": "PG",
        "abbrev": "P.N.G.",
        "subregion": "Melanesia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "PNG",
        "name": "PG",
        "continent": "Oceania"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              683,
              3325
            ],
            [
              706,
              3265
            ],
            [
              692,
              3185
            ],
            [
              686,
              3153
            ],
            [
              690,
              3143
            ],
            [
              692,
              3130
            ],
            [
              705,
              3116
            ],
            [
              711,
              3088
            ],
            [
              712,
              3088
            ],
            [
              714,
              3088
            ],
            [
              714,
              3085
            ],
            [
              716,
              3082
            ],
            [
              718,
              3078
            ],
            [
              718,
              3071
            ],
            [
              713,
              3068
            ],
            [
              713,
              3065
            ],
            [
              713,
              3064
            ],
            [
              712,
              3063
            ],
            [
              711,
              3061
            ],
            [
              698,
              3060
            ],
            [
              662,
              3028
            ],
            [
              631,
              2964
            ],
            [
              590,
              2986
            ],
            [
              423,
              2996
            ],
            [
              331,
              3059
            ],
            [
              304,
              3038
            ],
            [
              284,
              3079
            ],
            [
              209,
              3105
            ],
            [
              208,
              3094
            ],
            [
              198,
              3094
            ],
            [
              198,
              3096
            ],
            [
              201,
              3099
            ],
            [
              207,
              3109
            ],
            [
              207,
              3110
            ],
            [
              207,
              3112
            ],
            [
              207,
              3113
            ],
            [
              208,
              3113
            ],
            [
              208,
              3114
            ],
            [
              208,
              3115
            ],
            [
              208,
              3117
            ],
            [
              210,
              3123
            ],
            [
              210,
              3125
            ],
            [
              209,
              3126
            ],
            [
              207,
              3129
            ],
            [
              206,
              3138
            ],
            [
              194,
              3143
            ],
            [
              186,
              3167
            ],
            [
              189,
              3222
            ],
            [
              162,
              3241
            ],
            [
              176,
              3274
            ],
            [
              173,
              3287
            ],
            [
              172,
              3290
            ],
            [
              170,
              3292
            ],
            [
              168,
              3307
            ],
            [
              184,
              3307
            ],
            [
              197,
              3332
            ],
            [
              275,
              3350
            ],
            [
              297,
              3372
            ],
            [
              370,
              3393
            ],
            [
              410,
              3363
            ],
            [
              639,
              3356
            ],
            [
              683,
              3325
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Europe",
        "fullname": "Poland",
        "admin": "Poland",
        "woe_id": 23424923,
        "id": "PL",
        "abbrev": "Pol.",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "POL",
        "name": "PL",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -4376,
              1028
            ],
            [
              -4319,
              1025
            ],
            [
              -4329,
              995
            ],
            [
              -4388,
              997
            ],
            [
              -4376,
              1028
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Americas",
        "fullname": "Puerto Rico",
        "admin": "Puerto Rico",
        "woe_id": 23424935,
        "id": "PR",
        "abbrev": "P.R.",
        "subregion": "Caribbean",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "PRI",
        "name": "PR",
        "continent": "North America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              6668,
              2510
            ],
            [
              6678,
              2494
            ],
            [
              6621,
              2451
            ],
            [
              6615,
              2397
            ],
            [
              6544,
              2344
            ],
            [
              6500,
              2325
            ],
            [
              6492,
              2292
            ],
            [
              6547,
              2252
            ],
            [
              6529,
              2231
            ],
            [
              6480,
              2231
            ],
            [
              6452,
              2200
            ],
            [
              6360,
              2205
            ],
            [
              6353,
              2234
            ],
            [
              6372,
              2315
            ],
            [
              6324,
              2348
            ],
            [
              6416,
              2401
            ],
            [
              6465,
              2460
            ],
            [
              6537,
              2434
            ],
            [
              6542,
              2476
            ],
            [
              6577,
              2476
            ],
            [
              6622,
              2504
            ],
            [
              6668,
              2510
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Asia",
        "fullname": "Dem. Rep. Korea",
        "admin": "North Korea",
        "woe_id": 23424865,
        "id": "KP",
        "abbrev": "N.K.",
        "subregion": "Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "PRK",
        "name": "KP",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -1044,
              2159
            ],
            [
              -1112,
              2155
            ],
            [
              -1128,
              2244
            ],
            [
              -1160,
              2257
            ],
            [
              -1113,
              2407
            ],
            [
              -1119,
              2472
            ],
            [
              -1087,
              2462
            ],
            [
              -1029,
              2473
            ],
            [
              -977,
              2446
            ],
            [
              -1011,
              2411
            ],
            [
              -1014,
              2342
            ],
            [
              -1038,
              2306
            ],
            [
              -1019,
              2277
            ],
            [
              -1041,
              2240
            ],
            [
              -1044,
              2159
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Europe",
        "fullname": "Portugal",
        "admin": "Portugal",
        "woe_id": 23424925,
        "id": "PT",
        "abbrev": "Port.",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "PRT",
        "name": "PT",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -3881,
              -1161
            ],
            [
              -3877,
              -1174
            ],
            [
              -3875,
              -1176
            ],
            [
              -3874,
              -1176
            ],
            [
              -3873,
              -1176
            ],
            [
              -3872,
              -1177
            ],
            [
              -3865,
              -1194
            ],
            [
              -3864,
              -1229
            ],
            [
              -3868,
              -1237
            ],
            [
              -3867,
              -1250
            ],
            [
              -3870,
              -1260
            ],
            [
              -3868,
              -1263
            ],
            [
              -3872,
              -1271
            ],
            [
              -3864,
              -1277
            ],
            [
              -3828,
              -1280
            ],
            [
              -3821,
              -1283
            ],
            [
              -3817,
              -1282
            ],
            [
              -3816,
              -1283
            ],
            [
              -3815,
              -1283
            ],
            [
              -3814,
              -1284
            ],
            [
              -3812,
              -1284
            ],
            [
              -3810,
              -1286
            ],
            [
              -3808,
              -1286
            ],
            [
              -3784,
              -1273
            ],
            [
              -3778,
              -1281
            ],
            [
              -3775,
              -1283
            ],
            [
              -3772,
              -1285
            ],
            [
              -3755,
              -1287
            ],
            [
              -3747,
              -1293
            ],
            [
              -3746,
              -1298
            ],
            [
              -3746,
              -1300
            ],
            [
              -3740,
              -1305
            ],
            [
              -3741,
              -1317
            ],
            [
              -3741,
              -1319
            ],
            [
              -3740,
              -1324
            ],
            [
              -3740,
              -1325
            ],
            [
              -3740,
              -1327
            ],
            [
              -3740,
              -1328
            ],
            [
              -3734,
              -1363
            ],
            [
              -3731,
              -1367
            ],
            [
              -3730,
              -1371
            ],
            [
              -3727,
              -1385
            ],
            [
              -3716,
              -1388
            ],
            [
              -3701,
              -1385
            ],
            [
              -3683,
              -1375
            ],
            [
              -3673,
              -1381
            ],
            [
              -3672,
              -1382
            ],
            [
              -3669,
              -1386
            ],
            [
              -3664,
              -1389
            ],
            [
              -3662,
              -1390
            ],
            [
              -3662,
              -1390
            ],
            [
              -3663,
              -1406
            ],
            [
              -3675,
              -1448
            ],
            [
              -3673,
              -1454
            ],
            [
              -3677,
              -1463
            ],
            [
              -3681,
              -1468
            ],
            [
              -3683,
              -1472
            ],
            [
              -3683,
              -1475
            ],
            [
              -3682,
              -1476
            ],
            [
              -3682,
              -1477
            ],
            [
              -3682,
              -1479
            ],
            [
              -3682,
              -1480
            ],
            [
              -3682,
              -1481
            ],
            [
              -3681,
              -1483
            ],
            [
              -3681,
              -1485
            ],
            [
              -3682,
              -1495
            ],
            [
              -3686,
              -1524
            ],
            [
              -3693,
              -1538
            ],
            [
              -3693,
              -1545
            ],
            [
              -3699,
              -1545
            ],
            [
              -3700,
              -1546
            ],
            [
              -3701,
              -1547
            ],
            [
              -3703,
              -1553
            ],
            [
              -3747,
              -1593
            ],
            [
              -3827,
              -1595
            ],
            [
              -3874,
              -1582
            ],
            [
              -3886,
              -1581
            ],
            [
              -3901,
              -1583
            ],
            [
              -3906,
              -1581
            ],
            [
              -3909,
              -1578
            ],
            [
              -3909,
              -1577
            ],
            [
              -3909,
              -1575
            ],
            [
              -3898,
              -1562
            ],
            [
              -3886,
              -1551
            ],
            [
              -3884,
              -1530
            ],
            [
              -3882,
              -1527
            ],
            [
              -3882,
              -1522
            ],
            [
              -3877,
              -1513
            ],
            [
              -3865,
              -1506
            ],
            [
              -3859,
              -1486
            ],
            [
              -3857,
              -1485
            ],
            [
              -3856,
              -1485
            ],
            [
              -3855,
              -1485
            ],
            [
              -3855,
              -1485
            ],
            [
              -3854,
              -1485
            ],
            [
              -3854,
              -1483
            ],
            [
              -3851,
              -1483
            ],
            [
              -3851,
              -1481
            ],
            [
              -3849,
              -1480
            ],
            [
              -3849,
              -1480
            ],
            [
              -3848,
              -1479
            ],
            [
              -3848,
              -1474
            ],
            [
              -3848,
              -1473
            ],
            [
              -3849,
              -1471
            ],
            [
              -3852,
              -1468
            ],
            [
              -3854,
              -1463
            ],
            [
              -3857,
              -1461
            ],
            [
              -3859,
              -1457
            ],
            [
              -3918,
              -1433
            ],
            [
              -3947,
              -1416
            ],
            [
              -3949,
              -1412
            ],
            [
              -3950,
              -1412
            ],
            [
              -3952,
              -1410
            ],
            [
              -3953,
              -1409
            ],
            [
              -3954,
              -1408
            ],
            [
              -3986,
              -1387
            ],
            [
              -3988,
              -1387
            ],
            [
              -3991,
              -1388
            ],
            [
              -3992,
              -1388
            ],
            [
              -4003,
              -1388
            ],
            [
              -4040,
              -1375
            ],
            [
              -4046,
              -1363
            ],
            [
              -4055,
              -1358
            ],
            [
              -4056,
              -1358
            ],
            [
              -4057,
              -1356
            ],
            [
              -4068,
              -1352
            ],
            [
              -4070,
              -1350
            ],
            [
              -4069,
              -1348
            ],
            [
              -4070,
              -1348
            ],
            [
              -4071,
              -1348
            ],
            [
              -4071,
              -1347
            ],
            [
              -4074,
              -1345
            ],
            [
              -4082,
              -1341
            ],
            [
              -4094,
              -1330
            ],
            [
              -4106,
              -1311
            ],
            [
              -4106,
              -1306
            ],
            [
              -4109,
              -1301
            ],
            [
              -4111,
              -1299
            ],
            [
              -4111,
              -1299
            ],
            [
              -4111,
              -1299
            ],
            [
              -4112,
              -1299
            ],
            [
              -4112,
              -1297
            ],
            [
              -4117,
              -1296
            ],
            [
              -4120,
              -1293
            ],
            [
              -4121,
              -1292
            ],
            [
              -4122,
              -1291
            ],
            [
              -4122,
              -1291
            ],
            [
              -4131,
              -1286
            ],
            [
              -4132,
              -1282
            ],
            [
              -4111,
              -1214
            ],
            [
              -4111,
              -1185
            ],
            [
              -4083,
              -1132
            ],
            [
              -3985,
              -1111
            ],
            [
              -3933,
              -1110
            ],
            [
              -3882,
              -1141
            ],
            [
              -3881,
              -1161
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Americas",
        "fullname": "Paraguay",
        "admin": "Paraguay",
        "woe_id": 23424917,
        "id": "PY",
        "abbrev": "Para.",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "PRY",
        "name": "PY",
        "continent": "South America"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                1285,
                1783
              ],
              [
                1283,
                1790
              ],
              [
                1298,
                1806
              ],
              [
                1285,
                1783
              ]
            ]
          ],
          [
            [
              [
                1359,
                1856
              ],
              [
                1359,
                1855
              ],
              [
                1359,
                1855
              ],
              [
                1359,
                1855
              ],
              [
                1358,
                1855
              ],
              [
                1358,
                1854
              ],
              [
                1358,
                1852
              ],
              [
                1359,
                1847
              ],
              [
                1356,
                1836
              ],
              [
                1359,
                1817
              ],
              [
                1357,
                1816
              ],
              [
                1355,
                1813
              ],
              [
                1354,
                1810
              ],
              [
                1353,
                1805
              ],
              [
                1353,
                1801
              ],
              [
                1340,
                1794
              ],
              [
                1323,
                1792
              ],
              [
                1324,
                1843
              ],
              [
                1339,
                1866
              ],
              [
                1349,
                1863
              ],
              [
                1350,
                1858
              ],
              [
                1353,
                1857
              ],
              [
                1354,
                1857
              ],
              [
                1359,
                1856
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Asia",
        "fullname": "Palestine",
        "admin": "Palestine",
        "woe_id": 28289408,
        "id": "PS",
        "abbrev": "Pal.",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "PSE",
        "name": "PS",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2234,
              1386
            ],
            [
              2208,
              1433
            ],
            [
              2223,
              1469
            ],
            [
              2254,
              1460
            ],
            [
              2256,
              1416
            ],
            [
              2234,
              1386
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Asia",
        "fullname": "Qatar",
        "admin": "Qatar",
        "woe_id": 23424930,
        "id": "QA",
        "abbrev": "Qatar",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "QAT",
        "name": "QA",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              968,
              2592
            ],
            [
              882,
              2621
            ],
            [
              788,
              2586
            ],
            [
              675,
              2602
            ],
            [
              639,
              2625
            ],
            [
              639,
              2625
            ],
            [
              639,
              2626
            ],
            [
              639,
              2629
            ],
            [
              639,
              2630
            ],
            [
              633,
              2632
            ],
            [
              629,
              2637
            ],
            [
              628,
              2640
            ],
            [
              627,
              2641
            ],
            [
              627,
              2641
            ],
            [
              627,
              2642
            ],
            [
              627,
              2643
            ],
            [
              628,
              2644
            ],
            [
              632,
              2645
            ],
            [
              635,
              2648
            ],
            [
              635,
              2653
            ],
            [
              631,
              2655
            ],
            [
              627,
              2659
            ],
            [
              618,
              2656
            ],
            [
              617,
              2656
            ],
            [
              617,
              2655
            ],
            [
              611,
              2645
            ],
            [
              601,
              2654
            ],
            [
              581,
              2656
            ],
            [
              578,
              2662
            ],
            [
              577,
              2663
            ],
            [
              576,
              2663
            ],
            [
              568,
              2663
            ],
            [
              565,
              2669
            ],
            [
              570,
              2669
            ],
            [
              571,
              2669
            ],
            [
              574,
              2670
            ],
            [
              575,
              2672
            ],
            [
              573,
              2673
            ],
            [
              567,
              2675
            ],
            [
              568,
              2680
            ],
            [
              569,
              2683
            ],
            [
              569,
              2684
            ],
            [
              569,
              2684
            ],
            [
              569,
              2684
            ],
            [
              570,
              2685
            ],
            [
              570,
              2685
            ],
            [
              572,
              2688
            ],
            [
              570,
              2690
            ],
            [
              567,
              2692
            ],
            [
              556,
              2695
            ],
            [
              543,
              2701
            ],
            [
              543,
              2701
            ],
            [
              540,
              2704
            ],
            [
              537,
              2707
            ],
            [
              534,
              2710
            ],
            [
              533,
              2710
            ],
            [
              532,
              2711
            ],
            [
              532,
              2711
            ],
            [
              531,
              2720
            ],
            [
              529,
              2729
            ],
            [
              521,
              2739
            ],
            [
              516,
              2741
            ],
            [
              513,
              2743
            ],
            [
              508,
              2747
            ],
            [
              502,
              2755
            ],
            [
              552,
              2767
            ],
            [
              615,
              2868
            ],
            [
              650,
              2884
            ],
            [
              783,
              2881
            ],
            [
              810,
              2883
            ],
            [
              814,
              2884
            ],
            [
              819,
              2886
            ],
            [
              834,
              2887
            ],
            [
              841,
              2903
            ],
            [
              842,
              2903
            ],
            [
              843,
              2903
            ],
            [
              849,
              2904
            ],
            [
              857,
              2906
            ],
            [
              859,
              2906
            ],
            [
              861,
              2907
            ],
            [
              863,
              2907
            ],
            [
              867,
              2906
            ],
            [
              869,
              2906
            ],
            [
              877,
              2902
            ],
            [
              878,
              2898
            ],
            [
              880,
              2897
            ],
            [
              881,
              2897
            ],
            [
              883,
              2896
            ],
            [
              895,
              2868
            ],
            [
              938,
              2818
            ],
            [
              949,
              2793
            ],
            [
              950,
              2791
            ],
            [
              950,
              2790
            ],
            [
              949,
              2788
            ],
            [
              949,
              2787
            ],
            [
              948,
              2782
            ],
            [
              950,
              2780
            ],
            [
              950,
              2780
            ],
            [
              950,
              2778
            ],
            [
              949,
              2775
            ],
            [
              949,
              2775
            ],
            [
              948,
              2775
            ],
            [
              948,
              2774
            ],
            [
              947,
              2772
            ],
            [
              947,
              2772
            ],
            [
              947,
              2772
            ],
            [
              946,
              2771
            ],
            [
              947,
              2770
            ],
            [
              947,
              2769
            ],
            [
              947,
              2769
            ],
            [
              946,
              2767
            ],
            [
              944,
              2760
            ],
            [
              943,
              2758
            ],
            [
              943,
              2757
            ],
            [
              942,
              2753
            ],
            [
              941,
              2747
            ],
            [
              942,
              2744
            ],
            [
              942,
              2743
            ],
            [
              942,
              2742
            ],
            [
              943,
              2741
            ],
            [
              945,
              2731
            ],
            [
              945,
              2724
            ],
            [
              945,
              2722
            ],
            [
              944,
              2721
            ],
            [
              943,
              2721
            ],
            [
              939,
              2719
            ],
            [
              944,
              2717
            ],
            [
              945,
              2712
            ],
            [
              954,
              2700
            ],
            [
              976,
              2694
            ],
            [
              997,
              2662
            ],
            [
              972,
              2632
            ],
            [
              968,
              2592
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Europe",
        "fullname": "Romania",
        "admin": "Romania",
        "woe_id": 23424933,
        "id": "RO",
        "abbrev": "Rom.",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "ROU",
        "name": "RO",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1074,
              -78
            ],
            [
              1079,
              -92
            ],
            [
              1094,
              -107
            ],
            [
              1079,
              -152
            ],
            [
              1031,
              -175
            ],
            [
              1011,
              -176
            ],
            [
              1009,
              -167
            ],
            [
              999,
              -163
            ],
            [
              994,
              -164
            ],
            [
              993,
              -170
            ],
            [
              992,
              -169
            ],
            [
              991,
              -169
            ],
            [
              988,
              -168
            ],
            [
              986,
              -167
            ],
            [
              986,
              -166
            ],
            [
              986,
              -162
            ],
            [
              986,
              -161
            ],
            [
              984,
              -159
            ],
            [
              984,
              -153
            ],
            [
              984,
              -152
            ],
            [
              985,
              -151
            ],
            [
              988,
              -150
            ],
            [
              989,
              -150
            ],
            [
              990,
              -149
            ],
            [
              990,
              -148
            ],
            [
              991,
              -147
            ],
            [
              991,
              -146
            ],
            [
              996,
              -144
            ],
            [
              998,
              -143
            ],
            [
              999,
              -141
            ],
            [
              1000,
              -138
            ],
            [
              1001,
              -136
            ],
            [
              1000,
              -134
            ],
            [
              999,
              -125
            ],
            [
              999,
              -123
            ],
            [
              999,
              -121
            ],
            [
              1000,
              -119
            ],
            [
              1002,
              -115
            ],
            [
              1004,
              -113
            ],
            [
              1005,
              -111
            ],
            [
              1006,
              -110
            ],
            [
              1008,
              -107
            ],
            [
              1009,
              -107
            ],
            [
              1010,
              -104
            ],
            [
              1011,
              -103
            ],
            [
              1012,
              -102
            ],
            [
              1016,
              -102
            ],
            [
              1016,
              -102
            ],
            [
              1016,
              -102
            ],
            [
              1017,
              -102
            ],
            [
              1017,
              -102
            ],
            [
              1018,
              -100
            ],
            [
              1020,
              -98
            ],
            [
              1024,
              -96
            ],
            [
              1029,
              -95
            ],
            [
              1039,
              -92
            ],
            [
              1056,
              -93
            ],
            [
              1074,
              -78
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Rwanda",
        "admin": "Rwanda",
        "woe_id": 23424937,
        "id": "RW",
        "abbrev": "Rwa.",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "RWA",
        "name": "RW",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -1115,
              1567
            ],
            [
              -1115,
              1545
            ],
            [
              -1115,
              1527
            ],
            [
              -1115,
              1468
            ],
            [
              -1116,
              1467
            ],
            [
              -1301,
              1467
            ],
            [
              -1301,
              1319
            ],
            [
              -1308,
              1314
            ],
            [
              -1320,
              1309
            ],
            [
              -1335,
              1306
            ],
            [
              -1357,
              1291
            ],
            [
              -1357,
              1192
            ],
            [
              -1384,
              1192
            ],
            [
              -1449,
              1192
            ],
            [
              -1468,
              1192
            ],
            [
              -1488,
              1192
            ],
            [
              -1539,
              1192
            ],
            [
              -1578,
              1192
            ],
            [
              -1578,
              1192
            ],
            [
              -1579,
              1187
            ],
            [
              -1580,
              1181
            ],
            [
              -1584,
              1174
            ],
            [
              -1581,
              1197
            ],
            [
              -1565,
              1200
            ],
            [
              -1510,
              1201
            ],
            [
              -1502,
              1200
            ],
            [
              -1494,
              1199
            ],
            [
              -1459,
              1199
            ],
            [
              -1425,
              1249
            ],
            [
              -1422,
              1276
            ],
            [
              -1418,
              1296
            ],
            [
              -1415,
              1310
            ],
            [
              -1413,
              1314
            ],
            [
              -1325,
              1398
            ],
            [
              -1323,
              1401
            ],
            [
              -1321,
              1406
            ],
            [
              -1318,
              1415
            ],
            [
              -1302,
              1469
            ],
            [
              -1240,
              1528
            ],
            [
              -1219,
              1527
            ],
            [
              -1215,
              1525
            ],
            [
              -1208,
              1521
            ],
            [
              -1203,
              1519
            ],
            [
              -1174,
              1519
            ],
            [
              -1156,
              1533
            ],
            [
              -1133,
              1533
            ],
            [
              -1127,
              1534
            ],
            [
              -1121,
              1535
            ],
            [
              -1119,
              1539
            ],
            [
              -1121,
              1546
            ],
            [
              -1122,
              1549
            ],
            [
              -1123,
              1567
            ],
            [
              -1115,
              1567
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 7,
        "region_un": "Africa",
        "fullname": "W. Sahara",
        "admin": "Western Sahara",
        "woe_id": 23424990,
        "id": "EH",
        "abbrev": "W. Sah.",
        "subregion": "Northern Africa",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "ESH",
        "name": "EH",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1972,
              1654
            ],
            [
              2022,
              1648
            ],
            [
              2036,
              1620
            ],
            [
              2078,
              1620
            ],
            [
              2088,
              1597
            ],
            [
              2149,
              1525
            ],
            [
              2197,
              1411
            ],
            [
              2254,
              1364
            ],
            [
              2310,
              1286
            ],
            [
              2456,
              1272
            ],
            [
              2481,
              1230
            ],
            [
              2444,
              1114
            ],
            [
              2277,
              1056
            ],
            [
              2117,
              1034
            ],
            [
              2063,
              1007
            ],
            [
              2022,
              946
            ],
            [
              1997,
              939
            ],
            [
              1896,
              966
            ],
            [
              1784,
              960
            ],
            [
              1763,
              905
            ],
            [
              1735,
              967
            ],
            [
              1703,
              993
            ],
            [
              1636,
              1113
            ],
            [
              1587,
              1141
            ],
            [
              1555,
              1191
            ],
            [
              1559,
              1254
            ],
            [
              1529,
              1323
            ],
            [
              1472,
              1363
            ],
            [
              1452,
              1420
            ],
            [
              1330,
              1595
            ],
            [
              1308,
              1593
            ],
            [
              1324,
              1670
            ],
            [
              1331,
              1668
            ],
            [
              1384,
              1660
            ],
            [
              1386,
              1660
            ],
            [
              1387,
              1660
            ],
            [
              1410,
              1678
            ],
            [
              1425,
              1701
            ],
            [
              1465,
              1709
            ],
            [
              1475,
              1728
            ],
            [
              1475,
              1729
            ],
            [
              1477,
              1730
            ],
            [
              1494,
              1739
            ],
            [
              1494,
              1740
            ],
            [
              1494,
              1740
            ],
            [
              1494,
              1740
            ],
            [
              1494,
              1740
            ],
            [
              1491,
              1743
            ],
            [
              1485,
              1749
            ],
            [
              1479,
              1756
            ],
            [
              1473,
              1763
            ],
            [
              1466,
              1771
            ],
            [
              1459,
              1778
            ],
            [
              1451,
              1786
            ],
            [
              1444,
              1793
            ],
            [
              1437,
              1801
            ],
            [
              1492,
              1816
            ],
            [
              1549,
              1832
            ],
            [
              1549,
              1832
            ],
            [
              1551,
              1832
            ],
            [
              1559,
              1839
            ],
            [
              1628,
              1828
            ],
            [
              1723,
              1775
            ],
            [
              1869,
              1660
            ],
            [
              1972,
              1654
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Asia",
        "fullname": "Saudi Arabia",
        "admin": "Saudi Arabia",
        "woe_id": 23424938,
        "id": "SA",
        "abbrev": "Saud.",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "SAU",
        "name": "SA",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1433,
              1231
            ],
            [
              1450,
              1184
            ],
            [
              1452,
              1094
            ],
            [
              1463,
              1048
            ],
            [
              1529,
              999
            ],
            [
              1514,
              979
            ],
            [
              1439,
              945
            ],
            [
              1436,
              898
            ],
            [
              1407,
              833
            ],
            [
              1413,
              785
            ],
            [
              1408,
              769
            ],
            [
              1405,
              745
            ],
            [
              1396,
              733
            ],
            [
              1391,
              715
            ],
            [
              1392,
              712
            ],
            [
              1391,
              711
            ],
            [
              1390,
              710
            ],
            [
              1390,
              709
            ],
            [
              1390,
              697
            ],
            [
              1368,
              694
            ],
            [
              1367,
              694
            ],
            [
              1366,
              693
            ],
            [
              1366,
              693
            ],
            [
              1366,
              693
            ],
            [
              1365,
              690
            ],
            [
              1362,
              689
            ],
            [
              1351,
              671
            ],
            [
              1350,
              667
            ],
            [
              1350,
              666
            ],
            [
              1349,
              666
            ],
            [
              1347,
              664
            ],
            [
              1339,
              650
            ],
            [
              1332,
              647
            ],
            [
              1331,
              646
            ],
            [
              1331,
              644
            ],
            [
              1330,
              643
            ],
            [
              1331,
              630
            ],
            [
              1324,
              615
            ],
            [
              1324,
              591
            ],
            [
              1321,
              588
            ],
            [
              1320,
              587
            ],
            [
              1319,
              587
            ],
            [
              1319,
              586
            ],
            [
              1319,
              585
            ],
            [
              1318,
              584
            ],
            [
              1304,
              593
            ],
            [
              1287,
              575
            ],
            [
              1287,
              573
            ],
            [
              1287,
              570
            ],
            [
              1290,
              558
            ],
            [
              1288,
              549
            ],
            [
              1284,
              544
            ],
            [
              1277,
              525
            ],
            [
              1275,
              516
            ],
            [
              1275,
              512
            ],
            [
              1266,
              512
            ],
            [
              1266,
              554
            ],
            [
              1226,
              590
            ],
            [
              1220,
              632
            ],
            [
              1179,
              639
            ],
            [
              1183,
              602
            ],
            [
              1117,
              531
            ],
            [
              1090,
              528
            ],
            [
              1048,
              558
            ],
            [
              983,
              505
            ],
            [
              930,
              520
            ],
            [
              855,
              516
            ],
            [
              815,
              566
            ],
            [
              773,
              559
            ],
            [
              743,
              480
            ],
            [
              722,
              469
            ],
            [
              683,
              474
            ],
            [
              688,
              485
            ],
            [
              682,
              485
            ],
            [
              681,
              486
            ],
            [
              681,
              488
            ],
            [
              682,
              495
            ],
            [
              692,
              502
            ],
            [
              690,
              517
            ],
            [
              694,
              523
            ],
            [
              694,
              525
            ],
            [
              692,
              535
            ],
            [
              691,
              538
            ],
            [
              673,
              568
            ],
            [
              657,
              582
            ],
            [
              649,
              593
            ],
            [
              651,
              603
            ],
            [
              654,
              611
            ],
            [
              652,
              622
            ],
            [
              636,
              628
            ],
            [
              634,
              655
            ],
            [
              612,
              698
            ],
            [
              597,
              692
            ],
            [
              594,
              694
            ],
            [
              592,
              698
            ],
            [
              590,
              700
            ],
            [
              590,
              701
            ],
            [
              590,
              701
            ],
            [
              591,
              703
            ],
            [
              592,
              707
            ],
            [
              597,
              716
            ],
            [
              605,
              757
            ],
            [
              626,
              805
            ],
            [
              638,
              808
            ],
            [
              642,
              825
            ],
            [
              651,
              833
            ],
            [
              654,
              838
            ],
            [
              654,
              839
            ],
            [
              662,
              867
            ],
            [
              663,
              867
            ],
            [
              666,
              867
            ],
            [
              674,
              866
            ],
            [
              689,
              869
            ],
            [
              711,
              868
            ],
            [
              711,
              1085
            ],
            [
              711,
              1114
            ],
            [
              720,
              1114
            ],
            [
              746,
              1114
            ],
            [
              753,
              1114
            ],
            [
              761,
              1114
            ],
            [
              766,
              1114
            ],
            [
              767,
              1115
            ],
            [
              767,
              1231
            ],
            [
              1433,
              1231
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Sudan",
        "admin": "Sudan",
        "woe_id": -90,
        "id": "SD",
        "abbrev": "Sudan",
        "subregion": "Northern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "SDN",
        "name": "SD",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1275,
              512
            ],
            [
              1276,
              498
            ],
            [
              1278,
              465
            ],
            [
              1277,
              464
            ],
            [
              1277,
              463
            ],
            [
              1276,
              462
            ],
            [
              1275,
              460
            ],
            [
              1270,
              455
            ],
            [
              1269,
              455
            ],
            [
              1268,
              454
            ],
            [
              1262,
              454
            ],
            [
              1254,
              451
            ],
            [
              1249,
              456
            ],
            [
              1225,
              453
            ],
            [
              1224,
              447
            ],
            [
              1225,
              447
            ],
            [
              1225,
              446
            ],
            [
              1225,
              446
            ],
            [
              1226,
              445
            ],
            [
              1227,
              444
            ],
            [
              1218,
              419
            ],
            [
              1255,
              411
            ],
            [
              1301,
              360
            ],
            [
              1307,
              359
            ],
            [
              1311,
              356
            ],
            [
              1312,
              354
            ],
            [
              1313,
              351
            ],
            [
              1314,
              349
            ],
            [
              1315,
              346
            ],
            [
              1315,
              343
            ],
            [
              1318,
              338
            ],
            [
              1319,
              332
            ],
            [
              1326,
              309
            ],
            [
              1333,
              297
            ],
            [
              1372,
              280
            ],
            [
              1379,
              241
            ],
            [
              1375,
              241
            ],
            [
              1371,
              241
            ],
            [
              1366,
              239
            ],
            [
              1361,
              241
            ],
            [
              1359,
              256
            ],
            [
              1341,
              261
            ],
            [
              1270,
              218
            ],
            [
              1266,
              214
            ],
            [
              1255,
              203
            ],
            [
              1249,
              197
            ],
            [
              1245,
              193
            ],
            [
              1243,
              192
            ],
            [
              1240,
              192
            ],
            [
              1226,
              192
            ],
            [
              1225,
              193
            ],
            [
              1223,
              193
            ],
            [
              1216,
              199
            ],
            [
              1202,
              193
            ],
            [
              1183,
              191
            ],
            [
              1180,
              191
            ],
            [
              1170,
              185
            ],
            [
              1169,
              184
            ],
            [
              1169,
              183
            ],
            [
              1170,
              181
            ],
            [
              1170,
              180
            ],
            [
              1169,
              179
            ],
            [
              1169,
              179
            ],
            [
              1169,
              179
            ],
            [
              1168,
              179
            ],
            [
              1165,
              179
            ],
            [
              1161,
              183
            ],
            [
              1161,
              183
            ],
            [
              1156,
              183
            ],
            [
              1156,
              183
            ],
            [
              1155,
              184
            ],
            [
              1155,
              188
            ],
            [
              1147,
              196
            ],
            [
              1132,
              187
            ],
            [
              1120,
              193
            ],
            [
              1118,
              194
            ],
            [
              1113,
              194
            ],
            [
              1112,
              194
            ],
            [
              1109,
              192
            ],
            [
              1108,
              191
            ],
            [
              1101,
              188
            ],
            [
              1096,
              181
            ],
            [
              1095,
              178
            ],
            [
              1095,
              177
            ],
            [
              1094,
              178
            ],
            [
              1095,
              181
            ],
            [
              1086,
              185
            ],
            [
              1080,
              184
            ],
            [
              1078,
              197
            ],
            [
              1059,
              203
            ],
            [
              1026,
              242
            ],
            [
              958,
              221
            ],
            [
              905,
              266
            ],
            [
              902,
              270
            ],
            [
              897,
              273
            ],
            [
              893,
              280
            ],
            [
              893,
              284
            ],
            [
              892,
              285
            ],
            [
              892,
              286
            ],
            [
              892,
              287
            ],
            [
              895,
              293
            ],
            [
              894,
              293
            ],
            [
              895,
              294
            ],
            [
              894,
              295
            ],
            [
              894,
              295
            ],
            [
              892,
              298
            ],
            [
              890,
              302
            ],
            [
              887,
              305
            ],
            [
              879,
              310
            ],
            [
              870,
              312
            ],
            [
              863,
              318
            ],
            [
              854,
              320
            ],
            [
              845,
              355
            ],
            [
              788,
              393
            ],
            [
              759,
              440
            ],
            [
              722,
              469
            ],
            [
              743,
              480
            ],
            [
              773,
              559
            ],
            [
              815,
              566
            ],
            [
              855,
              516
            ],
            [
              930,
              520
            ],
            [
              983,
              505
            ],
            [
              1048,
              558
            ],
            [
              1090,
              528
            ],
            [
              1117,
              531
            ],
            [
              1183,
              602
            ],
            [
              1179,
              639
            ],
            [
              1220,
              632
            ],
            [
              1226,
              590
            ],
            [
              1266,
              554
            ],
            [
              1266,
              512
            ],
            [
              1275,
              512
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "S. Sudan",
        "admin": "South Sudan",
        "woe_id": -99,
        "id": "SS",
        "abbrev": "S. Sud.",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "SSD",
        "name": "SS",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -1315,
              814
            ],
            [
              -1306,
              753
            ],
            [
              -1270,
              717
            ],
            [
              -1266,
              679
            ],
            [
              -1321,
              673
            ],
            [
              -1361,
              692
            ],
            [
              -1397,
              694
            ],
            [
              -1417,
              694
            ],
            [
              -1437,
              694
            ],
            [
              -1479,
              694
            ],
            [
              -1506,
              681
            ],
            [
              -1537,
              682
            ],
            [
              -1545,
              677
            ],
            [
              -1554,
              676
            ],
            [
              -1562,
              676
            ],
            [
              -1564,
              676
            ],
            [
              -1565,
              675
            ],
            [
              -1569,
              680
            ],
            [
              -1566,
              716
            ],
            [
              -1556,
              746
            ],
            [
              -1590,
              807
            ],
            [
              -1542,
              911
            ],
            [
              -1532,
              916
            ],
            [
              -1527,
              912
            ],
            [
              -1514,
              913
            ],
            [
              -1511,
              912
            ],
            [
              -1509,
              912
            ],
            [
              -1506,
              911
            ],
            [
              -1505,
              912
            ],
            [
              -1504,
              912
            ],
            [
              -1502,
              914
            ],
            [
              -1499,
              913
            ],
            [
              -1496,
              916
            ],
            [
              -1464,
              921
            ],
            [
              -1432,
              920
            ],
            [
              -1402,
              891
            ],
            [
              -1384,
              893
            ],
            [
              -1378,
              887
            ],
            [
              -1378,
              885
            ],
            [
              -1377,
              883
            ],
            [
              -1374,
              879
            ],
            [
              -1370,
              862
            ],
            [
              -1367,
              863
            ],
            [
              -1366,
              862
            ],
            [
              -1366,
              862
            ],
            [
              -1365,
              862
            ],
            [
              -1363,
              861
            ],
            [
              -1361,
              855
            ],
            [
              -1354,
              856
            ],
            [
              -1348,
              839
            ],
            [
              -1327,
              826
            ],
            [
              -1322,
              818
            ],
            [
              -1315,
              814
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Senegal",
        "admin": "Senegal",
        "woe_id": 23424943,
        "id": "SN",
        "abbrev": "Sen.",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "SEN",
        "name": "SN",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -1254,
              536
            ],
            [
              -1226,
              504
            ],
            [
              -1222,
              476
            ],
            [
              -1221,
              447
            ],
            [
              -1210,
              458
            ],
            [
              -1204,
              457
            ],
            [
              -1204,
              455
            ],
            [
              -1206,
              448
            ],
            [
              -1207,
              446
            ],
            [
              -1207,
              444
            ],
            [
              -1206,
              442
            ],
            [
              -1207,
              441
            ],
            [
              -1208,
              439
            ],
            [
              -1209,
              439
            ],
            [
              -1223,
              417
            ],
            [
              -1265,
              382
            ],
            [
              -1329,
              396
            ],
            [
              -1370,
              468
            ],
            [
              -1370,
              477
            ],
            [
              -1364,
              482
            ],
            [
              -1373,
              485
            ],
            [
              -1372,
              487
            ],
            [
              -1373,
              488
            ],
            [
              -1373,
              489
            ],
            [
              -1372,
              490
            ],
            [
              -1368,
              491
            ],
            [
              -1363,
              489
            ],
            [
              -1358,
              492
            ],
            [
              -1354,
              502
            ],
            [
              -1340,
              510
            ],
            [
              -1329,
              535
            ],
            [
              -1295,
              542
            ],
            [
              -1260,
              543
            ],
            [
              -1254,
              536
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Africa",
        "fullname": "Sierra Leone",
        "admin": "Sierra Leone",
        "woe_id": 23424946,
        "id": "SL",
        "abbrev": "S.L.",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "SLE",
        "name": "SL",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -5626,
              793
            ],
            [
              -5623,
              793
            ],
            [
              -5618,
              791
            ],
            [
              -5607,
              787
            ],
            [
              -5577,
              768
            ],
            [
              -5539,
              736
            ],
            [
              -5546,
              722
            ],
            [
              -5651,
              743
            ],
            [
              -5667,
              754
            ],
            [
              -5668,
              758
            ],
            [
              -5668,
              760
            ],
            [
              -5667,
              761
            ],
            [
              -5667,
              761
            ],
            [
              -5663,
              766
            ],
            [
              -5655,
              772
            ],
            [
              -5645,
              778
            ],
            [
              -5641,
              781
            ],
            [
              -5635,
              782
            ],
            [
              -5635,
              783
            ],
            [
              -5635,
              784
            ],
            [
              -5636,
              786
            ],
            [
              -5637,
              787
            ],
            [
              -5639,
              787
            ],
            [
              -5639,
              788
            ],
            [
              -5639,
              789
            ],
            [
              -5638,
              789
            ],
            [
              -5638,
              790
            ],
            [
              -5638,
              790
            ],
            [
              -5639,
              792
            ],
            [
              -5638,
              792
            ],
            [
              -5637,
              793
            ],
            [
              -5628,
              795
            ],
            [
              -5627,
              794
            ],
            [
              -5626,
              793
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Americas",
        "fullname": "El Salvador",
        "admin": "El Salvador",
        "woe_id": 23424807,
        "id": "SV",
        "abbrev": "El. S.",
        "subregion": "Central America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "SLV",
        "name": "SV",
        "continent": "North America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2053,
              430
            ],
            [
              1997,
              430
            ],
            [
              1832,
              486
            ],
            [
              1798,
              510
            ],
            [
              1765,
              555
            ],
            [
              1770,
              599
            ],
            [
              1788,
              627
            ],
            [
              1846,
              569
            ],
            [
              1884,
              566
            ],
            [
              1931,
              592
            ],
            [
              1967,
              582
            ],
            [
              2019,
              609
            ],
            [
              2063,
              608
            ],
            [
              2091,
              618
            ],
            [
              2104,
              614
            ],
            [
              2105,
              613
            ],
            [
              2106,
              613
            ],
            [
              2107,
              613
            ],
            [
              2107,
              512
            ],
            [
              2100,
              502
            ],
            [
              2086,
              481
            ],
            [
              2083,
              476
            ],
            [
              2080,
              471
            ],
            [
              2063,
              445
            ],
            [
              2053,
              430
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Africa",
        "fullname": "Somaliland",
        "admin": "Somaliland",
        "woe_id": -99,
        "id": "Somaliland",
        "abbrev": "Solnd.",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "-99",
        "name": "-99",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1693,
              -113
            ],
            [
              1662,
              -67
            ],
            [
              1662,
              141
            ],
            [
              1682,
              161
            ],
            [
              1708,
              198
            ],
            [
              1712,
              205
            ],
            [
              1714,
              210
            ],
            [
              1726,
              217
            ],
            [
              1763,
              222
            ],
            [
              1829,
              259
            ],
            [
              1883,
              257
            ],
            [
              1966,
              346
            ],
            [
              2015,
              393
            ],
            [
              2028,
              406
            ],
            [
              2045,
              422
            ],
            [
              2050,
              427
            ],
            [
              2053,
              430
            ],
            [
              2063,
              445
            ],
            [
              2080,
              471
            ],
            [
              2083,
              476
            ],
            [
              2086,
              481
            ],
            [
              2093,
              491
            ],
            [
              2107,
              512
            ],
            [
              2107,
              607
            ],
            [
              2107,
              613
            ],
            [
              2181,
              633
            ],
            [
              2211,
              655
            ],
            [
              2229,
              628
            ],
            [
              2230,
              581
            ],
            [
              2213,
              512
            ],
            [
              2157,
              428
            ],
            [
              2112,
              326
            ],
            [
              2051,
              231
            ],
            [
              1944,
              118
            ],
            [
              1861,
              69
            ],
            [
              1801,
              17
            ],
            [
              1723,
              -66
            ],
            [
              1693,
              -113
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Africa",
        "fullname": "Somalia",
        "admin": "Somalia",
        "woe_id": -90,
        "id": "SO",
        "abbrev": "Som.",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "SOM",
        "name": "SO",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              444,
              2578
            ],
            [
              434,
              2669
            ],
            [
              442,
              2698
            ],
            [
              424,
              2734
            ],
            [
              427,
              2742
            ],
            [
              435,
              2745
            ],
            [
              436,
              2748
            ],
            [
              439,
              2748
            ],
            [
              440,
              2747
            ],
            [
              441,
              2746
            ],
            [
              447,
              2746
            ],
            [
              463,
              2759
            ],
            [
              471,
              2759
            ],
            [
              473,
              2758
            ],
            [
              476,
              2756
            ],
            [
              477,
              2756
            ],
            [
              499,
              2757
            ],
            [
              508,
              2747
            ],
            [
              509,
              2745
            ],
            [
              513,
              2743
            ],
            [
              516,
              2741
            ],
            [
              521,
              2739
            ],
            [
              529,
              2729
            ],
            [
              533,
              2730
            ],
            [
              531,
              2720
            ],
            [
              533,
              2714
            ],
            [
              532,
              2711
            ],
            [
              532,
              2711
            ],
            [
              533,
              2710
            ],
            [
              534,
              2710
            ],
            [
              537,
              2707
            ],
            [
              540,
              2704
            ],
            [
              543,
              2701
            ],
            [
              543,
              2701
            ],
            [
              556,
              2695
            ],
            [
              567,
              2692
            ],
            [
              570,
              2690
            ],
            [
              572,
              2688
            ],
            [
              572,
              2686
            ],
            [
              570,
              2685
            ],
            [
              570,
              2685
            ],
            [
              569,
              2684
            ],
            [
              568,
              2680
            ],
            [
              565,
              2679
            ],
            [
              567,
              2675
            ],
            [
              573,
              2673
            ],
            [
              574,
              2670
            ],
            [
              571,
              2669
            ],
            [
              570,
              2669
            ],
            [
              565,
              2669
            ],
            [
              576,
              2663
            ],
            [
              577,
              2663
            ],
            [
              578,
              2662
            ],
            [
              581,
              2656
            ],
            [
              601,
              2654
            ],
            [
              611,
              2645
            ],
            [
              617,
              2655
            ],
            [
              617,
              2656
            ],
            [
              618,
              2656
            ],
            [
              627,
              2659
            ],
            [
              631,
              2655
            ],
            [
              635,
              2653
            ],
            [
              628,
              2644
            ],
            [
              627,
              2643
            ],
            [
              627,
              2642
            ],
            [
              627,
              2641
            ],
            [
              627,
              2641
            ],
            [
              628,
              2640
            ],
            [
              629,
              2637
            ],
            [
              639,
              2630
            ],
            [
              639,
              2629
            ],
            [
              639,
              2626
            ],
            [
              639,
              2625
            ],
            [
              634,
              2621
            ],
            [
              634,
              2614
            ],
            [
              629,
              2611
            ],
            [
              625,
              2610
            ],
            [
              623,
              2610
            ],
            [
              623,
              2609
            ],
            [
              623,
              2608
            ],
            [
              623,
              2606
            ],
            [
              622,
              2605
            ],
            [
              622,
              2603
            ],
            [
              622,
              2601
            ],
            [
              621,
              2599
            ],
            [
              620,
              2598
            ],
            [
              620,
              2596
            ],
            [
              621,
              2594
            ],
            [
              622,
              2593
            ],
            [
              622,
              2592
            ],
            [
              622,
              2591
            ],
            [
              622,
              2591
            ],
            [
              622,
              2590
            ],
            [
              627,
              2585
            ],
            [
              627,
              2583
            ],
            [
              627,
              2582
            ],
            [
              627,
              2581
            ],
            [
              627,
              2579
            ],
            [
              628,
              2578
            ],
            [
              629,
              2575
            ],
            [
              629,
              2574
            ],
            [
              653,
              2547
            ],
            [
              644,
              2540
            ],
            [
              641,
              2534
            ],
            [
              633,
              2534
            ],
            [
              630,
              2533
            ],
            [
              630,
              2533
            ],
            [
              629,
              2533
            ],
            [
              628,
              2532
            ],
            [
              627,
              2531
            ],
            [
              627,
              2531
            ],
            [
              625,
              2530
            ],
            [
              625,
              2530
            ],
            [
              624,
              2529
            ],
            [
              627,
              2524
            ],
            [
              627,
              2523
            ],
            [
              627,
              2523
            ],
            [
              625,
              2520
            ],
            [
              626,
              2519
            ],
            [
              624,
              2513
            ],
            [
              630,
              2508
            ],
            [
              630,
              2507
            ],
            [
              630,
              2507
            ],
            [
              630,
              2506
            ],
            [
              630,
              2505
            ],
            [
              629,
              2502
            ],
            [
              629,
              2501
            ],
            [
              624,
              2496
            ],
            [
              613,
              2498
            ],
            [
              597,
              2497
            ],
            [
              576,
              2491
            ],
            [
              536,
              2553
            ],
            [
              515,
              2539
            ],
            [
              444,
              2578
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Europe",
        "fullname": "Serbia",
        "admin": "Republic of Serbia",
        "woe_id": -90,
        "id": "RS",
        "abbrev": "Serb.",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "SRB",
        "name": "RS",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -3658,
              281
            ],
            [
              -3659,
              280
            ],
            [
              -3664,
              277
            ],
            [
              -3668,
              271
            ],
            [
              -3673,
              265
            ],
            [
              -3674,
              263
            ],
            [
              -3674,
              262
            ],
            [
              -3674,
              261
            ],
            [
              -3674,
              259
            ],
            [
              -3674,
              258
            ],
            [
              -3676,
              257
            ],
            [
              -3676,
              256
            ],
            [
              -3676,
              256
            ],
            [
              -3675,
              255
            ],
            [
              -3675,
              248
            ],
            [
              -3675,
              247
            ],
            [
              -3674,
              247
            ],
            [
              -3673,
              246
            ],
            [
              -3673,
              245
            ],
            [
              -3673,
              244
            ],
            [
              -3673,
              242
            ],
            [
              -3672,
              240
            ],
            [
              -3672,
              240
            ],
            [
              -3672,
              237
            ],
            [
              -3673,
              236
            ],
            [
              -3674,
              234
            ],
            [
              -3674,
              233
            ],
            [
              -3672,
              218
            ],
            [
              -3668,
              207
            ],
            [
              -3659,
              195
            ],
            [
              -3648,
              184
            ],
            [
              -3649,
              175
            ],
            [
              -3650,
              173
            ],
            [
              -3652,
              171
            ],
            [
              -3652,
              170
            ],
            [
              -3652,
              169
            ],
            [
              -3653,
              168
            ],
            [
              -3653,
              167
            ],
            [
              -3659,
              160
            ],
            [
              -3661,
              137
            ],
            [
              -3665,
              132
            ],
            [
              -3667,
              128
            ],
            [
              -3669,
              122
            ],
            [
              -3678,
              113
            ],
            [
              -3678,
              113
            ],
            [
              -3680,
              113
            ],
            [
              -3681,
              113
            ],
            [
              -3682,
              113
            ],
            [
              -3683,
              112
            ],
            [
              -3685,
              112
            ],
            [
              -3688,
              112
            ],
            [
              -3688,
              113
            ],
            [
              -3688,
              113
            ],
            [
              -3688,
              114
            ],
            [
              -3689,
              115
            ],
            [
              -3688,
              119
            ],
            [
              -3690,
              120
            ],
            [
              -3698,
              119
            ],
            [
              -3706,
              127
            ],
            [
              -3745,
              116
            ],
            [
              -3760,
              123
            ],
            [
              -3761,
              116
            ],
            [
              -3767,
              113
            ],
            [
              -3769,
              109
            ],
            [
              -3769,
              108
            ],
            [
              -3768,
              108
            ],
            [
              -3762,
              103
            ],
            [
              -3756,
              97
            ],
            [
              -3756,
              97
            ],
            [
              -3756,
              96
            ],
            [
              -3756,
              90
            ],
            [
              -3756,
              88
            ],
            [
              -3758,
              86
            ],
            [
              -3762,
              85
            ],
            [
              -3781,
              90
            ],
            [
              -3784,
              89
            ],
            [
              -3785,
              90
            ],
            [
              -3787,
              91
            ],
            [
              -3788,
              91
            ],
            [
              -3788,
              92
            ],
            [
              -3793,
              95
            ],
            [
              -3800,
              96
            ],
            [
              -3826,
              140
            ],
            [
              -3832,
              157
            ],
            [
              -3832,
              161
            ],
            [
              -3833,
              161
            ],
            [
              -3832,
              162
            ],
            [
              -3832,
              163
            ],
            [
              -3832,
              164
            ],
            [
              -3832,
              164
            ],
            [
              -3833,
              165
            ],
            [
              -3833,
              165
            ],
            [
              -3832,
              166
            ],
            [
              -3832,
              166
            ],
            [
              -3832,
              168
            ],
            [
              -3832,
              169
            ],
            [
              -3834,
              172
            ],
            [
              -3837,
              170
            ],
            [
              -3848,
              169
            ],
            [
              -3863,
              187
            ],
            [
              -3874,
              205
            ],
            [
              -3876,
              214
            ],
            [
              -3876,
              215
            ],
            [
              -3875,
              217
            ],
            [
              -3870,
              222
            ],
            [
              -3863,
              242
            ],
            [
              -3857,
              261
            ],
            [
              -3844,
              261
            ],
            [
              -3838,
              262
            ],
            [
              -3834,
              263
            ],
            [
              -3833,
              263
            ],
            [
              -3832,
              263
            ],
            [
              -3834,
              265
            ],
            [
              -3834,
              266
            ],
            [
              -3834,
              267
            ],
            [
              -3834,
              267
            ],
            [
              -3833,
              269
            ],
            [
              -3833,
              271
            ],
            [
              -3829,
              270
            ],
            [
              -3828,
              271
            ],
            [
              -3827,
              271
            ],
            [
              -3827,
              272
            ],
            [
              -3827,
              273
            ],
            [
              -3830,
              277
            ],
            [
              -3833,
              275
            ],
            [
              -3833,
              279
            ],
            [
              -3834,
              279
            ],
            [
              -3835,
              279
            ],
            [
              -3835,
              280
            ],
            [
              -3835,
              280
            ],
            [
              -3835,
              281
            ],
            [
              -3831,
              284
            ],
            [
              -3831,
              288
            ],
            [
              -3826,
              293
            ],
            [
              -3823,
              308
            ],
            [
              -3814,
              319
            ],
            [
              -3692,
              317
            ],
            [
              -3651,
              309
            ],
            [
              -3648,
              304
            ],
            [
              -3653,
              289
            ],
            [
              -3655,
              287
            ],
            [
              -3656,
              285
            ],
            [
              -3658,
              281
            ],
            [
              -3658,
              281
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Americas",
        "fullname": "Surifullname",
        "admin": "Surifullname",
        "woe_id": 23424913,
        "id": "SR",
        "abbrev": "Sur.",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "SUR",
        "name": "SR",
        "continent": "South America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              631,
              2964
            ],
            [
              630,
              2959
            ],
            [
              627,
              2958
            ],
            [
              626,
              2957
            ],
            [
              618,
              2938
            ],
            [
              609,
              2930
            ],
            [
              608,
              2928
            ],
            [
              608,
              2927
            ],
            [
              609,
              2924
            ],
            [
              608,
              2922
            ],
            [
              608,
              2917
            ],
            [
              516,
              2925
            ],
            [
              482,
              2897
            ],
            [
              367,
              2870
            ],
            [
              329,
              2888
            ],
            [
              318,
              2931
            ],
            [
              369,
              2954
            ],
            [
              423,
              2996
            ],
            [
              590,
              2986
            ],
            [
              631,
              2964
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Europe",
        "fullname": "Slovakia",
        "admin": "Slovakia",
        "woe_id": 23424877,
        "id": "SK",
        "abbrev": "Svk.",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "SVK",
        "name": "SK",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              294,
              2782
            ],
            [
              284,
              2785
            ],
            [
              246,
              2762
            ],
            [
              243,
              2759
            ],
            [
              242,
              2757
            ],
            [
              242,
              2755
            ],
            [
              243,
              2753
            ],
            [
              244,
              2752
            ],
            [
              247,
              2751
            ],
            [
              247,
              2751
            ],
            [
              248,
              2750
            ],
            [
              248,
              2750
            ],
            [
              248,
              2749
            ],
            [
              247,
              2748
            ],
            [
              247,
              2747
            ],
            [
              247,
              2747
            ],
            [
              247,
              2745
            ],
            [
              247,
              2743
            ],
            [
              247,
              2742
            ],
            [
              246,
              2735
            ],
            [
              234,
              2734
            ],
            [
              233,
              2732
            ],
            [
              228,
              2730
            ],
            [
              226,
              2729
            ],
            [
              223,
              2727
            ],
            [
              217,
              2708
            ],
            [
              191,
              2715
            ],
            [
              147,
              2707
            ],
            [
              136,
              2783
            ],
            [
              181,
              2776
            ],
            [
              270,
              2807
            ],
            [
              294,
              2782
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Europe",
        "fullname": "Slovenia",
        "admin": "Slovenia",
        "woe_id": 23424945,
        "id": "SI",
        "abbrev": "Slo.",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "SVN",
        "name": "SI",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              721,
              4315
            ],
            [
              638,
              4310
            ],
            [
              597,
              4286
            ],
            [
              552,
              4211
            ],
            [
              533,
              4140
            ],
            [
              411,
              4078
            ],
            [
              351,
              3996
            ],
            [
              330,
              3947
            ],
            [
              330,
              3887
            ],
            [
              430,
              3799
            ],
            [
              373,
              3717
            ],
            [
              309,
              3693
            ],
            [
              303,
              3601
            ],
            [
              257,
              3491
            ],
            [
              192,
              3496
            ],
            [
              163,
              3437
            ],
            [
              97,
              3437
            ],
            [
              75,
              3549
            ],
            [
              25,
              3621
            ],
            [
              0,
              3683
            ],
            [
              10,
              3724
            ],
            [
              38,
              3799
            ],
            [
              70,
              3817
            ],
            [
              90,
              3922
            ],
            [
              50,
              3953
            ],
            [
              59,
              3996
            ],
            [
              41,
              4088
            ],
            [
              79,
              4147
            ],
            [
              150,
              4152
            ],
            [
              133,
              4203
            ],
            [
              182,
              4269
            ],
            [
              183,
              4343
            ],
            [
              288,
              4429
            ],
            [
              272,
              4464
            ],
            [
              339,
              4529
            ],
            [
              371,
              4516
            ],
            [
              386,
              4570
            ],
            [
              485,
              4552
            ],
            [
              523,
              4619
            ],
            [
              604,
              4564
            ],
            [
              646,
              4556
            ],
            [
              688,
              4516
            ],
            [
              712,
              4407
            ],
            [
              692,
              4372
            ],
            [
              721,
              4315
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Europe",
        "fullname": "Sweden",
        "admin": "Sweden",
        "woe_id": 23424954,
        "id": "SE",
        "abbrev": "Swe.",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "SWE",
        "name": "SE",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1152,
              -1505
            ],
            [
              1163,
              -1508
            ],
            [
              1164,
              -1515
            ],
            [
              1164,
              -1516
            ],
            [
              1162,
              -1523
            ],
            [
              1162,
              -1529
            ],
            [
              1163,
              -1531
            ],
            [
              1166,
              -1541
            ],
            [
              1166,
              -1556
            ],
            [
              1159,
              -1556
            ],
            [
              1158,
              -1561
            ],
            [
              1156,
              -1572
            ],
            [
              1157,
              -1581
            ],
            [
              1158,
              -1583
            ],
            [
              1158,
              -1584
            ],
            [
              1158,
              -1585
            ],
            [
              1153,
              -1585
            ],
            [
              1133,
              -1585
            ],
            [
              1112,
              -1578
            ],
            [
              1092,
              -1549
            ],
            [
              1091,
              -1534
            ],
            [
              1093,
              -1530
            ],
            [
              1098,
              -1523
            ],
            [
              1098,
              -1523
            ],
            [
              1102,
              -1518
            ],
            [
              1106,
              -1512
            ],
            [
              1109,
              -1505
            ],
            [
              1110,
              -1502
            ],
            [
              1110,
              -1500
            ],
            [
              1115,
              -1496
            ],
            [
              1121,
              -1491
            ],
            [
              1122,
              -1491
            ],
            [
              1124,
              -1490
            ],
            [
              1127,
              -1490
            ],
            [
              1133,
              -1494
            ],
            [
              1139,
              -1498
            ],
            [
              1150,
              -1505
            ],
            [
              1152,
              -1505
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Africa",
        "fullname": "Swaziland",
        "admin": "Swaziland",
        "woe_id": 23424993,
        "id": "SZ",
        "abbrev": "Swz.",
        "subregion": "Southern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "SWZ",
        "name": "SZ",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1739,
              2154
            ],
            [
              1686,
              2117
            ],
            [
              1674,
              2005
            ],
            [
              1645,
              1977
            ],
            [
              1538,
              1917
            ],
            [
              1429,
              1852
            ],
            [
              1404,
              1856
            ],
            [
              1393,
              1865
            ],
            [
              1392,
              1864
            ],
            [
              1391,
              1864
            ],
            [
              1391,
              1864
            ],
            [
              1389,
              1864
            ],
            [
              1388,
              1864
            ],
            [
              1387,
              1864
            ],
            [
              1387,
              1864
            ],
            [
              1387,
              1865
            ],
            [
              1384,
              1869
            ],
            [
              1384,
              1870
            ],
            [
              1377,
              1876
            ],
            [
              1370,
              1878
            ],
            [
              1377,
              1891
            ],
            [
              1372,
              1910
            ],
            [
              1372,
              1914
            ],
            [
              1373,
              1917
            ],
            [
              1373,
              1919
            ],
            [
              1373,
              1919
            ],
            [
              1373,
              1919
            ],
            [
              1376,
              1921
            ],
            [
              1377,
              1922
            ],
            [
              1379,
              1923
            ],
            [
              1379,
              1934
            ],
            [
              1380,
              1938
            ],
            [
              1380,
              1938
            ],
            [
              1381,
              1939
            ],
            [
              1383,
              1942
            ],
            [
              1386,
              1945
            ],
            [
              1391,
              1947
            ],
            [
              1392,
              1946
            ],
            [
              1392,
              1946
            ],
            [
              1394,
              1946
            ],
            [
              1395,
              1946
            ],
            [
              1395,
              1947
            ],
            [
              1396,
              1947
            ],
            [
              1397,
              1947
            ],
            [
              1400,
              1946
            ],
            [
              1402,
              1945
            ],
            [
              1401,
              1949
            ],
            [
              1399,
              1950
            ],
            [
              1398,
              1951
            ],
            [
              1399,
              1953
            ],
            [
              1400,
              1954
            ],
            [
              1401,
              1955
            ],
            [
              1403,
              1957
            ],
            [
              1405,
              1959
            ],
            [
              1410,
              1960
            ],
            [
              1410,
              1962
            ],
            [
              1412,
              1963
            ],
            [
              1414,
              1965
            ],
            [
              1415,
              1967
            ],
            [
              1417,
              1969
            ],
            [
              1416,
              1975
            ],
            [
              1413,
              1983
            ],
            [
              1407,
              1988
            ],
            [
              1402,
              1988
            ],
            [
              1402,
              1988
            ],
            [
              1401,
              1989
            ],
            [
              1402,
              1990
            ],
            [
              1405,
              1991
            ],
            [
              1401,
              1999
            ],
            [
              1400,
              2000
            ],
            [
              1399,
              1999
            ],
            [
              1399,
              1999
            ],
            [
              1399,
              1997
            ],
            [
              1399,
              1997
            ],
            [
              1398,
              1996
            ],
            [
              1398,
              1996
            ],
            [
              1385,
              1996
            ],
            [
              1381,
              1997
            ],
            [
              1378,
              2078
            ],
            [
              1416,
              2104
            ],
            [
              1420,
              2136
            ],
            [
              1464,
              2123
            ],
            [
              1506,
              2141
            ],
            [
              1594,
              2130
            ],
            [
              1646,
              2153
            ],
            [
              1739,
              2154
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Asia",
        "fullname": "Syria",
        "admin": "Syria",
        "woe_id": 23424956,
        "id": "SY",
        "abbrev": "Syria",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "SYR",
        "name": "SY",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              711,
              1085
            ],
            [
              711,
              868
            ],
            [
              689,
              869
            ],
            [
              674,
              866
            ],
            [
              666,
              867
            ],
            [
              663,
              867
            ],
            [
              662,
              867
            ],
            [
              652,
              859
            ],
            [
              654,
              850
            ],
            [
              655,
              846
            ],
            [
              654,
              839
            ],
            [
              654,
              838
            ],
            [
              651,
              833
            ],
            [
              642,
              825
            ],
            [
              638,
              808
            ],
            [
              626,
              805
            ],
            [
              605,
              757
            ],
            [
              615,
              732
            ],
            [
              597,
              716
            ],
            [
              592,
              707
            ],
            [
              591,
              703
            ],
            [
              590,
              701
            ],
            [
              590,
              701
            ],
            [
              590,
              700
            ],
            [
              592,
              698
            ],
            [
              594,
              694
            ],
            [
              597,
              692
            ],
            [
              612,
              698
            ],
            [
              634,
              655
            ],
            [
              636,
              628
            ],
            [
              649,
              595
            ],
            [
              585,
              579
            ],
            [
              581,
              556
            ],
            [
              513,
              494
            ],
            [
              438,
              487
            ],
            [
              410,
              433
            ],
            [
              359,
              429
            ],
            [
              308,
              405
            ],
            [
              236,
              403
            ],
            [
              219,
              457
            ],
            [
              150,
              522
            ],
            [
              163,
              542
            ],
            [
              239,
              547
            ],
            [
              213,
              588
            ],
            [
              217,
              629
            ],
            [
              200,
              691
            ],
            [
              177,
              717
            ],
            [
              157,
              717
            ],
            [
              131,
              753
            ],
            [
              131,
              755
            ],
            [
              130,
              758
            ],
            [
              130,
              760
            ],
            [
              128,
              765
            ],
            [
              124,
              784
            ],
            [
              122,
              791
            ],
            [
              174,
              869
            ],
            [
              235,
              936
            ],
            [
              250,
              1109
            ],
            [
              262,
              1136
            ],
            [
              219,
              1202
            ],
            [
              208,
              1290
            ],
            [
              264,
              1316
            ],
            [
              711,
              1085
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Chad",
        "admin": "Chad",
        "woe_id": 23424777,
        "id": "TD",
        "abbrev": "Chad",
        "subregion": "Middle Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "TCD",
        "name": "TD",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -579,
              599
            ],
            [
              -587,
              564
            ],
            [
              -555,
              543
            ],
            [
              -540,
              489
            ],
            [
              -539,
              330
            ],
            [
              -563,
              324
            ],
            [
              -601,
              365
            ],
            [
              -595,
              458
            ],
            [
              -609,
              560
            ],
            [
              -639,
              607
            ],
            [
              -579,
              599
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 6,
        "region_un": "Africa",
        "fullname": "Togo",
        "admin": "Togo",
        "woe_id": 23424965,
        "id": "TG",
        "abbrev": "Togo",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "TGO",
        "name": "TG",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4967,
              1133
            ],
            [
              4968,
              1129
            ],
            [
              4974,
              1135
            ],
            [
              4979,
              1138
            ],
            [
              4982,
              1136
            ],
            [
              4992,
              1118
            ],
            [
              4987,
              1088
            ],
            [
              5010,
              1092
            ],
            [
              5024,
              1089
            ],
            [
              5028,
              1083
            ],
            [
              5031,
              1038
            ],
            [
              5025,
              968
            ],
            [
              5050,
              991
            ],
            [
              5068,
              1001
            ],
            [
              5076,
              1010
            ],
            [
              5077,
              1011
            ],
            [
              5078,
              1011
            ],
            [
              5090,
              1002
            ],
            [
              5096,
              998
            ],
            [
              5098,
              997
            ],
            [
              5100,
              997
            ],
            [
              5105,
              997
            ],
            [
              5107,
              990
            ],
            [
              5144,
              1022
            ],
            [
              5146,
              1023
            ],
            [
              5151,
              1024
            ],
            [
              5151,
              1024
            ],
            [
              5185,
              1017
            ],
            [
              5212,
              978
            ],
            [
              5231,
              963
            ],
            [
              5227,
              914
            ],
            [
              5264,
              885
            ],
            [
              5266,
              870
            ],
            [
              5275,
              868
            ],
            [
              5277,
              866
            ],
            [
              5277,
              863
            ],
            [
              5275,
              856
            ],
            [
              5275,
              855
            ],
            [
              5275,
              853
            ],
            [
              5274,
              851
            ],
            [
              5272,
              850
            ],
            [
              5269,
              849
            ],
            [
              5268,
              848
            ],
            [
              5268,
              847
            ],
            [
              5268,
              846
            ],
            [
              5272,
              845
            ],
            [
              5272,
              842
            ],
            [
              5274,
              825
            ],
            [
              5268,
              814
            ],
            [
              5269,
              812
            ],
            [
              5269,
              807
            ],
            [
              5269,
              803
            ],
            [
              5264,
              794
            ],
            [
              5251,
              789
            ],
            [
              5134,
              786
            ],
            [
              5092,
              728
            ],
            [
              5124,
              636
            ],
            [
              5064,
              694
            ],
            [
              5008,
              696
            ],
            [
              5015,
              739
            ],
            [
              4975,
              740
            ],
            [
              4953,
              654
            ],
            [
              4919,
              573
            ],
            [
              4916,
              523
            ],
            [
              4953,
              503
            ],
            [
              4977,
              448
            ],
            [
              4972,
              403
            ],
            [
              5017,
              366
            ],
            [
              5049,
              365
            ],
            [
              5077,
              332
            ],
            [
              5023,
              298
            ],
            [
              5022,
              332
            ],
            [
              4969,
              343
            ],
            [
              4883,
              451
            ],
            [
              4868,
              498
            ],
            [
              4891,
              563
            ],
            [
              4892,
              582
            ],
            [
              4941,
              646
            ],
            [
              4917,
              698
            ],
            [
              4914,
              753
            ],
            [
              4859,
              834
            ],
            [
              4881,
              847
            ],
            [
              4885,
              910
            ],
            [
              4837,
              980
            ],
            [
              4841,
              1089
            ],
            [
              4906,
              1105
            ],
            [
              4958,
              1140
            ],
            [
              4961,
              1137
            ],
            [
              4963,
              1136
            ],
            [
              4965,
              1134
            ],
            [
              4967,
              1133
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Asia",
        "fullname": "Thailand",
        "admin": "Thailand",
        "woe_id": 23424960,
        "id": "TH",
        "abbrev": "Thai.",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "THA",
        "name": "TH",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3338,
              2357
            ],
            [
              3282,
              2355
            ],
            [
              3260,
              2348
            ],
            [
              3246,
              2341
            ],
            [
              3246,
              2341
            ],
            [
              3246,
              2340
            ],
            [
              3245,
              2340
            ],
            [
              3244,
              2337
            ],
            [
              3242,
              2330
            ],
            [
              3241,
              2325
            ],
            [
              3244,
              2319
            ],
            [
              3244,
              2311
            ],
            [
              3245,
              2310
            ],
            [
              3247,
              2311
            ],
            [
              3248,
              2312
            ],
            [
              3249,
              2312
            ],
            [
              3250,
              2311
            ],
            [
              3251,
              2310
            ],
            [
              3256,
              2311
            ],
            [
              3261,
              2314
            ],
            [
              3266,
              2314
            ],
            [
              3296,
              2310
            ],
            [
              3298,
              2310
            ],
            [
              3303,
              2314
            ],
            [
              3310,
              2315
            ],
            [
              3319,
              2313
            ],
            [
              3341,
              2303
            ],
            [
              3366,
              2316
            ],
            [
              3381,
              2305
            ],
            [
              3383,
              2294
            ],
            [
              3385,
              2294
            ],
            [
              3387,
              2295
            ],
            [
              3399,
              2300
            ],
            [
              3414,
              2298
            ],
            [
              3432,
              2302
            ],
            [
              3443,
              2299
            ],
            [
              3452,
              2299
            ],
            [
              3455,
              2300
            ],
            [
              3456,
              2300
            ],
            [
              3457,
              2300
            ],
            [
              3457,
              2300
            ],
            [
              3459,
              2299
            ],
            [
              3461,
              2299
            ],
            [
              3472,
              2305
            ],
            [
              3487,
              2305
            ],
            [
              3487,
              2304
            ],
            [
              3488,
              2300
            ],
            [
              3488,
              2298
            ],
            [
              3486,
              2290
            ],
            [
              3497,
              2276
            ],
            [
              3490,
              2267
            ],
            [
              3504,
              2246
            ],
            [
              3551,
              2244
            ],
            [
              3573,
              2173
            ],
            [
              3558,
              2162
            ],
            [
              3467,
              2176
            ],
            [
              3410,
              2145
            ],
            [
              3387,
              2128
            ],
            [
              3382,
              2126
            ],
            [
              3377,
              2127
            ],
            [
              3374,
              2128
            ],
            [
              3366,
              2143
            ],
            [
              3366,
              2147
            ],
            [
              3366,
              2148
            ],
            [
              3365,
              2150
            ],
            [
              3364,
              2151
            ],
            [
              3368,
              2183
            ],
            [
              3373,
              2199
            ],
            [
              3373,
              2205
            ],
            [
              3372,
              2207
            ],
            [
              3370,
              2208
            ],
            [
              3368,
              2208
            ],
            [
              3361,
              2206
            ],
            [
              3360,
              2205
            ],
            [
              3359,
              2204
            ],
            [
              3354,
              2208
            ],
            [
              3355,
              2211
            ],
            [
              3359,
              2218
            ],
            [
              3360,
              2225
            ],
            [
              3359,
              2229
            ],
            [
              3339,
              2242
            ],
            [
              3327,
              2240
            ],
            [
              3322,
              2238
            ],
            [
              3321,
              2237
            ],
            [
              3321,
              2237
            ],
            [
              3322,
              2236
            ],
            [
              3322,
              2235
            ],
            [
              3317,
              2233
            ],
            [
              3311,
              2220
            ],
            [
              3310,
              2219
            ],
            [
              3310,
              2218
            ],
            [
              3307,
              2217
            ],
            [
              3302,
              2212
            ],
            [
              3298,
              2209
            ],
            [
              3293,
              2204
            ],
            [
              3173,
              2142
            ],
            [
              3160,
              2159
            ],
            [
              3163,
              2181
            ],
            [
              3192,
              2222
            ],
            [
              3159,
              2317
            ],
            [
              3201,
              2312
            ],
            [
              3253,
              2395
            ],
            [
              3309,
              2409
            ],
            [
              3338,
              2357
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Asia",
        "fullname": "Tajikistan",
        "admin": "Tajikistan",
        "woe_id": 23424961,
        "id": "TJ",
        "abbrev": "Tjk.",
        "subregion": "Central Asia",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "TJK",
        "name": "TJ",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3090,
              2170
            ],
            [
              3089,
              2170
            ],
            [
              3088,
              2169
            ],
            [
              3087,
              2168
            ],
            [
              3086,
              2168
            ],
            [
              3082,
              2169
            ],
            [
              3078,
              2168
            ],
            [
              3077,
              2168
            ],
            [
              3042,
              2180
            ],
            [
              3039,
              2174
            ],
            [
              3040,
              2172
            ],
            [
              3040,
              2170
            ],
            [
              3039,
              2169
            ],
            [
              3039,
              2168
            ],
            [
              3037,
              2167
            ],
            [
              3035,
              2163
            ],
            [
              3033,
              2162
            ],
            [
              3032,
              2162
            ],
            [
              3008,
              2162
            ],
            [
              2991,
              2153
            ],
            [
              2983,
              2112
            ],
            [
              2982,
              2110
            ],
            [
              2981,
              2108
            ],
            [
              2977,
              2101
            ],
            [
              2975,
              2100
            ],
            [
              2974,
              2099
            ],
            [
              2972,
              2098
            ],
            [
              2968,
              2097
            ],
            [
              2966,
              2097
            ],
            [
              2966,
              2096
            ],
            [
              2965,
              2096
            ],
            [
              2964,
              2093
            ],
            [
              2964,
              2093
            ],
            [
              2963,
              2093
            ],
            [
              2962,
              2093
            ],
            [
              2960,
              2093
            ],
            [
              2956,
              2092
            ],
            [
              2952,
              2090
            ],
            [
              2951,
              2088
            ],
            [
              2951,
              2083
            ],
            [
              2936,
              2081
            ],
            [
              2912,
              2074
            ],
            [
              2872,
              2034
            ],
            [
              2839,
              2046
            ],
            [
              2811,
              2048
            ],
            [
              2796,
              2059
            ],
            [
              2785,
              2124
            ],
            [
              2744,
              2124
            ],
            [
              2726,
              2148
            ],
            [
              2661,
              2190
            ],
            [
              2577,
              2209
            ],
            [
              2559,
              2223
            ],
            [
              2469,
              2216
            ],
            [
              2433,
              2180
            ],
            [
              2385,
              2169
            ],
            [
              2380,
              2245
            ],
            [
              2340,
              2393
            ],
            [
              2399,
              2390
            ],
            [
              2384,
              2480
            ],
            [
              2343,
              2481
            ],
            [
              2302,
              2457
            ],
            [
              2332,
              2483
            ],
            [
              2392,
              2497
            ],
            [
              2431,
              2477
            ],
            [
              2469,
              2427
            ],
            [
              2500,
              2429
            ],
            [
              2558,
              2424
            ],
            [
              2554,
              2465
            ],
            [
              2647,
              2527
            ],
            [
              2718,
              2495
            ],
            [
              2748,
              2423
            ],
            [
              2830,
              2416
            ],
            [
              2862,
              2342
            ],
            [
              2955,
              2274
            ],
            [
              3038,
              2227
            ],
            [
              3091,
              2213
            ],
            [
              3097,
              2208
            ],
            [
              3089,
              2197
            ],
            [
              3088,
              2180
            ],
            [
              3090,
              2170
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Asia",
        "fullname": "Turkmenistan",
        "admin": "Turkmenistan",
        "woe_id": 23424972,
        "id": "TM",
        "abbrev": "Turkm.",
        "subregion": "Central Asia",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "TKM",
        "name": "TM",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              6363,
              -550
            ],
            [
              6355,
              -520
            ],
            [
              6367,
              -502
            ],
            [
              6469,
              -484
            ],
            [
              6471,
              -504
            ],
            [
              6363,
              -550
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 5,
        "region_un": "Asia",
        "fullname": "Timor-Leste",
        "admin": "East Timor",
        "woe_id": 23424968,
        "id": "TL",
        "abbrev": "T.L.",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "TLS",
        "name": "TL",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -78,
              2167
            ],
            [
              -40,
              2132
            ],
            [
              -36,
              2074
            ],
            [
              -8,
              2032
            ],
            [
              -63,
              1977
            ],
            [
              -30,
              1938
            ],
            [
              -10,
              1922
            ],
            [
              -5,
              1907
            ],
            [
              14,
              1905
            ],
            [
              11,
              1872
            ],
            [
              15,
              1866
            ],
            [
              17,
              1863
            ],
            [
              17,
              1861
            ],
            [
              16,
              1859
            ],
            [
              15,
              1858
            ],
            [
              14,
              1857
            ],
            [
              10,
              1855
            ],
            [
              -4,
              1848
            ],
            [
              -22,
              1840
            ],
            [
              -37,
              1829
            ],
            [
              -38,
              1822
            ],
            [
              -40,
              1820
            ],
            [
              -42,
              1817
            ],
            [
              -64,
              1796
            ],
            [
              -60,
              1785
            ],
            [
              -57,
              1775
            ],
            [
              -55,
              1765
            ],
            [
              -55,
              1763
            ],
            [
              -56,
              1761
            ],
            [
              -60,
              1754
            ],
            [
              -78,
              1731
            ],
            [
              -85,
              1729
            ],
            [
              -97,
              1723
            ],
            [
              -99,
              1730
            ],
            [
              -100,
              1737
            ],
            [
              -102,
              1743
            ],
            [
              -110,
              1777
            ],
            [
              -112,
              1784
            ],
            [
              -118,
              1811
            ],
            [
              -120,
              1818
            ],
            [
              -121,
              1825
            ],
            [
              -123,
              1831
            ],
            [
              -124,
              1836
            ],
            [
              -196,
              1907
            ],
            [
              -199,
              1911
            ],
            [
              -199,
              1920
            ],
            [
              -199,
              1920
            ],
            [
              -199,
              1922
            ],
            [
              -211,
              1950
            ],
            [
              -209,
              1963
            ],
            [
              -208,
              1964
            ],
            [
              -206,
              1966
            ],
            [
              -203,
              1969
            ],
            [
              -195,
              1973
            ],
            [
              -192,
              1983
            ],
            [
              -169,
              1997
            ],
            [
              -169,
              2072
            ],
            [
              -168,
              2077
            ],
            [
              -167,
              2081
            ],
            [
              -167,
              2082
            ],
            [
              -167,
              2085
            ],
            [
              -166,
              2090
            ],
            [
              -165,
              2091
            ],
            [
              -165,
              2092
            ],
            [
              -165,
              2093
            ],
            [
              -165,
              2095
            ],
            [
              -165,
              2096
            ],
            [
              -165,
              2098
            ],
            [
              -163,
              2112
            ],
            [
              -173,
              2116
            ],
            [
              -157,
              2134
            ],
            [
              -153,
              2134
            ],
            [
              -147,
              2136
            ],
            [
              -146,
              2136
            ],
            [
              -146,
              2137
            ],
            [
              -148,
              2140
            ],
            [
              -149,
              2143
            ],
            [
              -115,
              2162
            ],
            [
              -78,
              2167
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Tunisia",
        "admin": "Tunisia",
        "woe_id": 23424967,
        "id": "TN",
        "abbrev": "Tun.",
        "subregion": "Northern Africa",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "TUN",
        "name": "TN",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                827,
                2390
              ],
              [
                860,
                2433
              ],
              [
                843,
                2455
              ],
              [
                845,
                2462
              ],
              [
                851,
                2462
              ],
              [
                853,
                2462
              ],
              [
                859,
                2472
              ],
              [
                895,
                2480
              ],
              [
                910,
                2468
              ],
              [
                914,
                2471
              ],
              [
                920,
                2472
              ],
              [
                924,
                2470
              ],
              [
                925,
                2472
              ],
              [
                925,
                2473
              ],
              [
                925,
                2474
              ],
              [
                937,
                2472
              ],
              [
                942,
                2448
              ],
              [
                949,
                2413
              ],
              [
                890,
                2383
              ],
              [
                827,
                2390
              ]
            ]
          ],
          [
            [
              [
                1692,
                2442
              ],
              [
                1750,
                2445
              ],
              [
                1799,
                2415
              ],
              [
                1812,
                2349
              ],
              [
                1874,
                2322
              ],
              [
                1876,
                2318
              ],
              [
                1832,
                2301
              ],
              [
                1846,
                2266
              ],
              [
                1843,
                2203
              ],
              [
                1873,
                2156
              ],
              [
                1762,
                2171
              ],
              [
                1739,
                2154
              ],
              [
                1646,
                2153
              ],
              [
                1594,
                2130
              ],
              [
                1506,
                2141
              ],
              [
                1464,
                2123
              ],
              [
                1420,
                2136
              ],
              [
                1416,
                2104
              ],
              [
                1378,
                2078
              ],
              [
                1371,
                2103
              ],
              [
                1314,
                2135
              ],
              [
                1246,
                2092
              ],
              [
                1204,
                2085
              ],
              [
                1161,
                2118
              ],
              [
                1086,
                2140
              ],
              [
                1072,
                2098
              ],
              [
                1010,
                2099
              ],
              [
                997,
                2125
              ],
              [
                894,
                2168
              ],
              [
                893,
                2210
              ],
              [
                849,
                2225
              ],
              [
                864,
                2298
              ],
              [
                833,
                2338
              ],
              [
                865,
                2368
              ],
              [
                905,
                2363
              ],
              [
                987,
                2373
              ],
              [
                1005,
                2424
              ],
              [
                1118,
                2415
              ],
              [
                1174,
                2456
              ],
              [
                1234,
                2476
              ],
              [
                1316,
                2472
              ],
              [
                1377,
                2455
              ],
              [
                1408,
                2424
              ],
              [
                1513,
                2402
              ],
              [
                1575,
                2415
              ],
              [
                1615,
                2402
              ],
              [
                1692,
                2442
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Asia",
        "fullname": "Turkey",
        "admin": "Turkey",
        "woe_id": 23424969,
        "id": "TR",
        "abbrev": "Tur.",
        "subregion": "Western Asia",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "TUR",
        "name": "TR",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              6171,
              1421
            ],
            [
              6185,
              1415
            ],
            [
              6158,
              1298
            ],
            [
              6114,
              1248
            ],
            [
              6083,
              1299
            ],
            [
              6090,
              1335
            ],
            [
              6139,
              1411
            ],
            [
              6171,
              1421
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Asia",
        "fullname": "Taiwan",
        "admin": "Taiwan",
        "woe_id": 23424971,
        "id": "TW",
        "abbrev": "Taiwan",
        "subregion": "Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "TWN",
        "name": "TW",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1266,
              -74
            ],
            [
              1475,
              -188
            ],
            [
              1482,
              -222
            ],
            [
              1562,
              -280
            ],
            [
              1538,
              -356
            ],
            [
              1577,
              -402
            ],
            [
              1567,
              -484
            ],
            [
              1594,
              -575
            ],
            [
              1631,
              -606
            ],
            [
              1607,
              -625
            ],
            [
              1523,
              -659
            ],
            [
              1488,
              -654
            ],
            [
              1463,
              -676
            ],
            [
              1325,
              -668
            ],
            [
              1305,
              -636
            ],
            [
              1298,
              -576
            ],
            [
              1272,
              -549
            ],
            [
              1211,
              -546
            ],
            [
              1101,
              -497
            ],
            [
              1090,
              -477
            ],
            [
              1068,
              -427
            ],
            [
              1033,
              -390
            ],
            [
              1025,
              -333
            ],
            [
              1010,
              -289
            ],
            [
              1014,
              -275
            ],
            [
              1015,
              -270
            ],
            [
              1015,
              -268
            ],
            [
              1014,
              -267
            ],
            [
              1027,
              -267
            ],
            [
              1033,
              -268
            ],
            [
              1033,
              -267
            ],
            [
              1034,
              -266
            ],
            [
              1034,
              -265
            ],
            [
              1037,
              -262
            ],
            [
              1048,
              -257
            ],
            [
              1051,
              -252
            ],
            [
              1054,
              -249
            ],
            [
              1056,
              -247
            ],
            [
              1057,
              -244
            ],
            [
              1058,
              -242
            ],
            [
              1059,
              -238
            ],
            [
              1074,
              -214
            ],
            [
              1094,
              -195
            ],
            [
              1088,
              -185
            ],
            [
              1071,
              -177
            ],
            [
              1094,
              -149
            ],
            [
              1094,
              -107
            ],
            [
              1079,
              -92
            ],
            [
              1076,
              -78
            ],
            [
              1081,
              -77
            ],
            [
              1082,
              -77
            ],
            [
              1083,
              -78
            ],
            [
              1094,
              -74
            ],
            [
              1266,
              -74
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Tanzania",
        "admin": "United Republic of Tanzania",
        "woe_id": 23424973,
        "id": "TZ",
        "abbrev": "Tanz.",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "TZA",
        "name": "TZ",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1074,
              -78
            ],
            [
              1056,
              -93
            ],
            [
              1039,
              -92
            ],
            [
              1029,
              -95
            ],
            [
              1024,
              -96
            ],
            [
              1025,
              -92
            ],
            [
              1025,
              -91
            ],
            [
              1024,
              -89
            ],
            [
              1024,
              -89
            ],
            [
              1024,
              -87
            ],
            [
              1024,
              -87
            ],
            [
              1024,
              -86
            ],
            [
              1023,
              -84
            ],
            [
              1023,
              -69
            ],
            [
              1026,
              -66
            ],
            [
              1025,
              -59
            ],
            [
              1027,
              -51
            ],
            [
              1028,
              -50
            ],
            [
              1028,
              -46
            ],
            [
              1027,
              -44
            ],
            [
              1027,
              -43
            ],
            [
              1032,
              -12
            ],
            [
              1044,
              26
            ],
            [
              1056,
              33
            ],
            [
              1056,
              33
            ],
            [
              1057,
              34
            ],
            [
              1060,
              39
            ],
            [
              1061,
              46
            ],
            [
              1063,
              47
            ],
            [
              1063,
              48
            ],
            [
              1064,
              48
            ],
            [
              1066,
              47
            ],
            [
              1068,
              49
            ],
            [
              1073,
              50
            ],
            [
              1073,
              50
            ],
            [
              1074,
              51
            ],
            [
              1079,
              57
            ],
            [
              1081,
              60
            ],
            [
              1086,
              66
            ],
            [
              1105,
              82
            ],
            [
              1109,
              86
            ],
            [
              1110,
              88
            ],
            [
              1114,
              93
            ],
            [
              1117,
              97
            ],
            [
              1119,
              100
            ],
            [
              1119,
              103
            ],
            [
              1119,
              103
            ],
            [
              1119,
              104
            ],
            [
              1114,
              111
            ],
            [
              1088,
              119
            ],
            [
              1089,
              152
            ],
            [
              1099,
              172
            ],
            [
              1098,
              176
            ],
            [
              1099,
              178
            ],
            [
              1098,
              179
            ],
            [
              1097,
              179
            ],
            [
              1096,
              178
            ],
            [
              1095,
              177
            ],
            [
              1095,
              178
            ],
            [
              1096,
              181
            ],
            [
              1101,
              188
            ],
            [
              1108,
              191
            ],
            [
              1109,
              192
            ],
            [
              1112,
              194
            ],
            [
              1113,
              194
            ],
            [
              1118,
              194
            ],
            [
              1120,
              193
            ],
            [
              1132,
              187
            ],
            [
              1156,
              183
            ],
            [
              1161,
              183
            ],
            [
              1161,
              183
            ],
            [
              1165,
              179
            ],
            [
              1168,
              179
            ],
            [
              1169,
              179
            ],
            [
              1169,
              179
            ],
            [
              1169,
              179
            ],
            [
              1170,
              180
            ],
            [
              1170,
              181
            ],
            [
              1169,
              183
            ],
            [
              1169,
              184
            ],
            [
              1170,
              185
            ],
            [
              1180,
              191
            ],
            [
              1183,
              191
            ],
            [
              1202,
              193
            ],
            [
              1216,
              199
            ],
            [
              1223,
              193
            ],
            [
              1225,
              193
            ],
            [
              1226,
              192
            ],
            [
              1245,
              193
            ],
            [
              1249,
              197
            ],
            [
              1255,
              203
            ],
            [
              1266,
              214
            ],
            [
              1270,
              218
            ],
            [
              1272,
              217
            ],
            [
              1273,
              216
            ],
            [
              1274,
              215
            ],
            [
              1274,
              213
            ],
            [
              1274,
              213
            ],
            [
              1274,
              212
            ],
            [
              1275,
              211
            ],
            [
              1275,
              211
            ],
            [
              1275,
              206
            ],
            [
              1287,
              190
            ],
            [
              1296,
              160
            ],
            [
              1312,
              142
            ],
            [
              1317,
              127
            ],
            [
              1320,
              127
            ],
            [
              1321,
              123
            ],
            [
              1322,
              122
            ],
            [
              1323,
              121
            ],
            [
              1326,
              76
            ],
            [
              1316,
              51
            ],
            [
              1310,
              50
            ],
            [
              1304,
              47
            ],
            [
              1303,
              43
            ],
            [
              1300,
              44
            ],
            [
              1299,
              43
            ],
            [
              1298,
              42
            ],
            [
              1298,
              40
            ],
            [
              1297,
              39
            ],
            [
              1297,
              37
            ],
            [
              1297,
              36
            ],
            [
              1295,
              32
            ],
            [
              1294,
              30
            ],
            [
              1293,
              28
            ],
            [
              1292,
              27
            ],
            [
              1289,
              25
            ],
            [
              1288,
              25
            ],
            [
              1287,
              20
            ],
            [
              1281,
              17
            ],
            [
              1280,
              16
            ],
            [
              1279,
              14
            ],
            [
              1265,
              -12
            ],
            [
              1265,
              -13
            ],
            [
              1267,
              -19
            ],
            [
              1269,
              -24
            ],
            [
              1269,
              -27
            ],
            [
              1265,
              -55
            ],
            [
              1266,
              -63
            ],
            [
              1266,
              -74
            ],
            [
              1094,
              -74
            ],
            [
              1083,
              -78
            ],
            [
              1082,
              -77
            ],
            [
              1081,
              -77
            ],
            [
              1074,
              -78
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Uganda",
        "admin": "Uganda",
        "woe_id": 23424974,
        "id": "UG",
        "abbrev": "Uga.",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "UGA",
        "name": "UG",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1507,
              2824
            ],
            [
              1378,
              2793
            ],
            [
              1317,
              2759
            ],
            [
              1358,
              2686
            ],
            [
              1269,
              2636
            ],
            [
              1250,
              2673
            ],
            [
              1189,
              2710
            ],
            [
              1188,
              2752
            ],
            [
              1154,
              2793
            ],
            [
              1103,
              2789
            ],
            [
              1040,
              2724
            ],
            [
              976,
              2694
            ],
            [
              947,
              2710
            ],
            [
              1003,
              2772
            ],
            [
              1032,
              2779
            ],
            [
              1022,
              2832
            ],
            [
              981,
              2896
            ],
            [
              922,
              2920
            ],
            [
              871,
              2916
            ],
            [
              868,
              2914
            ],
            [
              863,
              2911
            ],
            [
              860,
              2909
            ],
            [
              859,
              2908
            ],
            [
              859,
              2907
            ],
            [
              859,
              2906
            ],
            [
              849,
              2904
            ],
            [
              846,
              2904
            ],
            [
              843,
              2903
            ],
            [
              842,
              2903
            ],
            [
              841,
              2903
            ],
            [
              834,
              2887
            ],
            [
              819,
              2886
            ],
            [
              814,
              2884
            ],
            [
              810,
              2883
            ],
            [
              783,
              2881
            ],
            [
              744,
              2884
            ],
            [
              657,
              2887
            ],
            [
              642,
              2896
            ],
            [
              634,
              2895
            ],
            [
              627,
              2905
            ],
            [
              627,
              2905
            ],
            [
              627,
              2905
            ],
            [
              627,
              2905
            ],
            [
              625,
              2905
            ],
            [
              620,
              2905
            ],
            [
              616,
              2917
            ],
            [
              612,
              2918
            ],
            [
              610,
              2917
            ],
            [
              609,
              2916
            ],
            [
              609,
              2916
            ],
            [
              608,
              2917
            ],
            [
              608,
              2922
            ],
            [
              608,
              2927
            ],
            [
              608,
              2928
            ],
            [
              609,
              2930
            ],
            [
              626,
              2957
            ],
            [
              627,
              2958
            ],
            [
              630,
              2959
            ],
            [
              631,
              2964
            ],
            [
              640,
              2971
            ],
            [
              662,
              3028
            ],
            [
              698,
              3060
            ],
            [
              711,
              3061
            ],
            [
              712,
              3063
            ],
            [
              713,
              3064
            ],
            [
              713,
              3065
            ],
            [
              713,
              3068
            ],
            [
              718,
              3071
            ],
            [
              716,
              3082
            ],
            [
              714,
              3085
            ],
            [
              713,
              3086
            ],
            [
              714,
              3087
            ],
            [
              714,
              3088
            ],
            [
              711,
              3088
            ],
            [
              705,
              3116
            ],
            [
              692,
              3130
            ],
            [
              695,
              3135
            ],
            [
              691,
              3141
            ],
            [
              691,
              3143
            ],
            [
              691,
              3144
            ],
            [
              691,
              3145
            ],
            [
              690,
              3148
            ],
            [
              690,
              3149
            ],
            [
              690,
              3149
            ],
            [
              690,
              3150
            ],
            [
              692,
              3151
            ],
            [
              697,
              3152
            ],
            [
              698,
              3152
            ],
            [
              706,
              3150
            ],
            [
              707,
              3149
            ],
            [
              709,
              3148
            ],
            [
              711,
              3148
            ],
            [
              734,
              3170
            ],
            [
              811,
              3173
            ],
            [
              955,
              3143
            ],
            [
              970,
              3146
            ],
            [
              984,
              3146
            ],
            [
              989,
              3146
            ],
            [
              994,
              3151
            ],
            [
              995,
              3151
            ],
            [
              996,
              3151
            ],
            [
              999,
              3151
            ],
            [
              1001,
              3149
            ],
            [
              1002,
              3147
            ],
            [
              1004,
              3144
            ],
            [
              1004,
              3143
            ],
            [
              1005,
              3141
            ],
            [
              1005,
              3138
            ],
            [
              1005,
              3138
            ],
            [
              1007,
              3137
            ],
            [
              1007,
              3135
            ],
            [
              1008,
              3134
            ],
            [
              1008,
              3132
            ],
            [
              1010,
              3132
            ],
            [
              1011,
              3132
            ],
            [
              1014,
              3134
            ],
            [
              1018,
              3133
            ],
            [
              1026,
              3141
            ],
            [
              1056,
              3140
            ],
            [
              1068,
              3127
            ],
            [
              1072,
              3126
            ],
            [
              1074,
              3124
            ],
            [
              1076,
              3124
            ],
            [
              1077,
              3123
            ],
            [
              1078,
              3122
            ],
            [
              1079,
              3122
            ],
            [
              1079,
              3123
            ],
            [
              1079,
              3123
            ],
            [
              1080,
              3127
            ],
            [
              1082,
              3128
            ],
            [
              1083,
              3129
            ],
            [
              1083,
              3132
            ],
            [
              1083,
              3133
            ],
            [
              1083,
              3135
            ],
            [
              1080,
              3145
            ],
            [
              1077,
              3149
            ],
            [
              1101,
              3184
            ],
            [
              1118,
              3182
            ],
            [
              1121,
              3186
            ],
            [
              1125,
              3187
            ],
            [
              1130,
              3188
            ],
            [
              1140,
              3186
            ],
            [
              1146,
              3186
            ],
            [
              1189,
              3202
            ],
            [
              1260,
              3205
            ],
            [
              1294,
              3123
            ],
            [
              1333,
              3118
            ],
            [
              1366,
              3057
            ],
            [
              1421,
              3051
            ],
            [
              1463,
              3063
            ],
            [
              1561,
              3021
            ],
            [
              1616,
              2983
            ],
            [
              1590,
              2941
            ],
            [
              1593,
              2876
            ],
            [
              1544,
              2878
            ],
            [
              1507,
              2824
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Europe",
        "fullname": "Ukraine",
        "admin": "Ukraine",
        "woe_id": 23424976,
        "id": "UA",
        "abbrev": "Ukr.",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "UKR",
        "name": "UA",
        "continent": "Europe"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -3811,
              -1755
            ],
            [
              -3807,
              -1754
            ],
            [
              -3805,
              -1756
            ],
            [
              -3803,
              -1758
            ],
            [
              -3798,
              -1761
            ],
            [
              -3774,
              -1783
            ],
            [
              -3771,
              -1786
            ],
            [
              -3770,
              -1788
            ],
            [
              -3769,
              -1788
            ],
            [
              -3768,
              -1789
            ],
            [
              -3768,
              -1790
            ],
            [
              -3766,
              -1793
            ],
            [
              -3765,
              -1794
            ],
            [
              -3761,
              -1797
            ],
            [
              -3682,
              -1839
            ],
            [
              -3656,
              -1866
            ],
            [
              -3636,
              -1875
            ],
            [
              -3630,
              -1886
            ],
            [
              -3629,
              -1890
            ],
            [
              -3629,
              -1893
            ],
            [
              -3616,
              -1907
            ],
            [
              -3608,
              -1909
            ],
            [
              -3599,
              -1916
            ],
            [
              -3600,
              -1919
            ],
            [
              -3611,
              -1932
            ],
            [
              -3621,
              -1940
            ],
            [
              -3621,
              -1940
            ],
            [
              -3621,
              -1940
            ],
            [
              -3623,
              -1945
            ],
            [
              -3623,
              -1949
            ],
            [
              -3622,
              -1959
            ],
            [
              -3623,
              -1975
            ],
            [
              -3616,
              -1981
            ],
            [
              -3636,
              -2021
            ],
            [
              -3701,
              -2058
            ],
            [
              -3764,
              -2054
            ],
            [
              -3823,
              -2026
            ],
            [
              -3862,
              -2027
            ],
            [
              -3894,
              -1993
            ],
            [
              -3875,
              -1928
            ],
            [
              -3884,
              -1899
            ],
            [
              -3882,
              -1862
            ],
            [
              -3862,
              -1804
            ],
            [
              -3864,
              -1778
            ],
            [
              -3846,
              -1765
            ],
            [
              -3836,
              -1765
            ],
            [
              -3833,
              -1766
            ],
            [
              -3832,
              -1765
            ],
            [
              -3830,
              -1766
            ],
            [
              -3828,
              -1766
            ],
            [
              -3822,
              -1756
            ],
            [
              -3811,
              -1755
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 4,
        "region_un": "Americas",
        "fullname": "Uruguay",
        "admin": "Uruguay",
        "woe_id": 23424979,
        "id": "UY",
        "abbrev": "Ury.",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "URY",
        "name": "UY",
        "continent": "South America"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -9330,
                1122
              ],
              [
                -9285,
                1084
              ],
              [
                -9345,
                1058
              ],
              [
                -9355,
                1099
              ],
              [
                -9330,
                1122
              ]
            ]
          ],
          [
            [
              [
                -5950,
                2986
              ],
              [
                -5940,
                2985
              ],
              [
                -5933,
                2982
              ],
              [
                -5932,
                2982
              ],
              [
                -5931,
                2980
              ],
              [
                -5925,
                2953
              ],
              [
                -5925,
                2945
              ],
              [
                -5924,
                2944
              ],
              [
                -5924,
                2943
              ],
              [
                -5918,
                2940
              ],
              [
                -5898,
                2934
              ],
              [
                -5878,
                2933
              ],
              [
                -5874,
                2927
              ],
              [
                -5872,
                2925
              ],
              [
                -5844,
                2933
              ],
              [
                -5810,
                2926
              ],
              [
                -5741,
                2890
              ],
              [
                -5671,
                2896
              ],
              [
                -5625,
                2887
              ],
              [
                -5569,
                2909
              ],
              [
                -5375,
                2810
              ],
              [
                -5359,
                2780
              ],
              [
                -5343,
                2782
              ],
              [
                -5337,
                2785
              ],
              [
                -5334,
                2785
              ],
              [
                -5332,
                2784
              ],
              [
                -5332,
                2771
              ],
              [
                -5331,
                2761
              ],
              [
                -5327,
                2758
              ],
              [
                -5323,
                2751
              ],
              [
                -5303,
                2734
              ],
              [
                -5247,
                2704
              ],
              [
                -5245,
                2701
              ],
              [
                -5244,
                2698
              ],
              [
                -5222,
                2583
              ],
              [
                -5243,
                2518
              ],
              [
                -5277,
                2490
              ],
              [
                -5238,
                2453
              ],
              [
                -5174,
                2488
              ],
              [
                -5116,
                2499
              ],
              [
                -5048,
                2528
              ],
              [
                -5056,
                2573
              ],
              [
                -5029,
                2584
              ],
              [
                -4926,
                2584
              ],
              [
                -4904,
                2615
              ],
              [
                -4838,
                2669
              ],
              [
                -4806,
                2678
              ],
              [
                -4627,
                2679
              ],
              [
                -4594,
                2695
              ],
              [
                -4558,
                2742
              ],
              [
                -4544,
                2796
              ],
              [
                -4502,
                2848
              ],
              [
                -4442,
                2841
              ],
              [
                -4429,
                2831
              ],
              [
                -4421,
                2820
              ],
              [
                -4419,
                2744
              ],
              [
                -4420,
                2726
              ],
              [
                -4401,
                2720
              ],
              [
                -4400,
                2705
              ],
              [
                -4401,
                2700
              ],
              [
                -4402,
                2697
              ],
              [
                -4402,
                2696
              ],
              [
                -4374,
                2665
              ],
              [
                -4502,
                2605
              ],
              [
                -4557,
                2589
              ],
              [
                -4589,
                2533
              ],
              [
                -4579,
                2455
              ],
              [
                -4627,
                2432
              ],
              [
                -4699,
                2426
              ],
              [
                -4746,
                2408
              ],
              [
                -4778,
                2325
              ],
              [
                -4817,
                2286
              ],
              [
                -4850,
                2200
              ],
              [
                -4893,
                2242
              ],
              [
                -4901,
                2169
              ],
              [
                -4878,
                2141
              ],
              [
                -4863,
                2064
              ],
              [
                -4901,
                2049
              ],
              [
                -4929,
                2003
              ],
              [
                -4965,
                1986
              ],
              [
                -4992,
                1950
              ],
              [
                -5033,
                1943
              ],
              [
                -5059,
                1904
              ],
              [
                -5152,
                1836
              ],
              [
                -5186,
                1768
              ],
              [
                -5168,
                1684
              ],
              [
                -5105,
                1516
              ],
              [
                -5120,
                1430
              ],
              [
                -5163,
                1415
              ],
              [
                -5176,
                1455
              ],
              [
                -5203,
                1473
              ],
              [
                -5261,
                1577
              ],
              [
                -5250,
                1642
              ],
              [
                -5308,
                1704
              ],
              [
                -5341,
                1715
              ],
              [
                -5399,
                1690
              ],
              [
                -5465,
                1738
              ],
              [
                -5538,
                1724
              ],
              [
                -5602,
                1736
              ],
              [
                -5674,
                1653
              ],
              [
                -5730,
                1663
              ],
              [
                -5772,
                1699
              ],
              [
                -5789,
                1681
              ],
              [
                -5840,
                1695
              ],
              [
                -5935,
                1671
              ],
              [
                -5975,
                1634
              ],
              [
                -6060,
                1589
              ],
              [
                -6084,
                1527
              ],
              [
                -6073,
                1489
              ],
              [
                -6070,
                1475
              ],
              [
                -6067,
                1472
              ],
              [
                -6066,
                1467
              ],
              [
                -6061,
                1470
              ],
              [
                -6061,
                1466
              ],
              [
                -6064,
                1465
              ],
              [
                -6067,
                1465
              ],
              [
                -6073,
                1462
              ],
              [
                -6082,
                1462
              ],
              [
                -6121,
                1472
              ],
              [
                -6169,
                1492
              ],
              [
                -6171,
                1493
              ],
              [
                -6171,
                1494
              ],
              [
                -6170,
                1496
              ],
              [
                -6170,
                1498
              ],
              [
                -6171,
                1499
              ],
              [
                -6173,
                1499
              ],
              [
                -6174,
                1500
              ],
              [
                -6174,
                1501
              ],
              [
                -6175,
                1505
              ],
              [
                -6179,
                1517
              ],
              [
                -6190,
                1531
              ],
              [
                -6189,
                1540
              ],
              [
                -6192,
                1548
              ],
              [
                -6191,
                1553
              ],
              [
                -6193,
                1562
              ],
              [
                -6193,
                1563
              ],
              [
                -6195,
                1564
              ],
              [
                -6197,
                1565
              ],
              [
                -6198,
                1566
              ],
              [
                -6201,
                1565
              ],
              [
                -6204,
                1567
              ],
              [
                -6205,
                1569
              ],
              [
                -6206,
                1569
              ],
              [
                -6206,
                1570
              ],
              [
                -6206,
                1571
              ],
              [
                -6206,
                1571
              ],
              [
                -6208,
                1572
              ],
              [
                -6210,
                1574
              ],
              [
                -6211,
                1574
              ],
              [
                -6213,
                1576
              ],
              [
                -6213,
                1576
              ],
              [
                -6213,
                1578
              ],
              [
                -6217,
                1587
              ],
              [
                -6225,
                1597
              ],
              [
                -6236,
                1606
              ],
              [
                -6243,
                1623
              ],
              [
                -6255,
                1644
              ],
              [
                -6256,
                1647
              ],
              [
                -6257,
                1651
              ],
              [
                -6258,
                1654
              ],
              [
                -6258,
                1655
              ],
              [
                -6258,
                1655
              ],
              [
                -6260,
                1656
              ],
              [
                -6260,
                1656
              ],
              [
                -6263,
                1659
              ],
              [
                -6264,
                1660
              ],
              [
                -6263,
                1661
              ],
              [
                -6264,
                1663
              ],
              [
                -6265,
                1664
              ],
              [
                -6266,
                1665
              ],
              [
                -6269,
                1666
              ],
              [
                -6272,
                1669
              ],
              [
                -6277,
                1672
              ],
              [
                -6286,
                1679
              ],
              [
                -6287,
                1680
              ],
              [
                -6288,
                1680
              ],
              [
                -6289,
                1681
              ],
              [
                -6289,
                1682
              ],
              [
                -6289,
                1684
              ],
              [
                -6289,
                1685
              ],
              [
                -6290,
                1686
              ],
              [
                -6291,
                1686
              ],
              [
                -6294,
                1685
              ],
              [
                -6293,
                1688
              ],
              [
                -6295,
                1688
              ],
              [
                -6296,
                1688
              ],
              [
                -6296,
                1689
              ],
              [
                -6297,
                1689
              ],
              [
                -6298,
                1692
              ],
              [
                -6299,
                1695
              ],
              [
                -6299,
                1696
              ],
              [
                -6300,
                1695
              ],
              [
                -6300,
                1695
              ],
              [
                -6301,
                1695
              ],
              [
                -6303,
                1696
              ],
              [
                -6307,
                1697
              ],
              [
                -6312,
                1695
              ],
              [
                -6331,
                1698
              ],
              [
                -6350,
                1703
              ],
              [
                -6371,
                1693
              ],
              [
                -6397,
                1647
              ],
              [
                -6447,
                1669
              ],
              [
                -6459,
                1680
              ],
              [
                -6464,
                1681
              ],
              [
                -6468,
                1684
              ],
              [
                -6469,
                1684
              ],
              [
                -6469,
                1684
              ],
              [
                -6469,
                1685
              ],
              [
                -6469,
                1685
              ],
              [
                -6470,
                1685
              ],
              [
                -6470,
                1686
              ],
              [
                -6473,
                1688
              ],
              [
                -6474,
                1689
              ],
              [
                -6474,
                1691
              ],
              [
                -6476,
                1694
              ],
              [
                -6476,
                1696
              ],
              [
                -6482,
                1706
              ],
              [
                -6482,
                1721
              ],
              [
                -6492,
                1736
              ],
              [
                -6494,
                1743
              ],
              [
                -6501,
                1750
              ],
              [
                -6502,
                1751
              ],
              [
                -6503,
                1752
              ],
              [
                -6505,
                1753
              ],
              [
                -6513,
                1759
              ],
              [
                -6520,
                1761
              ],
              [
                -6585,
                1818
              ],
              [
                -6680,
                1818
              ],
              [
                -6680,
                1790
              ],
              [
                -6836,
                1790
              ],
              [
                -7049,
                1863
              ],
              [
                -7178,
                1865
              ],
              [
                -7178,
                1865
              ],
              [
                -7178,
                1867
              ],
              [
                -7179,
                1870
              ],
              [
                -7178,
                1870
              ],
              [
                -7178,
                1875
              ],
              [
                -7183,
                1877
              ],
              [
                -7189,
                1901
              ],
              [
                -7199,
                1915
              ],
              [
                -7257,
                1959
              ],
              [
                -7317,
                1983
              ],
              [
                -7365,
                1985
              ],
              [
                -7389,
                2035
              ],
              [
                -7445,
                2103
              ],
              [
                -7439,
                2134
              ],
              [
                -7473,
                2159
              ],
              [
                -7480,
                2199
              ],
              [
                -7543,
                2266
              ],
              [
                -7554,
                2331
              ],
              [
                -7583,
                2359
              ],
              [
                -7566,
                2437
              ],
              [
                -7593,
                2531
              ],
              [
                -7571,
                2601
              ],
              [
                -7557,
                2778
              ],
              [
                -7586,
                2869
              ],
              [
                -7565,
                2900
              ],
              [
                -7495,
                2894
              ],
              [
                -7493,
                2959
              ],
              [
                -5951,
                2959
              ],
              [
                -5950,
                2986
              ]
            ]
          ],
          [
            [
              [
                -8098,
                3512
              ],
              [
                -8039,
                3472
              ],
              [
                -8009,
                3427
              ],
              [
                -8018,
                3383
              ],
              [
                -8098,
                3512
              ]
            ]
          ],
          [
            [
              [
                -9184,
                3638
              ],
              [
                -9223,
                3552
              ],
              [
                -9269,
                3563
              ],
              [
                -9280,
                3598
              ],
              [
                -9224,
                3636
              ],
              [
                -9184,
                3638
              ]
            ]
          ],
          [
            [
              [
                -8217,
                3662
              ],
              [
                -8173,
                3646
              ],
              [
                -8169,
                3601
              ],
              [
                -8225,
                3595
              ],
              [
                -8255,
                3634
              ],
              [
                -8217,
                3662
              ]
            ]
          ],
          [
            [
              [
                -8173,
                3674
              ],
              [
                -8160,
                3657
              ],
              [
                -8132,
                3657
              ],
              [
                -8122,
                3639
              ],
              [
                -8114,
                3592
              ],
              [
                -8150,
                3566
              ],
              [
                -8147,
                3595
              ],
              [
                -8158,
                3611
              ],
              [
                -8173,
                3674
              ]
            ]
          ],
          [
            [
              [
                -9376,
                4855
              ],
              [
                -9301,
                4829
              ],
              [
                -9275,
                4797
              ],
              [
                -9158,
                4802
              ],
              [
                -9151,
                4772
              ],
              [
                -9097,
                4757
              ],
              [
                -8979,
                4764
              ],
              [
                -8855,
                4730
              ],
              [
                -8786,
                4731
              ],
              [
                -8752,
                4713
              ],
              [
                -8638,
                4725
              ],
              [
                -8584,
                4699
              ],
              [
                -8549,
                4690
              ],
              [
                -8536,
                4679
              ],
              [
                -8525,
                4681
              ],
              [
                -8514,
                4679
              ],
              [
                -8513,
                4679
              ],
              [
                -8513,
                4679
              ],
              [
                -8513,
                4679
              ],
              [
                -8513,
                4636
              ],
              [
                -8513,
                4607
              ],
              [
                -8513,
                4523
              ],
              [
                -8513,
                4495
              ],
              [
                -8513,
                3834
              ],
              [
                -8513,
                3833
              ],
              [
                -8512,
                3832
              ],
              [
                -8487,
                3825
              ],
              [
                -8458,
                3823
              ],
              [
                -8436,
                3835
              ],
              [
                -8323,
                3744
              ],
              [
                -8268,
                3737
              ],
              [
                -8248,
                3776
              ],
              [
                -8204,
                3790
              ],
              [
                -8134,
                3712
              ],
              [
                -8091,
                3681
              ],
              [
                -8011,
                3566
              ],
              [
                -8016,
                3552
              ],
              [
                -7998,
                3531
              ],
              [
                -7945,
                3514
              ],
              [
                -7920,
                3494
              ],
              [
                -7910,
                3491
              ],
              [
                -7904,
                3493
              ],
              [
                -7903,
                3492
              ],
              [
                -7902,
                3491
              ],
              [
                -7900,
                3488
              ],
              [
                -7899,
                3485
              ],
              [
                -7899,
                3481
              ],
              [
                -7899,
                3478
              ],
              [
                -7899,
                3477
              ],
              [
                -7899,
                3477
              ],
              [
                -7899,
                3477
              ],
              [
                -7906,
                3468
              ],
              [
                -7902,
                3425
              ],
              [
                -7943,
                3389
              ],
              [
                -7948,
                3462
              ],
              [
                -7995,
                3475
              ],
              [
                -8008,
                3512
              ],
              [
                -8087,
                3590
              ],
              [
                -8098,
                3621
              ],
              [
                -8114,
                3640
              ],
              [
                -8135,
                3661
              ],
              [
                -8149,
                3672
              ],
              [
                -8164,
                3676
              ],
              [
                -8181,
                3672
              ],
              [
                -8195,
                3664
              ],
              [
                -8297,
                3676
              ],
              [
                -8355,
                3727
              ],
              [
                -8418,
                3754
              ],
              [
                -8441,
                3801
              ],
              [
                -8475,
                3782
              ],
              [
                -8613,
                3817
              ],
              [
                -8676,
                3807
              ],
              [
                -8731,
                3855
              ],
              [
                -8777,
                3846
              ],
              [
                -8835,
                3887
              ],
              [
                -8883,
                3892
              ],
              [
                -8936,
                3804
              ],
              [
                -8979,
                3809
              ],
              [
                -9002,
                3779
              ],
              [
                -9113,
                3738
              ],
              [
                -9121,
                3790
              ],
              [
                -9053,
                3918
              ],
              [
                -9114,
                3884
              ],
              [
                -9169,
                3800
              ],
              [
                -9228,
                3769
              ],
              [
                -9253,
                3656
              ],
              [
                -9297,
                3646
              ],
              [
                -9493,
                3485
              ],
              [
                -9566,
                3473
              ],
              [
                -9596,
                3506
              ],
              [
                -9537,
                3537
              ],
              [
                -9447,
                3609
              ],
              [
                -9436,
                3681
              ],
              [
                -9504,
                3714
              ],
              [
                -9522,
                3678
              ],
              [
                -9554,
                3719
              ],
              [
                -9595,
                3731
              ],
              [
                -9668,
                3691
              ],
              [
                -9699,
                3809
              ],
              [
                -9804,
                3793
              ],
              [
                -9872,
                3856
              ],
              [
                -9830,
                3879
              ],
              [
                -9898,
                3930
              ],
              [
                -9916,
                3961
              ],
              [
                -9807,
                4086
              ],
              [
                -9748,
                4068
              ],
              [
                -9703,
                4106
              ],
              [
                -9641,
                4107
              ],
              [
                -9619,
                4128
              ],
              [
                -9641,
                4235
              ],
              [
                -9721,
                4196
              ],
              [
                -9751,
                4207
              ],
              [
                -9846,
                4191
              ],
              [
                -9921,
                4203
              ],
              [
                -9989,
                4277
              ],
              [
                -9999,
                4306
              ],
              [
                -9898,
                4361
              ],
              [
                -9798,
                4388
              ],
              [
                -9781,
                4339
              ],
              [
                -9682,
                4335
              ],
              [
                -9658,
                4381
              ],
              [
                -9678,
                4429
              ],
              [
                -9781,
                4434
              ],
              [
                -9807,
                4482
              ],
              [
                -9930,
                4560
              ],
              [
                -9923,
                4604
              ],
              [
                -9818,
                4608
              ],
              [
                -9752,
                4650
              ],
              [
                -9740,
                4693
              ],
              [
                -9682,
                4745
              ],
              [
                -9623,
                4748
              ],
              [
                -9528,
                4802
              ],
              [
                -9466,
                4797
              ],
              [
                -9376,
                4855
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Americas",
        "fullname": "United States",
        "admin": "United States of America",
        "woe_id": 23424977,
        "id": "US",
        "abbrev": "U.S.A.",
        "subregion": "Northern America",
        "region_wb": "North America",
        "iso_a3": "USA",
        "name": "US",
        "continent": "North America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3337,
              2491
            ],
            [
              3338,
              2492
            ],
            [
              3341,
              2494
            ],
            [
              3344,
              2493
            ],
            [
              3352,
              2488
            ],
            [
              3339,
              2477
            ],
            [
              3336,
              2477
            ],
            [
              3334,
              2477
            ],
            [
              3331,
              2476
            ],
            [
              3330,
              2470
            ],
            [
              3294,
              2446
            ],
            [
              3311,
              2434
            ],
            [
              3327,
              2423
            ],
            [
              3364,
              2418
            ],
            [
              3378,
              2437
            ],
            [
              3378,
              2436
            ],
            [
              3379,
              2437
            ],
            [
              3380,
              2437
            ],
            [
              3380,
              2436
            ],
            [
              3381,
              2438
            ],
            [
              3382,
              2437
            ],
            [
              3388,
              2428
            ],
            [
              3389,
              2421
            ],
            [
              3405,
              2419
            ],
            [
              3431,
              2400
            ],
            [
              3417,
              2367
            ],
            [
              3415,
              2367
            ],
            [
              3411,
              2370
            ],
            [
              3410,
              2372
            ],
            [
              3401,
              2370
            ],
            [
              3387,
              2358
            ],
            [
              3385,
              2356
            ],
            [
              3384,
              2354
            ],
            [
              3383,
              2353
            ],
            [
              3382,
              2353
            ],
            [
              3380,
              2351
            ],
            [
              3379,
              2351
            ],
            [
              3378,
              2351
            ],
            [
              3377,
              2352
            ],
            [
              3377,
              2355
            ],
            [
              3376,
              2358
            ],
            [
              3373,
              2355
            ],
            [
              3367,
              2356
            ],
            [
              3360,
              2361
            ],
            [
              3353,
              2363
            ],
            [
              3338,
              2357
            ],
            [
              3309,
              2409
            ],
            [
              3253,
              2395
            ],
            [
              3201,
              2312
            ],
            [
              3159,
              2317
            ],
            [
              3192,
              2222
            ],
            [
              3163,
              2181
            ],
            [
              3160,
              2159
            ],
            [
              3159,
              2161
            ],
            [
              3152,
              2163
            ],
            [
              3143,
              2165
            ],
            [
              3130,
              2159
            ],
            [
              3123,
              2165
            ],
            [
              3122,
              2169
            ],
            [
              3120,
              2170
            ],
            [
              3118,
              2171
            ],
            [
              3117,
              2171
            ],
            [
              3102,
              2170
            ],
            [
              3099,
              2169
            ],
            [
              3093,
              2170
            ],
            [
              3092,
              2171
            ],
            [
              3091,
              2171
            ],
            [
              3090,
              2170
            ],
            [
              3088,
              2180
            ],
            [
              3089,
              2197
            ],
            [
              3097,
              2208
            ],
            [
              3091,
              2213
            ],
            [
              3038,
              2227
            ],
            [
              2955,
              2274
            ],
            [
              2862,
              2342
            ],
            [
              2830,
              2416
            ],
            [
              2748,
              2423
            ],
            [
              2718,
              2495
            ],
            [
              2647,
              2527
            ],
            [
              2554,
              2465
            ],
            [
              2558,
              2424
            ],
            [
              2500,
              2429
            ],
            [
              2500,
              2678
            ],
            [
              2646,
              2716
            ],
            [
              2783,
              2636
            ],
            [
              2838,
              2574
            ],
            [
              2904,
              2584
            ],
            [
              2973,
              2579
            ],
            [
              2996,
              2590
            ],
            [
              3066,
              2541
            ],
            [
              3061,
              2474
            ],
            [
              3089,
              2474
            ],
            [
              3099,
              2421
            ],
            [
              3169,
              2421
            ],
            [
              3198,
              2380
            ],
            [
              3231,
              2433
            ],
            [
              3277,
              2454
            ],
            [
              3310,
              2481
            ],
            [
              3325,
              2487
            ],
            [
              3329,
              2487
            ],
            [
              3333,
              2488
            ],
            [
              3335,
              2489
            ],
            [
              3337,
              2491
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Asia",
        "fullname": "Uzbekistan",
        "admin": "Uzbekistan",
        "woe_id": 23424980,
        "id": "UZ",
        "abbrev": "Uzb.",
        "subregion": "Central Asia",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "UZB",
        "name": "UZ",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -3985,
              462
            ],
            [
              -3974,
              446
            ],
            [
              -4025,
              404
            ],
            [
              -4008,
              371
            ],
            [
              -4052,
              351
            ],
            [
              -4061,
              313
            ],
            [
              -4026,
              273
            ],
            [
              -4018,
              256
            ],
            [
              -4070,
              221
            ],
            [
              -4211,
              201
            ],
            [
              -4220,
              157
            ],
            [
              -4212,
              74
            ],
            [
              -4271,
              45
            ],
            [
              -4339,
              25
            ],
            [
              -4369,
              50
            ],
            [
              -4394,
              120
            ],
            [
              -4422,
              144
            ],
            [
              -4411,
              193
            ],
            [
              -4423,
              279
            ],
            [
              -4408,
              333
            ],
            [
              -4512,
              325
            ],
            [
              -4549,
              371
            ],
            [
              -4660,
              378
            ],
            [
              -4680,
              399
            ],
            [
              -4677,
              449
            ],
            [
              -4712,
              503
            ],
            [
              -4706,
              568
            ],
            [
              -4653,
              637
            ],
            [
              -4617,
              647
            ],
            [
              -4653,
              633
            ],
            [
              -4631,
              584
            ],
            [
              -4662,
              533
            ],
            [
              -4628,
              489
            ],
            [
              -4602,
              529
            ],
            [
              -4629,
              574
            ],
            [
              -4625,
              598
            ],
            [
              -4522,
              626
            ],
            [
              -4480,
              625
            ],
            [
              -4428,
              569
            ],
            [
              -4350,
              579
            ],
            [
              -4312,
              560
            ],
            [
              -4250,
              549
            ],
            [
              -4182,
              578
            ],
            [
              -4144,
              573
            ],
            [
              -4089,
              533
            ],
            [
              -4028,
              506
            ],
            [
              -4039,
              462
            ],
            [
              -3985,
              462
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Americas",
        "fullname": "Venezuela",
        "admin": "Venezuela",
        "woe_id": 23424982,
        "id": "VE",
        "abbrev": "Ven.",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "iso_a3": "VEN",
        "name": "VE",
        "continent": "South America"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              5408,
              1201
            ],
            [
              5375,
              1189
            ],
            [
              5298,
              1114
            ],
            [
              5277,
              1050
            ],
            [
              5319,
              1005
            ],
            [
              5340,
              960
            ],
            [
              5417,
              896
            ],
            [
              5461,
              825
            ],
            [
              5482,
              763
            ],
            [
              5478,
              641
            ],
            [
              5466,
              619
            ],
            [
              5371,
              568
            ],
            [
              5341,
              565
            ],
            [
              5329,
              523
            ],
            [
              5228,
              476
            ],
            [
              5235,
              534
            ],
            [
              5210,
              567
            ],
            [
              5283,
              600
            ],
            [
              5289,
              630
            ],
            [
              5319,
              654
            ],
            [
              5382,
              675
            ],
            [
              5386,
              743
            ],
            [
              5371,
              776
            ],
            [
              5374,
              794
            ],
            [
              5381,
              800
            ],
            [
              5383,
              808
            ],
            [
              5382,
              810
            ],
            [
              5380,
              815
            ],
            [
              5381,
              816
            ],
            [
              5383,
              818
            ],
            [
              5384,
              819
            ],
            [
              5385,
              829
            ],
            [
              5389,
              838
            ],
            [
              5390,
              841
            ],
            [
              5390,
              842
            ],
            [
              5390,
              843
            ],
            [
              5362,
              870
            ],
            [
              5363,
              875
            ],
            [
              5375,
              880
            ],
            [
              5369,
              887
            ],
            [
              5368,
              889
            ],
            [
              5366,
              891
            ],
            [
              5365,
              892
            ],
            [
              5362,
              893
            ],
            [
              5361,
              893
            ],
            [
              5359,
              898
            ],
            [
              5339,
              908
            ],
            [
              5320,
              940
            ],
            [
              5225,
              1044
            ],
            [
              5193,
              1067
            ],
            [
              5187,
              1070
            ],
            [
              5184,
              1069
            ],
            [
              5180,
              1071
            ],
            [
              5180,
              1072
            ],
            [
              5180,
              1072
            ],
            [
              5179,
              1073
            ],
            [
              5177,
              1074
            ],
            [
              5177,
              1074
            ],
            [
              5177,
              1075
            ],
            [
              5198,
              1097
            ],
            [
              5213,
              1091
            ],
            [
              5214,
              1091
            ],
            [
              5216,
              1091
            ],
            [
              5216,
              1092
            ],
            [
              5217,
              1092
            ],
            [
              5217,
              1092
            ],
            [
              5218,
              1092
            ],
            [
              5219,
              1092
            ],
            [
              5219,
              1092
            ],
            [
              5220,
              1092
            ],
            [
              5230,
              1102
            ],
            [
              5239,
              1120
            ],
            [
              5215,
              1146
            ],
            [
              5219,
              1153
            ],
            [
              5188,
              1170
            ],
            [
              5136,
              1165
            ],
            [
              5126,
              1216
            ],
            [
              5080,
              1254
            ],
            [
              5129,
              1257
            ],
            [
              5226,
              1280
            ],
            [
              5260,
              1308
            ],
            [
              5289,
              1284
            ],
            [
              5334,
              1282
            ],
            [
              5337,
              1231
            ],
            [
              5408,
              1201
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Asia",
        "fullname": "Vietnam",
        "admin": "Vietnam",
        "woe_id": 23424984,
        "id": "VN",
        "abbrev": "Viet.",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "iso_a3": "VNM",
        "name": "VN",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2339,
              921
            ],
            [
              2286,
              883
            ],
            [
              2291,
              862
            ],
            [
              2242,
              840
            ],
            [
              2142,
              812
            ],
            [
              2092,
              772
            ],
            [
              2055,
              773
            ],
            [
              1981,
              737
            ],
            [
              1924,
              732
            ],
            [
              1879,
              697
            ],
            [
              1841,
              691
            ],
            [
              1788,
              725
            ],
            [
              1758,
              868
            ],
            [
              1763,
              905
            ],
            [
              1784,
              960
            ],
            [
              1896,
              966
            ],
            [
              1997,
              939
            ],
            [
              2022,
              946
            ],
            [
              2063,
              1007
            ],
            [
              2117,
              1034
            ],
            [
              2277,
              1056
            ],
            [
              2339,
              921
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Asia",
        "fullname": "Yemen",
        "admin": "Yemen",
        "woe_id": 23425002,
        "id": "YE",
        "abbrev": "Yem.",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "iso_a3": "YEM",
        "name": "YE",
        "continent": "Asia"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1120,
              -1292
            ],
            [
              1133,
              -1352
            ],
            [
              1159,
              -1412
            ],
            [
              1157,
              -1503
            ],
            [
              1150,
              -1505
            ],
            [
              1139,
              -1498
            ],
            [
              1133,
              -1494
            ],
            [
              1127,
              -1490
            ],
            [
              1124,
              -1490
            ],
            [
              1122,
              -1491
            ],
            [
              1121,
              -1491
            ],
            [
              1115,
              -1496
            ],
            [
              1110,
              -1500
            ],
            [
              1110,
              -1502
            ],
            [
              1109,
              -1505
            ],
            [
              1106,
              -1512
            ],
            [
              1102,
              -1518
            ],
            [
              1098,
              -1523
            ],
            [
              1098,
              -1523
            ],
            [
              1093,
              -1530
            ],
            [
              1091,
              -1534
            ],
            [
              1092,
              -1549
            ],
            [
              1112,
              -1578
            ],
            [
              1133,
              -1585
            ],
            [
              1147,
              -1585
            ],
            [
              1153,
              -1585
            ],
            [
              1158,
              -1585
            ],
            [
              1158,
              -1584
            ],
            [
              1158,
              -1583
            ],
            [
              1157,
              -1581
            ],
            [
              1156,
              -1572
            ],
            [
              1158,
              -1561
            ],
            [
              1159,
              -1556
            ],
            [
              1159,
              -1554
            ],
            [
              1164,
              -1555
            ],
            [
              1166,
              -1556
            ],
            [
              1168,
              -1557
            ],
            [
              1172,
              -1556
            ],
            [
              1179,
              -1557
            ],
            [
              1209,
              -1557
            ],
            [
              1181,
              -1660
            ],
            [
              1114,
              -1721
            ],
            [
              1070,
              -1800
            ],
            [
              967,
              -1907
            ],
            [
              886,
              -1967
            ],
            [
              822,
              -1979
            ],
            [
              760,
              -2009
            ],
            [
              692,
              -1995
            ],
            [
              636,
              -1997
            ],
            [
              584,
              -2021
            ],
            [
              518,
              -2025
            ],
            [
              486,
              -2048
            ],
            [
              403,
              -1990
            ],
            [
              369,
              -1923
            ],
            [
              392,
              -1912
            ],
            [
              386,
              -1851
            ],
            [
              337,
              -1770
            ],
            [
              310,
              -1692
            ],
            [
              299,
              -1678
            ],
            [
              296,
              -1669
            ],
            [
              292,
              -1661
            ],
            [
              308,
              -1653
            ],
            [
              310,
              -1649
            ],
            [
              308,
              -1646
            ],
            [
              308,
              -1645
            ],
            [
              308,
              -1643
            ],
            [
              308,
              -1642
            ],
            [
              309,
              -1642
            ],
            [
              311,
              -1642
            ],
            [
              315,
              -1631
            ],
            [
              326,
              -1628
            ],
            [
              329,
              -1631
            ],
            [
              330,
              -1632
            ],
            [
              331,
              -1632
            ],
            [
              332,
              -1633
            ],
            [
              332,
              -1639
            ],
            [
              340,
              -1640
            ],
            [
              355,
              -1672
            ],
            [
              386,
              -1681
            ],
            [
              399,
              -1681
            ],
            [
              400,
              -1680
            ],
            [
              401,
              -1680
            ],
            [
              401,
              -1680
            ],
            [
              404,
              -1680
            ],
            [
              408,
              -1679
            ],
            [
              418,
              -1677
            ],
            [
              437,
              -1685
            ],
            [
              488,
              -1650
            ],
            [
              488,
              -1432
            ],
            [
              509,
              -1448
            ],
            [
              510,
              -1449
            ],
            [
              511,
              -1453
            ],
            [
              513,
              -1455
            ],
            [
              513,
              -1456
            ],
            [
              516,
              -1460
            ],
            [
              516,
              -1461
            ],
            [
              518,
              -1464
            ],
            [
              517,
              -1464
            ],
            [
              517,
              -1465
            ],
            [
              519,
              -1469
            ],
            [
              521,
              -1470
            ],
            [
              523,
              -1474
            ],
            [
              525,
              -1473
            ],
            [
              525,
              -1474
            ],
            [
              524,
              -1475
            ],
            [
              526,
              -1480
            ],
            [
              526,
              -1481
            ],
            [
              525,
              -1483
            ],
            [
              524,
              -1484
            ],
            [
              526,
              -1484
            ],
            [
              526,
              -1484
            ],
            [
              526,
              -1485
            ],
            [
              526,
              -1488
            ],
            [
              528,
              -1488
            ],
            [
              528,
              -1489
            ],
            [
              530,
              -1494
            ],
            [
              529,
              -1495
            ],
            [
              531,
              -1495
            ],
            [
              531,
              -1496
            ],
            [
              532,
              -1496
            ],
            [
              533,
              -1499
            ],
            [
              536,
              -1514
            ],
            [
              535,
              -1515
            ],
            [
              533,
              -1518
            ],
            [
              531,
              -1522
            ],
            [
              523,
              -1531
            ],
            [
              523,
              -1532
            ],
            [
              522,
              -1535
            ],
            [
              522,
              -1537
            ],
            [
              522,
              -1539
            ],
            [
              523,
              -1547
            ],
            [
              523,
              -1549
            ],
            [
              523,
              -1551
            ],
            [
              524,
              -1553
            ],
            [
              524,
              -1555
            ],
            [
              527,
              -1559
            ],
            [
              529,
              -1558
            ],
            [
              532,
              -1557
            ],
            [
              536,
              -1554
            ],
            [
              551,
              -1558
            ],
            [
              583,
              -1557
            ],
            [
              641,
              -1505
            ],
            [
              657,
              -1465
            ],
            [
              757,
              -1496
            ],
            [
              801,
              -1483
            ],
            [
              817,
              -1431
            ],
            [
              840,
              -1424
            ],
            [
              842,
              -1424
            ],
            [
              847,
              -1424
            ],
            [
              872,
              -1401
            ],
            [
              873,
              -1396
            ],
            [
              873,
              -1394
            ],
            [
              880,
              -1366
            ],
            [
              914,
              -1340
            ],
            [
              916,
              -1341
            ],
            [
              917,
              -1341
            ],
            [
              919,
              -1339
            ],
            [
              930,
              -1332
            ],
            [
              932,
              -1325
            ],
            [
              955,
              -1303
            ],
            [
              994,
              -1282
            ],
            [
              1004,
              -1279
            ],
            [
              1006,
              -1279
            ],
            [
              1010,
              -1280
            ],
            [
              1013,
              -1280
            ],
            [
              1017,
              -1278
            ],
            [
              1022,
              -1279
            ],
            [
              1022,
              -1278
            ],
            [
              1023,
              -1277
            ],
            [
              1024,
              -1277
            ],
            [
              1026,
              -1277
            ],
            [
              1028,
              -1276
            ],
            [
              1029,
              -1276
            ],
            [
              1034,
              -1276
            ],
            [
              1065,
              -1288
            ],
            [
              1066,
              -1289
            ],
            [
              1071,
              -1288
            ],
            [
              1076,
              -1287
            ],
            [
              1083,
              -1288
            ],
            [
              1095,
              -1285
            ],
            [
              1120,
              -1292
            ]
          ],
          [
            [
              955,
              -1668
            ],
            [
              954,
              -1669
            ],
            [
              953,
              -1669
            ],
            [
              951,
              -1669
            ],
            [
              949,
              -1669
            ],
            [
              945,
              -1669
            ],
            [
              937,
              -1678
            ],
            [
              931,
              -1679
            ],
            [
              930,
              -1681
            ],
            [
              928,
              -1682
            ],
            [
              922,
              -1682
            ],
            [
              894,
              -1721
            ],
            [
              900,
              -1767
            ],
            [
              903,
              -1768
            ],
            [
              904,
              -1767
            ],
            [
              905,
              -1767
            ],
            [
              906,
              -1768
            ],
            [
              922,
              -1785
            ],
            [
              939,
              -1788
            ],
            [
              956,
              -1758
            ],
            [
              972,
              -1756
            ],
            [
              977,
              -1754
            ],
            [
              980,
              -1754
            ],
            [
              984,
              -1752
            ],
            [
              997,
              -1744
            ],
            [
              1000,
              -1743
            ],
            [
              1000,
              -1743
            ],
            [
              1000,
              -1742
            ],
            [
              999,
              -1741
            ],
            [
              999,
              -1740
            ],
            [
              999,
              -1740
            ],
            [
              999,
              -1739
            ],
            [
              998,
              -1738
            ],
            [
              998,
              -1736
            ],
            [
              998,
              -1733
            ],
            [
              1001,
              -1728
            ],
            [
              1016,
              -1708
            ],
            [
              1012,
              -1698
            ],
            [
              1010,
              -1697
            ],
            [
              1010,
              -1696
            ],
            [
              1009,
              -1693
            ],
            [
              1008,
              -1692
            ],
            [
              1005,
              -1692
            ],
            [
              1003,
              -1691
            ],
            [
              999,
              -1687
            ],
            [
              991,
              -1682
            ],
            [
              988,
              -1679
            ],
            [
              985,
              -1675
            ],
            [
              973,
              -1663
            ],
            [
              972,
              -1662
            ],
            [
              971,
              -1661
            ],
            [
              971,
              -1661
            ],
            [
              958,
              -1664
            ],
            [
              957,
              -1664
            ],
            [
              956,
              -1665
            ],
            [
              955,
              -1668
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Africa",
        "fullname": "South Africa",
        "admin": "South Africa",
        "woe_id": 23424942,
        "id": "ZA",
        "abbrev": "S.Af.",
        "subregion": "Southern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "ZAF",
        "name": "ZA",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1070,
              -900
            ],
            [
              1066,
              -901
            ],
            [
              1059,
              -901
            ],
            [
              1048,
              -900
            ],
            [
              1039,
              -898
            ],
            [
              1002,
              -910
            ],
            [
              982,
              -947
            ],
            [
              923,
              -978
            ],
            [
              864,
              -1039
            ],
            [
              783,
              -1024
            ],
            [
              725,
              -1006
            ],
            [
              678,
              -1015
            ],
            [
              678,
              -1013
            ],
            [
              676,
              -1012
            ],
            [
              675,
              -1010
            ],
            [
              674,
              -1009
            ],
            [
              673,
              -1009
            ],
            [
              673,
              -1009
            ],
            [
              668,
              -1009
            ],
            [
              660,
              -1001
            ],
            [
              659,
              -997
            ],
            [
              656,
              -995
            ],
            [
              655,
              -994
            ],
            [
              653,
              -994
            ],
            [
              649,
              -992
            ],
            [
              643,
              -987
            ],
            [
              609,
              -955
            ],
            [
              600,
              -930
            ],
            [
              599,
              -921
            ],
            [
              599,
              -900
            ],
            [
              599,
              -895
            ],
            [
              599,
              -887
            ],
            [
              599,
              -749
            ],
            [
              712,
              -749
            ],
            [
              705,
              -737
            ],
            [
              710,
              -671
            ],
            [
              713,
              -665
            ],
            [
              713,
              -662
            ],
            [
              715,
              -659
            ],
            [
              716,
              -659
            ],
            [
              716,
              -658
            ],
            [
              713,
              -651
            ],
            [
              712,
              -635
            ],
            [
              711,
              -631
            ],
            [
              710,
              -628
            ],
            [
              712,
              -628
            ],
            [
              715,
              -629
            ],
            [
              719,
              -631
            ],
            [
              719,
              -638
            ],
            [
              729,
              -638
            ],
            [
              734,
              -642
            ],
            [
              733,
              -644
            ],
            [
              734,
              -647
            ],
            [
              734,
              -648
            ],
            [
              735,
              -649
            ],
            [
              734,
              -650
            ],
            [
              734,
              -651
            ],
            [
              731,
              -656
            ],
            [
              729,
              -657
            ],
            [
              728,
              -657
            ],
            [
              729,
              -658
            ],
            [
              730,
              -659
            ],
            [
              737,
              -662
            ],
            [
              744,
              -661
            ],
            [
              750,
              -655
            ],
            [
              788,
              -672
            ],
            [
              824,
              -687
            ],
            [
              860,
              -691
            ],
            [
              893,
              -681
            ],
            [
              916,
              -709
            ],
            [
              960,
              -722
            ],
            [
              1001,
              -774
            ],
            [
              1002,
              -774
            ],
            [
              1003,
              -774
            ],
            [
              1004,
              -772
            ],
            [
              1009,
              -769
            ],
            [
              1017,
              -766
            ],
            [
              1024,
              -762
            ],
            [
              1037,
              -701
            ],
            [
              994,
              -714
            ],
            [
              964,
              -684
            ],
            [
              960,
              -655
            ],
            [
              971,
              -608
            ],
            [
              971,
              -596
            ],
            [
              968,
              -591
            ],
            [
              968,
              -590
            ],
            [
              970,
              -589
            ],
            [
              971,
              -587
            ],
            [
              971,
              -576
            ],
            [
              973,
              -569
            ],
            [
              974,
              -568
            ],
            [
              975,
              -567
            ],
            [
              975,
              -567
            ],
            [
              973,
              -566
            ],
            [
              972,
              -564
            ],
            [
              971,
              -559
            ],
            [
              965,
              -544
            ],
            [
              957,
              -540
            ],
            [
              956,
              -538
            ],
            [
              957,
              -536
            ],
            [
              969,
              -528
            ],
            [
              979,
              -519
            ],
            [
              987,
              -493
            ],
            [
              1090,
              -477
            ],
            [
              1101,
              -497
            ],
            [
              1211,
              -546
            ],
            [
              1253,
              -612
            ],
            [
              1228,
              -659
            ],
            [
              1232,
              -670
            ],
            [
              1233,
              -686
            ],
            [
              1232,
              -691
            ],
            [
              1230,
              -696
            ],
            [
              1234,
              -709
            ],
            [
              1244,
              -713
            ],
            [
              1236,
              -722
            ],
            [
              1230,
              -722
            ],
            [
              1229,
              -723
            ],
            [
              1228,
              -725
            ],
            [
              1227,
              -726
            ],
            [
              1227,
              -726
            ],
            [
              1226,
              -726
            ],
            [
              1226,
              -726
            ],
            [
              1226,
              -726
            ],
            [
              1225,
              -727
            ],
            [
              1224,
              -727
            ],
            [
              1224,
              -726
            ],
            [
              1223,
              -725
            ],
            [
              1222,
              -725
            ],
            [
              1221,
              -726
            ],
            [
              1221,
              -726
            ],
            [
              1220,
              -726
            ],
            [
              1220,
              -727
            ],
            [
              1220,
              -726
            ],
            [
              1219,
              -726
            ],
            [
              1219,
              -725
            ],
            [
              1219,
              -725
            ],
            [
              1218,
              -726
            ],
            [
              1217,
              -727
            ],
            [
              1200,
              -782
            ],
            [
              1208,
              -795
            ],
            [
              1214,
              -803
            ],
            [
              1214,
              -803
            ],
            [
              1214,
              -806
            ],
            [
              1218,
              -806
            ],
            [
              1222,
              -804
            ],
            [
              1223,
              -802
            ],
            [
              1223,
              -802
            ],
            [
              1224,
              -802
            ],
            [
              1227,
              -807
            ],
            [
              1223,
              -808
            ],
            [
              1218,
              -810
            ],
            [
              1211,
              -812
            ],
            [
              1201,
              -816
            ],
            [
              1172,
              -825
            ],
            [
              1140,
              -836
            ],
            [
              1127,
              -842
            ],
            [
              1121,
              -844
            ],
            [
              1114,
              -845
            ],
            [
              1108,
              -847
            ],
            [
              1093,
              -850
            ],
            [
              1060,
              -862
            ],
            [
              1060,
              -862
            ],
            [
              1060,
              -862
            ],
            [
              1060,
              -864
            ],
            [
              1060,
              -867
            ],
            [
              1060,
              -869
            ],
            [
              1062,
              -876
            ],
            [
              1064,
              -879
            ],
            [
              1068,
              -885
            ],
            [
              1070,
              -900
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Zambia",
        "admin": "Zambia",
        "woe_id": 23425003,
        "id": "ZM",
        "abbrev": "Zambia",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "ZMB",
        "name": "ZM",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1070,
              -900
            ],
            [
              1070,
              -910
            ],
            [
              1070,
              -917
            ],
            [
              1070,
              -921
            ],
            [
              1077,
              -920
            ],
            [
              1081,
              -920
            ],
            [
              1096,
              -920
            ],
            [
              1102,
              -924
            ],
            [
              1118,
              -922
            ],
            [
              1142,
              -932
            ],
            [
              1148,
              -938
            ],
            [
              1152,
              -940
            ],
            [
              1153,
              -942
            ],
            [
              1153,
              -944
            ],
            [
              1155,
              -945
            ],
            [
              1160,
              -946
            ],
            [
              1171,
              -946
            ],
            [
              1176,
              -946
            ],
            [
              1182,
              -949
            ],
            [
              1190,
              -952
            ],
            [
              1197,
              -955
            ],
            [
              1199,
              -960
            ],
            [
              1209,
              -961
            ],
            [
              1212,
              -960
            ],
            [
              1213,
              -960
            ],
            [
              1214,
              -960
            ],
            [
              1213,
              -961
            ],
            [
              1212,
              -967
            ],
            [
              1206,
              -974
            ],
            [
              1213,
              -988
            ],
            [
              1214,
              -991
            ],
            [
              1214,
              -995
            ],
            [
              1217,
              -999
            ],
            [
              1215,
              -1063
            ],
            [
              1203,
              -1115
            ],
            [
              1217,
              -1139
            ],
            [
              1209,
              -1158
            ],
            [
              1208,
              -1163
            ],
            [
              1208,
              -1165
            ],
            [
              1208,
              -1167
            ],
            [
              1207,
              -1168
            ],
            [
              1207,
              -1168
            ],
            [
              1204,
              -1171
            ],
            [
              1201,
              -1176
            ],
            [
              1199,
              -1179
            ],
            [
              1197,
              -1183
            ],
            [
              1196,
              -1184
            ],
            [
              1186,
              -1192
            ],
            [
              1187,
              -1204
            ],
            [
              1179,
              -1218
            ],
            [
              1184,
              -1228
            ],
            [
              1184,
              -1228
            ],
            [
              1185,
              -1228
            ],
            [
              1182,
              -1227
            ],
            [
              1128,
              -1286
            ],
            [
              1120,
              -1292
            ],
            [
              1095,
              -1285
            ],
            [
              1083,
              -1288
            ],
            [
              1076,
              -1287
            ],
            [
              1071,
              -1288
            ],
            [
              1066,
              -1289
            ],
            [
              1065,
              -1288
            ],
            [
              1034,
              -1276
            ],
            [
              1029,
              -1276
            ],
            [
              1028,
              -1276
            ],
            [
              1026,
              -1277
            ],
            [
              1024,
              -1277
            ],
            [
              1023,
              -1277
            ],
            [
              1017,
              -1278
            ],
            [
              1011,
              -1280
            ],
            [
              1005,
              -1273
            ],
            [
              993,
              -1268
            ],
            [
              994,
              -1257
            ],
            [
              938,
              -1244
            ],
            [
              919,
              -1181
            ],
            [
              831,
              -1123
            ],
            [
              783,
              -1024
            ],
            [
              864,
              -1039
            ],
            [
              923,
              -978
            ],
            [
              982,
              -947
            ],
            [
              1002,
              -910
            ],
            [
              1039,
              -898
            ],
            [
              1048,
              -900
            ],
            [
              1059,
              -901
            ],
            [
              1066,
              -901
            ],
            [
              1070,
              -900
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 3,
        "region_un": "Africa",
        "fullname": "Zimbabwe",
        "admin": "Zimbabwe",
        "woe_id": 23425004,
        "id": "ZW",
        "abbrev": "Zimb.",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "iso_a3": "ZWE",
        "name": "ZW",
        "continent": "Africa"
      }
    },
    {
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                7357,
                3351
              ],
              [
                7384,
                3231
              ],
              [
                7375,
                3169
              ],
              [
                7434,
                2998
              ],
              [
                7375,
                2977
              ],
              [
                7340,
                2889
              ],
              [
                7385,
                2786
              ],
              [
                7300,
                2789
              ],
              [
                7320,
                2885
              ],
              [
                7305,
                2931
              ],
              [
                7319,
                3008
              ],
              [
                7312,
                3070
              ],
              [
                7325,
                3110
              ],
              [
                7289,
                3170
              ],
              [
                7305,
                3259
              ],
              [
                7354,
                3307
              ],
              [
                7357,
                3351
              ]
            ]
          ],
          [
            [
              [
                643,
                3357
              ],
              [
                471,
                3363
              ],
              [
                491,
                3402
              ],
              [
                540,
                3428
              ],
              [
                544,
                3428
              ],
              [
                559,
                3426
              ],
              [
                646,
                3398
              ],
              [
                643,
                3357
              ]
            ]
          ],
          [
            [
              [
                2181,
                4634
              ],
              [
                2150,
                4604
              ],
              [
                2087,
                4589
              ],
              [
                2071,
                4642
              ],
              [
                2125,
                4665
              ],
              [
                2181,
                4634
              ]
            ]
          ],
          [
            [
              [
                2462,
                5057
              ],
              [
                2526,
                5045
              ],
              [
                2476,
                4971
              ],
              [
                2466,
                4915
              ],
              [
                2507,
                4840
              ],
              [
                2561,
                4800
              ],
              [
                2455,
                4769
              ],
              [
                2390,
                4789
              ],
              [
                2354,
                4848
              ],
              [
                2310,
                4872
              ],
              [
                2270,
                4860
              ],
              [
                2246,
                4910
              ],
              [
                2315,
                4948
              ],
              [
                2342,
                5037
              ],
              [
                2442,
                5066
              ],
              [
                2462,
                5057
              ]
            ]
          ],
          [
            [
              [
                7325,
                5114
              ],
              [
                7388,
                5079
              ],
              [
                7375,
                5040
              ],
              [
                7282,
                5053
              ],
              [
                7261,
                5115
              ],
              [
                7325,
                5114
              ]
            ]
          ],
          [
            [
              [
                7574,
                5292
              ],
              [
                7668,
                5285
              ],
              [
                7655,
                5216
              ],
              [
                7545,
                5257
              ],
              [
                7574,
                5292
              ]
            ]
          ],
          [
            [
              [
                7148,
                5361
              ],
              [
                7246,
                5321
              ],
              [
                7274,
                5371
              ],
              [
                7340,
                5335
              ],
              [
                7398,
                5335
              ],
              [
                7467,
                5310
              ],
              [
                7460,
                5256
              ],
              [
                7330,
                5218
              ],
              [
                7301,
                5238
              ],
              [
                7143,
                5200
              ],
              [
                7038,
                5253
              ],
              [
                7041,
                5326
              ],
              [
                7094,
                5364
              ],
              [
                7148,
                5361
              ]
            ]
          ],
          [
            [
              [
                3181,
                5461
              ],
              [
                3188,
                5382
              ],
              [
                2852,
                5287
              ],
              [
                2772,
                5255
              ],
              [
                2756,
                5223
              ],
              [
                2622,
                5144
              ],
              [
                2610,
                5107
              ],
              [
                2542,
                5047
              ],
              [
                2470,
                5056
              ],
              [
                2447,
                5068
              ],
              [
                2416,
                5063
              ],
              [
                2376,
                5098
              ],
              [
                2504,
                5179
              ],
              [
                2560,
                5282
              ],
              [
                2655,
                5331
              ],
              [
                2711,
                5342
              ],
              [
                2785,
                5382
              ],
              [
                2951,
                5386
              ],
              [
                3039,
                5417
              ],
              [
                3113,
                5459
              ],
              [
                3181,
                5461
              ]
            ]
          ],
          [
            [
              [
                5125,
                5739
              ],
              [
                5184,
                5721
              ],
              [
                5229,
                5624
              ],
              [
                5029,
                5607
              ],
              [
                4934,
                5577
              ],
              [
                4984,
                5675
              ],
              [
                5048,
                5747
              ],
              [
                5125,
                5739
              ]
            ]
          ],
          [
            [
              [
                4837,
                5846
              ],
              [
                4845,
                5817
              ],
              [
                4917,
                5836
              ],
              [
                4953,
                5798
              ],
              [
                4928,
                5683
              ],
              [
                4818,
                5682
              ],
              [
                4782,
                5703
              ],
              [
                4683,
                5709
              ],
              [
                4617,
                5793
              ],
              [
                4681,
                5842
              ],
              [
                4837,
                5846
              ]
            ]
          ],
          [
            [
              [
                2851,
                5931
              ],
              [
                2783,
                5881
              ],
              [
                2705,
                5884
              ],
              [
                2708,
                5936
              ],
              [
                2851,
                5931
              ]
            ]
          ],
          [
            [
              [
                4736,
                5984
              ],
              [
                4792,
                5945
              ],
              [
                4804,
                5859
              ],
              [
                4656,
                5846
              ],
              [
                4609,
                5829
              ],
              [
                4542,
                5849
              ],
              [
                4555,
                5940
              ],
              [
                4688,
                5993
              ],
              [
                4736,
                5984
              ]
            ]
          ],
          [
            [
              [
                9505,
                4872
              ],
              [
                9550,
                4818
              ],
              [
                9434,
                4813
              ],
              [
                9369,
                4794
              ],
              [
                9373,
                4838
              ],
              [
                9449,
                4873
              ],
              [
                9505,
                4872
              ]
            ]
          ],
          [
            [
              [
                6678,
                2494
              ],
              [
                6668,
                2510
              ],
              [
                6710,
                2567
              ],
              [
                6697,
                2672
              ],
              [
                6740,
                2701
              ],
              [
                6804,
                2680
              ],
              [
                6857,
                2765
              ],
              [
                6871,
                2835
              ],
              [
                6906,
                2867
              ],
              [
                6884,
                2915
              ],
              [
                6812,
                2895
              ],
              [
                6780,
                2867
              ],
              [
                6696,
                2865
              ],
              [
                6669,
                2933
              ],
              [
                6623,
                2979
              ],
              [
                6564,
                3003
              ],
              [
                6532,
                2998
              ],
              [
                6467,
                3109
              ],
              [
                6434,
                3208
              ],
              [
                6394,
                3258
              ],
              [
                6344,
                3265
              ],
              [
                6282,
                3296
              ],
              [
                6128,
                3275
              ],
              [
                6095,
                3244
              ],
              [
                6123,
                3187
              ],
              [
                6086,
                3154
              ],
              [
                6040,
                3075
              ],
              [
                6041,
                3038
              ],
              [
                5954,
                2996
              ],
              [
                5894,
                3019
              ],
              [
                5821,
                3024
              ],
              [
                5760,
                3052
              ],
              [
                5755,
                3050
              ],
              [
                5751,
                3048
              ],
              [
                5743,
                3043
              ],
              [
                5736,
                3037
              ],
              [
                5721,
                3033
              ],
              [
                5700,
                3019
              ],
              [
                5693,
                3008
              ],
              [
                5692,
                3004
              ],
              [
                5692,
                3002
              ],
              [
                5691,
                3002
              ],
              [
                5688,
                3002
              ],
              [
                5685,
                3001
              ],
              [
                5684,
                3000
              ],
              [
                5681,
                2998
              ],
              [
                5673,
                2995
              ],
              [
                5656,
                2997
              ],
              [
                5634,
                2989
              ],
              [
                5595,
                2986
              ],
              [
                5574,
                2974
              ],
              [
                5568,
                2971
              ],
              [
                5561,
                2969
              ],
              [
                5439,
                2983
              ],
              [
                5411,
                3002
              ],
              [
                5406,
                3013
              ],
              [
                5405,
                3021
              ],
              [
                5406,
                3024
              ],
              [
                5406,
                3026
              ],
              [
                5406,
                3027
              ],
              [
                5403,
                3027
              ],
              [
                5400,
                3028
              ],
              [
                5400,
                3028
              ],
              [
                5398,
                3027
              ],
              [
                5397,
                3028
              ],
              [
                5394,
                3030
              ],
              [
                5373,
                3030
              ],
              [
                5334,
                3055
              ],
              [
                5259,
                3066
              ],
              [
                5166,
                3041
              ],
              [
                5092,
                3071
              ],
              [
                5076,
                3133
              ],
              [
                4962,
                3159
              ],
              [
                4899,
                3188
              ],
              [
                4889,
                3167
              ],
              [
                4884,
                3162
              ],
              [
                4873,
                3160
              ],
              [
                4868,
                3158
              ],
              [
                4852,
                3139
              ],
              [
                4845,
                3127
              ],
              [
                4839,
                3105
              ],
              [
                4839,
                3103
              ],
              [
                4840,
                3100
              ],
              [
                4842,
                3099
              ],
              [
                4845,
                3097
              ],
              [
                4847,
                3095
              ],
              [
                4849,
                3093
              ],
              [
                4849,
                3093
              ],
              [
                4847,
                3090
              ],
              [
                4846,
                3088
              ],
              [
                4848,
                3084
              ],
              [
                4851,
                3078
              ],
              [
                4866,
                3069
              ],
              [
                4864,
                3052
              ],
              [
                4855,
                3036
              ],
              [
                4852,
                3033
              ],
              [
                4844,
                3030
              ],
              [
                4840,
                3026
              ],
              [
                4825,
                3026
              ],
              [
                4811,
                3012
              ],
              [
                4730,
                3033
              ],
              [
                4661,
                3033
              ],
              [
                4639,
                3073
              ],
              [
                4550,
                3081
              ],
              [
                4539,
                3089
              ],
              [
                4534,
                3095
              ],
              [
                4533,
                3095
              ],
              [
                4532,
                3094
              ],
              [
                4531,
                3093
              ],
              [
                4481,
                3065
              ],
              [
                4459,
                3063
              ],
              [
                4441,
                3047
              ],
              [
                4429,
                3047
              ],
              [
                4405,
                3038
              ],
              [
                4401,
                3029
              ],
              [
                4395,
                3027
              ],
              [
                4387,
                3027
              ],
              [
                4384,
                3026
              ],
              [
                4381,
                3024
              ],
              [
                4381,
                3024
              ],
              [
                4381,
                3021
              ],
              [
                4381,
                3020
              ],
              [
                4381,
                3020
              ],
              [
                4382,
                3019
              ],
              [
                4382,
                3015
              ],
              [
                4385,
                3010
              ],
              [
                4381,
                3009
              ],
              [
                4375,
                3007
              ],
              [
                4373,
                3006
              ],
              [
                4371,
                3006
              ],
              [
                4370,
                3004
              ],
              [
                4369,
                3002
              ],
              [
                4367,
                3001
              ],
              [
                4360,
                3005
              ],
              [
                4339,
                2991
              ],
              [
                4331,
                2991
              ],
              [
                4329,
                2991
              ],
              [
                4326,
                2995
              ],
              [
                4321,
                2993
              ],
              [
                4312,
                2994
              ],
              [
                4302,
                2992
              ],
              [
                4300,
                2991
              ],
              [
                4300,
                2990
              ],
              [
                4297,
                2985
              ],
              [
                4299,
                2979
              ],
              [
                4292,
                2975
              ],
              [
                4289,
                2972
              ],
              [
                4288,
                2971
              ],
              [
                4280,
                2971
              ],
              [
                4253,
                2965
              ],
              [
                4213,
                3017
              ],
              [
                4175,
                2995
              ],
              [
                4137,
                3001
              ],
              [
                4121,
                3037
              ],
              [
                4080,
                3052
              ],
              [
                4035,
                3104
              ],
              [
                3959,
                3084
              ],
              [
                3881,
                3127
              ],
              [
                3843,
                3089
              ],
              [
                3724,
                3274
              ],
              [
                3649,
                3327
              ],
              [
                3599,
                3337
              ],
              [
                3520,
                3299
              ],
              [
                3449,
                3339
              ],
              [
                3350,
                3337
              ],
              [
                3339,
                3412
              ],
              [
                3235,
                3437
              ],
              [
                3182,
                3403
              ],
              [
                2897,
                3336
              ],
              [
                2808,
                3330
              ],
              [
                2783,
                3204
              ],
              [
                2754,
                3150
              ],
              [
                2807,
                3136
              ],
              [
                2802,
                3089
              ],
              [
                2712,
                3071
              ],
              [
                2665,
                3081
              ],
              [
                2630,
                3116
              ],
              [
                2530,
                3110
              ],
              [
                2482,
                3070
              ],
              [
                2368,
                3133
              ],
              [
                2258,
                3138
              ],
              [
                2208,
                3157
              ],
              [
                2184,
                3127
              ],
              [
                2101,
                3075
              ],
              [
                2077,
                3018
              ],
              [
                2029,
                3065
              ],
              [
                1993,
                3019
              ],
              [
                1984,
                2953
              ],
              [
                2008,
                2871
              ],
              [
                2057,
                2871
              ],
              [
                2083,
                2846
              ],
              [
                2123,
                2770
              ],
              [
                2096,
                2742
              ],
              [
                2030,
                2718
              ],
              [
                1981,
                2640
              ],
              [
                2026,
                2594
              ],
              [
                2024,
                2543
              ],
              [
                2086,
                2464
              ],
              [
                2047,
                2421
              ],
              [
                2013,
                2428
              ],
              [
                1966,
                2467
              ],
              [
                1879,
                2525
              ],
              [
                1819,
                2524
              ],
              [
                1761,
                2553
              ],
              [
                1694,
                2557
              ],
              [
                1643,
                2578
              ],
              [
                1606,
                2568
              ],
              [
                1550,
                2619
              ],
              [
                1466,
                2656
              ],
              [
                1448,
                2700
              ],
              [
                1512,
                2762
              ],
              [
                1507,
                2824
              ],
              [
                1544,
                2878
              ],
              [
                1593,
                2876
              ],
              [
                1590,
                2941
              ],
              [
                1616,
                2983
              ],
              [
                1561,
                3021
              ],
              [
                1463,
                3063
              ],
              [
                1421,
                3051
              ],
              [
                1366,
                3057
              ],
              [
                1333,
                3118
              ],
              [
                1294,
                3123
              ],
              [
                1260,
                3205
              ],
              [
                1189,
                3202
              ],
              [
                1146,
                3186
              ],
              [
                1146,
                3188
              ],
              [
                1146,
                3190
              ],
              [
                1146,
                3191
              ],
              [
                1143,
                3193
              ],
              [
                1142,
                3198
              ],
              [
                1138,
                3207
              ],
              [
                1127,
                3269
              ],
              [
                1164,
                3260
              ],
              [
                1182,
                3268
              ],
              [
                1200,
                3287
              ],
              [
                1199,
                3289
              ],
              [
                1196,
                3288
              ],
              [
                1195,
                3291
              ],
              [
                1185,
                3295
              ],
              [
                1180,
                3308
              ],
              [
                1176,
                3311
              ],
              [
                1169,
                3313
              ],
              [
                1164,
                3315
              ],
              [
                1152,
                3313
              ],
              [
                1146,
                3316
              ],
              [
                1149,
                3321
              ],
              [
                1151,
                3329
              ],
              [
                1145,
                3336
              ],
              [
                1141,
                3337
              ],
              [
                1136,
                3339
              ],
              [
                1133,
                3340
              ],
              [
                1132,
                3341
              ],
              [
                1122,
                3347
              ],
              [
                1121,
                3348
              ],
              [
                1120,
                3350
              ],
              [
                1120,
                3355
              ],
              [
                1120,
                3356
              ],
              [
                1118,
                3358
              ],
              [
                1116,
                3362
              ],
              [
                1115,
                3364
              ],
              [
                1114,
                3364
              ],
              [
                1113,
                3365
              ],
              [
                1107,
                3367
              ],
              [
                1110,
                3381
              ],
              [
                1099,
                3438
              ],
              [
                1074,
                3468
              ],
              [
                1013,
                3481
              ],
              [
                971,
                3491
              ],
              [
                958,
                3491
              ],
              [
                955,
                3488
              ],
              [
                953,
                3488
              ],
              [
                952,
                3488
              ],
              [
                951,
                3489
              ],
              [
                949,
                3491
              ],
              [
                945,
                3494
              ],
              [
                945,
                3497
              ],
              [
                948,
                3506
              ],
              [
                943,
                3527
              ],
              [
                916,
                3554
              ],
              [
                926,
                3576
              ],
              [
                927,
                3580
              ],
              [
                927,
                3587
              ],
              [
                916,
                3594
              ],
              [
                910,
                3597
              ],
              [
                909,
                3598
              ],
              [
                909,
                3599
              ],
              [
                909,
                3601
              ],
              [
                910,
                3606
              ],
              [
                900,
                3605
              ],
              [
                918,
                3636
              ],
              [
                903,
                3705
              ],
              [
                937,
                3764
              ],
              [
                993,
                3824
              ],
              [
                968,
                3864
              ],
              [
                925,
                3853
              ],
              [
                1003,
                3912
              ],
              [
                1116,
                4019
              ],
              [
                1114,
                4082
              ],
              [
                1035,
                4222
              ],
              [
                1032,
                4281
              ],
              [
                1052,
                4315
              ],
              [
                997,
                4409
              ],
              [
                1043,
                4472
              ],
              [
                962,
                4571
              ],
              [
                989,
                4618
              ],
              [
                1095,
                4694
              ],
              [
                1129,
                4683
              ],
              [
                1163,
                4710
              ],
              [
                1250,
                4647
              ],
              [
                1381,
                4632
              ],
              [
                1664,
                4490
              ],
              [
                1673,
                4406
              ],
              [
                1632,
                4369
              ],
              [
                1570,
                4344
              ],
              [
                1491,
                4339
              ],
              [
                1242,
                4399
              ],
              [
                1250,
                4360
              ],
              [
                1312,
                4330
              ],
              [
                1298,
                4266
              ],
              [
                1328,
                4188
              ],
              [
                1464,
                4132
              ],
              [
                1440,
                4256
              ],
              [
                1568,
                4208
              ],
              [
                1628,
                4233
              ],
              [
                1597,
                4295
              ],
              [
                1682,
                4337
              ],
              [
                1730,
                4380
              ],
              [
                1789,
                4372
              ],
              [
                1833,
                4339
              ],
              [
                1857,
                4420
              ],
              [
                1819,
                4456
              ],
              [
                1844,
                4543
              ],
              [
                1903,
                4571
              ],
              [
                1939,
                4561
              ],
              [
                1979,
                4500
              ],
              [
                1906,
                4493
              ],
              [
                1884,
                4454
              ],
              [
                1934,
                4414
              ],
              [
                2039,
                4424
              ],
              [
                2045,
                4481
              ],
              [
                2084,
                4487
              ],
              [
                2219,
                4551
              ],
              [
                2358,
                4607
              ],
              [
                2403,
                4539
              ],
              [
                2499,
                4581
              ],
              [
                2576,
                4573
              ],
              [
                2662,
                4615
              ],
              [
                2675,
                4557
              ],
              [
                2751,
                4588
              ],
              [
                2738,
                4680
              ],
              [
                2777,
                4700
              ],
              [
                2916,
                4681
              ],
              [
                3045,
                4623
              ],
              [
                3116,
                4603
              ],
              [
                3186,
                4553
              ],
              [
                3224,
                4580
              ],
              [
                3178,
                4667
              ],
              [
                3104,
                4688
              ],
              [
                3135,
                4785
              ],
              [
                3112,
                4844
              ],
              [
                3199,
                4899
              ],
              [
                3228,
                4990
              ],
              [
                3278,
                5008
              ],
              [
                3372,
                5012
              ],
              [
                3424,
                4999
              ],
              [
                3444,
                4944
              ],
              [
                3402,
                4843
              ],
              [
                3442,
                4802
              ],
              [
                3425,
                4712
              ],
              [
                3428,
                4609
              ],
              [
                3482,
                4574
              ],
              [
                3457,
                4492
              ],
              [
                3380,
                4416
              ],
              [
                3424,
                4389
              ],
              [
                3501,
                4425
              ],
              [
                3549,
                4489
              ],
              [
                3533,
                4585
              ],
              [
                3641,
                4614
              ],
              [
                3685,
                4576
              ],
              [
                3714,
                4604
              ],
              [
                3592,
                4641
              ],
              [
                3522,
                4629
              ],
              [
                3483,
                4682
              ],
              [
                3525,
                4765
              ],
              [
                3483,
                4840
              ],
              [
                3481,
                4898
              ],
              [
                3576,
                4893
              ],
              [
                3759,
                4959
              ],
              [
                3809,
                4956
              ],
              [
                3940,
                4885
              ],
              [
                3965,
                4946
              ],
              [
                3883,
                4973
              ],
              [
                3875,
                5083
              ],
              [
                4038,
                5091
              ],
              [
                4222,
                5118
              ],
              [
                4180,
                5157
              ],
              [
                4246,
                5238
              ],
              [
                4359,
                5294
              ],
              [
                4494,
                5311
              ],
              [
                4593,
                5336
              ],
              [
                4608,
                5365
              ],
              [
                4731,
                5366
              ],
              [
                4742,
                5346
              ],
              [
                4840,
                5349
              ],
              [
                4934,
                5404
              ],
              [
                5009,
                5412
              ],
              [
                5015,
                5464
              ],
              [
                5082,
                5512
              ],
              [
                5186,
                5552
              ],
              [
                5260,
                5529
              ],
              [
                5294,
                5465
              ],
              [
                5367,
                5466
              ],
              [
                5406,
                5435
              ],
              [
                5581,
                5438
              ],
              [
                5714,
                5367
              ],
              [
                5722,
                5270
              ],
              [
                5682,
                5237
              ],
              [
                5520,
                5169
              ],
              [
                5412,
                5089
              ],
              [
                5393,
                5039
              ],
              [
                5526,
                5076
              ],
              [
                5522,
                5130
              ],
              [
                5621,
                5100
              ],
              [
                5678,
                5101
              ],
              [
                5716,
                5075
              ],
              [
                5823,
                5097
              ],
              [
                5993,
                5084
              ],
              [
                5994,
                5043
              ],
              [
                6109,
                5008
              ],
              [
                6217,
                5023
              ],
              [
                6255,
                5012
              ],
              [
                6262,
                5081
              ],
              [
                6322,
                5107
              ],
              [
                6392,
                5065
              ],
              [
                6465,
                5078
              ],
              [
                6526,
                5071
              ],
              [
                6561,
                4967
              ],
              [
                6597,
                4965
              ],
              [
                6597,
                4870
              ],
              [
                6622,
                4825
              ],
              [
                6690,
                4789
              ],
              [
                6725,
                4802
              ],
              [
                6791,
                4911
              ],
              [
                6815,
                4873
              ],
              [
                6872,
                4851
              ],
              [
                6973,
                4879
              ],
              [
                7053,
                4850
              ],
              [
                7128,
                4879
              ],
              [
                7182,
                4860
              ],
              [
                7172,
                4968
              ],
              [
                7247,
                4972
              ],
              [
                7284,
                4997
              ],
              [
                7500,
                4974
              ],
              [
                7516,
                4954
              ],
              [
                7668,
                4949
              ],
              [
                7735,
                4932
              ],
              [
                7763,
                4866
              ],
              [
                7848,
                4844
              ],
              [
                7881,
                4802
              ],
              [
                7962,
                4800
              ],
              [
                8089,
                4824
              ],
              [
                8233,
                4811
              ],
              [
                8299,
                4780
              ],
              [
                8299,
                4703
              ],
              [
                8398,
                4654
              ],
              [
                8451,
                4681
              ],
              [
                8541,
                4691
              ],
              [
                8586,
                4671
              ],
              [
                8704,
                4664
              ],
              [
                8745,
                4691
              ],
              [
                8779,
                4639
              ],
              [
                8854,
                4593
              ],
              [
                8925,
                4614
              ],
              [
                8909,
                4725
              ],
              [
                9031,
                4710
              ],
              [
                9063,
                4693
              ],
              [
                9183,
                4701
              ],
              [
                9393,
                4642
              ],
              [
                9422,
                4614
              ],
              [
                9499,
                4591
              ],
              [
                9630,
                4508
              ],
              [
                9698,
                4487
              ],
              [
                9745,
                4380
              ],
              [
                9789,
                4435
              ],
              [
                9844,
                4413
              ],
              [
                9887,
                4421
              ],
              [
                9985,
                4354
              ],
              [
                9963,
                4296
              ],
              [
                9860,
                4279
              ],
              [
                9820,
                4172
              ],
              [
                9689,
                4225
              ],
              [
                9663,
                4277
              ],
              [
                9603,
                4295
              ],
              [
                9516,
                4286
              ],
              [
                9491,
                4346
              ],
              [
                9452,
                4348
              ],
              [
                9453,
                4253
              ],
              [
                9338,
                4182
              ],
              [
                9412,
                4037
              ],
              [
                9375,
                4008
              ],
              [
                9263,
                4036
              ],
              [
                9226,
                4003
              ],
              [
                9126,
                3960
              ],
              [
                9073,
                3955
              ],
              [
                8992,
                3882
              ],
              [
                8920,
                3850
              ],
              [
                8897,
                3804
              ],
              [
                8828,
                3855
              ],
              [
                8769,
                3855
              ],
              [
                8702,
                3832
              ],
              [
                8669,
                3847
              ],
              [
                8585,
                3790
              ],
              [
                8520,
                3812
              ],
              [
                8488,
                3725
              ],
              [
                8450,
                3697
              ],
              [
                8427,
                3651
              ],
              [
                8474,
                3594
              ],
              [
                8496,
                3543
              ],
              [
                8487,
                3485
              ],
              [
                8436,
                3494
              ],
              [
                8415,
                3454
              ],
              [
                8435,
                3389
              ],
              [
                8412,
                3369
              ],
              [
                8357,
                3371
              ],
              [
                8319,
                3343
              ],
              [
                8295,
                3273
              ],
              [
                8239,
                3243
              ],
              [
                8235,
                3201
              ],
              [
                8191,
                3145
              ],
              [
                8133,
                3096
              ],
              [
                8117,
                3195
              ],
              [
                8099,
                3249
              ],
              [
                8067,
                3428
              ],
              [
                8099,
                3550
              ],
              [
                8175,
                3628
              ],
              [
                8261,
                3677
              ],
              [
                8308,
                3736
              ],
              [
                8361,
                3775
              ],
              [
                8424,
                3842
              ],
              [
                8503,
                3874
              ],
              [
                8536,
                3911
              ],
              [
                8543,
                4033
              ],
              [
                8499,
                4021
              ],
              [
                8483,
                3958
              ],
              [
                8337,
                3866
              ],
              [
                8313,
                3886
              ],
              [
                8318,
                3963
              ],
              [
                8178,
                3958
              ],
              [
                8130,
                3935
              ],
              [
                8083,
                3868
              ],
              [
                7993,
                3796
              ],
              [
                7983,
                3729
              ],
              [
                7945,
                3745
              ],
              [
                7910,
                3717
              ],
              [
                7833,
                3713
              ],
              [
                7848,
                3764
              ],
              [
                7734,
                3787
              ],
              [
                7689,
                3744
              ],
              [
                7620,
                3744
              ],
              [
                7558,
                3763
              ],
              [
                7374,
                3754
              ],
              [
                7324,
                3735
              ],
              [
                7248,
                3674
              ],
              [
                7226,
                3631
              ],
              [
                7123,
                3562
              ],
              [
                7094,
                3519
              ],
              [
                6986,
                3434
              ],
              [
                6948,
                3413
              ],
              [
                6960,
                3373
              ],
              [
                7019,
                3380
              ],
              [
                7012,
                3315
              ],
              [
                7081,
                3298
              ],
              [
                7188,
                3346
              ],
              [
                7278,
                3265
              ],
              [
                7271,
                3181
              ],
              [
                7237,
                3129
              ],
              [
                7223,
                3071
              ],
              [
                7229,
                3001
              ],
              [
                7207,
                2920
              ],
              [
                7164,
                2881
              ],
              [
                7116,
                2816
              ],
              [
                7092,
                2763
              ],
              [
                6955,
                2621
              ],
              [
                6926,
                2576
              ],
              [
                6858,
                2534
              ],
              [
                6815,
                2520
              ],
              [
                6737,
                2561
              ],
              [
                6678,
                2494
              ]
            ]
          ]
        ]
      },
      "type": "Feature",
      "properties": {
        "labelrank": 2,
        "region_un": "Europe",
        "fullname": "Russia",
        "admin": "Russia",
        "woe_id": 23424936,
        "id": "RU",
        "abbrev": "Rus.",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "iso_a3": "RUS",
        "name": "RU",
        "continent": "Europe"
      }
    }
  ],
  "ac-tx": {
    "default": {
      "crs": "+proj=mill +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +R_A +datum=WGS84 +units=m +no_defs",
      "scale": 0.00050280868031406,
      "xoffset": "-620.8680523857",
      "yoffset": 0
    }
  }
}

export default worldMap